import { Application } from '../documentaryType'

export const getApplicationUrlByType = {
  [Application.NORMATIVE]: `/normative`,
  [Application.NORMATIVE_DOC]: `/normative`,
  [Application.LABORATORY]: `/laboratorydata`,
  [Application.EXECUTIVE_SCHEME]: `/executivescheme`,
  [Application.EXECUTIVE_DRAWING]: `/executivedrawing`,
  [Application.PERMIT]: `/permits`,
  [Application.WORKING_DRAFT]: `/workingdraft`,
  [Application.CERTIFICATE]: `/certificate`,
  [Application.ADDITIONAL_DOCUMENT]: `/certificate/additional`,
  [Application.SUPPORTING]: `/supporting`,
}
