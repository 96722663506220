/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export const RoleNames = {
  UNDEFINED: 'Роль не известна',
  ADMIN: 'Администратор',
  USER: 'Не трудоустроен',
  WORKER: 'Сотрудник',
  PRORAB: 'Сотрудник с правом создавать договор',
}
