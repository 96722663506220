import {
  ActForm,
  UUID,
  WorkType,
  ApprovalState,
} from '@builddocs/data-management'

import {
  Certificate,
  DocumentInspector,
  DocumentSign,
  ExecutiveDrawing,
  ExecutiveScheme,
  Laboratory,
  Normative,
  Permits,
  Supporting,
  WorkingDraft,
} from '../applications'

export interface ExecutiveDoc {
  id: string
  title: string
  link: string
}

export interface MaterialsRegistry {
  assignees: DocumentSign[]
  base64signature: string
  creationDate: string
  deleted: boolean
  document: string
  documentUrl: string
  externalId: UUID
  fileName: string
  id: UUID
  journalId: UUID
  name: string
  signatureEmbedded: boolean
  approvalStatus: ApprovalState
  workDocSection: string
}

export interface ActRegistry {
  id: UUID
  documentUrl: string
  externalId: UUID
  fileName: string
  document: null
  approvalStatus: ApprovalState
  assignees: DocumentSign[]
  base64signature: string
  creatorId: UUID
  deleted: boolean
  creationDate: string
  name: string
  journalId: UUID
  registryExternalId: UUID
  actId: UUID
  inspectors: DocumentInspector[]
  integrations: Integrations
  signatureEmbedded: boolean
}

export interface ExecutiveSchemeRegistry {
  actId: UUID
  assignees: DocumentSign[]
  base64signature: string
  creationDate: string
  deleted: boolean
  document: string
  documentUrl: string
  externalId: UUID
  fileName: string
  id: UUID
  journalId: UUID
  name: string
  signatureEmbedded: boolean
  approvalStatus: ApprovalState
  workDocSection: string
}

export type Integrations = {
  EXON: {
    actIds: Record<string, string>
    externalIds: string[]
    documentUrl: string
  }
}

export interface ActInAOOK {
  agigs: string[]
  aiios: string[]
  allSectionsIds: string[]
  anmos: string[]
  aooks: string[]
  aptos: string[]
  assignees: any[]
  base64signature: string
  certificateObjList: Certificate[]
  certificates: string[]
  creationDate: string
  currentWork: string
  deletable: boolean
  document: any
  documentUrl: string
  endDate: string
  executiveSchemes: string[]
  executiveSchemesObjList: ExecutiveScheme[]
  externalId: string
  fileName: string
  fullName: string
  id: string
  approvalStatus: ApprovalState
  inspectors: DocumentInspector[]
  employmentId: string
  journalId: string
  laboratories: []
  laboratoriesObjList: Laboratory[]
  materialsRegistry: MaterialsRegistry
  name: string
  nextWork: string
  number: string
  sectionId: string
  signatureEmbedded: boolean
  startDate: string
  workDocSection: string
  workingDrafts: any[]
  workingDraftsObjList: WorkingDraft[]
}

export interface AOSRType {
  actType: 'AOSR'
  versionRemarkExon: number | null
  attachedDocuments: UUID[]
  actExamples: number
  allSectionsIds: UUID[]
  assignees: DocumentSign[]
  assigneesForActRegistry: Record<string, UUID[]> | null
  base64signature: string
  certificateObjList: Certificate[]
  certificates: UUID[]
  closed: boolean
  closingFullName: string
  closingId: UUID
  closingName: string
  closingNumber: string
  creationDate: string
  creatorId: UUID
  currentWork: string
  changedCurrentWork: boolean
  deletable: boolean
  document: string
  documentUrl: string
  endDate: string
  executiveSchemeRegistry: ExecutiveSchemeRegistry
  executiveSchemeRegistryId: UUID
  executiveSchemes: UUID[]
  executiveSchemesObjList: ExecutiveScheme[]
  executiveDrawingsObjList: ExecutiveDrawing[]
  externalId: UUID
  fileName: string
  fullName: string
  id: UUID
  inspectors: DocumentInspector[]
  integrations: Integrations
  journalId: UUID
  laboratories: UUID[]
  laboratoriesObjList: Laboratory[]
  manualFields: any
  materialsRegistry: MaterialsRegistry
  name: string
  nextWork: string
  normatives: UUID[]
  normativesObjList: Normative[]
  number: string
  permits: UUID[]
  permitsObjList: Permits[]
  sectionId: UUID
  signatureEmbedded: boolean
  supportingDocsObjList: Supporting[]
  supportingDocsForWorksObjList: Supporting[]
  approvalStatus: ApprovalState
  startDate: string
  workDocSection: string
  workType: WorkType
  workingDrafts: UUID[]
  workingDraftsObjList: WorkingDraft[]

  actDate: string
  actRegistryId: UUID
  actRegistry: ActRegistry
  includeCertificatesInAttachments: boolean
  shouldCreateActRegistry: boolean
  forceIncludeOrganization: string[]
  documentHistory: DocumentHistoryType
  shouldIncludeSupportingsInProjectDocuments: boolean
}

export type SecondaryActType = {
  actType: Exclude<ActForm, 'AOSR'>
  versionRemarkExon: number | null
  sectionId?: UUID
  shouldCreateActRegistry: boolean
  actDate: string
  attachedDocuments: UUID[]
  acts: string[]
  actsObjList: AOSRType[]
  actRegistryId?: UUID
  actRegistry: ActRegistry
  assignees: DocumentSign[]
  assigneesForActRegistry: Record<string, UUID[]> | null
  base64signature: string
  certificateObjList: Certificate[]
  certificates: string[]
  closed: boolean
  closingId: UUID
  closingName: string
  closingFullName: string
  closingNumber: string
  creationDate: string
  creatorId: UUID
  document: string
  documentUrl: string
  executiveSchemeRegistry: ExecutiveSchemeRegistry
  executiveSchemeRegistryId: UUID
  executiveSchemes: string[]
  executiveSchemesObjList: ExecutiveScheme[]
  executiveDrawingsObjList: ExecutiveDrawing[]
  externalId: UUID
  fileName: string
  fullName: string
  id: UUID
  approvalStatus: ApprovalState
  inspectors: DocumentInspector[]
  integrations: Integrations
  journalId: UUID
  laboratories: string[]
  laboratoriesObjList: Laboratory[]
  manualFields: any
  manualAssignees: Record<string, UUID[]>
  manualAssigneesObjMap: Record<
    UUID,
    {
      position: string
      organizationInn: string
      permitId: UUID
      firstName: string
      lastName: string
      middleName?: string
    }
  >
  manualOrganizations: Record<string, UUID>
  name: string
  normatives: string[]
  normativesObjList: Normative[]
  number: string
  permits: string[]
  permitsObjList: Permits[]
  signatureEmbedded: boolean
  supportingDocsObjList: Supporting[]
  workDocSection: string
  workType: WorkType
  workingDrafts: string[]
  workingDraftsObjList: WorkingDraft[]
  documentHistory: DocumentHistoryType
  shouldShowMaterials: boolean
  shouldShowLaboratories: boolean
  shouldShowExecutiveSchemes: boolean
}

export type DocumentHistoryType = {
  documentEvents: DocumentEvents[]
  documentId: string
}

export type DocumentEvents = {
  dateTime: string
  type: DocumentEventType
  userId: UUID
}

export enum DocumentEventEnum {
  /**
   * Создан
   */
  CREATED = 'Создан',
  /**
   * Обновлён
   */
  UPDATED = 'Обновлён',
  /**
   * Добавлен в закрытия
   */
  ADDED_TO_CLOSING = 'Добавлен в закрытие',
  /**
   * Изменена дата создания акта
   */
  MODIFIED_AOSR_ACT_DATE = 'Изменена дата создания акта',
  /**
   * Удалён из закрытия
   */
  REMOVED_FROM_CLOSING = 'Удалён из закрытия',
  /**
   * Отправлен на согласование
   */
  SENT_ON_APPROVAL = 'Отправлен на согласование',
  /**
   * На согласовании в ГИС
   */
  ON_EXON_APPROVAL = 'На согласовании в ГИС',
  /**
   * Согласован
   */
  APPROVED = 'Согласован',
  /**
   * Отправлен на подписание
   */
  SENT_ON_SIGNING = 'Отправлен на подписание',
  /**
   * Подписан
   */
  SIGNED = 'Подписан',
  /**
   * Отклонён
   */
  CANCELLED = 'Отклонён',
  /**
   * Отправлен на согласование в Экзон
   */
  SENT_ON_EXON_APPROVAL = 'Отправлен на согласование в ГИС',
  /**
   * Отправлен на пересогласование в Экзон
   */
  SENT_ON_EXON_REAPPROVAL = 'Отправлен на пересогласование в ГИС',
  /**
   * Отправлен на подписание в Экзон
   */
  SENT_ON_EXON_SIGNING = 'Отправлен на подписание в ГИС',
  /**
   * Отклонён в Экзоне
   */
  EXON_CANCELLED = 'Отклонён в ГИС',
  /**
   * Удалён
   */
  DELETED = 'Удалён',
  /**
   *Согласован в Экзоне
   */
  EXON_APPROVED = 'Согласован в ГИС',
  /*
   * Подписан в Экзоне
   */
  EXON_SIGNED = 'Подписан в ГИС',
  /*
   * Отправлен на доработку из Экзона
   */
  EXON_REVISION = 'Отправлен на доработку из ГИС',
}

export type DocumentEventType = keyof typeof DocumentEventEnum
