import React, { FC, useEffect, useState } from 'react'

import {
  getFullNameReversed,
  searchUser,
  searchUserByEmail,
  User,
} from '@builddocs/data-management'
import { useToast } from '@builddocs/system'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { PhoneField } from '../atoms'

interface DialogBodyProps {
  valueSearch: string
  onChange: (value: string) => void
  onFind: (e: any) => void
  employee: User | undefined
  inn: string | undefined
}

const FindEmployeeDialogContent: FC<DialogBodyProps> = ({
  employee,
  onChange,
  onFind,
  valueSearch,
  inn,
}) => {
  const { toast } = useToast()
  const [isFinding, setIsFinding] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState('')

  const handleSearchEmployee = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (valueSearch.length && inn) {
      setIsFinding(true)
      const configuredRequest =
        findType === 'PHONE'
          ? searchUser(valueSearch, inn)
          : searchUserByEmail(valueSearch, inn)

      configuredRequest
        .then(res => {
          if (res.status === 204) {
            toast.warning.t('employee.messages.find_failure')
            setErrorMsg('Сотрудник не найден')
            return
          }
          if (!res.data.userId) {
            setErrorMsg('Этот номер телефона или почта ни к кому не привязан')
            return
          }
          // if (res.data.userId === user.userId) {
          //   setErrorMsg('Себя нельзя сделать уполномоченным')
          //   return
          // }
          onFind(res.data)
        })
        .catch(() => {
          toast.warning.t('employee.messages.find_no_valid')
          setErrorMsg('')
        })
        .finally(() => setIsFinding(false))
    }
  }
  const [findType, setFindType] = useState<'PHONE' | 'EMAIL'>('PHONE')

  useEffect(() => {
    onChange('')
  }, [findType])
  return (
    <Box>
      <Typography>
        Найдите своего сотрудника по номеру телефона или email и добавьте
        представителем
      </Typography>
      <Stack mt={2} spacing={2}>
        <RadioGroup
          row
          aria-labelledby="control-label-placement"
          defaultValue="top"
          name="position"
          value={findType}
          onChange={e => setFindType(e.target.value as 'PHONE' | 'EMAIL')}
        >
          <FormControlLabel control={<Radio />} label="Телефон" value="PHONE" />
          {/*<FormControlLabel control={<Radio />} label="Почта" value="EMAIL" />*/}
        </RadioGroup>
        <Stack alignItems="start" direction="row" spacing={1}>
          {findType === 'PHONE' && (
            <PhoneField
              error={!!errorMsg}
              helperText={errorMsg}
              id="phone"
              label="Номер телефона"
              name="phone"
              size="small"
              value={valueSearch}
              onChange={e => onChange(e.target.value)}
            />
          )}
          {findType === 'EMAIL' && (
            <TextField
              fullWidth
              id="email"
              label="Почта"
              size="small"
              value={valueSearch}
              variant="outlined"
              onChange={e => onChange(e.target.value)}
            />
          )}
          <LoadingButton
            id="find-employee-dialog-button-find"
            loading={isFinding}
            size="small"
            variant="tonal"
            onClick={handleSearchEmployee}
          >
            Найти
          </LoadingButton>
        </Stack>
        {employee ? (
          <Typography>{getFullNameReversed(employee)}</Typography>
        ) : (
          <Typography color="gray">Сотрудник не выбран</Typography>
        )}
      </Stack>
    </Box>
  )
}

export default FindEmployeeDialogContent
