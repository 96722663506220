/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export enum TypeOfWorksEnum {
  OTHER = 'OTHER',
  CONCRETING = 'CONCRETING',
  WELDING = 'WELDING',
  CABLING = 'CABLING',
  PALIFICATION = 'PALIFICATION',
  DRILLING_WELLS = 'DRILLING_WELLS',
  INSTALLATION_STRUCTURE = 'INSTALLATION_STRUCTURE',
  ANTI_CORROSION_PROTECTION = 'ANTI_CORROSION_PROTECTION',
  // новые виды работ
  AUTOMATIC_FIRE_EXTINGUISHING_INSTALLATIONS = 'AUTOMATIC_FIRE_EXTINGUISHING_INSTALLATIONS',
  INTERNAL_WATER_SUPPLY_AND_SEWERAGE_NETWORKS = 'INTERNAL_WATER_SUPPLY_AND_SEWERAGE_NETWORKS',
  INTERNAL_POWER_SUPPLY_NETWORKS = 'INTERNAL_POWER_SUPPLY_NETWORKS',
  INSTALLATION_AND_COMMISSIONING_OF_AUTOMATION_SYSTEMS = 'INSTALLATION_AND_COMMISSIONING_OF_AUTOMATION_SYSTEMS',
  INSTALLATION_OF_ELEVATORS = 'INSTALLATION_OF_ELEVATORS',
  INSTALLATION_OF_TECHNOLOGICAL_EQUIPMENT_AND_PIPELINES = 'INSTALLATION_OF_TECHNOLOGICAL_EQUIPMENT_AND_PIPELINES',
  EXTERNAL_WATER_SUPPLY_AND_SEWERAGE_NETWORKS = 'EXTERNAL_WATER_SUPPLY_AND_SEWERAGE_NETWORKS',
  EXTERNAL_POWER_SUPPLY_NETWORKS = 'EXTERNAL_POWER_SUPPLY_NETWORKS',
  CIVIL_WORKS = 'CIVIL_WORKS',
  FIRE_AND_SECURITY_AND_FIRE_ALARMS = 'FIRE_AND_SECURITY_AND_FIRE_ALARMS',
  HEATING_AND_VENTILATION_NETWORKS = 'HEATING_AND_VENTILATION_NETWORKS',
  HEATING_NETWORK = 'HEATING_NETWORK',
  CONSTRUCTION_OF_FOUNDATIONS = 'CONSTRUCTION_OF_FOUNDATIONS',
  BEARING_AND_ENCLOSING_STRUCTURES = 'BEARING_AND_ENCLOSING_STRUCTURES',
  ROOF = 'ROOF',
  EXTERNAL_FINISHING_WORKS = 'EXTERNAL_FINISHING_WORKS',
  INTERIOR_FINISHING_WORKS = 'INTERIOR_FINISHING_WORKS',
  VENTILATION_AND_AIR_CONDITIONING = 'VENTILATION_AND_AIR_CONDITIONING',
  GAS_PIPELINE = 'GAS_PIPELINE',
  COMMUNICATION_SIGNALING_AND_AUTOMATION_SYSTEMS = 'COMMUNICATION_SIGNALING_AND_AUTOMATION_SYSTEMS',
  IMPROVEMENT_OF_THE_TERRITORY = 'IMPROVEMENT_OF_THE_TERRITORY',
  ROAD_CONSTRUCTION = 'ROAD_CONSTRUCTION',
  TECHNOLOGICAL_SOLUTIONS = 'TECHNOLOGICAL_SOLUTIONS',
}

export type WorkType = keyof typeof TypeOfWorksEnum
