import { atom, selectorFamily } from 'recoil'

import { UUID } from '../../models'
import { getOrganizationsProject } from '../organization'

import { OrganizationsProjectResponseType } from './types'

export const organizationsProjectQueryRequestIDState = atom({
  key: 'OrganizationsProjectQueryRequestID',
  default: 0,
})

export const organizationsProjectQuery = selectorFamily<
  OrganizationsProjectResponseType[],
  { projectId: UUID; contractId: UUID }
>({
  key: 'OrganizationsProjectQuery',
  get:
    ({ projectId, contractId }) =>
    async ({ get }) => {
      get(organizationsProjectQueryRequestIDState)
      return projectId && contractId
        ? await getOrganizationsProject(projectId, contractId)
        : undefined
    },
})
