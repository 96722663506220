/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useQuery, useQueryClient } from 'react-query'

import { getStampsForDocument } from './filestore.stamp.service'
import { DocumentSigningTask } from './stamp.types'

interface StatusStampHook {
  isLoading: boolean
  data: DocumentSigningTask[] | undefined
  error: unknown
  isError: boolean
  refresh: (pageNumber?: number, pageSize?: number) => void
}

/**
 * Хук для работы с тасками для конкретного документа
 * @param {string} documentId
 * @returns {StatusStampHook}
 */
export const useTasksOfDocument = (documentId: string): StatusStampHook => {
  const queryClient = useQueryClient()

  const { data, isLoading, isError, error } = useQuery<DocumentSigningTask[]>(
    ['tasks_for_document', documentId],
    async () => await getStampsForDocument(documentId),
  )

  const refresh = () =>
    queryClient.invalidateQueries(['tasks_for_document', documentId])

  return {
    isLoading,
    data,
    error,
    isError,
    refresh,
  }
}
