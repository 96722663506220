/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useState } from 'react'

import {
  loginExtSign,
  User,
  useRefreshCurrentUser,
  useRouter,
} from '@builddocs/data-management'
import { toast } from 'react-toastify'

type OwnProps = {
  redirectUrl?: string
  onLogin?: (user: User) => void
}
export const useLoginExternal = ({ redirectUrl, onLogin }: OwnProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isFail, setIsFail] = useState(false)
  const refreshCurrentUser = useRefreshCurrentUser({ onLogin })

  const { location, navigate } = useRouter()

  const loginExternal = async (signId: string) => {
    try {
      setIsLoading(true)

      await loginExtSign(signId)
      await refreshCurrentUser()

      setIsLoading(false)
      setIsFail(false)
      const { from } = (location.state as any) || {
        from: { pathname: redirectUrl },
      }

      redirectUrl && navigate(from)
    } catch (e) {
      setIsLoading(false)
      setIsFail(true)
      toast.error('Ошибка входа')
    }
  }

  return {
    loginExternal,
    isLoading,
    isFail,
  }
}
