/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useEffect, useState } from 'react'

import { useToast } from '@builddocs/system'
import * as Sentry from '@sentry/react'

import { Ksi } from './ksi-works.type'
import { getConstructionsTitlesKsi, getWorksTitlesKsi } from './stages.service'

interface IKsiWorksHook {
  content: Ksi | undefined
  isLoading: boolean
}

/* Хук для получения КСИ для Наименований Работ и Конструкций */
export const useKsiTitles = (actionType = 'works'): IKsiWorksHook => {
  const [ksi, setKsi] = useState<Ksi>()
  const [isLoading, setIsLoading] = useState(false)
  const { toast } = useToast()

  const fetchTitles = () => {
    setIsLoading(true)
    if (actionType === 'works') {
      getWorksTitlesKsi()
        .then(data => {
          setKsi(data)
          setIsLoading(false)
        })
        .catch(error => {
          toast.error('Ошибка получения списка работ')
          Sentry.captureException(error)
          setIsLoading(false)
        })
    } else if (actionType === 'constructions') {
      getConstructionsTitlesKsi()
        .then(data => {
          setKsi(data)
          setIsLoading(false)
        })
        .catch(error => {
          toast.error('Ошибка получения списка конструкций')
          Sentry.captureException(error)
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    fetchTitles()
  }, [])

  return {
    content: ksi,
    isLoading,
  }
}
