import { UUID } from '../../../models'

export interface EmploymentController {
  senderName: string
  receiverName: string
  senderId: UUID
  receiverId: UUID
  senderEmail: null
  receiverEmail: null
  businessKey: UUID
  positionName: string
  orderName: string
  orderDate: string
  status: EmploymentStatus
  type: EmploymentType
  createDateTime: string
  solved: boolean
  viewed: boolean
  path: string
  page: number
  version: number
  notificationId: string
  fileName: string
}

export interface EmploymentControllerDTO {
  businessKey: UUID
  senderName: string
  receiverName: string
  senderId: UUID
  receiverId: UUID
  senderEmail: string | null
  receiverEmail: string | null
  positionName: string
  orderName: string
  orderDate: string
}

export enum EmploymentType {
  UNDEFINED_TYPE = 'UNDEFINED_TYPE',
  HIRE = 'HIRE',
  FIRE = 'FIRE',
}

export enum EmploymentStatus {
  UNDEFINED_STATUS = 'UNDEFINED_STATUS',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export default EmploymentController
