/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useCallback, useState } from 'react'

import {
  DocumentSigningTask,
  getStampsForDocument,
} from '@builddocs/filestore-api'
import { useSetRecoilState } from 'recoil'

import { tasksExternal } from './external.container'

interface StatusStampHook {
  isTasksFail: boolean
  isTasksLoading: boolean
  loadTasks: (documentId: string) => void
}

export const useExtTasksOfDocument = (): StatusStampHook => {
  const [isTasksLoading, setIsTasksLoading] = useState(false)
  const [isTasksFail, setIsTasksFail] = useState(false)
  const setTasksExternal = useSetRecoilState(tasksExternal)

  const loadTasks = useCallback(async (documentId: string) => {
    let tasksloaded: DocumentSigningTask[] | undefined

    setIsTasksLoading(true)

    try {
      tasksloaded = await getStampsForDocument(documentId)
    } catch {
      setIsTasksLoading(false)
      setIsTasksFail(true)
    }

    setIsTasksLoading(false)

    tasksloaded && setTasksExternal(tasksloaded)
  }, [])

  return {
    isTasksFail,
    isTasksLoading,
    loadTasks,
  }
}
