/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { EventTypeEnum } from '../events'
import { ExonEvent } from '../events/exon/ExonEvent.type'

import type { User, UUID } from '../../models'
import type { EventType } from '../events'
import type { ActEvent } from '../events'

export interface NotificationBase {
  /**
   * Идентификатор уведомления
   */
  notificationTargetId: UUID
  /**
   * Ид генератора события (userId)
   */
  generatorId: UUID
  /**
   * Профиль инициатора события
   */
  generator: User
  /**
   * Ид получателя уведомления (userId)
   */
  receiverId: UUID
  /**
   * Профиль получателя уведомления
   */
  receiver: User
  /**
   * Тип события
   */
  eventType: EventType
  /**
   * Признак просмотра события
   */
  viewed: boolean
  /**
   * Признак удаленного события
   */
  deleted: boolean
  /**
   * Ид ключ камунды
   */
  businessKey: string
  /**
   * Дата создания события в системе
   */
  createDateTime: string
}

export interface ActNotification extends NotificationBase {
  eventType: EventTypeEnum.ACT
  /**
   * Модель данных события связанного с актом
   */
  actEvent: ActEvent
}

export interface ExonNotification extends NotificationBase {
  eventType: EventTypeEnum.EXON
  /**
   * Модель данных события связанного с Экзоном
   */
  exonEventModel: ExonEvent
}

/**
 * Полная модель уведомления
 */
export type Notification = ActNotification | ExonNotification

export default Notification
