/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

/** Документ, хранящийся в Билдоксе */
export type DocumentMeta = {
  documentId: string
  documentName: string
  documentNumber?: string
  documentType:
    | 'LAB'
    | 'NORMATIVE_DOC'
    | 'CERTIFICATE'
    | 'EXECUTIVE_SCHEME'
    | 'EXECUTIVE_DRAWING'
    | 'PERMIT'
  subDocuments: any
  issueDate: string | undefined
  expirationDate?: string
  manufacturer?: string
  documentUrl?: string
  fileName?: string
  sheetNumber?: string
  sheet?: string
  description?: string
  organization?: string
}
