import { ActForm } from '@builddocs/data-management'

export const getActUrlByType: Record<string, string> = {
  [ActForm.AOSR]: `/act-aosr`,
  [ActForm.AOOK]: `/act-aook`,
  [ActForm.AGIG]: `/act-agig`,
  [ActForm.AIIO]: `/act-aiio`,
  [ActForm.ANMO]: `/act-anmo`,
  [ActForm.APTO]: `/act-apto`,
}
