import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

import { ChartColors } from '../../mui-provider-crutch/colors'

interface CustomColorProps {
  chartColor?: ChartColors
}

type LinearProgressBarProps = CustomColorProps & LinearProgressProps

export const LinearProgressBar = styled((props: LinearProgressBarProps) => (
  <LinearProgress {...props} />
))(({ theme, chartColor }) => ({
  height: 20,
  borderRadius: 4,
  transition: 'none',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.chart['grey'],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: chartColor && theme.palette.chart.colors[chartColor],
    transition: 'none',
  },
}))
