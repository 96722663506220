/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

/** @jsxImportSource @emotion/react */

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useToast } from '@builddocs/system'
import { css } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import {
  Select,
  Box,
  Button,
  Stack,
  Typography,
  Dialog,
  Toolbar,
  IconButton,
  DialogContent,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import format from 'date-fns/format'
import Webcam from 'react-webcam'

import { cryptopro, initCrypto } from './cryptopro/inits'
import { CertificateInList, CryptoproCertificate } from './cryptopro/types'
import { FaceControlContext } from './FaceControlContext'

const videoConstraints = {
  width: 300,
  height: 300,
  facingMode: 'user',
}

interface FaceControlDialogProps {
  onClose: () => void
  isOpen: boolean
  isVerifyOnly: boolean
  isLoading: boolean
}

function FaceControlDialog(props: FaceControlDialogProps) {
  const { onClose, isOpen, isVerifyOnly, isLoading } = props
  const { toast } = useToast()

  const [img, setImg] = useState<string>()
  const [certs, setCerts] = useState<CertificateInList[]>([])
  const [certSelected, setCertSelected] = useState<CryptoproCertificate>()
  const [isSuccess, setIsSuccess] = useState(false)
  const [certId, setCertId] = React.useState<string>(certs[0]?.id ?? '')
  const faceControl = useContext(FaceControlContext)

  const refContainer = useRef(null)

  const capture = React.useCallback(() => {
    const imageSrc = (refContainer.current as any)?.getScreenshot()

    setImg(imageSrc)
  }, [refContainer])

  const clearCapture = () => {
    setImg(undefined)
  }

  const getCertInfo = useCallback((certID: string) => {
    initCrypto()
      .then(() => {
        return cryptopro.certificateInfo(certID)
      })
      .then((info: CryptoproCertificate) => {
        setCertSelected(info)
      })
      .catch((e: Error) => {
        console.error('Failed! ' + e)
      })
  }, [])

  const getCertsList = useCallback(() => {
    initCrypto()
      .then(() => {
        return cryptopro.listCertificates()
      })
      .then((_certs: CertificateInList[]) => {
        setCerts(_certs)
        getCertInfo(_certs[0].id)
      })
      .catch((e: Error) => {
        console.error(`Failed! ${e}`)
        toast.error('Ошибка при получении списка сертификатов')
      })
  }, [getCertInfo])

  const signEntity = () => {
    // setIsLoading(true)
    certSelected &&
      faceControl.signData({
        certSelected,
        facePhoto: img as string,
        keySerialNumber: certSelected.SerialNumber,
        certificateDateStart: format(certSelected.ValidFromDate, 'yyyy-MM-dd'),
        certificateDateEnd: format(certSelected.ValidToDate, 'yyyy-MM-dd'),
        signerName: `${certSelected.Subject.SN} ${certSelected.Subject.G}`,
        onFailure: () => {
          setIsSuccess(false)
          // setIsLoading(false)
        },
        onSuccess: () => {
          setIsSuccess(true)
          // setIsLoading(false)
        },
      })
  }

  const doVerifyingAction = () => {
    // setIsLoading(true)
    faceControl.verifyPerson({
      facePhoto: img as string,
      keySerialNumber: certSelected?.SerialNumber as string,
      onFailure: () => {
        setIsSuccess(false)
        // setIsLoading(false)
      },
      onSuccess: () => {
        setIsSuccess(true)
        // setIsLoading(false)
      },
    })
  }

  useEffect(() => {
    isOpen && getCertsList()
  }, [getCertsList, isOpen])

  useEffect(() => {
    certs.length > 0 && setCertId(certs[0].id)
  }, [certs])

  const allowSend = certSelected && img

  const handleChange = (event: SelectChangeEvent) => {
    setCertId(event.target.value as string)
    getCertInfo(event.target.value)
  }

  const handleClose = (isLoading: boolean) => !isLoading && onClose

  return (
    <Dialog open={isOpen} onClose={() => handleClose(isLoading)}>
      <Toolbar variant="dense">
        <IconButton
          aria-label="close"
          color="inherit"
          edge="start"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography component="div" sx={{ ml: 2, flex: 1 }} variant="h6">
          Подписание
        </Typography>
        {isLoading && <CircularProgress size={16} />}
      </Toolbar>
      <DialogContent>
        <Stack alignItems="center" justifyContent="center">
          <Stack alignItems="center" spacing={2}>
            <Box
              style={{
                border: '1px solid lightgray',
                borderRadius: 1.25,
                position: 'relative',
                borderColor: isSuccess ? 'green.accepted' : 'grays.200',
              }}
            >
              {img ? (
                <img
                  alt=""
                  height={300}
                  src={img}
                  style={{
                    height: '400px',
                    width: '400px',
                    objectFit: 'cover',
                    alignSelf: 'center',
                  }}
                  width={300}
                />
              ) : (
                <Webcam
                  ref={refContainer}
                  mirrored
                  audio={false}
                  css={css`
                    height: 400px;
                    width: 400px;
                    object-fit: cover;
                    align-self: center;
                  `}
                  height={400}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  width={400}
                />
              )}
              <Box style={{ position: 'absolute' }} width="100%">
                <Typography>
                  {isSuccess
                    ? 'Личность идентифицирована'
                    : 'Смотрите в камеру'}
                </Typography>
              </Box>
            </Box>

            <Button
              disabled={isLoading}
              id="face-control-dialog-button-take-photo"
              onClick={img ? clearCapture : capture}
            >
              {img ? 'Переснять' : 'Сделать снимок'}
            </Button>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h6">Выберите свой сертификат</Typography>
            <Select
              disabled={isLoading}
              id="certs-select"
              size="small"
              value={certId}
              onChange={handleChange}
            >
              {certs?.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {isVerifyOnly ? (
              <Button
                disabled={!allowSend || isLoading}
                id="face-control-dialog-button-continue"
                variant="outlined"
                onClick={doVerifyingAction}
              >
                {isLoading ? 'Верификация...' : 'Продолжить'}
              </Button>
            ) : (
              <Button
                disabled={!allowSend || isLoading}
                id="face-control-dialog-button-sign"
                variant="outlined"
                onClick={signEntity}
              >
                {isLoading ? 'Обработка подписи...' : 'Подписать запись'}
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default FaceControlDialog
