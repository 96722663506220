/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import {
  InspectionState,
  SignatureState,
  WorkType,
  UUID,
} from '@builddocs/data-management'

import { FilestoreAPI } from '../FilestoreAPI'

/************
 * Закрытие *
 ************/

/** Получение дайджеста Закрытия */
export const getDigestClosing = (id: UUID) => {
  return FilestoreAPI.get(`/closing/${id}/digest`).then(res => res.data)
}

/** Инициализация подписания Закрытия*/
export const signInitClosing = ({
  closingId,
  isEmbedded = false,
  aosrIds,
  secondaryActIds,
  workType,
  projectId,
  journalId,
  workDocSection,
}: {
  closingId: UUID
  isEmbedded?: boolean
  addActAssignees?: string[]
  aosrIds: UUID[]
  secondaryActIds: UUID[]
  workType: WorkType
  projectId: string
  journalId: string
  workDocSection: string
}) => {
  return FilestoreAPI.post(
    `/closing/${closingId}/sign`,
    {
      id: closingId,
      embedded: isEmbedded.toString(),
      // employmentIds: addActAssignees,
      aosrIds: aosrIds,
      secondaryActIds: secondaryActIds,
    },
    {
      params: {
        workType: workType,
        projectId: projectId,
        journalId: journalId,
        workDocSection: workDocSection,
      },
    },
  ).then(res => res.data)
}

/** Подписание/Отклонение Закрытия */
export const signClosing = (
  data:
    | {
        secondaryActs?: { actId: UUID; base64signature: string }[]
        aosrs?: {
          actId: UUID
          actRegistryBase64signature?: string
          base64signature: string
        }[]
        closingId: string
        keyIssuer: string
        imprint: string
        employmentId: string
        faceId: {
          faceImageBytes?: string
          keySerialNumber: string
          faceImage?: string
        }
        signatureState: SignatureState.SIGNED
      }
    | {
        closingId: string
        comment: string
        employmentId: string
        signatureState: SignatureState.REJECTED
        secondaryActs?: { actId: UUID; base64signature: string }[]
        aosrs?: { actId: UUID; base64signature: string }[]
        faceId?: {
          faceImageBytes?: string
          keySerialNumber: string
          faceImage?: string
        }
      },
) => {
  return FilestoreAPI.put(`/closing/${data.closingId}/sign`, data).then(
    res => res.data,
  )
}

/** Добавление проверяющих в Закрытие */
export const inspectInitClosing = (
  projectId: string,
  journalId: string,
  workDocSection: string,
  closingId: UUID,
  workType: WorkType,
  employmentIds: string[],
  aosrIds: UUID[],
  secondaryActIds: UUID[],
) => {
  return FilestoreAPI.post(
    `/closing/${closingId}/inspect`,
    {
      employmentIds,
      aosrIds,
      secondaryActIds,
    },
    {
      params: {
        workType: workType,
        projectId: projectId,
        journalId: journalId,
        workDocSection: workDocSection,
      },
    },
  ).then(res => res.data)
}

/** Проверка Закрытия */
export const inspectClosing = (
  closingId: UUID,
  employmentId: UUID,
  aosrIds: UUID[],
  secondaryActIds: UUID[],
  state: InspectionState,
  faceId?: any,
  comment?: string,
) => {
  return FilestoreAPI.put(`/closing/${closingId}/inspect`, {
    employmentId,
    aosrIds,
    secondaryActIds,
    state,
    faceId,
    comment,
  }).then(res => res.data)
}
