/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2023 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Employment, PaginationModel, UUID } from '../../../models'
import { RestAPI } from '../../../rest/RestAPI'
import { CamundaAPI } from '../../CamundaAPI'

import { EmploymentNotification } from './Employment.type'
import {
  EmploymentControllerDTO,
  EmploymentStatus,
} from './EmploymentController.type'

/**
 * Отправить приглашение на работу в организации пользователю
 *
 * @requires Roles.ADMIN - права администратора
 * @param userId Id пользователя, которого необходимо принять на работу
 * @param employment Данные о приеме на работу
 * @returns {Promise<AxiosResponse<any>>}
 */
export const hire = async (
  userId: UUID,
  employment:
    | Omit<
        Employment,
        'employmentId' | 'employmentStartDate' | 'employmentEndDate'
      >
    | { position: { positionId: string | null } },
) => RestAPI.post(`/users/hire/${userId}`, employment)

/**
 * Ответить на уведомление по найму в организацию
 *
 * @param employment Данные события
 * @param {EmploymentStatus} employeeResponseType Принятие/отказ от работы
 * @returns {Promise<AxiosResponse<any>>}
 */
export const responseOffer = async (
  employment: EmploymentControllerDTO,
  employeeResponseType: EmploymentStatus,
) => RestAPI.post(`/users/offer-response/${employeeResponseType}`, employment)

/**
 * Уволить пользователя из организации
 *
 * @requires Roles.ADMIN - права администратора
 * @param userId Id пользователя, которого необходимо уволить
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fire = async (userId: UUID) =>
  RestAPI.post(`/users/fire/${userId}`)

/**
 * Получить уведомления по найму
 *
 * @param pageNumber Необходимая страница
 * @param pageSize Количество уведомлений на странице
 * @returns {Promise<AxiosResponse<EmploymentNotification>>}
 */
export const getEmployments = async (
  pageNumber = 0,
  pageSize = 5,
): Promise<PaginationModel<EmploymentNotification>> =>
  CamundaAPI.get(`/employment/notifications`, {
    params: { pageNumber, pageSize },
  }).then(response => response.data)

/**
 * Получить количество непрочитанных уведомлений о найме
 *
 * @returns {Promise<AxiosResponse<number>>}
 */
export const getUnreadEmployments = async (): Promise<number> =>
  CamundaAPI.get(`/employment/unread`).then(response => response.data)

/**
 * Прочитать уведомления о найме
 *
 * @returns {Promise<AxiosResponse<null>>}
 */
export const readNotificationEmployment = async (ids: UUID[]): Promise<null> =>
  CamundaAPI.post(`/employment/read`, ids)
