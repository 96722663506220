/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { DocumentMeta, UUID } from '../../models'
import { Unit } from '../../utils/units'

export type InventoryItem = {
  material: {
    materialId: UUID
    materialName: string
    unit: Unit
    documents: DocumentMeta[]
    manufacturer: string | undefined
  }
  actualAmount: number | undefined
  usedAmount: number | undefined
}
