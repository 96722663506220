/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export enum UnitType {
  PIECES = 'PIECES',
  KG = 'KG',
  M = 'M', // метр
  M2 = 'M_2', // метр^2
  M3 = 'M_3', // метр^3
  KG_M2 = 'KG_M2', // кг/м2
  KG_M3 = 'KG_M3', // кг/м3
  TON = 'TON', // тонна
  HA = 'HA', // гектар
}
