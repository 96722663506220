import { ActForm, UUID, WorkType } from '@builddocs/data-management'

import { FilestoreAPI } from '../FilestoreAPI'

import { SecondaryActType } from './acts.interfaces'
import { getActUrlByType } from './getActUrlByType'

export const deleteAct = (actId: string, type: ActForm) =>
  FilestoreAPI.delete(`${getActUrlByType[type]}/${actId}`)

export const getActsList = (
  journalId: UUID,
  actType: string,
  pageNumber = 0,
  pageSize = 10,
  workType?: string,
  workDocSection?: string,
  startDate?: string,
  endDate?: string,
  closingId?: UUID,
) =>
  FilestoreAPI.post(`/acts-${actType.toLowerCase()}/search`, {
    journalId,
    pageNumber,
    pageSize,
    workDocSection,
    workType,
    startDate,
    endDate,
    closingId,
  }).then(res => res.data)

/** Получение списка секондари-актов по виду работ и АОСР*/
export const getSecondaryActsByWorkTypeAndAosr = (
  workType: WorkType,
  aosrId?: string | null,
): Promise<SecondaryActType[] | []> =>
  FilestoreAPI.get(
    `/secondary-act/list?workType=${workType}&aosrId=${aosrId}`,
  ).then(res => res.data ?? [])

/** Получение списка секондари-актов по виду работ и АОСР*/
export const getSecondaryActsWithoutAosr = (
  journalId: UUID,
  workDocSection: string,
  workType: WorkType,
): Promise<SecondaryActType[] | []> =>
  FilestoreAPI.post(`/secondary-act/search`, {
    journalId,
    workDocSection,
    workType,
  }).then(res => res.data ?? [])
