import { useState } from 'react'

import ListIcon from '@mui/icons-material/List'
import { Box, IconButton, Link, Stack, styled, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import { PDFObject } from '../PDFViewerUniversal'

import { NavContentProps } from './drawer.type'
import { NavDocuments } from './NavDocuments'

const Main = styled('div')<{
  open?: boolean
  drawerwidth?: number
}>(({ theme, open, drawerwidth }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerwidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

type Props = {
  //Компонент, передаваемый в качестве содержимого для левого меню
  navContent: React.ReactElement<NavContentProps>
  //Компонент, передаваемый для работы с замечаниями к документу
  commentContent?: React.ReactElement<NavContentProps> | undefined
  //Компонент, передаваемый для работы с подписанием документа
  signContent?: React.ReactElement<NavContentProps> | undefined
  //Ссылка на документ, отображающися в левом окне просмотра
  url: string
  //Ссылка на документ, отображающися в правом окне просмотра
  urlSecond: string
  //Заголовок левого меню
  navTitle: string
  //Ширина левого меню. По умолчанию 350
  drawerWidth?: number
  //если true, pdf всё равно перезагрузится
  forceUpdate?: boolean
  //панель навигации закрыта по умолчанию
  isDrawerOpenInitial?: boolean
}

export const TwoPdfViewer = ({
  navContent,
  commentContent,
  signContent,
  url,
  urlSecond,
  navTitle,
  drawerWidth = 350,
  forceUpdate,
  isDrawerOpenInitial = false,
}: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(isDrawerOpenInitial)

  return (
    <Stack direction="row" height="100%">
      <CssBaseline />
      <NavDocuments
        drawerWidth={drawerWidth}
        isOpen={isDrawerOpen}
        navContent={navContent}
        navTitle={navTitle}
        setIsOpenHandler={() => setIsDrawerOpen(prev => !prev)}
      />

      <Main drawerwidth={drawerWidth} open={isDrawerOpen}>
        <Stack direction="row" height="100%" px={1} spacing={1}>
          {!isDrawerOpen && (
            <Box>
              <IconButton
                id="documents-drawer-button-open-modal"
                onClick={() => setIsDrawerOpen(prev => !prev)}
              >
                <ListIcon color="info" />
              </IconButton>
            </Box>
          )}
          {url ? (
            <Stack height="100%" width={urlSecond ? '50%' : '100%'}>
              <Link
                href={url}
                pb={1}
                rel="noopener"
                target="_blank"
                variant="body1"
              >
                Открыть в новом окне
              </Link>

              <PDFObject
                containerId="registry-pdf"
                containerProps={{
                  style: { height: '100%' },
                }}
                forceUpdate={forceUpdate}
                pdfOpenParams={{
                  view: 'FitH,top',
                  page: 1,
                  zoom: 'scale',
                  nameddest: '',
                  pagemode: 'none',
                }}
                url={url + '?showDeleted=true'}
              />
            </Stack>
          ) : (
            <Stack alignItems="center" justifyContent="center">
              <Typography variant="subtitle2">
                Выберите документ из приложений для просмотра
              </Typography>
            </Stack>
          )}
          {urlSecond ? (
            <Stack height="100%" width="50%">
              <Link
                href={urlSecond}
                pb={1}
                rel="noopener"
                target="_blank"
                variant="body1"
              >
                Открыть в новом окне
              </Link>
              <PDFObject
                containerId="act-pdf"
                containerProps={{
                  style: { height: '100%' },
                }}
                forceUpdate={forceUpdate}
                pdfOpenParams={{
                  view: 'FitH,top',
                  page: 1,
                  zoom: 'scale',
                  nameddest: '',
                  pagemode: 'none',
                }}
                url={urlSecond + '?showDeleted=true'}
              />
            </Stack>
          ) : (
            <Box sx={{ px: 1 }} width="70%">
              {signContent}
              {commentContent}
            </Box>
          )}
        </Stack>
      </Main>
    </Stack>
  )
}
