import { useCurrentUser } from '@builddocs/data-management'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

type Props = {
  isOpen: boolean
  onContinue: () => void
  logoutHandler: () => void
}

export const LogoutRequestDialog = ({
  isOpen,
  onContinue,
  logoutHandler,
}: Props) => {
  const { user } = useCurrentUser()

  return (
    <Dialog open={isOpen && !!user}>
      <DialogTitle>Вход уже выполнен</DialogTitle>
      <DialogContent>
        <Typography>{`В систему уже выполнен вход пользователем ${
          user?.lastName ?? ''
        } ${user?.firstName ?? ''}`}</Typography>
        <Typography>Продолжить работу в этом аккаунте?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinue}>Остаться</Button>
        <Button variant="contained" onClick={logoutHandler}>
          Сменить пользователя
        </Button>
      </DialogActions>
    </Dialog>
  )
}
