/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { atomFamily, selectorFamily } from 'recoil'

import { CommentItem } from '../../models'

import commentsLogService from './commentsLog.service'

export const commentsLogQueryRequestIDState = atomFamily({
  key: 'commentsLogQueryRequestID',
  default: 0,
})

type Params = {
  contractId: string
  pageNumber: number
  pageSize: number
}

export const commentsLogQuery = selectorFamily<
  {
    pageCount: number
    pageNumber: number
    pageSize: number
    entityList: CommentItem[]
  },
  Params
>({
  key: 'CommentsLogQuery',
  get:
    params =>
    async ({ get }) => {
      get(commentsLogQueryRequestIDState(params.contractId))
      return params.contractId
        ? await commentsLogService.getList(params)
        : {
            pageCount: 0,
            pageNumber: 0,
            pageSize: 0,
            entityList: [],
          }
    },
})

type CommentsListParams = {
  entityId?: string
  externalEntityId?: string
}
export const commentsLogByEntityIdQuery = selectorFamily<
  CommentItem[],
  CommentsListParams
>({
  key: 'CommentsLogQuery',
  get:
    ({ entityId, externalEntityId }) =>
    async ({ get }) => {
      get(
        commentsLogQueryRequestIDState({
          entityId,
          externalEntityId,
        }),
      )
      if (entityId) {
        return await commentsLogService.getListByEntityId(entityId)
      } else if (externalEntityId) {
        return await commentsLogService.getListByExternalEntityId(
          externalEntityId,
        )
      } else {
        throw new Error('No entityId')
      }
    },
})
