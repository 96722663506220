import { atomFamily, selectorFamily } from 'recoil'

import { getInventory } from './inventory.service'
import { InventoryItem } from './inventory.type'

export const inventoryQueryRequestIDState = atomFamily({
  key: 'InventoryQueryRequestID',
  default: 0,
})

type Props = {
  contractId: string
  pageNumber?: number
  pageSize?: number
  searchValue?: string
  searchValueDate?: string
}

export const inventoryQuery = selectorFamily<
  {
    pageCount: number
    pageNumber: number
    pageSize: number
    entityList: InventoryItem[]
  },
  Props
>({
  key: 'InventoryQuery',
  get:
    ({ contractId, pageNumber, pageSize, searchValue, searchValueDate }) =>
    async ({ get }) => {
      get(inventoryQueryRequestIDState(contractId))

      return await getInventory({
        contractId: contractId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchValue: searchValue,
        searchValueDate: searchValueDate,
      })
    },
})
