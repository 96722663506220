/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import formatISO from 'date-fns/formatISO'

import { Assignee } from '../rest/contracts'

import { buildAddressString } from './address'

export const toForm = (data: any) => ({
  inn: data.inn,
  title: data.title,
  description: data?.description,
  constructionAddress: data.constructionAddress,
  developer: {
    title: data.developer?.name,
    inn: data.developer?.inn,
    ogrn: data.developer?.ogrn,
    ogrnDate: data.developer?.ogrnDate,
    documentNumber: data.developer?.ogrn,
    issueDate: data.developer?.ogrnDate,
    address: buildAddressString(data.developer?.physicalAddress, ''),
    sro: data.developer?.sro,
    sroDate: data.developer?.sroDate,
    sroEngineering: data.developer?.sroEngineering,
    sroEngineeringDate: data.developer?.sroEngineeringDate,
    phone: data.developer?.phone,
    representatives: data.developerAssignees,
    executiveSchemeAssignees: data.executiveSchemeAssignees,
  },
  customer: {
    inn: data.customer?.inn,
    title: data.customer?.name,
    ogrn: data.customer?.ogrn,
    ogrnDate: data.customer?.ogrnDate,
    documentNumber: data.customer?.ogrn,
    issueDate: data.customer?.ogrnDate,
    address: buildAddressString(data.customer?.physicalAddress, ''),
    sro: data.customer?.sro,
    sroDate: data.customer?.sroDate,
    sroEngineering: data.developer?.sroEngineering,
    sroEngineeringDate: data.developer?.sroEngineeringDate,
    phone: data.customer?.phone,
    representatives: data.customerAssignees,
  },
  customerTechControl: {
    inn: data.customerTechControl?.inn,
    title: data.customerTechControl?.name,
    ogrn: data.customerTechControl?.ogrn,
    ogrnDate: data.customerTechControl?.ogrnDate,
    documentNumber: data.customerTechControl?.ogrn,
    issueDate: data.customerTechControl?.ogrnDate,
    address: buildAddressString(data.customerTechControl?.physicalAddress, ''),
    sro: data.customerTechControl?.sro,
    sroDate: data.customerTechControl?.sroDate,
    phone: data.customerTechControl?.phone,
    representatives: data.customerTechControlAssignees,
  },
  preparator: {
    title: data.prep?.name,
    inn: data.prep?.inn,
    sro: data.prep?.sro,
    sroDate: data.prep?.sroDate,
    phone: data.prep?.phone,
    representatives: data.prepAssignees,
    ogrn: data.prep?.ogrn,
    ogrnDate: data.prep?.ogrnDate,
    documentNumber: data.prep?.ogrn,
    issueDate: data.prep?.ogrnDate,
    address: buildAddressString(data.prep?.physicalAddress, ''),
  },
  govExamNumber: data.stateExamNumber,
  govExamDate: data.stateExamDateOfIssue,
  govExamName: data.stateAuthorityValue,
  builder: {
    inn: data.builder?.inn,
    title: data.builder?.name,
    ogrn: data.builder?.ogrn,
    ogrnDate: data.builder?.ogrnDate,
    documentNumber: data.builder?.ogrn,
    issueDate: data.builder?.ogrnDate,
    address: buildAddressString(data.builder?.physicalAddress, ''),
    sro: data.builder?.sro,
    sroDate: data?.builder?.sroDate,
    phone: data?.builder?.phone,
    representatives: data.builderAssignees,
    representativesByCustomerOrDeveloperControl: data.firstAssignees,
    representativesByBuilderControl: data.secondAssignees,
  },
  governmentRevisor: {
    title: data.inspectorTitle,
    address: data.inspectorAddress,
    phone: data.inspectorPhone,
    fax: data.inspectorFax,
    executive: data.inspectorEmp,
    regId: data.registrationNumber,
  },
  buildingObject: {
    title: data.buildingName,
    characteristics: data.projectDescription,
    startDate: data.projectStartData,
    finishDate: data.projectEndData,
  },
  buildingPermitNumber: data.permitNumber,
  buildingPermitDate: data.permitDateOfIssue,
  buildingPermitAgencyEmitter: data.permitAuthorityValue,
})

export const toFormOfProject = (data: any) => ({
  description: data?.description,
  constructionAddress: data?.constructionAddress,
  developer: {
    title: data?.developer?.name,
    inn: data?.developer?.inn,
    ogrn: data?.developer?.ogrn,
    ogrnDate: data?.developer?.ogrnDate,
    documentNumber: data?.developer?.ogrn,
    issueDate: data?.developer?.ogrnDate,
    address: buildAddressString(data?.developer?.physicalAddress, ''),
    sro: data?.developer?.sro,
    sroDate: data?.developer?.sroDate,
    sroEngineering: data?.developer?.sroEngineering,
    sroEngineeringDate: data?.developer?.sroEngineeringDate,
    phone: data?.developer?.phone,
  },
  customerTechControl: {
    inn: data?.customer?.inn,
    title: data?.customer?.name,
    ogrn: data?.customer?.ogrn,
    ogrnDate: data?.customer?.ogrnDate,
    documentNumber: data?.customer?.ogrn,
    issueDate: data?.customer?.ogrnDate,
    address: buildAddressString(data?.customer?.physicalAddress, ''),
    sro: data?.customer?.sro,
    sroDate: data?.customer?.sroDate,
    sroEngineering: data?.developer?.sroEngineering,
    sroEngineeringDate: data?.developer?.sroEngineeringDate,
    phone: data?.customer?.phone,
  },
})

export const toFormOfParentContract = (data: any) => ({
  description: data?.description,
  constructionAddress: data?.constructionAddress,
  developer: {
    title: data?.developer?.name,
    inn: data?.developer?.inn,
    ogrn: data?.developer?.ogrn,
    ogrnDate: data?.developer?.ogrnDate,
    documentNumber: data?.developer?.ogrn,
    issueDate: data?.developer?.ogrnDate,
    address: buildAddressString(data?.developer?.physicalAddress, ''),
    sro: data?.developer?.sro,
    sroDate: data?.developer?.sroDate,
    sroEngineering: data?.developer?.sroEngineering,
    sroEngineeringDate: data?.developer?.sroEngineeringDate,
    phone: data?.developer?.phone,
  },
  customerTechControl: {
    inn: data?.customerTechControl?.inn,
    title: data?.customerTechControl?.name,
    ogrn: data?.customerTechControl?.ogrn,
    ogrnDate: data?.customerTechControl?.ogrnDate,
    documentNumber: data?.customerTechControl?.ogrn,
    issueDate: data?.customerTechControl?.ogrnDate,
    address: buildAddressString(data?.customerTechControl?.physicalAddress, ''),
    sro: data?.customerTechControl?.sro,
    sroDate: data?.customerTechControl?.sroDate,
    sroEngineering: data?.customerTechControl?.sroEngineering,
    sroEngineeringDate: data?.customerTechControl?.sroEngineeringDate,
    phone: data?.customerTechControl?.phone,
  },
  preparator: {
    inn: data?.prep?.inn,
    title: data?.prep?.name,
    ogrn: data?.prep?.ogrn,
    ogrnDate: data?.prep?.ogrnDate,
    documentNumber: data?.prep?.ogrn,
    issueDate: data?.prep?.ogrnDate,
    address: buildAddressString(data?.prep?.physicalAddress, ''),
    sro: data?.prep?.sro,
    sroDate: data?.prep?.sroDate,
    sroEngineering: data?.prep?.sroEngineering,
    sroEngineeringDate: data?.prep?.sroEngineeringDate,
    phone: data?.prep?.phone,
  },
})

export const toAPI = (
  {
    developer,
    customer,
    customerTechControl,
    preparator,
    builder,
    governmentRevisor,
    buildingObject,
    ...data
  }: any,
  projectId: string,
) => ({
  projectId,
  journalId: data.journalId,
  inn: data.inn,
  title: data.title,
  description: data.description,
  constructionAddress: data.constructionAddress,

  developer: {
    inn: developer.inn || undefined,
    sro: developer.sro || undefined,
    sroDate:
      (developer.sroDate &&
        formatISO(new Date(developer.sroDate), { representation: 'date' })) ||
      undefined,
    phone: developer.phone?.replace(/[\s]/gi, ''),
  },
  developerAssignees: developer.representatives?.map((item: Assignee) => ({
    user: { userId: item.user?.userId },
    workTypes: item.workTypes,
    order: item.order,
    canExecute: item.canExecute,
    canSign: item.canSign,
    canInspect: item.canInspect,
    startDate: item.startDate,
    endDate: item.endDate,
  })),

  executiveSchemeAssignees: developer.executiveSchemeAssignees?.map(
    (item: Assignee) => ({
      user: { userId: item.user?.userId },
      workTypes: item.workTypes,
      order: item.order,
      startDate: item.startDate,
      endDate: item.endDate,
    }),
  ),

  customer: {
    inn: customer?.inn || undefined,
    sro: customer?.sro || undefined,
    sroDate:
      (customer?.sroDate &&
        formatISO(new Date(customer?.sroDate), { representation: 'date' })) ||
      undefined,
    phone: customer?.phone?.replace(/[\s]/gi, ''),
  },
  customerAssignees: customer?.representatives?.map((item: Assignee) => ({
    user: { userId: item.user?.userId },
    workTypes: item.workTypes,
    order: item.order,
    canExecute: item.canExecute,
    canSign: item.canSign,
    canInspect: item.canInspect,
    startDate: item.startDate,
    endDate: item.endDate,
  })),

  customerTechControl: {
    inn: customerTechControl.inn || undefined,
    sro: customerTechControl.sro || undefined,
    sroDate:
      (customerTechControl.sroDate &&
        formatISO(new Date(customerTechControl.sroDate), {
          representation: 'date',
        })) ||
      undefined,
    phone: customerTechControl.phone?.replace(/[\s]/gi, ''),
  },
  customerTechControlAssignees: customerTechControl.representatives?.map(
    (item: Assignee) => ({
      user: { userId: item.user?.userId },
      workTypes: item.workTypes,
      order: item.order,
      canExecute: item.canExecute,
      canSign: item.canSign,
      canInspect: item.canInspect,
      startDate: item.startDate,
      endDate: item.endDate,
    }),
  ),
  parentJournalId: data.parentJournalId,
  permitNumber: data.buildingPermitNumber,
  permitDateOfIssue:
    (data.buildingPermitDate &&
      formatISO(new Date(data.buildingPermitDate), {
        representation: 'date',
      })) ||
    undefined,
  permitAuthorityValue: data.buildingPermitAgencyEmitter,

  prep: {
    inn: preparator.inn || undefined,
    sro: preparator.sro || undefined,
    sroDate: preparator.sroDate || undefined,
    phone: preparator.phone?.replace(/[\s]/gi, ''),
  },
  prepAssignees: preparator.representatives?.map((item: Assignee) => ({
    user: { userId: item.user?.userId },
    workTypes: item.workTypes,
    order: item.order,
    canExecute: item.canExecute,
    canSign: item.canSign,
    canInspect: item.canInspect,
    startDate: item.startDate,
    endDate: item.endDate,
  })),

  stateExamNumber: data.govExamNumber,
  stateExamDateOfIssue:
    (data.govExamDate &&
      formatISO(new Date(data.govExamDate), { representation: 'date' })) ||
    undefined,
  stateAuthorityValue: data.govExamName,

  builder: {
    inn: builder.inn || undefined,
    sro: builder.sro || undefined,
    sroDate: builder.sroDate || undefined,
    phone: builder.phone?.replace(/[\s]/gi, ''),
  },
  builderAssignees: builder.representatives?.map((item: Assignee) => ({
    user: { userId: item.user?.userId },
    workTypes: item.workTypes,
    order: item.order,
    canExecute: item.canExecute,
    canSign: item.canSign,
    canInspect: item.canInspect,
    startDate: item.startDate,
    endDate: item.endDate,
  })),
  firstAssignees: builder.representativesByCustomerOrDeveloperControl?.map(
    (item: Assignee) => ({
      user: { userId: item.user?.userId },
      workTypes: item.workTypes,
      order: item.order,
      canExecute: item.canExecute,
      canSign: item.canSign,
      canInspect: item.canInspect,
      startDate: item.startDate,
      endDate: item.endDate,
    }),
  ),
  secondAssignees: builder.representativesByBuilderControl?.map(
    (item: Assignee) => ({
      user: { userId: item.user?.userId },
      workTypes: item.workTypes,
      order: item.order,
      canExecute: item.canExecute,
      canSign: item.canSign,
      canInspect: item.canInspect,
      startDate: item.startDate,
      endDate: item.endDate,
    }),
  ),

  inspectorTitle: governmentRevisor.title,
  inspectorAddress: governmentRevisor.address,
  inspectorPhone: governmentRevisor.phone?.replace(/[\s]/gi, ''),
  inspectorFax: governmentRevisor.fax?.replace(/[\s]/gi, ''),
  inspectorEmp: governmentRevisor.executive,

  buildingName: buildingObject.title,
  projectDescription: buildingObject.characteristics,
  projectStartData:
    (buildingObject.startDate &&
      formatISO(new Date(buildingObject.startDate), {
        representation: 'date',
      })) ||
    undefined,
  projectEndData:
    (buildingObject.finishDate &&
      formatISO(new Date(buildingObject.finishDate), {
        representation: 'date',
      })) ||
    undefined,

  registrationNumber: governmentRevisor.regId,
})
