/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 *  Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 *  You may use, distribute and modify this code under the
 *  terms of the ООО АДАПТ license
 */

export enum TypeOfSros {
  UNDEFINED = 'UNDEFINED',
  SRO_CONSTRUCTION = 'SRO_CONSTRUCTION',
  SRO_ENGINEERING = 'SRO_ENGINEERING',
}

export enum SroNames {
  UNDEFINED = 'не известно',
  SRO_CONSTRUCTION = 'СРО Строительство',
  SRO_ENGINEERING = 'СРО Проектирование',
}
