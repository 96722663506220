import { FilestoreAPI } from '../FilestoreAPI'

/** Получение БЛОБа документа */
export const getBlobDocument = (id: string) => {
  return FilestoreAPI.get(`${id}/blob`).then(res => res.data)
}

/** Получение дайджеста документа */
export const getDigestDocument = (id: string) => {
  return FilestoreAPI.get(`/${id}/digest`).then(res => res.data)
}
