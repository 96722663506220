/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { checkToken } from '@builddocs/data-management'
import axios from 'axios'

export const RestAPI = axios.create({
  baseURL: '/api',
})

RestAPI.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
  'Content-Type': 'application/json',
}

RestAPI.interceptors.request.use(function (config) {
  // Do something before request is sent
  return checkToken(config)
})
