/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useRecoilValue } from 'recoil'

import { User } from '../../models'

import { usersProfilesStateFamily } from './contract.container'

interface IHook {
  usersProfiles: Record<string, User>
}

export const useUsersProfiles = (contractId: string): IHook => {
  const usersProfiles = useRecoilValue(usersProfilesStateFamily(contractId))

  return {
    usersProfiles,
  }
}
