// Валидно если date передается в формате гггг-мм--дд
// Дата перехода на новую форму АОСР и АООК  - 01.09.2023
export const isNewActType = (date: string): boolean => {
  if (!date) {
    return false
  }
  const [year, month, day] = date.split('-')

  return !(2023 > +year || 9 > +month || 1 > +day)
}
