import { useState } from 'react'

import { useSetRecoilState } from 'recoil'

import { externalSigningData } from './external.container'
import { getExternalReq } from './external.service'
import { ExternalSigning } from './ExternalSigning.type'

export const useExternal = () => {
  const [isExternalLoading, setIsExternalLoading] = useState(false)
  const setExternalSigningValue = useSetRecoilState<
    ExternalSigning | undefined
  >(externalSigningData)

  const getExternal = async (signId: string) => {
    setIsExternalLoading(true)
    const value = await getExternalReq(signId)

    setExternalSigningValue(value)

    setIsExternalLoading(false)
  }

  return {
    getExternal,
    isExternalLoading,
  }
}
