/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { UUID } from '../../models'
import { RestAPI } from '../RestAPI'

import { TokenManipulate } from './hooks'

/**
 * Test Auth.
 * @return {promise} result of testing.
 */
export function testAuth() {
  return RestAPI.get('/login/testauth')
}

export type SignUpProps = {
  firstName: string
  lastName: string
  middleName?: string
  phone: string
  email: string
  password: string
}

/**
 * Регистрация нового пользователя в системе
 */
export function signUp({
  firstName,
  lastName,
  middleName,
  phone,
  email,
  password,
}: SignUpProps) {
  return RestAPI.post(
    `/registration/${password}`,
    {
      firstName,
      lastName,
      middleName,
      phone: `+${phone.replace(/[^0-9]/g, '')}`,
      email: email.toLowerCase(),
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

/**
 * Login request
 * @param {string} username
 * @param {string} password
 * @returns {Promise}
 */
export function login(username: string, password: string) {
  return RestAPI.post(
    '/login',
    { username, password },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).then(response => {
    TokenManipulate.print(response.data.jwtToken, response.data.refreshToken)
    return response
  })
}

/**
 * Logout system
 */
export const logout = () => TokenManipulate.remove()

export const sendEmailForPassword = (email: string) =>
  RestAPI.get(`/users/requestpassword/${email}`)

export const resetPassword = (requestId: UUID, password: string) =>
  RestAPI.put(`/users/resetpassword/${requestId}/${password}`)

export function loginExtSign(signId: string): Promise<any> {
  return RestAPI.post(`/login/external/${signId}`).then(response =>
    TokenManipulate.print(response.data.jwtToken, response.data.refreshToken),
  )
}

export const refreshToken = (refreshToken: string, type = 'Bearer') =>
  RestAPI.post('/tokens/jwt/refresh', refreshToken, {
    headers: {
      Authorization: type + ' ' + refreshToken,
      'Content-Type': 'text/plain',
    },
  }).then(res => res.data)

/**
 * Авторизация через ГК "Основа"
 */
export const sendAccessToken = (code: string) =>
  RestAPI.post(`/oauth/login/osnova`, {}, { params: { code } }).then(
    response => {
      TokenManipulate.print(response.data.jwtToken, response.data.refreshToken)
      return response
    },
  )
