/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 *  Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 *  You may use, distribute and modify this code under the
 *  terms of the ООО АДАПТ license
 */

/** Pseudo protected */
export const generateUniqueId = (length?: number) =>
  Math.random()
    .toString(36)
    .substr(2, length ?? 6)
