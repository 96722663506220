import { UUID } from '@builddocs/data-management'

export enum Integration {
  EXON = 'EXON',
  ULAB = 'ULAB',
  CYNTEKA = 'CYNTEKA',
}

export type IntegrationKeys =
  | Integration.EXON
  | Integration.ULAB
  | Integration.CYNTEKA

export type IntegrationType = {
  actIds: Record<string, UUID>
  externalIds: string[]
}
