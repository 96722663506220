/**
 * Очистка кэша браузера и перезагрузка страницы при необходимости
 * @param {boolean} reloadPage по умолчанию true
 * @returns {void} void
 */
export const clearBrowserCache = (reloadPage = true) => {
  if ('caches' in window) {
    caches.keys().then(names => {
      names.forEach(async name => {
        await caches.delete(name)
      })
    })
    reloadPage && window.location.reload()
  }
}
