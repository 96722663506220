/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Library } from '@builddocs/exdoc-app/data-access-translate'
import { AxiosError } from 'axios'
import { useSnackbar, VariantType } from 'notistack'
import { useTranslation } from 'react-i18next'

import { PropertyStringPath } from '../utils'

const variants: Array<VariantType> = [
  'default',
  'error',
  'success',
  'warning',
  'info',
]

/**
 * Тост со встроенным переводом
 * Обёртка для удобного доступа к Snackbar из MUI
 */
export const useToast = () => {
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  /**
   * Показать тост с сообщением
   */
  const jumpToast = function (variant: VariantType) {
    /**
     * Показать тост с простым сообщением
     */
    function enqueueBaseSnackbar(msg: string) {
      return enqueueSnackbar(msg, {
        variant,
      })
    }

    /**
     * Показать тост с переводом
     */
    enqueueBaseSnackbar.t = (key: string) => {
      enqueueSnackbar(translate(key), {
        variant,
      })
    }

    /**
     * Получение тоста с ошибкой по коду
     */
    enqueueBaseSnackbar.exception = (error, fallbackMessageKey) => {
      const errorCode =
        typeof error === 'string' || typeof error === 'number'
          ? error
          : error?.response?.data.statusCode

      const message = errorCode
        ? translate(
            (fallbackMessageKey
              ? [
                  `exceptions.${errorCode}`,
                  fallbackMessageKey,
                  'error.unspecific',
                ]
              : `exceptions.${errorCode}`) as string | string[],
          )
        : `Ошибка: ${error?.response?.data.status}`

      return enqueueSnackbar(message, {
        variant,
      })
    }

    return enqueueBaseSnackbar
  }

  const toast = variants.reduce(
    (acc, variant) => ({
      ...acc,
      [variant]: jumpToast(variant),
    }),
    {} as ToastType,
  )

  /**
   * Перевод кода ошибки в текст
   */
  const getErrorMessage = (
    error: number | string | AxiosError<BackendResponse> | undefined,
    fallbackMessageKey = '',
  ) => {
    const errorCode =
      typeof error === 'string' || typeof error === 'number'
        ? error
        : error?.response?.data.statusCode

    return translate([
      `exceptions.${errorCode}`,
      fallbackMessageKey,
      'error.unspecific',
    ])
  }

  return { toast, getErrorMessage }
}

interface BackendResponse {
  message: string
  payload: null | Record<string, string>
  statusCode: string
}

type LibraryPath = PropertyStringPath<Library>

export interface IJumpToast {
  (msg: string): void

  t: (msg: string) => void
  exception: (
    /**
     * Ошибка: код ошибки, либо текст, либо объект ошибки
     */
    error: number | string | AxiosError<BackendResponse> | undefined | unknown,
    /**
     * Ключ ошибки, если error нет в словаре
     */
    fallbackMessageKey?: LibraryPath,
  ) => void
}

export type ToastType = Record<VariantType, IJumpToast>
