/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */
import {
  PaginationModel,
  SignatureState,
  UUID,
} from '@builddocs/data-management'

import { FilestoreAPI } from '../FilestoreAPI'

import { SigningStampTask, DocumentSigningTask } from './stamp.types'

/** Отправка штампа на подписание */
export const sendStampForSigning = (
  dataStamp: SigningStampTask,
  projectId: UUID,
) =>
  FilestoreAPI.post(`/stamps`, {
    stampTemplateId: dataStamp.stampTemplateId.id,
    signers: dataStamp.signers,
    documents: dataStamp.documents,
    projectId,
  })

/** Получение шаблонов штампов или одного штампа по его ID */
export const getTemplatesStamps = (page: number, size: number) =>
  FilestoreAPI.get(`/stamp-templates?page=${page}&pageSize=${size}`).then(
    res => res.data || [],
  )

/** Получение шаблона штампа по его ID */
export const getTemplateStamp = (stampId: string) =>
  FilestoreAPI.get(`/stamp-templates/${stampId}`).then(res => res.data || [])

/** Получение штампов со статусами на документе */
export const getStampsForDocument = (
  documentId: string,
): Promise<DocumentSigningTask[]> =>
  FilestoreAPI.get(`/stamps/tasks/document?id=${documentId}`).then(
    res => res.data || [],
  )

/** Получение штампов на подписание по employmentId */
export const getTasksSignStamps = (
  employmentId: string,
  pageNumber = 0,
  pageSize = 25,
): Promise<PaginationModel<DocumentSigningTask>> =>
  FilestoreAPI.get('/stamps/tasks/signer/search', {
    params: { employmentId, pageNumber, pageSize },
  }).then(res => res.data)

/** Удалить штамп*/
export const deleteStamp = (stampTemplateId: UUID | null, documentId: UUID) =>
  FilestoreAPI.delete(`/stamps/tasks`, {
    params: { documentId: documentId, stampTemplateId: stampTemplateId },
  })

/** Обновление штампа штамп*/
export const updateStamp = (statusStamp: DocumentSigningTask) =>
  FilestoreAPI.put(`/stamps/tasks`, statusStamp)

/** Подписание/Отклонение штампа */
export const signStamp = (
  formSignStamp:
    | {
        base64signature: string
        documentId: string
        employmentId: string
        imprint: string
        keyIssuer: string
        signatureState: SignatureState.SIGNED
        stampTemplateId: string
        certificateDateStart: string
        certificateDateEnd: string
        faceId: {
          faceImage?: string
          faceImageBytes?: string
          keySerialNumber: string
        }
      }
    | {
        documentId: string
        employmentId: string
        signatureState: SignatureState.REJECTED
        stampTemplateId: string
        comment: string
      },
) => {
  return FilestoreAPI.post(`/sign`, formSignStamp, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

type PrepareStampParams = {
  base64signature: null
  documentId: string
  employmentId: string
  imprint: string
  keyIssuer: string
  /**
   * Имя, которое будет поставлено в штамп
   */
  name: string
  signatureState: SignatureState.SIGNED
  stampTemplateId: string
  certificateDateStart: string
  certificateDateEnd: string
  faceId: {
    faceImage?: string
    faceImageBytes?: string
    keySerialNumber: string
  }
}

/** Подготовка штампа */
export const prepareStamp = (formSignStamp: PrepareStampParams) =>
  FilestoreAPI.post(`/prepare`, formSignStamp, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.data)

/** Подготовка списка штампов */
export const getDigestAndPrepareListStamps = (formSignStamp: {
  faceId: {
    faceImage?: string
    faceImageBytes?: string
    keySerialNumber: string
  }
  signRequests: {
    certificateDateEnd: string
    certificateDateStart: string
    documentId: string
    employmentId: string
    imprint: string
    keyIssuer: string
    name: string
    signatureState: SignatureState.PENDING
    stampTemplateId: string
  }[]
}) =>
  FilestoreAPI.post(`/bulk-prepare`, formSignStamp, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.data)

/** Подписание комплекта штампов */
export const multipleSignStamps = (
  formSignStamp:
    | {
        faceId: {
          faceImage?: string
          faceImageBytes?: string
          keySerialNumber: string
        }
        signRequests: {
          base64signature: string
          comment: string
          documentId: string
          employmentId: string
          imprint: string
          keyIssuer: string
          signatureState: SignatureState.SIGNED
          stampTemplateId: string
        }[]
      }
    | {
        signRequests: {
          comment: string
          documentId: string
          employmentId: string
          signatureState: SignatureState.REJECTED
          stampTemplateId: string
        }[]
      },
) => {
  return FilestoreAPI.post(`/bulk-sign`, formSignStamp, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
