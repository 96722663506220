import { UUID } from '../../../models/uuid'

import { EmploymentType, EmploymentStatus } from './EmploymentController.type'

export interface EmploymentNotification {
  senderName: string
  receiverName: string
  senderId: UUID
  receiverId: UUID
  senderEmail: null
  receiverEmail: null
  businessKey: UUID
  notificationId: UUID
  positionName: string
  orderName: string
  orderDate: string
  status: EmploymentStatus
  type: EmploymentType
  createDateTime: string
  solved: boolean
  viewed: boolean
}
