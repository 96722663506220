import { atomFamily } from 'recoil'

import { WorkDocSection } from './rest'

const localStorageEffect =
  key =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)

    if (savedValue != null && savedValue != 'undefined') {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }

export const workDocSectionAtom = atomFamily<
  WorkDocSection | undefined,
  string
>({
  key: 'workDocSectionState',
  default: undefined,
  effects_UNSTABLE: contractId => [localStorageEffect(contractId)],
})
