/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { User, UUID } from '../../models'
import { RestAPI } from '../RestAPI'

export const getCurrentUser = (): Promise<User> =>
  RestAPI.get('/users/current').then(res => res.data || {})

export const updateCurrentUserProfile = (
  data: Omit<User, 'employment' | 'role' | 'createDateTime'>,
) => RestAPI.put('/users/user', data)

type UpdatePasswordProps = {
  oldPassword: string
  newPassword: string
}
export const updateCurrentUserPassword = ({
  oldPassword,
  newPassword,
}: UpdatePasswordProps) =>
  RestAPI.put(`/users/credentials/${oldPassword}/${newPassword}`)

export const getUserByID = (userId: UUID): Promise<User> =>
  RestAPI.get(`/users/user/${userId}`).then(res => res.data || {})

export const getUserByEmploymentId = (employmentId: UUID): Promise<User> =>
  RestAPI.get(`/users/byemploymentid/${employmentId}`).then(
    res => res.data || {},
  )

export const switchRoles = (adminId: UUID, employeeId: UUID) =>
  RestAPI.post(
    `/users/swap-roles`,
    {},
    { params: { 'user-id-off': adminId, 'user-id-add': employeeId } },
  )
