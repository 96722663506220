import { useMemo, useState } from 'react'

import {
  DocumentEventEnum,
  DocumentEventType,
  DocumentHistoryType,
} from '@builddocs/filestore-api'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from '@mui/lab'
import {
  Typography,
  Card,
  CardActions,
  Button,
} from '@mui/material'
import Popover from '@mui/material/Popover'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import { ru } from 'date-fns/locale'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'

import UserInfoBubble from '../user-info-bubble/UserInfoBubble'
import MenuItem from '@mui/material/MenuItem'

type OwnProps = {
  documentHistory: DocumentHistoryType
}

const getColor = (type: DocumentEventType) => {
  switch (type) {
    case 'CREATED':
      return 'newable.main'

    case 'UPDATED':
    case 'ADDED_TO_CLOSING':
    case 'SENT_ON_APPROVAL':
    case 'MODIFIED_AOSR_ACT_DATE':
    case 'ON_EXON_APPROVAL':
    case 'SENT_ON_SIGNING':
    case 'SENT_ON_EXON_APPROVAL':
    case 'SENT_ON_EXON_REAPPROVAL':
    case 'SENT_ON_EXON_SIGNING':
    case 'EXON_REVISION':
      return 'processing.main'

    case 'REMOVED_FROM_CLOSING':
    case 'CANCELLED':
    case 'EXON_CANCELLED':
    case 'DELETED':
      return 'rejected.main'

    case 'APPROVED':
    case 'SIGNED':
    case 'EXON_APPROVED':
    case 'EXON_SIGNED':
      return 'accepted.main'

    default:
      return 'grey'
  }
}

const SPLIT_LENGTH = 3

export const DocumentHistory = ({ documentHistory }: OwnProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const reversedHistory = useMemo(
    () => [...documentHistory?.documentEvents]?.reverse(),
    [documentHistory],
  )

  const splittedHistoryStart = useMemo(
    () => reversedHistory.slice(0, SPLIT_LENGTH),
    [reversedHistory],
  )

  const handleExpandClick = () => {
    setIsExpanded(expanded => !expanded)
  }

  const hasHidden = documentHistory?.documentEvents?.length > SPLIT_LENGTH

  return (
    <PopupState
      popupId={`previewPopper_${documentHistory?.documentId}`}
      variant="popover"
    >
      {popupState => (
        <div>
          <MenuItem {...bindTrigger(popupState)}>
            История акта
          </MenuItem>
          <Popover
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            {...bindPopover(popupState)}
          >
            <Card elevation={6}>
              <Typography color="text.secondary" ml={1} variant="overline">
                История
              </Typography>

              <Timeline>
                {(isExpanded ? reversedHistory : splittedHistoryStart).map(
                  (documentEvent, index, arr) => (
                    <TimelineItem key={documentEvent.dateTime}>
                      <TimelineOppositeContent maxWidth="120px">
                        {isValid(new Date(documentEvent.dateTime)) && (
                          <Typography variant="caption">
                            {format(new Date(documentEvent.dateTime), 'PPp', {
                              locale: ru,
                            })}
                          </Typography>
                        )}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            backgroundColor: getColor(documentEvent.type),
                          }}
                        />
                        {(index < arr.length - 1 ||
                          (hasHidden && !isExpanded)) && <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent maxWidth="130px">
                        {DocumentEventEnum[documentEvent.type]}
                        {documentEvent.type !== 'SENT_ON_SIGNING' && (
                          <UserInfoBubble userId={documentEvent.userId} />
                        )}
                      </TimelineContent>
                    </TimelineItem>
                  ),
                )}
              </Timeline>
              {hasHidden && (
                <CardActions>
                  <Button fullWidth variant="tonal" onClick={handleExpandClick}>
                    {isExpanded ? 'Скрыть' : 'Показать всю историю'}
                  </Button>
                </CardActions>
              )}
            </Card>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}
