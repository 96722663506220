/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import {
  getUserByEmploymentId,
  getUserByID,
  UUID,
} from '@builddocs/data-management'
import { TypographyProps } from '@mui/material'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import UserInfoPopover from './UserInfoPopover'

import type { User } from '@builddocs/data-management'

export type UserInfoPopoverProps =
  | {
      userId?: UUID
      employmentId: UUID
      labelProps?: TypographyProps
      userData?: User
    }
  | {
      userId: UUID
      employmentId?: UUID
      labelProps?: TypographyProps
      userData?: User
    }
  | {
      userId?: UUID
      employmentId?: UUID
      userData: User
      labelProps?: TypographyProps
    }

export function UserInfoBubble(props: UserInfoPopoverProps) {
  const { employmentId, userId, labelProps, userData } = props

  /** Получение User по ID или по employmentId */
  const {
    data: user,
    isLoading,
    error,
  } = useQuery<User, AxiosError>(
    ['userByID', userId, employmentId],
    async () =>
      userId
        ? await getUserByID(userId)
        : employmentId
        ? await getUserByEmploymentId(employmentId as UUID)
        : (userData as User),
    { staleTime: 1000 * 15 * 60 },
  )

  return (
    <UserInfoPopover
      error={
        error
          ? {
              message: error.message,
              code: error.response?.status ?? 666,
            }
          : undefined
      }
      isLoading={isLoading}
      labelProps={labelProps}
      user={userData ?? user}
    />
  )
}

export default UserInfoBubble
