/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useCallback, useEffect, useState } from 'react'

import { useSetRecoilState } from 'recoil'

import { linkedMaterialsAtom } from './matlink.container'
import { getLinkedMaterials } from './matlink.service'

export type LinkedUsedItem = {
  projectMaterialId: string | null
  materialId: string | null
  materialName: string | null
  unit: string | null
}
export type LinkedUsedArray = LinkedUsedItem[]
interface IMatlinkHook {
  refreshMatlink: () => void
  isLoading: boolean
}

export const useMatlink = (
  contractId: string | undefined,
  workDocSection: string | undefined,
): IMatlinkHook => {
  const setAllLinkedMaterials = useSetRecoilState(linkedMaterialsAtom)
  const [isLoading, setIsLoadig] = useState(false)

  const fetchMatlink = useCallback(async () => {
    if (!workDocSection) return
    setIsLoadig(true)
    const data = await getLinkedMaterials(contractId, workDocSection)

    setAllLinkedMaterials(data)
    setIsLoadig(false)
  }, [contractId, setAllLinkedMaterials, workDocSection])

  useEffect(() => {
    fetchMatlink()
  }, [fetchMatlink])

  return {
    refreshMatlink: fetchMatlink,
    isLoading,
  }
}
