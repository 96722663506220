import React from 'react'

import { Box, Stack, Typography } from '@mui/material'

export interface UserInfoProps {
  email: string
  phone: string
  avatar: React.ReactElement
  status?: string
  statusColor?: string
  position?: string
}

export function UserInfo({
  email,
  phone,
  avatar,
  status,
  statusColor = 'success.main',
  position,
}: UserInfoProps) {
  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex' }}>
        <Box>{avatar}</Box>
        <Stack mt={0} pl={1} pt={0} spacing={1}>
          <a href={`mailto:${email}`} title="Написать письмо">
            {email}
          </a>
          <a href={`tel:${phone}`} title="Позвонить">
            {phone}
          </a>
          <Typography>{position ?? ''}</Typography>
        </Stack>
      </Box>
      <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
        <Typography>Статус:</Typography>
        <Typography color={statusColor}>{status}</Typography>
      </Stack>
    </Box>
  )
}

export default UserInfo
