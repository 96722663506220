/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export type PairId = {
  projectMaterialId: string
  estimateMaterialId: string
  locked: boolean
}

export interface ITotalAmount {
  totalAmount: number | null
  workMaterialUnit: string | null
}

export interface IUsedWorkMaterial {
  journalInvoiceTitle: string | null
  usedAmount: number | null
  workMaterialId: string | null
  workMaterialName: string | null
  workMaterialUnit: string | null
  workTitle: string | null
}

export interface LinkedMaterial {
  materialType: string | null
  projectMaterialId: string | null
  projectMaterialName: string | null
  projectMaterialUnit: string | null
  projectMaterialAmount: number | null
  estimateMaterialId: string | null
  estimateMaterialName: string | null
  estimateMaterialAmount: number | null
  estimateMaterialUnit: string | null
  locked: boolean
  totalAmounts: Array<ITotalAmount>
  usedWorkMaterials: Array<IUsedWorkMaterial>
}
