/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { FC, useState } from 'react'

import {
  commentsLogService,
  useCoordinator,
  CommentItem,
  UUID,
} from '@builddocs/data-management'
import { useToast } from '@builddocs/system'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import {
  IconButton,
  Stack,
  FormControlLabel,
  TextField,
  Checkbox,
  Box,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

type Props = {
  comment: CommentItem
  forceContractId?: UUID
  onClose: () => void
  refreshComments: () => void
  withoutDeadline?: boolean
}

export const EditComment: FC<Props> = ({
  comment,
  forceContractId,
  onClose,
  refreshComments,
  withoutDeadline,
}) => {
  const { toast } = useToast()

  const { handleSubmit, errors, control } = useForm({
    defaultValues: {
      resolved: comment.resolved,
      information: comment.information,
      problemDescription: comment.problemDescription,
      deadline: comment.deadline,
    },
  })
  const { contractId } = useCoordinator() as { contractId: string }

  const [isLoading, setIsLoading] = useState(false)

  const onUpdate = (data: any) => {
    setIsLoading(true)
    commentsLogService
      .update(forceContractId || contractId, {
        ...data,
        commentId: comment.commentId,
      })
      .then(() => {
        onClose()
        refreshComments()
      })
      .catch(() => {
        toast.error('Не удалось обновить запись')
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <form style={{ position: 'relative' }} onSubmit={handleSubmit(onUpdate)}>
      <Stack mt={1} spacing={1}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={2}>
            <Controller
              as={
                <TextField
                  fullWidth
                  multiline
                  disabled={isLoading}
                  error={!!errors?.information?.message}
                  helperText={errors?.information?.message}
                  label="Место в документах"
                  name="information"
                  size="small"
                />
              }
              control={control}
              name="information"
              rules={{
                required: 'Должно быть заполнено',
              }}
            />
            <Controller
              as={
                <TextField
                  fullWidth
                  multiline
                  disabled={isLoading}
                  error={!!errors?.problemDescription?.message}
                  helperText={errors?.problemDescription?.message}
                  label="Комментарий"
                  name="problemDescription"
                  size="small"
                />
              }
              control={control}
              name="problemDescription"
              rules={{
                required: 'Должно быть заполнено',
              }}
            />
          </Stack>
          {!withoutDeadline && (
            <Box>
              <Controller
                as={
                  <TextField
                    multiline
                    disabled={isLoading}
                    error={!!errors?.deadline?.message}
                    helperText={errors?.deadline?.message}
                    label="Срок устранения"
                    name="deadline"
                    size="small"
                  />
                }
                control={control}
                name="deadline"
                rules={{
                  required: 'Должно быть заполнено',
                }}
              />
            </Box>
          )}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Controller
            control={control}
            name="resolved"
            render={({ onChange, onBlur, value, ref, ...rest }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    inputRef={ref}
                    onBlur={onBlur}
                    onChange={e => onChange(e.target.checked)}
                    {...rest}
                  />
                }
                disabled={isLoading}
                label="Замечание устранено"
                {...rest}
              />
            )}
          />
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="Update this position"
              size="small"
              type="submit"
            >
              <CheckRoundedIcon fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="Cancel update"
              size="small"
              onClick={onClose}
            >
              <ClearRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </form>
  )
}

export default EditComment
