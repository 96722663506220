/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React, { FC } from 'react'

import {
  User,
  UUID,
  formatPhoneNumber,
  getAvatarSource,
  getUserByID,
  getUserByEmploymentId,
} from '@builddocs/data-management'
import { Avatar, Box, Popover, Skeleton, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import {
  usePopupState,
  bindToggle,
  bindPopover,
} from 'material-ui-popup-state/hooks'
import { useQuery } from 'react-query'

import UserField from './UserField'
import UserInfo from './UserInfo'

interface IProps {
  userInitial?: User
  userEmploymentId?: UUID
  statusColor?: string
  currentUserId?: UUID
  isFullName?: boolean
  icon?: React.ReactElement
  status?: string
  color?: string
}

export const UserInfoPopoverWithStatus: FC<IProps> = ({
  userInitial = {} as User,
  userEmploymentId,
  status,
  statusColor,
  isFullName = false,
  icon,
  currentUserId,
  color,
}: IProps) => {
  /** Получение User по ID или по employmentId */
  const {
    data: user,
    isLoading,
    error,
  } = useQuery<User, AxiosError>(
    ['userByID', currentUserId, userEmploymentId],
    async () =>
      currentUserId
        ? await getUserByID(currentUserId)
        : userEmploymentId
        ? await getUserByEmploymentId(userEmploymentId as UUID)
        : (userInitial as User),
    { staleTime: 1000 * 15 * 60 },
  )

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'adviceBubble',
  })

  const [avatar, setAvatar] = React.useState<string>('')

  // React.useEffect(() => {
  //   getAvatarSource(user?.userId).then(avatar => setAvatar(avatar))
  // }, [])

  if (isLoading) return <Skeleton width={150} />
  if (error) return <Typography color="error">{error.message}</Typography>

  return (
    <>
      {user && user?.userId ? (
        <Box {...bindToggle(popupState)}>
          <UserField
            color={color ? color : 'primary'}
            icon={icon}
            name={
              isFullName
                ? `${user.lastName ?? ''} ${user.firstName ?? ''} ${
                    user.middleName ?? ''
                  }`
                : `${user.firstName ?? ''} ${user.lastName ?? ''}`
            }
          />
        </Box>
      ) : (
        <Typography>Грузится...</Typography>
      )}
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {user && (
          <UserInfo
            avatar={<Avatar src={avatar} variant="square" />}
            email={user.email}
            phone={formatPhoneNumber(user.phone) ?? ''}
            position={user.employment?.position?.description}
            status={status}
            statusColor={statusColor}
          />
        )}
      </Popover>
    </>
  )
}

export default UserInfoPopoverWithStatus
