/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useEffect } from 'react'

import { Organization } from '../models'

import { searchOrganizationByInn } from './dadata'
import { getInnOrKpp } from './getInnOrKpp'
import { useStickyState } from './useStickyState'

export const useDadataOrganization = (
  inn?: string,
  organization?: Organization,
): { data: (Organization & { value: string; kpp: string }) | undefined } => {
  const [_organization, setOrganization] = useStickyState<
    (Organization & { value: string; kpp: string }) | undefined
  >(undefined, inn, 'session')

  const organizationInn = _organization?.inn

  useEffect(() => {
    const fetchOrg = async (__inn: string, kpp?: string) => {
      const org = await searchOrganizationByInn(__inn)

      if (!org) {
        return
      }
      const { data, value } = org

      const { address, inn: _inn, ogrn, ogrn_date: ogrnDate } = data ?? {}

      setOrganization({
        inn: _inn,
        kpp: kpp ?? '',
        value,
        name: value,
        ogrn,
        legalAddress: address,
        ogrnDate: ogrnDate,
        phone: organization?.phone,
        sro: organization?.sro,
        sroDate: organization?.sroDate,
        sroIssuerName: organization?.sroIssuerName,
        sroIssuerInn: organization?.sroIssuerInn,
        sroIssuerOgrn: organization?.sroIssuerOgrn,

        sroEngineering: organization?.sroEngineering,
        sroEngineeringDate: organization?.sroEngineeringDate,
        sroEngineeringInn: organization?.sroEngineeringInn,
        sroEngineeringName: organization?.sroEngineeringName,
        sroEngineeringOgrn: organization?.sroEngineeringOgrn,
      })
    }
    const innKpp = getInnOrKpp(inn)

    if (
      innKpp.inn &&
      innKpp.inn !== _organization?.inn &&
      inn !== _organization?.inn
    ) {
      fetchOrg(innKpp.inn, innKpp.kpp)
    } else if (
      _organization &&
      (_organization?.inn === innKpp.inn || inn !== _organization?.inn)
    ) {
      setOrganization({
        ..._organization,
        kpp: innKpp.kpp ?? '',
        phone: organization?.phone,
        sro: organization?.sro,
        sroDate: organization?.sroDate,
        sroIssuerName: organization?.sroIssuerName,
        sroIssuerInn: organization?.sroIssuerInn,
        sroIssuerOgrn: organization?.sroIssuerOgrn,

        sroEngineering: organization?.sroEngineering,
        sroEngineeringDate: organization?.sroEngineeringDate,
        sroEngineeringInn: organization?.sroEngineeringInn,
        sroEngineeringName: organization?.sroEngineeringName,
        sroEngineeringOgrn: organization?.sroEngineeringOgrn,
      })
    }
  }, [organizationInn, inn, organization, setOrganization])

  return { data: _organization }
}
