/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export enum AssigneeTypes {
  UNDEFINED_AUTH_USER = 'UNDEFINED_AUTH_USER',
  /** Ген. подрядчик */
  DEVELOPER = 'DEVELOPER',
  /** Заказчик */
  CUSTOMER = 'CUSTOMER',
  /** Подрядчик */
  BUILDER = 'BUILDER',
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  /** Лицо по вопросам строительного контроля */
  CUSTOMER_TECH_CONTROL = 'CUSTOMER_TECH_CONTROL',
  UNDEFINED = 'UNDEFINED',
  PREP = 'PREP',
}

export enum Roles {
  UNDEFINED_ROLE = 0,
  ADMIN = 'ADMIN',
  USER = 'USER',
  WORKER = 'WORKER',
  PRORAB = 'PRORAB',
}

export enum OrganizationRoles {
  CUSTOMER_TECH_CONTROL = 'Застройщик',
  PREP = 'Проектная организация',
  DEVELOPER = 'Лицо, осущ. строительство',
  BUILDER = 'Организация, вып. строительство',
  CUSTOMER = 'Иные организации',
  UNDEFINED = '',
}
