/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { track } from '@amplitude/analytics-browser'
import { useToast } from '@builddocs/system'
import { useNavigate } from 'react-router-dom'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { SroNames, TypeOfSros } from '../../constants'
import { Organization } from '../../models'
import routes from '../../routes'
// import { useCurrentUser } from '../current-user'
import { TokenManipulate } from '../auth/hooks'
import { useRefreshCurrentUser } from '../current-user'

import {
  organizationQuery,
  organizationQueryRequestIDState,
} from './Organization.container'
import {
  createOrganization,
  deleteSroOrganization,
  updateOrganization,
} from './organization.service'

interface IOrganizationHook {
  isLoading: boolean
  organization: Organization | undefined
  error?: Error
  refreshOrganization: () => void
  update: (data: Organization) => void
  create: (data: Organization) => void
  deleteSro: (sroType: TypeOfSros) => void
}

export const useOrganization = (inn?: string): IOrganizationHook => {
  const organizationLoadable = useRecoilValueLoadable(organizationQuery(inn))
  const setRequestID = useSetRecoilState(organizationQueryRequestIDState(inn))
  const navigate = useNavigate()
  const refreshCurrentUser = useRefreshCurrentUser()

  const { toast } = useToast()

  const refreshOrganization = () => setRequestID(value => value + 1)

  const organization =
    organizationLoadable.state === 'hasValue'
      ? organizationLoadable.contents
      : undefined

  const isLoading = organizationLoadable.state === 'loading'

  let error =
    organizationLoadable.state === 'hasError'
      ? organizationLoadable.contents
      : undefined

  const update = async (newValue: Organization) => {
    try {
      await updateOrganization(newValue)

      TokenManipulate.refresh(localStorage.getItem('token') as string).then(
        async () => {
          refreshOrganization()
          await refreshCurrentUser()

          toast.success('Изменения сохранены')
        },
      )
    } catch (err) {
      error = err
      toast.error('Не удалось обновить организацию')
    }
  }

  const create = async (newValue: Organization) => {
    try {
      await createOrganization(newValue)
      track('create_organization', {
        inn: newValue.inn,
        name: newValue.name,
      })

      TokenManipulate.refresh(localStorage.getItem('token') as string).then(
        async () => {
          refreshOrganization()
          await refreshCurrentUser()

          navigate(routes.organization.base())
          toast.success('Организация успешно создана')
        },
      )
    } catch (err) {
      error = err
      toast.error('Не удалось создать организацию')
    }
  }

  /**
   * Удаление одного отдельного вида СРО из организации
   */
  const deleteSro = async (sroType: TypeOfSros) => {
    try {
      await deleteSroOrganization(sroType)
      await TokenManipulate.refresh(localStorage.getItem('token') as string)
      refreshOrganization()
      // refreshCurrentUser()
      // push(routes.organization.base())
      toast.success(`${SroNames[sroType]} успешно удалён`)
    } catch (err) {
      error = err
      toast.error('Не удалось удалить СРО')
    }
  }

  return {
    organization,
    isLoading,
    error,
    refreshOrganization,
    update,
    create,
    deleteSro,
  }
}
