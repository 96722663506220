/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import {
  inventoryQuery,
  inventoryQueryRequestIDState,
} from './Inventory.container'
import { InventoryItem } from './inventory.type'

type OwnProps = {
  contractId: string
  pageNumber?: number
  pageSize?: number
  searchValue?: string
  searchValueDate?: string
}

interface IInventoryHook {
  isLoading: boolean
  inventory: InventoryItem[]
  pageNumber: number
  pageSize: number
  pageCount: number
  refreshInventory: () => void
  error?: Error
}

export const useInventory = ({
  contractId,
  pageNumber,
  pageSize,
  searchValue,
  searchValueDate,
}: OwnProps): IInventoryHook => {
  const inventoryLoadable = useRecoilValueLoadable(
    inventoryQuery({
      contractId,
      pageNumber,
      pageSize,
      searchValue,
      searchValueDate,
    }),
  )
  const setRequestID = useSetRecoilState(
    inventoryQueryRequestIDState(contractId),
  )

  const refreshInventory = () => setRequestID(requestID => requestID + 1)

  const data =
    inventoryLoadable.state === 'hasValue'
      ? inventoryLoadable.contents
      : {
          pageCount: 0,
          pageNumber: 0,
          pageSize: 0,
          entityList: [],
        }

  const isLoading = inventoryLoadable.state === 'loading'

  const error =
    inventoryLoadable.state === 'hasError'
      ? inventoryLoadable.contents
      : undefined

  return {
    inventory: data?.entityList,
    pageNumber: data?.pageNumber,
    pageSize: data?.pageSize,
    pageCount: data?.pageCount,
    isLoading,
    error,
    refreshInventory,
  }
}
