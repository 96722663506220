import { ActForm, UUID, WorkType } from '@builddocs/data-management'

import { FilestoreAPI } from '../FilestoreAPI'

/** Получение JSON secondaryAct по actId*/
export const getJSONSecondaryActById = (actId: UUID) =>
  FilestoreAPI.get(`/secondary-act/${actId}/json`).then(res => res.data)

/** Обновление JSON secondaryAc по actId*/
export const updateJSONSecondaryAct = (
  actId: UUID,
  json: Record<string, any>,
) =>
  FilestoreAPI.put(
    `/secondary-act/${actId}/json`,
    {},
    { params: { json: json } },
  )

/** Получение производных актов по типу акта (не используется)*/
export const getSecondaryActsList = (_journalId: UUID, actType: ActForm) =>
  FilestoreAPI.get(`/secondary-act?type=${actType}`).then(res => res.data)

export const getSecondaryActsListSearch = (
  actType: keyof typeof ActForm,
  closingId: UUID,
  workType: WorkType,
) =>
  FilestoreAPI.post(`/secondary-act/search`, {
    actType,
    workType,
    closingId,
  }).then(res => res.data)

/** Получение secondaryAct по actId*/
export const getSecondaryActById = (actId: UUID) =>
  FilestoreAPI.get(`/secondary-act/${actId}`).then(res => res.data)

/** Обновление производных актов по actId*/
export const updateSecondaryAct = (actId: UUID) =>
  FilestoreAPI.put(`/secondary-act/${actId}`, {}).then(res => res.data)

/** Удаление производного акта по actId*/
export const deleteSecondaryAct = (actId: UUID) =>
  FilestoreAPI.delete(`/secondary-act/${actId}`)

/** Получение секондари-актов по actId (АОСР)*/
export const getSecondaryActsbyAosr = (
  selectedWorkType: WorkType,
  actId: UUID,
) =>
  FilestoreAPI.get(
    `/secondary-act/list?workType=${selectedWorkType}&aosrId=${actId}`,
  ).then(res => res.data)

/** Получение ЖОР проекта*/
export const getProjectZHOR = (projectId: UUID) =>
  FilestoreAPI.get(`/secondary-act/${projectId}/ZHOR`).then(res => res.data)
