/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */
import { FC, useMemo } from 'react'

import {
  commentsLogService,
  useCoordinator,
  useCurrentUser,
  UUID,
  CommentItem,
  User,
  getUserByEmploymentId,
} from '@builddocs/data-management'
import { useToast } from '@builddocs/system'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Chip, IconButton, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import { UserCardInCommentCard } from './UserCardInCommentCard'

interface OwnProps {
  comment: CommentItem
  forceContractId?: UUID
  refreshComments: () => void
  onEdit: () => void
}

type Props = OwnProps

export const CommentCard: FC<Props> = props => {
  const { comment, refreshComments, onEdit } = props
  const { user } = useCurrentUser()
  const canEdit = useMemo(
    () => user.employment.employmentId === comment.employmentId,
    [user, comment],
  )

  /** Получение User по employmentId */
  const { data: userFromComment } = useQuery<User, AxiosError>(
    ['userByEmploymentId', comment.employmentId],
    async () =>
      canEdit ? user : await getUserByEmploymentId(comment.employmentId),
  )
  const { contractId } = useCoordinator() as { contractId: string }
  const { toast } = useToast()

  const handleRemove = (commentId: UUID) => {
    commentsLogService
      .deleteComment({
        journalId: props.forceContractId || contractId,
        commentId,
      })
      .then(() => refreshComments())
      .catch(error => toast.error.exception(error))
  }

  return (
    <Stack spacing={1}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        {userFromComment && (
          <UserCardInCommentCard comment={comment} user={userFromComment} />
        )}

        <Stack alignItems="center" direction="row" spacing={1}>
          <Chip
            color={comment.resolved ? 'success' : 'warning'}
            label={comment.resolved ? 'Устранено' : 'Не устранено'}
            size="small"
            sx={{ mr: 2 }}
            variant="outlined"
          />
          <IconButton
            aria-label="edit-comment"
            disabled={!canEdit}
            size="small"
            onClick={() => onEdit()}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete-comment"
            disabled={!canEdit}
            size="small"
            onClick={() => handleRemove(comment.commentId)}
          >
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
      <Typography pl={5}>{comment.problemDescription}</Typography>
      {comment.information && (
        <Typography pl={5} variant="body2">
          <Typography color="text.secondary" component="span" variant="inherit">
            Место в документе:
          </Typography>
          {` ${comment.information}`}
        </Typography>
      )}
    </Stack>
  )
}

export default CommentCard
