import { useQuery, useQueryClient } from 'react-query'

import { UUID } from '../../../models'
import { ActKsEnum, ResponseKSType } from '../ks.model'
import { getKSList } from '../KS.service'

interface KSHookProps {
  contractId: UUID | undefined
  ksName: ActKsEnum
  page?: number
  perPage?: number
}

interface IKSHookResponse {
  ksList: (ResponseKSType & { ksName: ActKsEnum }) | undefined
  refreshKSList: (ksName: ActKsEnum) => Promise<void>
  // updateClosingLocal: (nextData: Record<string, any>) => void
  isLoading: boolean
  isFetching: boolean
  error?: unknown
  isError?: boolean
}

export const useKS = (props: KSHookProps): IKSHookResponse => {
  const { contractId, ksName, page = 0, perPage = 10 } = props

  const queryClient = useQueryClient()

  const queryKey = ['KSList', contractId, ksName, page, perPage]
  const { data, isLoading, isFetching, isError, error } =
    useQuery<ResponseKSType>(
      queryKey,
      async () =>
        contractId
          ? await getKSList(contractId, ksName, page, perPage)
          : ({} as ResponseKSType),
      { keepPreviousData: true },
    )

  const refreshKSList = () => queryClient.invalidateQueries(queryKey)

  // const updateClosingLocal = (nextData: Record<string, any>) => {
  //   if (data) {
  //     queryClient.setQueryData(queryKey, () => ({ ...data, ...nextData }))
  //   }
  // }

  return {
    ksList: data ? { ...data, ksName } : undefined,
    isLoading,
    isFetching,
    error,
    isError,
    refreshKSList,
  }
}
