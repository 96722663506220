/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React, { useState } from 'react'

import { useDisclosure, useToast } from '@builddocs/system'

import AttentionSignDialog from './AttentionSignDialog'
import { cryptopro } from './cryptopro/inits'
import {
  FaceControlContext,
  IFaceControlCtx,
  ISignatureCallbacks,
  ISignatureSetActsCallbacks,
  ISignatureMultipleStampsCallbacks,
  IVerifyCallbacks,
} from './FaceControlContext'
import SignDataDialog from './SignDataDialog'

interface Props {
  children: React.ReactElement[] | React.ReactElement
}

export interface Attention {
  open(config?: { title: string; description: string }): void
  close(): void
  isOpen: boolean
  title: string
  description: string
}

export const AttentionContext = React.createContext<Attention | null>(null)

export function useAttentionContext() {
  return React.useContext(AttentionContext)
}

function FaceControlProvider({ children }: Props): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { toast } = useToast()

  const [callbacks, setCallbacks] = useState<
    | ISignatureCallbacks
    | IVerifyCallbacks
    | ISignatureSetActsCallbacks
    | ISignatureMultipleStampsCallbacks
    | string
  >()

  const [isVerifyOnly, setIsVerifyOnly] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const handleOpen = (
    data: ISignatureCallbacks | ISignatureSetActsCallbacks,
  ) => {
    setCallbacks(data)
    setIsVerifyOnly(false)
    onOpen()
  }

  const handleOpenVerify = (data: IVerifyCallbacks) => {
    setCallbacks(data)
    setIsVerifyOnly(true)
    onOpen()
  }

  const handleClose = () => {
    onClose()
    setCallbacks(undefined)
    setIsProcessing(false)
  }

  const signSetActs = async (hash, certSelected) => {
    setIsProcessing(true)

    await cryptopro.bind('')

    const aosrSignedHash = await Promise.allSettled(
      Object.keys(hash.aosrDigest).map(async key => ({
        actId: key,
        base64signature: await cryptopro.signHash(
          hash.aosrDigest[key].digest,
          certSelected.Thumbprint,
        ),
      })),
    )

    const aosrSignedHashFiltered = {
      result: aosrSignedHash
        .filter(item => item.status === 'fulfilled')
        .map(item => 'value' in item && item.value),
      errors: aosrSignedHash
        .filter(item => item.status === 'rejected')
        .map(item => 'reason' in item && item.reason.message),
    }

    const aosrRegistrySignedHash = await Promise.allSettled(
      Object.keys(hash.aosrDigest).map(async key => ({
        actId: key,
        actRegistryBase64signature: await cryptopro.signHash(
          hash.aosrDigest[key].actRegistryDigest,
          certSelected.Thumbprint,
        ),
      })),
    )

    const aosrRegistrySignedHashFiltered: {
      result: { actId: string; actRegistryBase64signature: string }[]
      errors: string[]
    } = {
      result: aosrRegistrySignedHash
        .filter(item => item.status === 'fulfilled')
        .map(item =>
          'value' in item
            ? item.value
            : { actId: '', actRegistryBase64signature: '' },
        ),
      errors: aosrRegistrySignedHash
        .filter(item => item.status === 'rejected')
        .map(item => 'reason' in item && item.reason.message),
    }

    const secondarySignedHash = await Promise.allSettled(
      Object.keys(hash.secondaryDigest).map(async key => ({
        actId: key,
        base64signature: await cryptopro.signHash(
          hash.secondaryDigest[key].digest,
          certSelected.Thumbprint,
        ),
      })),
    )

    const secondarySignedHashFiltered = {
      result: secondarySignedHash
        .filter(item => item.status === 'fulfilled')
        .map(item => 'value' in item && item.value),
      errors: secondarySignedHash
        .filter(item => item.status === 'rejected')
        .map(item => 'reason' in item && item.reason.message),
    }

    const secondaryRegistrySignedHash = await Promise.allSettled(
      Object.keys(hash.secondaryDigest).map(async key => ({
        actId: key,
        actRegistryBase64signature: await cryptopro.signHash(
          hash.secondaryDigest[key].actRegistryDigest,
          certSelected.Thumbprint,
        ),
      })),
    )

    const secondaryRegistrySignedHashFiltered: {
      result: { actId: string; actRegistryBase64signature: string }[]
      errors: string[]
    } = {
      result: secondaryRegistrySignedHash
        .filter(item => item.status === 'fulfilled')
        .map(item =>
          'value' in item
            ? item.value
            : { actId: '', actRegistryBase64signature: '' },
        ),
      errors: secondaryRegistrySignedHash
        .filter(item => item.status === 'rejected')
        .map(item => 'reason' in item && item.reason.message),
    }

    return {
      aosrSignedHash: aosrSignedHashFiltered.result.map(aosr => ({
        ...aosr,
        actRegistryBase64signature: aosrRegistrySignedHashFiltered.result.find(
          registry => aosr && registry && registry.actId === aosr.actId,
        )?.actRegistryBase64signature,
      })),
      secondarySignedHash: secondarySignedHashFiltered.result.map(secondry => ({
        ...secondry,
        actRegistryBase64signature:
          secondaryRegistrySignedHashFiltered.result.find(
            registry =>
              secondry && registry && registry.actId === secondry.actId,
          )?.actRegistryBase64signature,
      })),
    }
  }

  const signSetStamps = async (hash, certSelected): Promise<any> => {
    const stampDigests: {
      documentId: string
      stampTemplateId: string
      digest: string
    }[] = Object.keys(hash).map(documentId => ({
      documentId,
      stampTemplateId: Object.keys(hash[documentId])[0],
      digest: hash[documentId][Object.keys(hash[documentId])[0]],
    }))

    await cryptopro.bind('')

    const signedHash = await Promise.allSettled(
      stampDigests.map(async obj => ({
        base64signature: await cryptopro.signHash(
          obj.digest,
          certSelected.Thumbprint,
        ),
        stampTemplateId: obj.stampTemplateId,
        documentId: obj.documentId,
      })),
    )

    const resultSignedHash = signedHash
      .filter(item => item.status === 'fulfilled')
      .map(item => 'value' in item && item.value)

    const errors = signedHash
      .filter(item => item.status === 'rejected')
      .map(item => 'reason' in item && item.reason.message)

    if (errors.length > 0) {
      toast.error(errors[0])
    }

    return resultSignedHash
  }

  const signData: IFaceControlCtx['signData'] = async ({
    certSelected,
    facePhoto,
    keySerialNumber,
    certificateDateStart,
    certificateDateEnd,
    signerName,
    onSuccess,
    onFailure,
  }) => {
    if (
      !callbacks ||
      typeof callbacks === 'string' ||
      !('signHandler' in callbacks)
    )
      return

    setIsProcessing(true)

    const thumbprint = certSelected.Thumbprint

    try {
      await cryptopro.bind('')
      await cryptopro
        .init()
        // CryptoPro ведет себя неодинаково, если отправлять пустую строку
        .then(() => cryptopro.signData('MQ==', thumbprint))
        .then(async () => {
          const props = await callbacks.getDigestHandler({
            signedHash: null,
            keyIssuer: certSelected.Issuer.CN,
            imprint: thumbprint,
            signerName,
            facePhoto,
            keySerialNumber,
            certificateDateStart,
            certificateDateEnd,
          })

          let digest = ''
          let actRegistryDigest = undefined
          let currentSignatureBase64 = undefined

          if (typeof props === 'string') digest = props
          else {
            digest = props.hash as any
            actRegistryDigest = props.actRegistryDigest as any
            currentSignatureBase64 = props.currentSignatureBase64 as any
          }

          if (typeof digest === 'string') {
            return {
              signedData: currentSignatureBase64
                ? cryptopro.addSign(digest, currentSignatureBase64, thumbprint)
                : 'isEmbedded' in callbacks && callbacks.isEmbedded
                ? cryptopro.signHash(digest, thumbprint)
                : cryptopro.signData(digest, thumbprint),

              signedActRegistry: actRegistryDigest
                ? 'isEmbedded' in callbacks && callbacks.isEmbedded
                  ? cryptopro.signHash(actRegistryDigest, thumbprint)
                  : cryptopro.signData(actRegistryDigest, thumbprint)
                : undefined,
            }
          } else {
            try {
              const elements = (await digest) as { [key: string]: any }

              const hasMultipleActs =
                elements &&
                (elements.aosrDigest || elements.secondaryDigest) &&
                ((elements.aosrDigest &&
                  Object.keys(elements.aosrDigest)?.length > 1) ||
                  (elements.secondaryDigest &&
                    Object.keys(elements.secondaryDigest)?.length > 1))

              const hasMultipleStamps =
                elements &&
                !elements.aosrDigest &&
                !elements.secondaryDigest &&
                Object.keys(elements).length > 1

              if (hasMultipleActs || hasMultipleStamps) {
                open({
                  title: 'Внимание',
                  description:
                    'Не закрывайте диалоговое окно до окончания массового подписания.\nПримечание: При успешном массовом подписании диалоговое окно автоматически закроется.',
                })
              }
            } catch (error) {
              toast.error('Не удалось открыть диалоговое окно.')
            }

            return {
              signedData:
                'aosrDigest' in digest || 'secondaryDigest' in digest
                  ? signSetActs(digest, certSelected)
                  : signSetStamps(digest, certSelected),
              // Не знаю зачем это сделал, на всякий случай
              signedActRegistry: new Promise<undefined>(resolve =>
                resolve(undefined),
              ),
            }
          }
        })
        .then(async resSign =>
          callbacks.signHandler({
            signedHash: await resSign.signedData,
            actRegistryBase64signature: await resSign.signedActRegistry,
            keyIssuer: certSelected.Issuer.CN,
            imprint: thumbprint,
            facePhoto,
            keySerialNumber,
            certificateDateStart,
            certificateDateEnd,
            signerName,
          }),
        )
        .then((res: any) => {
          onSuccess && onSuccess()
          callbacks.onSuccess(res)
          handleClose()
        })
        .catch(e => {
          onFailure?.()
          callbacks.onFailure(e)
        })
        .finally(() => {
          cryptopro.unbind()
          setIsProcessing(false)
          close()
        })
      cryptopro.unbind()
    } catch (e) {
      cryptopro.unbind()

      setIsProcessing(false)
      onFailure?.()

      callbacks.onFailure(e)
      close()
    }
  }

  const verifyPerson: IFaceControlCtx['verifyPerson'] = async ({
    keySerialNumber,
    facePhoto,
    onSuccess,
    onFailure,
  }) => {
    if (
      !callbacks ||
      typeof callbacks === 'string' ||
      !('handleVerify' in callbacks)
    )
      return

    setIsProcessing(true)

    await callbacks
      .handleVerify({
        facePhoto,
        keySerialNumber,
      })
      .then(res => {
        onSuccess(res)
        callbacks.onSuccess(res)
        handleClose()
      })
      .catch(e => {
        onFailure(e)
        callbacks.onFailure(e)
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  const [isOpenAttention, setIsOpenAttention] = React.useState<boolean>(false)
  const [title, setTitle] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('')

  function open(config: { title: string; description: string }) {
    setTitle(config.title)
    setDescription(config.description)
    setIsOpenAttention(true)
  }

  function close() {
    setIsOpenAttention(false)
  }

  return (
    <FaceControlContext.Provider
      value={{
        handleClose,
        signData,
        verifyPerson,
        openFaceControl: handleOpen,
        openFaceControlVerify: handleOpenVerify,
      }}
    >
      <AttentionContext.Provider
        value={{ open, close, isOpen: isOpenAttention, title, description }}
      >
        <AttentionSignDialog />
      </AttentionContext.Provider>
      {React.Children.map(children, child => child)}
      <SignDataDialog
        isLoading={isProcessing}
        isOpen={isOpen}
        isVerifyOnly={isVerifyOnly}
        onClose={handleClose}
      />
    </FaceControlContext.Provider>
  )
}

export default FaceControlProvider
