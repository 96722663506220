import { useMemo } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TableSortLabel from '@mui/material/TableSortLabel'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'

export type SortingState = {
  orderBy:
    | 'orderByCreateDate'
    | 'orderByWorkDate'
    | 'orderByUpdatedDate'
    | 'orderByIssueDate'
  order: 'desc' | 'asc' | undefined
}

export enum SortingField {
  'orderByCreateDate' = 'creationDate',
  'orderByWorkDate' = 'workDate',
  'orderByUpdatedDate' = 'updateDate',
  'orderByIssueDate' = 'issueDate',
}

export const SortLabel = (props: {
  sorting: SortingState
  currentField: SortingState['orderBy']
  label: string
  title: string
  onClick: (property: SortingState['orderBy']) => (event: unknown) => void
}) => {
  const { sorting, onClick, currentField, label, title } = props

  const { order, orderBy } = sorting

  const isCurrent = useMemo(
    () => orderBy === currentField,
    [orderBy, currentField],
  )

  return (
    <Tooltip arrow placement="top" title={title}>
      <TableSortLabel
        active={isCurrent}
        direction={isCurrent ? order : undefined}
        hideSortIcon={true}
        sx={{
          fontSize: 'inherit',
          fontWeight: 'inherit',
          color: 'inherit',
          '.MuiTableSortLabel-icon': { display: 'none' },
          '&:focus': { color: 'text.primary', textDecoration: 'underline' },
          '&:hover': { textDecoration: 'underline' },
        }}
        onClick={onClick(currentField)}
      >
        <Typography
          sx={{
            textTransform: 'none',
            fontWeight: isCurrent ? 'bold' : 'initial',
            fontSize: 12,
          }}
          variant="overline"
        >
          {label}
        </Typography>
        {isCurrent ? (
          <Box component="span" sx={visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
        <Stack direction="column" sx={{ height: 24, width: 20 }}>
          <ArrowDropUpIcon
            sx={{
              opacity: isCurrent ? (order === 'asc' ? 1 : 0.5) : 0.5,
              position: 'absolute',
              top: 0,
            }}
          />
          <ArrowDropDownIcon
            sx={{
              opacity: isCurrent ? (order === 'desc' ? 1 : 0.5) : 0.5,
              position: 'absolute',
              top: 6,
            }}
          />
        </Stack>
      </TableSortLabel>
    </Tooltip>
  )
}
