/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { AxiosError } from 'axios'
import { RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query'

import { PaginationModel } from '../../../models'

import { getEmployments, getUnreadEmployments } from './employment.service'
import { EmploymentNotification } from './Employment.type'

interface IEmploymentsHook {
  isLoading: boolean
  isError: boolean
  pageNumber: number
  pageSize: number
  pageCount: number
  employments: EmploymentNotification[]
  unreadCount: number
  setUnreadCount: Dispatch<SetStateAction<number>>
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => void
  refreshUnreadCount: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => void
}

export const useEmployments = (
  opened = false,
  pageNumber = 0,
): IEmploymentsHook => {
  const [unread, setUnread] = useState<number>(0)

  let interval: any = null

  const { isFetching, isError, data, refetch } = useQuery<
    PaginationModel<EmploymentNotification> | undefined,
    AxiosError
  >(
    ['employmentsData'],
    () => {
      if (!opened) return undefined
      return getEmployments(pageNumber)
    },
    { enabled: opened },
  )

  const { data: unreadCount, refetch: refreshUnreadCount } = useQuery<
    number,
    AxiosError
  >(['employmentsUnread'], () => getUnreadEmployments())

  useEffect(() => {
    interval = setInterval(() => refreshUnreadCount(), 30000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (unreadCount) setUnread(unreadCount)
  }, [unreadCount])

  return {
    isLoading: isFetching,
    isError,
    pageNumber: data?.pageNumber ?? pageNumber,
    pageSize: data?.pageSize ?? 5,
    pageCount: data?.pageCount ?? 1,
    employments: data?.entityList ?? [],
    unreadCount: unread ?? 0,
    setUnreadCount: setUnread,
    refetch,
    refreshUnreadCount: () => {
      clearInterval(interval)
      refreshUnreadCount()
    },
  }
}
