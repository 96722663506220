/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import format from 'date-fns/format'
import { ru } from 'date-fns/locale'

export const formatPhoneNumber = (str: string) => {
  // Filter only numbers from the text-field
  const cleaned = ('' + str).replace(/\D/g, '')

  // Check if the text-field is of correct length
  const match = cleaned.match(/^7(\d{3})(\d{3})(\d{2})(\d{2})$/)

  if (match) {
    return `+7 (${match[1]}) ${match[2]}-${match[3]}-${match[4]}`
  }

  return undefined
}

export function formatDate(date?: string | null) {
  return date ? format(new Date(date), 'd MMM yyyy г.', { locale: ru }) : '—'
}
