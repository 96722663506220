/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import * as Sentry from '@sentry/react'
import { atom, selector, useRecoilCallback, useSetRecoilState } from 'recoil'

import { updateRules } from '../../casl/ability'
import { User } from '../../models'

import { getCurrentUser } from './user.service'

export const currentUser = atom<User>({
  key: 'CurrentUser',
  default: undefined as unknown as User,
})

export const currentUserQuery = selector<User>({
  key: 'CurrentUserQuery',
  get: ({ get }) => get(currentUser),
})

interface IRefreshCurrentUser {
  onLogin?: (user: User) => void
}

export function useRefreshCurrentUser(props?: IRefreshCurrentUser) {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        const userInfo = await getCurrentUser()

        Sentry.setUser({
          email: userInfo.email,
          username: `${userInfo.firstName ?? ''} ${userInfo.lastName ?? ''}`,
          id: userInfo.userId,
        })

        updateRules(userInfo)
        set(currentUser, userInfo)
        props?.onLogin && props?.onLogin(userInfo)
      },
    [props],
  )
}

export function useResetCurrentUser() {
  const setCurrentUser = useSetRecoilState(currentUser)

  return () => {
    console.log('reset Current User')
    setCurrentUser(undefined as unknown as User)
  }
}
