/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Fragment, forwardRef } from 'react'

import { Typography, Box, BoxProps, Stack } from '@mui/material'

interface IGridPairsProps {
  title?: string
  /** Массив с заголовками и контентами */
  content: Array<[string, string | JSX.Element | undefined | null] | null>
  /** Тёмный заголовок */
  isBlack?: boolean
  /** Лайфхак для быстрой перезаписи сетки */
  gridTemplateColumns?: string
  /** Размер заголовка */
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'inherit'
  widthLeftBlock?: number | string
  /**
   * Режим вертикального расположения заголовков и контента
   */
  isVerticalMode?: boolean
}

type GridPairsProps = IGridPairsProps & BoxProps

const GridPairs = forwardRef<HTMLDivElement, GridPairsProps>(function GridPairs(
  props,
  ref,
) {
  const {
    title,
    content,
    isBlack = false,
    variant = 'h5',
    widthLeftBlock = '180px',
    isVerticalMode = false,
    ...rest
  } = props

  if (isVerticalMode) {
    return (
      <Box ref={ref} {...rest}>
        {title && (
          <Typography mb={2} variant={variant}>
            {title}
          </Typography>
        )}
        <Stack spacing={1}>
          {content
            .filter(item => item !== null)
            .map(item => {
              if (item === null) return null
              return (
                <Box key={item[0]}>
                  <Typography
                    color={isBlack ? 'text.primary' : 'text.secondary'}
                    style={{ alignSelf: 'center' }}
                    //                    fontSize={isBlack ? 'md' : 'sm'}
                    variant="body2"
                  >
                    {item[0] ? item[0] + ':' : '…'}
                  </Typography>
                  {typeof item[1] === 'string' ? (
                    <Typography
                      //                      isTruncated
                      fontSize="md"
                      style={{
                        alignSelf: 'center',
                        maxWidth: '600px',
                      }}
                      title={item[1] || '—'}
                    >
                      {item[1] || '—'}
                    </Typography>
                  ) : (
                    <Box overflow="hidden">{item[1]}</Box>
                  )}
                </Box>
              )
            })}
        </Stack>
      </Box>
    )
  }

  return (
    <Box ref={ref} {...rest}>
      {title && (
        <Typography mb={2} variant={variant}>
          {title}
        </Typography>
      )}
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    display: 'grid',*/}
      {/*    gridColumnGap: 2,*/}
      {/*    gridRowGap: config.gridRowGap,*/}
      {/*    gridTemplateColumns: config.gridTemplateColumns,*/}
      {/*  }}*/}
      {/*  {...rest}*/}
      {/*>*/}
      <Box
        display="grid"
        gap={0.5}
        gridTemplateColumns={
          isVerticalMode ? '1fr' : `minmax(${widthLeftBlock}, min-content) auto`
        }
        rowGap={isVerticalMode ? 1 : 0.5}
      >
        {content
          .filter(item => item !== null)
          .map(item => {
            if (item === null) return null
            return (
              <Fragment key={item[0]}>
                <Box>
                  <Typography
                    color={isBlack ? 'text.primary' : 'text.secondary'}
                    //                    fontSize={isBlack ? 'md' : 'sm'}
                    style={{ alignSelf: 'center' }}
                  >
                    {item[0] ? item[0] + ':' : '…'}
                  </Typography>
                </Box>
                <Box>
                  {typeof item[1] === 'string' ? (
                    <Typography
                      //                      isTruncated
                      fontSize="md"
                      style={{
                        alignSelf: 'center',
                        maxWidth: '600px',
                      }}
                      title={item[1] || '—'}
                    >
                      {item[1] || '—'}
                    </Typography>
                  ) : (
                    <Box overflow="hidden">{item[1]}</Box>
                  )}
                </Box>
              </Fragment>
            )
          })}
      </Box>
    </Box>
  )
})

export default GridPairs
