import { FC, useEffect, useMemo } from 'react'

import {
  ActForm,
  useCommentsLogByEntityId,
  useContract,
  useCoordinator,
  UUID,
} from '@builddocs/data-management'
import { DocsTypes } from '@builddocs/shared/feature-pdf-viewer'
import { Box, Skeleton, Typography } from '@mui/material'

import AddComment from './AddComment'
import CommentsList from './CommentsList'

interface OwnProps {
  onDeviate?: (
    body: Record<string, any>,
    additionalInfo: Record<string, any>,
    onSuccess: () => any,
  ) => void
  commentEntity:
    | {
        place: string
        docType: string
        docId: UUID
        name: string
      }
    | DocsTypes
  title?: string | null
  onChangeCommentCount?: (count: number) => void
  forceContractId?: UUID
}

type Props = OwnProps

export const CommentsViewer: FC<Props> = ({
  onDeviate,
  commentEntity,
  title = 'Замечания к документу',
  onChangeCommentCount,
  forceContractId,
}) => {
  const { contractId } = useCoordinator() as { contractId: string }

  const {
    getRights: { canInspect, canSendActToSigning, canSignAct },
  } = useContract(forceContractId || contractId)

  const currentCommentEntity = useMemo(() => {
    return 'place' in commentEntity
      ? commentEntity
      : {
          place: '',
          docType: commentEntity['actType'] ?? commentEntity['documentType'],
          docId: commentEntity.id,
          name: commentEntity['closingName'] ?? commentEntity.name,
        }
  }, [commentEntity])

  const { comments, refreshComments, isLoading } = useCommentsLogByEntityId({
    contractId: forceContractId || contractId,
    externalEntityId: currentCommentEntity.docId,
  })

  const documentType = commentEntity['actType'] ?? commentEntity['documentType']

  useEffect(() => {
    onChangeCommentCount && onChangeCommentCount(comments.length)
  }, [comments])

  if (isLoading) {
    return <Skeleton />
  }
  return (
    <Box
      height="100%"
      style={{ overflowY: 'hidden', overflowX: 'hidden' }}
      sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}
      width="100%"
    >
      {title && (
        <Typography gutterBottom variant="h6">
          {title}
        </Typography>
      )}
      <AddComment
        canInspect={canInspect || canSendActToSigning || canSignAct}
        commentEntity={currentCommentEntity}
        contractId={forceContractId || contractId}
        isShowButtonForAll={ActForm[documentType]}
        refreshComments={refreshComments}
        onDeviate={onDeviate}
      />
      <CommentsList
        comments={comments}
        forceContractId={forceContractId}
        isLoading={isLoading}
        refreshComments={refreshComments}
      />
    </Box>
  )
}

export default CommentsViewer
