import { ReactNode } from 'react'

import { CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  Divider,
  Modal as MuiModal,
  SxProps,
} from '@mui/material'

export const Modal = MuiModal
export const ModalContent = ({
  children,
  sx,
}: {
  children: ReactNode | ReactNode[]
  sx?: SxProps
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'white',
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        borderRadius: 2,
        transform: 'translate(-50%, -50%)',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
export const ModalHeader = ({
  children,
  onClose,
  divider = false,
}: {
  children: ReactNode
  onClose?: () => void
  divider?: boolean
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          mx: 4,
          my: 2,
          gap: 4,
        }}
      >
        {children}
        {onClose && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              color: '#888',
              '&:hover': { color: '#000' },
            }}
            onClick={() => {
              onClose()
            }}
          >
            {' '}
            <CloseIcon />
          </Box>
        )}
      </Box>
      {divider && <Divider sx={{ mx: 4 }} />}
    </>
  )
}
export const ModalBody = ({
  children,
  sx,
  ...rest
}: {
  children: ReactNode
  sx?: SxProps
} & BoxProps) => {
  return (
    <Box sx={{ px: 4, py: 2, ...sx }} {...rest}>
      {children}
    </Box>
  )
}
export const ModalFooter = ({
  children,
  sx,
  divider = false,
}: {
  children: ReactNode
  sx?: SxProps
  divider?: boolean
}) => {
  return (
    <Box sx={{ px: 4, py: 2, ...sx }}>
      {divider && <Divider />}
      <Box sx={{ pt: 2 }}>{children}</Box>
    </Box>
  )
}
