import {
  AOSRType,
  DocumentSigningTask,
  ExecutiveScheme,
  SecondaryActType,
} from '@builddocs/filestore-api'
import { atom } from 'recoil'

import { ExternalSigning } from './ExternalSigning.type'

export const externalSigningData = atom<ExternalSigning | undefined>({
  key: 'externalSigningData',
  default: undefined,
})

export const actExternal = atom<AOSRType | SecondaryActType | undefined>({
  key: 'actExternal',
  default: undefined,
})

export const applicationExternal = atom<ExecutiveScheme | undefined>({
  key: 'applicationExternal',
  default: undefined,
})

export const tasksExternal = atom<DocumentSigningTask[] | undefined>({
  key: 'tasksExternal',
  default: undefined,
})
