import { FilestoreAPI } from '@builddocs/filestore-api'
import fileDownload from 'js-file-download'

export const downloadFile = (documentUrl: string, fileName: string) => {
  FilestoreAPI.get(`${documentUrl.replace('/api/v1/', '')}`, {
    responseType: 'blob',
  }).then(data => {
    fileDownload(data.data, fileName + '.pdf')
  })
}
