import { User } from '@builddocs/data-management'
import { ActFormKeys, Application } from '@builddocs/filestore-api'

export interface ExternalSigningAct {
  actId: string
  actType: ActFormKeys
  createDateTime: Date
  externalUser: User
  externalUserEmail: string
  externalUserExecuted: boolean
  externalUserId: string
  journalDescription: string
  resolveDatetime: Date
  journalId: string
  senderUser: User
  senderOrganizationName: string
  senderUserId: string
  signRequestId: string
  signType: 'ACT'
}

export interface ExternalSigningApplication {
  createDateTime: string
  documentId: string
  externalUser: User
  externalUserEmail: string
  externalUserExecuted: false
  externalUserId: string
  senderOrganizationName: string
  senderUser: User
  senderUserId: string
  signRequestId: string
  taskId: string
  signType: Application.EXECUTIVE_SCHEME
}

export type ExternalSigning = ExternalSigningAct | ExternalSigningApplication
