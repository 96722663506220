import { ActKsInfo, WorkType } from '@builddocs/data-management'
import produce from 'immer'
import { useQuery, useQueryClient } from 'react-query'

import { getClosingsList } from './closing.service'
import { ResponseClosingType } from './closings'

interface ClosingsProps {
  contractId: string
  workDocSection: string
  workType?: WorkType
  startDate?: string
  endDate?: string
  attachedKsActs?: ActKsInfo[]
}

interface IClosingsHookResponse {
  closings: ResponseClosingType[]
  refreshClosings: () => Promise<void>
  updateClosingsLocal: (nextData: ResponseClosingType, index: number) => void
  isLoading: boolean
  error?: unknown
  isError?: boolean
}

export const useClosings: (props: ClosingsProps) => IClosingsHookResponse =
  props => {
    const {
      contractId: journalId,
      workDocSection,
      workType,
      startDate,
      endDate,
      attachedKsActs,
    } = props

    const queryClient = useQueryClient()

    const queryKey = [
      'closings',
      journalId,
      workDocSection,
      workType,
      startDate,
      endDate,
      attachedKsActs,
    ]
    const { data, isLoading, isError, error } = useQuery<ResponseClosingType[]>(
      queryKey,
      async () =>
        workDocSection
          ? await getClosingsList({
              journalId,
              workDocSection,
              workType,
              startDate,
              endDate,
              attachedKsActs,
            })
          : [],
      { keepPreviousData: true },
    )

    const refreshClosings = () => queryClient.invalidateQueries(queryKey)

    const updateClosingsLocal = (
      nextData: ResponseClosingType,
      index: number,
    ) => {
      if (!data) return

      queryClient.setQueryData(
        queryKey,
        produce(data, draft => {
          if (!draft.length) {
            draft[index] = nextData
          }
        }),
      )
    }

    return {
      closings: data ?? [],
      isLoading,
      error,
      isError,
      refreshClosings,
      updateClosingsLocal,
    }
  }
