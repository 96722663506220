import {
  ListItemButton,
  Typography,
  Stack,
  Box,
  Tooltip,
  styled,
  SxProps,
  Theme,
} from '@mui/material'
import { Link } from 'react-router-dom'

const CustomizedButton = styled(ListItemButton)<{ $disablePadding?: boolean }>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    minWidth: 40,
    borderRadius: 8,
    background: 'inherit',
    color: 'red',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
    }),
    [theme.breakpoints.up('xs')]: {
      gap: 8,
    },
    [theme.breakpoints.up('sm')]: {
      gap: 16,
    },
    [theme.breakpoints.up('md')]: {
      gap: 8,
    },
    [theme.breakpoints.up('lg')]: {
      gap: 16,
    },
    '&:hover': {
      background: 'inherit',
    },
    '&:active': {
      background: 'inherit',
    },
    '&:after': {
      content: '""',
      pointerEvents: 'none',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: -8,
      backgroundColor: 'black',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      width: 0,
      height: 4,
      transition: theme.transitions.create(['width', 'transform']),
    },
    '&.selected:after': {
      width: 'calc(100% - 16px)',
      marginRight: 8,
    },
  }),
)

const CustomizedTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('xs')]: { display: 'none' },
  [theme.breakpoints.up('md')]: { display: 'initial' },
}))

type NavigationTabProps = {
  alt?: string
  label?: string
  tooltip?: string
  disablePadding?: boolean
  disabled?: boolean
  selected?: boolean
  icon?: string
  to?: string
  onClick?: () => void
  sx?: SxProps<Theme>
  StackProps?: {
    sx?: SxProps<Theme>
  }
}

export const NavigationTab = ({
  alt,
  label,
  icon,
  tooltip,
  disablePadding,
  disabled,
  selected = false,
  to = '',
  onClick,
  sx,
  StackProps,
}: NavigationTabProps) => {
  const Content = () => (
    <Stack alignItems="center" direction="row" gap={1} {...StackProps}>
      {icon && (
        <Box
          alt={alt ?? label}
          component="img"
          src={icon}
          sx={{ width: '100%' }}
        />
      )}
      {label && (
        <CustomizedTypography
          sx={{ color: selected ? '#333333' : '#0034AD', fontSize: 16 }}
        >
          {label}
        </CustomizedTypography>
      )}
    </Stack>
  )

  return (
    <Tooltip title={tooltip ?? ''}>
      <CustomizedButton
        {...(!disabled && to && { to, component: Link })}
        disableRipple
        className={disabled ? 'disabled' : selected ? 'selected' : undefined}
        sx={{
          opacity: disabled ? 0.5 : 'initial',
          pointerEvents: disabled ? 'none' : 'initial',
          '& > *': { pointerEvents: disabled ? 'none' : 'initial' },
          mx: { xs: 0.25, sm: 0.5 },
          px: { xs: disablePadding ? 0 : 0.25, sm: disablePadding ? 0 : 0.5 },
          p: { xs: disablePadding ? 0 : undefined },
          width: 'fit-content',
          ...sx,
        }}
        tabIndex={disabled ? -1 : 0}
        onClick={onClick}
      >
        <Content />
      </CustomizedButton>
    </Tooltip>
  )
}

export default NavigationTab
