/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import axios from 'axios'

import { WorkType } from '../constants'
import { UUID } from '../models'

import { DocgenAPI } from './DocgenAPI'

/**  Получение прогресса генерация актов*/
export const statusGenerate = async (contractId: string, userId: string) =>
  DocgenAPI.get(`/status`, {
    params: {
      journalId: contractId,
      userId,
    },
  })

/**  Генерация АОСР*/
export const generateAOSR = async (data: {
  contractId: string
  dateStart?: string
  dateEnd?: string
  sectionIds?: string[]
}) =>
  DocgenAPI.post(
    `/generate-document/aosr`,
    {},
    {
      params: {
        journalId: data.contractId,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        sectionIds: data.sectionIds?.join(','),
      },
    },
  )

export interface SelectedActs {
  aosrIds?: string[]
  abbreviations: string[]
}

/**  Генерация АОСР c выбранными пользователем секондари-актами*/
export const generateAOSRWithSecondary = async (
  contractId: string,
  workDocSection: string,
  selectedSectionIds: string[],
  acts?: Map<WorkType, SelectedActs>,
) =>
  DocgenAPI.post(`/generate-document/aosr-with-acts`, {
    journalId: contractId,
    workDocSection,
    data: acts,
    selectedSectionIds,
  })

type UpdateActs = {
  contractId: string
  actId: string
  actType: 'AOSR'
  reviewInfo?: string
  dateStart?: string
  dateEnd?: string
  nextWork?: string
  currentWork?: string
  executiveSchemes?: string[]
  tests?: string
  permission?: string
  laboratoriesIds?: string[]
  supportingDocsIds?: string[]
  executiveDrawings?: string[]
  numberCopies?: string
  additionalInformation?: string
  showCertAOSR?: boolean
  shouldGenerateRegistry?: boolean
  actExamples?: string
  forceIncludeOrganization?: string[]
  versionRemarkExon: number | null
  assigneesForActRegistry: Record<string, UUID[]> | null
  shouldIncludeSupportingsInProjectDocuments?: boolean
  supportingDocsForWorksIds?: string[]
}

export type UpdateSecondaryActType = {
  contractId: UUID
  actId: UUID
  actDate: string
  manualFields: Record<string, any>
  manualAssignees: Record<string, UUID[]>
  manualOrganizations: Record<string, { inn: string; name: string } | null>
  versionRemarkExon: number | null
  shouldGenerateRegistry: boolean
  assigneesForActRegistry: Record<string, UUID[]> | null
  shouldShowMaterials: boolean
  shouldShowLaboratories: boolean
  shouldShowExecutiveSchemes: boolean
  forceIncludeOrganization: string[]
}

/**  Обновление АОСР*/
export const updateActs = async (data: UpdateActs) => {
  return await DocgenAPI.put(`/generate-document/aosr`, {
    actExamples: data.numberCopies,
    additionalInformation: data.additionalInformation,
    aosrId: data.actId,
    executiveDrawingsIds: data.executiveDrawings,
    executiveSchemesIds: data.executiveSchemes,
    forceIncludeOrganization: data.forceIncludeOrganization,
    includeCertificatesInAttachments: data.showCertAOSR,
    journalId: data.contractId,
    laboratoriesIds: data.laboratoriesIds,
    supportingDocsIds: data.supportingDocsIds,
    nextWork: data.nextWork,
    shouldCreateActRegistry: data.shouldGenerateRegistry,
    versionRemarkExon: data.versionRemarkExon,
    assigneesForActRegistry: data.assigneesForActRegistry,
    shouldIncludeSupportingsInProjectDocuments:
      data.shouldIncludeSupportingsInProjectDocuments,
    supportingDocsForWorksIds: data.supportingDocsForWorksIds,
  })
}

/**  Обновление всего закрытия*/
export const regenerationClosing = async (closingId: UUID) =>
  await DocgenAPI.put(
    `/generate-document/closing`,
    {},
    {
      params: {
        closingId,
      },
    },
  )

/**  Обновление раздела закрытия*/
export const regenerationSectionClosing = async (
  closingId: UUID,
  workTypes: WorkType,
) =>
  await DocgenAPI.put(
    `/generate-document/closing`,
    {},
    {
      params: {
        closingId,
        workTypes,
      },
    },
  )

export const updateActsFields = async (
  actId: string,
  contractId: UUID,
  data: { manualFields: any; manualAssignees: any; manualOrganizations: any },
) =>
  await DocgenAPI.put(`/generate-document/secondary`, {
    journalId: contractId,
    secondaryActId: actId,
    manualFields: data.manualFields,
    manualAssignees: data.manualAssignees,
    manualOrganizations: data.manualOrganizations,
  })

/**  Обновление Secondary акта для интеррактивного вьюера*/
export const updateSecondaryAct = async (data: UpdateSecondaryActType) =>
  await DocgenAPI.put(`/generate-document/secondary`, {
    journalId: data.contractId,
    secondaryActId: data.actId,
    shouldCreateActRegistry: data.shouldGenerateRegistry,
    manualFields: data.manualFields,
    manualAssignees: data.manualAssignees,
    manualOrganizations: data.manualOrganizations,
    actDate: data.actDate,
    versionRemarkExon: data.versionRemarkExon,
    assigneesForActRegistry: data.assigneesForActRegistry,
    shouldShowMaterials: data.shouldShowMaterials,
    shouldShowLaboratories: data.shouldShowLaboratories,
    shouldShowExecutiveSchemes: data.shouldShowExecutiveSchemes,
    forceIncludeOrganization: data.forceIncludeOrganization,
  })

export const updateActsAssignees = async (
  actId: string,
  contractId: UUID,
  data: any,
) =>
  await DocgenAPI.put(`/generate-document/secondary`, {
    journalId: contractId,
    secondaryActId: actId,
    manualAssignees: data,
  })

export const makeRegistry = async (contractId: string, actIds: string[]) =>
  DocgenAPI.post(
    `/generate-document/registry`,
    {},
    {
      params: {
        journalId: contractId,
        actIds: actIds.join(','),
      },
    },
  )

export const updateRegistry = async (
  contractId: string,
  closingId: string,
  data: any,
  workType?: string,
) =>
  DocgenAPI.put(`/generate-document/registry`, data, {
    params: {
      journalId: contractId,
      closingId,
      workType,
    },
  })

export const updateFinalRegistry = async (
  contractId: string,
  closingId: string,
  data: any,
  workDocSection?: string,
) =>
  DocgenAPI.put(`/generate-document/final-registry`, data, {
    params: {
      journalId: contractId,
      closingId,
      workDocSection,
    },
  })

/**  Создание/перегенерация ЖОР для проекта*/
export const makeProjectZHOR = async (projectId: UUID) =>
  DocgenAPI.post(
    `/generate-document/journal-of-works`,
    {},
    {
      params: {
        projectId,
      },
    },
  )

export const changeAosrDate = async (data:{actDate:string, aosrId:string, journalId:string, versionRemarkExon:number | null }) =>
  DocgenAPI.put(`/generate-document/aosr/act-date`, {...data})

export const makeTitlePage = async (contractId: string, sectionId: string) =>
  axios.post(
    `/generate-document/titlelist`,
    {},
    {
      params: {
        journalId: contractId,
        sectionId,
      },
    },
  )
