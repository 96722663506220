/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { ActForm, WorkType } from '../constants'

import { UUID } from './uuid'

export type ClosingComments =
  | 'ADDITION'
  | 'CLOSING_COMPLECT_DOC'
  | 'CLOSING_ALL_DOC'
  | 'CLOSING_REGISTRY_DOC'

export type CommentItem = {
  commentId: UUID
  createDateTime: string
  deadline: string
  employmentId: UUID
  information: string
  journalId: UUID
  problemDescription: string
  entityId: UUID
  entityType: string
  externalEntityId: UUID
  externalEntityInfo: ActForm | ClosingComments

  place: string
  resolved: boolean
  actType: string
  workTypeComment: {
    workType: WorkType
    aosrIds: string[]
    secondaryActIds: string[]
  }
}
