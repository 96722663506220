import { Divider, List, Stack } from '@mui/material'

import NavigationTab from './NavigationTab'

interface INavigationProps {
  pathname: string | undefined
  tabs: { label: string; path: string; tooltip?: string; icon?: string }[]
}

export const TabsNavigation = ({ tabs, pathname }: INavigationProps) => {
  return (
    <>
      <Stack
        component="nav"
        direction="row"
        justifyContent="flex-start"
        spacing={{ xs: 0, sm: 1, md: 0, lg: 1 }}
      >
        <List sx={{ display: 'flex', '& *': { color: 'text.primary' } }}>
          {tabs.map(tab => {
            return (
              <NavigationTab
                key={`tab-${tab.label}`}
                icon={tab.icon ? tab.icon : ''}
                label={tab.label}
                selected={pathname === tab.path}
                to={tab.path}
              />
            )
          })}
        </List>
      </Stack>
      <Divider sx={{ height: '1px', backgroundColor: '#DDDDDD' }} />
    </>
  )
}
