export const getInnOrKpp: (innKpp?: string) => { inn: string; kpp: string } =
  innKpp =>
    innKpp
      ? innKpp.includes('-')
        ? { inn: innKpp.split('-')[0], kpp: innKpp.split('-')[1] }
        : { inn: innKpp, kpp: '' }
      : { inn: '', kpp: '' }

export const getInnKpp: (inn?: string, kpp?: string) => string = (inn, kpp) =>
  inn ? (kpp ? `${inn}-${kpp}` : inn) : ''
