/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

/**
 * @startuml
 *
 * title Движение по статусам Экзона
 *
 * [*] --> NEW
 *  NEW -[#blue]-> REVISION
 *  REVISION -[#blue]-> ON_APPROVAL
 *  ON_APPROVAL -[#blue]-> CANCELED
 *  ON_APPROVAL -[#blue]-> SIGNED
 *
 *  NEW: Новый
 *  ON_APPROVAL: На согласовании
 *  CANCELED: Аннулирован
 *  SIGNED: Подписан
 *  REVISION: На доработке
 * @enduml
 */

/**
 * Старые статусы
 */
export enum ItdStatus {
  NEW = 'NEW',
  REVIEW = 'REVIEW',
  SIGN = 'SIGN',
  REMARKS = 'REMARKS',
  VERIFIED = 'VERIFIED',
  APPROVED = 'APPROVED',
}

/**
 * Ярлык
 * Старые + новые статусы
 */
export enum ItdStatusLabels {
  REVIEW = 'На проверке',
  SIGN = 'На подписании',
  REMARKS = 'Есть замечания',
  VERIFIED = 'Проверен',

  ON_APPROVAL = 'На согласовании',
  APPROVED = 'Согласован',
  CANCELED = 'Аннулирован',
  NEW = 'Новый',
  REVISION = 'На доработке',
  SIGNED = 'Подписан',
  SIGNED_AND_APPROVED = 'Подписан и согласован',
  REVISION_FROM_SIGN = 'На доработке после подписания',
}

/**
 * Новые статусы
 * для "гибких маршрутов" Экзона
 */
export enum ItdFlexibleStatus {
  /** Новый */
  NEW = 'NEW',
  /** "На согласовании" */
  ON_APPROVAL = 'ON_APPROVAL',
  /** "Подписан" */
  SIGNED = 'SIGNED',
  /** "Аннулирован" */
  CANCELED = 'CANCELED',
  /** "На доработке" */
  REVISION = 'REVISION',
  /** "Подписан и согласован" */
  SIGNED_AND_APPROVED = 'SIGNED_AND_APPROVED',
  /** "На доработке после подписания" */
  REVISION_FROM_SIGN = 'REVISION_FROM_SIGN',
}

/**
 * Ярлыки только к новым статусам
 */
export enum ItdFlexibleStatusLabels {
  ON_APPROVAL = 'На согласовании',
  APPROVED = 'Согласован',
  CANCELED = 'Аннулирован',
  NEW = 'Новый',
  REVISION = 'На доработке',
  SIGNED = 'Подписан',
  SIGNED_AND_APPROVED = 'Подписан и согласован',
  REVISION_FROM_SIGN = 'На доработке после подписания',
}
