import { UUID } from '@builddocs/data-management'
import { useQuery, useQueryClient } from 'react-query'

import { getProjectZHOR } from '../secondary-act/secondary-act.service'

import { SecondaryActType } from './acts.interfaces'

interface IZHORHookResponse {
  data: SecondaryActType | undefined
  refreshZHOR: () => Promise<void>
  updateZHORLocal: (nextData: Record<string, any>) => void
  isLoading: boolean
  isFetching: boolean
  error?: unknown
  isError?: boolean
}

export const useProjectZHOR: (projectId: UUID) => IZHORHookResponse =
  projectId => {
    const queryClient = useQueryClient()

    const queryKey = ['zhor', projectId]
    const { data, isLoading, isFetching, isError, error } =
      useQuery<SecondaryActType>(
        queryKey,
        async () =>
          projectId
            ? await getProjectZHOR(projectId)
            : ({} as SecondaryActType),
        { keepPreviousData: true },
      )
    const refreshZHOR = () => queryClient.invalidateQueries(queryKey)

    const updateZHORLocal = (nextData: Record<string, any>) => {
      if (data) {
        queryClient.setQueryData(queryKey, () => ({ ...data, ...nextData }))
      }
    }

    return {
      data,
      isLoading,
      isFetching,
      error,
      isError,
      refreshZHOR,
      updateZHORLocal,
    }
  }
