/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React, { forwardRef } from 'react'

import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'
import Cleave from 'cleave.js/react'

import 'react-phone-input-2/lib/style.css'

require('cleave.js/dist/addons/cleave-phone.i18n')

const PhoneCustom = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>((inputProps: React.InputHTMLAttributes<HTMLInputElement>, ref) => (
  <Cleave
    htmlRef={ref as any}
    options={{
      prefix: '+',
      phone: true,
      phoneRegionCode: '{country}',
    }}
    {...inputProps}
  >
    {null}
  </Cleave>
))

export interface IPhoneFieldProps extends OutlinedInputProps {
  readonly name: string
  readonly error?: boolean
  readonly helperText?: string
  readonly label?: string
}

export const PhoneField = React.forwardRef<HTMLInputElement, IPhoneFieldProps>(
  function _PhoneField({ id, name, error, helperText, label, ...rest }, ref) {
    const realId = id || name

    return (
      <FormControl fullWidth={rest.fullWidth}>
        <InputLabel shrink htmlFor={realId}>
          {label}
        </InputLabel>
        <OutlinedInput
          id={realId}
          inputComponent={PhoneCustom}
          inputRef={ref}
          label={label}
          {...rest}
          data-cy="phone"
        />
        {helperText && (
          <FormHelperText error={error} id={realId + '-helper-text'}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    )
  },
)

PhoneCustom.displayName = 'PhoneCustom'

export default PhoneField
