/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React from 'react'

import { alpha, darken, lighten } from '@mui/material'
import { blue, green, grey, red } from '@mui/material/colors'
import { ruRU } from '@mui/material/locale'
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles'

import {
  BRANDING_COLOR,
  CHART_COLORS,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  THIRTY_COLOR,
  romanSilver,
} from './colors'

declare module '@mui/material/styles' {
  interface Palette {
    branding: Palette['primary']
    tertiary: Palette['primary']
    newable: Palette['primary']
    processing: Palette['primary']
    accepted: Palette['primary']
    rejected: Palette['primary']
    chart: typeof CHART_COLORS
  }

  interface PaletteOptions {
    branding?: PaletteOptions['primary']
    tertiary?: PaletteOptions['primary']
    newable?: PaletteOptions['primary']
    processing?: PaletteOptions['primary']
    accepted?: PaletteOptions['primary']
    rejected?: PaletteOptions['primary']
    chart?: typeof CHART_COLORS
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    newable: true
    tertiary: true
    processing: true
    accepted: true
    rejected: true
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    chart: true
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    glory: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tonal: true
  }

  interface ButtonPropsColorOverrides {
    branding: true
    tertiary: true
    newable: true
    processing: true
    accepted: true
    rejected: true
  }
}

export interface MuiProviderCrutchProps {
  children: React.ReactNode
}

const palette = {
  background: {
    default: romanSilver[10],
    //    default: '#fafafa',
    paper: '#ffffff',
  },
  text: {
    primary: romanSilver[900],
  },
  primary: {
    main: PRIMARY_COLOR,
  },
  secondary: {
    main: SECONDARY_COLOR,
  },
  tertiary: {
    main: THIRTY_COLOR,
    contrastText: romanSilver[900],
  },
  grey: romanSilver,
  //  text: {
  //    primary: blueGrey[900],
  //  },
  branding: {
    main: BRANDING_COLOR,
  },
  newable: {
    main: grey[100],
    contrastText: grey[900],
  },
  processing: {
    main: blue[100],
    contrastText: blue[900],
  },
  accepted: {
    main: green[600],
    contrastText: '#ffffff',
  },
  rejected: {
    main: red[600],
    contrastText: '#ffffff',
  },
  chart: CHART_COLORS,
  //      background: {
  //        default: blueGrey[50],
  //      },
}
let theme = createTheme(
  {
    palette,
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'tonal' },
            style: (({ theme }) => ({
              color: theme.palette.primary.main,
              backgroundColor: lighten(theme.palette.primary.main, 0.92),
              '&:hover': {
                backgroundColor: lighten(theme.palette.primary.main, 0.85),
              },
              '.MuiTouchRipple-root': {
                '.MuiTouchRipple-child': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.7),
                },
              },
            })) as any,
          },
          {
            props: { variant: 'tonal', color: 'secondary' },
            style: (({ theme }) => ({
              color: darken(theme.palette.secondary.main, 0.6),
              backgroundColor: lighten(theme.palette.secondary.main, 0.92),
              '&:hover': {
                backgroundColor: lighten(theme.palette.secondary.main, 0.85),
              },
              '.MuiTouchRipple-root': {
                '.MuiTouchRipple-child': {
                  backgroundColor: alpha(theme.palette.secondary.main, 0.7),
                },
              },
            })) as any,
          },
          {
            props: { variant: 'tonal', color: 'tertiary' },
            style: (({ theme }) => ({
              color: darken(theme.palette.tertiary.main, 0.6),
              backgroundColor: lighten(theme.palette.tertiary.main, 0.92),
              '&:hover': {
                backgroundColor: lighten(theme.palette.tertiary.main, 0.85),
              },
              '.MuiTouchRipple-root': {
                '.MuiTouchRipple-child': {
                  backgroundColor: alpha(theme.palette.tertiary.main, 0.7),
                },
              },
            })) as any,
          },
        ],
      },
      MuiTableCell: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          //          root: { padding: '8px 16px 8px 16px' },
          head: (({ theme }) => ({
            color: theme.palette.text.secondary,
            fontWeight: 400,
            fontSize: '0.825rem',
            letterSpacing: '0.05rem',
            backgroundColor: theme.palette.background.paper,
          })) as any,
        },
        variants: [
          {
            props: { size: 'small' },
            style: {
              padding: '8px 16px 8px 16px',
            },
          },
        ],
      },
      MuiPaper: {
        variants: [
          {
            props: { variant: 'glory', elevation: 6 },
            style: {
              boxShadow:
                'rgb(255 255 255 / 20%) 0px 7px 8px -4px, ' +
                'rgb(255 255 255 / 14%) 0px 12px 17px 2px, ' +
                'rgb(255 255 255 / 12%) 0px 5px 22px 4px;',
            },
          },
          {
            props: { variant: 'glory', elevation: 1 },
            style: {
              boxShadow:
                'rgb(255 255 255 / 20%) 0px 2px 1px -1px, ' +
                'rgb(255 255 255 / 14%) 0px 1px 1px 0px, ' +
                'rgb(255 255 255 / 12%) 0px 1px 3px 0px;',
            },
          },
        ],
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            borderWidth: 0,
            borderStyle: 'solid',
            borderColor: 'rgba(0,0,0,0.12)',
            borderTopWidth: 'thin',
          },
        },
      },
    },
    typography: {
      fontFamily: [
        //        'Golos UI',
        'Roboto',
        'sans-serif',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  },
  ruRU,
)

// eslint-disable-next-line
theme.components!.MuiContainer = {
  variants: [
    {
      props: { maxWidth: 'xl' },
      style: {
        [theme.breakpoints.up('lg')]: {
          maxWidth: 'unset',
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  ],
}

theme = responsiveFontSizes(theme)

export function MuiProviderCrutch(props: MuiProviderCrutchProps) {
  const { children } = props

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default MuiProviderCrutch
