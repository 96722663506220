/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { UUID } from '@builddocs/data-management'
import { atomFamily, selectorFamily } from 'recoil'

import { getSheetsWorkingDraft } from '../applications'

export const sheetsQueryRequestIDState = atomFamily({
  key: 'sheetsQueryRequestID',
  default: 0,
})

export const sheetsQuery = selectorFamily({
  key: 'sheetsQuery',
  get:
    (id: UUID) =>
    async ({ get }) => {
      get(sheetsQueryRequestIDState(id))
      return await getSheetsWorkingDraft(id)
    },
})
