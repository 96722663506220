/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export type PaginationModel<T> = {
  entityList: T[]
  pageCount: number
  pageNumber: number
  pageSize: number
}
