import { useState } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button } from '@mui/material'

export function ListButtonExpand({
  label,
  onClick,
}: {
  readonly label: string
  readonly onClick?: () => void
}) {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleClick = () => {
    setIsExpanded(!isExpanded)
    onClick && onClick()
  }

  return (
    <Button
      sx={{
        minWidth: 32,
        minHeight: 24,
        py: 0,
        pl: label ? 1.5 : 1,
        pr: 1,
        color: 'text.primary',
      }}
      variant={isExpanded ? 'tonal' : 'text'}
      onClick={handleClick}
    >
      {label}
      {isExpanded ? (
        <KeyboardArrowUpIcon sx={{ color: 'text.primary' }} />
      ) : (
        <KeyboardArrowDownIcon sx={{ color: 'text.primary' }} />
      )}
    </Button>
  )
}

export default ListButtonExpand
