/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { UUID } from '../../models'
import { RestAPI } from '../RestAPI'

import { Ksi } from './ksi-works.type'
import { WorkDocSection } from './worksNames'

/**Получение разделов рабочей документации */
export const getWorkDocSectionsForWork = (
  journalId: string,
): Promise<string[]> =>
  RestAPI.get(`/scheduler/workdocsections/${journalId}`).then(res => res.data)

/**Получение иерархии разделов рабочей документации */
export const getHierarchyWorkDocSectionsForWork = (
  contractId: UUID,
  searchValue?: string,
): Promise<WorkDocSection[]> =>
  RestAPI.get(`/scheduler/hierarchy-stage/journal/${contractId}`, {
    params: { searchValue },
  }).then(res => res.data)

/**
 * Получение графика работ
 * @return {Promise<object[]>}
 */
export const getStagesForProject = (projectId: UUID) =>
  RestAPI.get(`/scheduler/stages/project/${projectId}`).then(
    res => res.data || [],
  )

/**
 * Получение графика работ
 * @return {Promise<object[]>}
 */
export const getStages = (contractId: UUID) =>
  RestAPI.get(`/scheduler/stages/journal/${contractId}`).then(
    res => res.data || [],
  )

type CreateStageProps = {
  data: any
  parentStageId?: number
}

export const createStageOld = ({ contractId, parentStageId, data }) =>
  RestAPI.post('/scheduler/stage', {
    ...data,
    journalId: contractId,
    parentStageId,
  })

/* Создание Секции-Конструкции-Работы */
export const createStage = ({ parentStageId, data }: CreateStageProps) =>
  RestAPI.post('/scheduler/hierarchy/stage', {
    ...data,
    parentStageId,
  })

/* Создание Конструкции c Наименованием Работы */
export const createConstruction = ({ data }) =>
  RestAPI.post('/scheduler/hierarchy/construction', {
    ...data,
  })

/* Создание отдельной Работы или Подработы */
export const createChildrenWork = ({ contractId, parentStageId, data }) =>
  RestAPI.post('/scheduler/children-work', {
    ...data,
    journalId: contractId,
    parentStageId,
  })

type UpdateStagePropsOld = {
  contractId: UUID
  parentId?: number
  data: any
}
export const updateStageOld = ({ contractId, data }: UpdateStagePropsOld) => {
  return RestAPI.put('/scheduler/stage', {
    ...data,
    journalId: contractId,
  })
}

type UpdateStageProps = {
  data: any
}

/* Обновление Раздела, Конструкции или Наименования работ */
export const updateStage = ({ data }: UpdateStageProps) => {
  return RestAPI.put('/scheduler/hierarchy/stage', {
    ...data,
  })
}

type DeleteStageOldProps = {
  stageId: number
}

/* Удаление Наименования работы */
export const deleteStageOld = ({ stageId }: DeleteStageOldProps) => {
  return RestAPI.delete(`/scheduler/${stageId}`)
}

type DeleteStageProps = {
  stageId: number
}

/* Удаление Секции, Конструкции или Наименования работы */
export const deleteStage = ({ stageId }: DeleteStageProps) => {
  return RestAPI.delete(`/scheduler/?stageId=${stageId}`)
}

type VerifySignProps = {
  imageBase64: string
  keySerialNumber: string
  employmentId: UUID
  journalId: UUID
}
export const verifySignerScheduler = ({
  imageBase64,
  keySerialNumber,
  employmentId,
  journalId,
}: VerifySignProps) => {
  const dataBase64 = imageBase64.replace(/^data:.+;base64,/, '')

  return RestAPI.post(
    `/scheduler/verify_signer/${employmentId}/${journalId}/${keySerialNumber}`,
    {
      faceIdCredentials: {
        faceImage: dataBase64,
        keySerialNumber,
      },
      journalId,
    },
  )
}

type MakeSignProps = {
  signature: string
  journalId: UUID
}
export const makeSignScheduler = ({ journalId, signature }: MakeSignProps) =>
  RestAPI.post(`/scheduler/save_sign/${journalId}`, {
    journalId: journalId,
    signature: signature,
  })

/** Получение базы КСИ.Работы */
export const getWorksTitlesKsi = (rootCode?: string): Promise<Ksi> =>
  rootCode
    ? RestAPI.get(`/scheduler/work-titles/${rootCode}`).then(
        res => res.data || {},
      )
    : RestAPI.get(`/scheduler/work-titles`).then(res => res.data || {})

/** Получение базы КСИ.Конструкции */
export const getConstructionsTitlesKsi = (rootCode?: string): Promise<Ksi> =>
  rootCode
    ? RestAPI.get(`/scheduler/construction-titles/${rootCode}`).then(
        res => res.data || {},
      )
    : RestAPI.get(`/scheduler/construction-titles`).then(res => res.data || {})

/* Создание Раздела нулевого уровня */
export const createWorkDocSectionRoot = ({ data }) =>
  RestAPI.post('/scheduler/hierarchy/section-stage', {
    ...data,
  })

/* Создание отдельного Подраздела, Конструкции, Работы или Подработы */
export const createRowWorksNames = ({ data }) =>
  RestAPI.put('/scheduler/hierarchy/enrich', {
    ...data,
  })
