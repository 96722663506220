import { useEffect, useState } from 'react'

import { Box, ToggleButton, Stack, Typography } from '@mui/material'

export type PeriodType = {
  year: { title: string; isChecked: boolean }
  months: {
    title: string
    isChecked: boolean
    quarter: string
    numberMonth: string
  }[]
}
export interface TimePeriodProps {
  onSelectPeriod: (period: Array<PeriodType>) => void
}

const years = [
  new Date().getFullYear().toString(),
  (new Date().getFullYear() - 1).toString(),
  (new Date().getFullYear() - 2).toString(),
]

/** Если месяц - Декабрь -> добавить возможность планировать на следующий год */
if (new Date().getMonth() > 10)
  years.push((new Date().getFullYear() + 1).toString())

const months = [
  { title: 'Январь', isChecked: false, quarter: '1', numberMonth: '01' },
  { title: 'Февраль', isChecked: false, quarter: '1', numberMonth: '02' },
  { title: 'Март', isChecked: false, quarter: '1', numberMonth: '03' },
  { title: 'Апрель', isChecked: false, quarter: '2', numberMonth: '04' },
  { title: 'Май', isChecked: false, quarter: '2', numberMonth: '05' },
  { title: 'Июнь', isChecked: false, quarter: '2', numberMonth: '06' },
  { title: 'Июль', isChecked: false, quarter: '3', numberMonth: '07' },
  { title: 'Август', isChecked: false, quarter: '3', numberMonth: '08' },
  { title: 'Сентябрь', isChecked: false, quarter: '3', numberMonth: '09' },
  { title: 'Октябрь', isChecked: false, quarter: '4', numberMonth: '10' },
  { title: 'Ноябрь', isChecked: false, quarter: '4', numberMonth: '11' },
  { title: 'Декабрь', isChecked: false, quarter: '4', numberMonth: '12' },
]
const initQuarters = [
  { title: '1', isChecked: false, number: '1' },
  { title: '2', isChecked: false, number: '2' },
  { title: '3', isChecked: false, number: '3' },
  { title: '4', isChecked: false, number: '4' },
]

export function TimePeriod({ onSelectPeriod }: TimePeriodProps) {
  const [period, setPeriod] = useState<Array<PeriodType>>(
    years.map(year => ({ year: { title: year, isChecked: false }, months })),
  )

  const [quarters, setQuarters] = useState(
    years.reduce((acc, year) => ({ ...acc, [year]: initQuarters }), {}),
  )

  useEffect(() => {
    onSelectPeriod(period)
  }, [period])

  const choiceMonth = (
    data: PeriodType,
    currentMonth: {
      isChecked: boolean
      numberMonth: string
      quarter: string
      title: string
    },
  ) => {
    setPeriod(prev =>
      prev.map(element =>
        element.year.title === data.year.title
          ? {
              ...element,
              months: element.months.map(item =>
                item.numberMonth === currentMonth.numberMonth
                  ? { ...item, isChecked: !currentMonth.isChecked }
                  : item,
              ),
            }
          : element,
      ),
    )

    if (
      !data.months
        .filter(
          month =>
            month.quarter === currentMonth.quarter &&
            month.numberMonth !== currentMonth.numberMonth,
        )
        .some(item => !item.isChecked)
    ) {
      setQuarters(prev => ({
        ...prev,
        [data.year.title]: prev[data.year.title].map(quarter =>
          quarter.number === currentMonth.quarter
            ? { ...quarter, isChecked: !currentMonth.isChecked }
            : quarter,
        ),
      }))
    }
  }

  const choiceQuarter = (
    yearTitle: string,
    quarter: { title: string; number: string; isChecked: boolean },
  ) => {
    setQuarters(prev => ({
      ...prev,
      [yearTitle]: prev[yearTitle].map(item =>
        item.number === quarter.number
          ? { ...item, isChecked: !item.isChecked }
          : item,
      ),
    }))

    setPeriod(prev =>
      prev.map(element =>
        element.year.title === yearTitle
          ? {
              ...element,
              months: element.months.map(item =>
                item.quarter === quarter.number
                  ? { ...item, isChecked: !quarter.isChecked }
                  : item,
              ),
            }
          : element,
      ),
    )
  }

  const choiceYear = (currentYear: { title: string; isChecked: boolean }) => {
    setPeriod(prev =>
      prev.map(element =>
        element.year.title === currentYear.title
          ? {
              months: element.months.map(month => ({
                ...month,
                isChecked: !currentYear.isChecked,
              })),
              year: { ...element.year, isChecked: !currentYear.isChecked },
            }
          : element,
      ),
    )

    setQuarters(prev => ({
      ...prev,
      [currentYear.title]: prev[currentYear.title].map(item => ({
        ...item,
        isChecked: !currentYear.isChecked,
      })),
    }))
  }

  useEffect(() => {
    setPeriod(prev =>
      prev.map(item => ({
        ...item,
        year: {
          ...item.year,
          isChecked: !quarters[item.year.title].some(item => !item.isChecked),
        },
      })),
    )
  }, [quarters])

  return (
    <Stack spacing={2}>
      {period.map(item => (
        <Stack
          key={item.year.title}
          direction="row"
          justifyContent="center"
          spacing={4}
        >
          <Stack spacing={1}>
            <Typography>Год</Typography>
            <Box width="110px">
              <ToggleButton
                fullWidth
                selected={item.year.isChecked}
                size="small"
                value={item.year.title}
                onClick={() => choiceYear(item.year)}
              >
                {item.year.title}
              </ToggleButton>
            </Box>
          </Stack>
          <Stack spacing={1}>
            <Typography>Квартал</Typography>
            <Stack spacing={1}>
              {quarters[item.year.title].map(quarter => (
                <Box key={quarter.title} position="relative" width="110px">
                  <ToggleButton
                    fullWidth
                    selected={quarter.isChecked}
                    size="small"
                    value={quarter.title}
                    onClick={() => choiceQuarter(item.year.title, quarter)}
                  >
                    {quarter.title}
                  </ToggleButton>
                </Box>
              ))}
            </Stack>
          </Stack>
          <Stack spacing={1} width="346px">
            <Typography>Месяц</Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {item.months.map(month => (
                <Box key={month.title} position="relative" width="110px">
                  <ToggleButton
                    fullWidth
                    selected={month.isChecked}
                    size="small"
                    value={month.title}
                    onClick={() => choiceMonth(item, month)}
                  >
                    {month.title}
                  </ToggleButton>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}

export default TimePeriod
