/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useState } from 'react'

import { track } from '@amplitude/analytics-browser'
import { useToast } from '@builddocs/system'

import { useRouter } from '../../../../index'
import { User } from '../../../models'
import { emailRegExp } from '../../../validator'
import { useRefreshCurrentUser } from '../../current-user'
import { login as loginReq } from '../auth.service'

type OwnProps = {
  redirectUrl?: string
  onLogin?: (user: User) => void
}
export const useLogin = ({ redirectUrl, onLogin }: OwnProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const refreshCurrentUser = useRefreshCurrentUser({ onLogin })
  const { getErrorMessage } = useToast()

  const { location, navigate } = useRouter()

  const login = async (credentials: { username: string; password: string }) => {
    try {
      const { username, password } = credentials

      setIsLoading(true)

      let clearUsrnm: string

      if (emailRegExp.test(username)) clearUsrnm = username.toLowerCase()
      else clearUsrnm = '+' + username.replace(/\D/g, '')

      await loginReq(clearUsrnm, password)

      track('user_login')

      await refreshCurrentUser()

      setIsLoading(false)
      setErrorMessage(undefined)
      const { from } = (location.state as any) || {
        from: { pathname: redirectUrl },
      }

      redirectUrl && navigate(from)
    } catch (e) {
      setIsLoading(false)
      setErrorMessage(getErrorMessage(e as any, 'auth.message.login_failure'))
    }
  }

  return {
    login,
    isLoading,
    errorMessage,
  }
}
