/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { atomFamily, selectorFamily } from 'recoil'

import { User } from '../../models/user'
import { UUID } from '../../models/uuid'
import { getContract } from '../organization/'

import { Journal } from './journal'

export const contractStateRequestID = atomFamily({
  key: 'ContractStateRequestID',
  default: 0,
})

export const contractQuery = selectorFamily<Journal | undefined, string | null>(
  {
    key: 'ContractQuery',
    get:
      contractId =>
      async ({ get }) => {
        get(contractStateRequestID(contractId))
        if (contractId === null) {
          return undefined
        }
        return contractId && contractId !== 'create'
          ? await getContract(contractId)
          : undefined
      },
  },
)

export const assigneesStateFamily = selectorFamily<any[], string>({
  key: 'AssigneesStateFamily',
  get:
    (contractId: string) =>
    ({ get }) => {
      const contract = get(contractQuery(contractId))

      if (!contract) {
        return []
      }

      return [
        ...(contract.developerAssignees ?? []),
        ...(contract.customerAssignees ?? []),
        ...(contract.customerTechControlAssignees ?? []),
        ...(contract.builderAssignees ?? []),
        ...(contract.prepAssignees ?? []),
        ...(contract.firstAssignees ?? []),
        ...(contract.secondAssignees ?? []),
      ]
    },
})

export const usersProfilesStateFamily = selectorFamily<
  Record<string, User>,
  string
>({
  key: 'usersProfilesSelector',
  get:
    (contractId: string) =>
    ({ get }) => {
      const contract = get(contractQuery(contractId))

      if (!contract) {
        return {}
      }

      const profiles: Record<UUID, User> = {}

      ;[
        ...(contract.developerAssignees ?? []),
        ...(contract.customerAssignees ?? []),
        ...(contract.customerTechControlAssignees ?? []),
        ...(contract.builderAssignees ?? []),
        ...(contract.prepAssignees ?? []),
        ...(contract.firstAssignees ?? []),
        ...(contract.secondAssignees ?? []),
      ].forEach(item => {
        if (item.user?.employment?.employmentId) {
          profiles[item.user.employment.employmentId] = item.user
        }
      })

      if (contract.prorab?.employment?.employmentId) {
        profiles[contract.prorab.employment.employmentId] = contract.prorab
      }

      return profiles
    },
})
