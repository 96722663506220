/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { CommentItem } from '../../models'

import {
  commentsLogByEntityIdQuery,
  commentsLogQueryRequestIDState,
} from './CommentsLog.container'

interface ICommentsLogHook {
  isLoading: boolean
  comments: CommentItem[]
  refreshComments: () => void
  error?: Error
}

type Props = {
  contractId: string
  entityId?: string
  externalEntityId?: string
}

export const useCommentsLogByEntityId = ({
  entityId,
  externalEntityId,
}: Props): ICommentsLogHook => {
  const commentsLogLoadable = useRecoilValueLoadable(
    commentsLogByEntityIdQuery({ entityId, externalEntityId }),
  )
  const setRequestID = useSetRecoilState(
    commentsLogQueryRequestIDState({ entityId, externalEntityId }),
  )

  const refreshComments = () => setRequestID(requestID => requestID + 1)

  const comments =
    commentsLogLoadable.state === 'hasValue' ? commentsLogLoadable.contents : []

  const isLoading = commentsLogLoadable.state === 'loading'

  const error =
    commentsLogLoadable.state === 'hasError'
      ? commentsLogLoadable.contents
      : undefined

  return {
    comments,
    isLoading,
    error,
    refreshComments,
  }
}
