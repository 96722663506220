/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useMemo } from 'react'

import * as queryString from 'query-string'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

export function useRouter() {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      } as Record<string, string | undefined>,
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      // match,
      location,
      navigate,
    }
  }, [params, location, navigate])
}
