/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { atom, atomFamily, selectorFamily } from 'recoil'

import {
  getApplicationUrlByType,
  getDocumentsOriginal,
  getExecutiveDrawing,
} from './applications'
import { Application } from './documentaryType'

export const docsQueryRequestIDState = atomFamily({
  key: 'docsQueryRequestID',
  default: 0,
})

export const workTypeQueryRequestIDState = atom({
  key: 'workTypeQueryRequestID',
  default: 0,
})

export const docsQuery = selectorFamily<
  {
    entityList: any[]
    pageCount: number
    pageNumber: number
    pageSize: number
    documentType: keyof typeof getApplicationUrlByType
  },
  {
    documentType: keyof typeof getApplicationUrlByType
    projectId: string
    pageNumber: number
    pageSize?: number
    cipher?: string
    searchValue?: string
    sortParams?: {
      sortField: string
      sortDirection: 'DESC' | 'ASC'
    }
  }
>({
  key: 'docsQuery',
  get:
    ({
      documentType,
      projectId,
      pageNumber,
      pageSize,
      cipher,
      searchValue,
      sortParams,
    }) =>
    async ({ get }) => {
      get(
        docsQueryRequestIDState({
          documentType,
          projectId,
        }),
      )
      if (documentType === Application.EXECUTIVE_DRAWING)
        return await getExecutiveDrawing({
          documentType,
          projectId,
          pageNumber,
          pageSize,
          cipher,
          searchValue,
          sortParams,
        })
      else
        return await getDocumentsOriginal({
          documentType,
          projectId,
          pageNumber,
          pageSize,
          cipher,
          searchValue,
          sortParams,
        })
    },
})
