import {
  AOSRType,
  MaterialsRegistry,
  SecondaryActType,
  Certificate,
  WorkingDraft,
  Laboratory,
  ActRegistry,
  ExecutiveScheme,
  ExecutiveDrawing,
  Permits,
  Supporting,
} from '@builddocs/filestore-api'

export type DocsTypes =
  | Certificate
  | WorkingDraft
  | AOSRType
  | SecondaryActType
  | MaterialsRegistry
  | ActRegistry
  | ExecutiveScheme
  | ExecutiveDrawing
  | Permits
  | Laboratory
  | Supporting

export type SchemesTypes =
  | Certificate
  | WorkingDraft
  | ExecutiveScheme
  | ExecutiveDrawing
  | Permits
  | Laboratory
  | Supporting
