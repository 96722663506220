/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'

export const DRAFT = 'DRAFT'
export const AGREEMENT = 'AGREEMENT'
export const SIGNING = 'SIGNING'
export const PENDING = 'PENDING'
export const SIGN_REQUIRED = 'SIGN_REQUIRED'
export const REJECTED = 'REJECTED'
export const SIGNED = 'SIGNED'
export const ACCEPTED = 'ACCEPTED'

export const theState = (state: any) => {
  switch (state) {
    case DRAFT:
      return 'Черновик'
    case AGREEMENT:
      return 'На согласовании'
    case SIGNING:
      return 'Частично согласована'
    case PENDING:
      return 'Требуется решить'
    case SIGN_REQUIRED:
      return 'Требуется ваше решение'
    case REJECTED:
      return 'Отклонена'
    case SIGNED:
      return 'Подписана'
    case ACCEPTED:
      return 'Подписана'
    default:
      return 'Неизвестный статус'
  }
}

export const theStateWithDescription = (state: any) => {
  switch (state) {
    case DRAFT:
      return 'Черновик: видно только вам'
    case AGREEMENT:
      return 'На согласовании: ожидайте решения уполномоченных'
    case SIGNING:
      return 'Частично согласовано'
    case PENDING:
      return 'Требуется решить'
    case SIGN_REQUIRED:
      return 'Требуется ваше решение'
    case REJECTED:
      return 'Отклонено'
    case SIGNED:
      return 'Подписано'
    case ACCEPTED:
      return 'Согласовано'
    default:
      return 'Неизвестный статус'
  }
}

export const statusColor = (state: string) => {
  switch (state) {
    case PENDING:
      return 'warning'
    case SIGNED:
    case ACCEPTED:
      return 'success'
    case REJECTED:
      return 'error'
    default:
      return 'info'
  }
}
export const statusIcon = (state: string) => {
  switch (state) {
    case PENDING:
      return (
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3927_3239)">
            <circle cx="8" cy="8" fill="#FFC554" r="8" />
            <path
              d="M7.78 3H7.72C7.32 3 7 3.32 7 3.72V8.44C7 8.79 7.18 9.12 7.49 9.3L11.64 11.79C11.98 11.99 12.42 11.89 12.62 11.55C12.83 11.21 12.72 10.76 12.37 10.56L8.5 8.26V3.72C8.5 3.32 8.18 3 7.78 3Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_3927_3239">
              <rect fill="white" height="16" width="16" />
            </clipPath>
          </defs>
        </svg>
      )

    case SIGNED:
    case ACCEPTED:
      return (
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3927_3229)">
            <circle cx="8" cy="8" fill="#43A047" r="8" />
            <path d="M3 8L6.5 11.5L13 5" stroke="white" strokeWidth="1.5" />
          </g>
          <defs>
            <clipPath id="clip0_3927_3229">
              <rect fill="white" height="16" width="16" />
            </clipPath>
          </defs>
        </svg>
      )

    case REJECTED:
      return (
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3882_4299)">
            <circle cx="8" cy="8" fill="#F20404" r="8" />
            <path d="M11 5L5 11M5 5L11 11" stroke="white" />
          </g>
          <defs>
            <clipPath id="clip0_3882_4299">
              <rect fill="white" height="16" width="16" />
            </clipPath>
          </defs>
        </svg>
      )

    default:
      return <WarningAmberRoundedIcon color={statusColor(state)} />
  }
}
