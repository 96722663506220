/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useContext, useMemo, useState, useEffect } from 'react'

import {
  ActForm,
  SignatureState,
  commentsLogService,
} from '@builddocs/data-management'
import {
  actExternal,
  ExternalSigningAct,
  useActExternal,
} from '@builddocs/ext-sign-data-access'
import {
  FaceControlContext,
  SignHandlerParams,
} from '@builddocs/feature-faceid'
import {
  getBlobAOSR,
  getDigestAndPrepareAOSR,
  getDigestAndPrepareSecondaryAct,
  signAct,
  signSecondaryAct,
} from '@builddocs/filestore-api'
import { useToast } from '@builddocs/system'
import { Divider, Link, Stack, Typography } from '@mui/material'
import { useRecoilState } from 'recoil'

import { ActionButtons } from './ActionButtons'
import { CommentsDialog } from './CommentsDialog'
import { UserInfo } from './UserInfo'

type OwnProps = {
  externalSigningValue: ExternalSigningAct
}

export const HeaderContent = ({ externalSigningValue }: OwnProps) => {
  const faceControl = useContext(FaceControlContext)
  const { toast } = useToast()

  const [isLoading] = useState(false)
  const { loadAct, isActLoading, isActFail } = useActExternal()
  const [act] = useRecoilState(actExternal)

  const handleDeviate = async data => {
    if (!act) return

    const user = externalSigningValue?.externalUser

    if (!user || !externalSigningValue) return

    if (act.actType === ActForm.AOSR) {
      await signAct({
        documentId: act.id,
        employmentId: user.employment.employmentId,
        signatureState: SignatureState.REJECTED,
      })
    } else {
      await signSecondaryAct({
        comment: data.problemDescription,
        documentId: act.id,
        employmentId: user.employment.employmentId,
        signatureState: SignatureState.REJECTED,
      })
    }
    externalSigningValue && loadAct(externalSigningValue)

    await commentsLogService.create(externalSigningValue.journalId, {
      ...data,
      externalEntityId: act.id,
      externalEntityInfo: act.actType,
      entityId: externalSigningValue.journalId,
      entityType: 'ACT_DOC',
    })

    toast.info(
      `Сделана запись в журнал замечаний для ${
        act.closingName ?? act.name ?? 'акта'
      }`,
    )
    externalSigningValue && loadAct(externalSigningValue)
  }

  const handleSign = () => {
    if (!act) return
    const isAosr = act.actType === ActForm.AOSR
    const user = externalSigningValue?.externalUser

    user &&
      faceControl.openFaceControl({
        isEmbedded: act.signatureEmbedded,
        getDigestHandler: async ({
          signedHash,
          keyIssuer,
          imprint,
          facePhoto,
          keySerialNumber,
          certificateDateStart,
          certificateDateEnd,
          signerName,
        }) => {
          if (isAosr) {
            let digest: string
            let actRegistryDigest: string | undefined

            if (act.signatureEmbedded) {
              const aosrDigestResponse = await getDigestAndPrepareAOSR({
                base64signature: signedHash,
                documentId: act.id,
                employmentId: user.employment.employmentId,
                imprint: imprint,
                keyIssuer: keyIssuer,
                signatureState: SignatureState.PENDING,
                certificateDateStart,
                certificateDateEnd,
                name: signerName,
                faceId: {
                  faceImage: facePhoto,
                  keySerialNumber,
                },
              })

              digest = aosrDigestResponse.digest
              actRegistryDigest = aosrDigestResponse.actRegistryDigest
            } else {
              digest = await getBlobAOSR(act.id)
            }

            return {
              hash: digest,
              actRegistryDigest,
              currentSignatureBase64: act.base64signature,
            }
          } else {
            return {
              hash: await getDigestAndPrepareSecondaryAct({
                base64signature: signedHash,
                documentId: act.id,
                employmentId: user.employment.employmentId,
                imprint: imprint,
                keyIssuer: keyIssuer,
                signatureState: SignatureState.PENDING,
                certificateDateStart,
                certificateDateEnd,
                name: signerName,
                faceId: {
                  faceImage: facePhoto,
                  keySerialNumber,
                },
              }),
              currentSignatureBase64: act.base64signature,
            }
          }
        },
        signHandler: ({
          signedHash,
          actRegistryBase64signature,
          keyIssuer,
          imprint,
          keySerialNumber,
          signerName,
        }: SignHandlerParams) =>
          (isAosr ? signAct : signSecondaryAct)({
            documentId: act.id,
            employmentId: user.employment.employmentId,
            signatureState: SignatureState.SIGNED,
            base64signature: signedHash,
            actRegistryBase64signature,
            keyIssuer,
            imprint,
            name: signerName,
            faceId: {
              keySerialNumber,
            },
          }),
        onSuccess: () => {
          toast.success.t('act.message.signed')
          externalSigningValue && loadAct(externalSigningValue)
        },
        onFailure: e => {
          toast.error.exception(e, 'act.message.failedToSign')
        },
      })
  }

  useEffect(() => {
    isActFail && toast.warning('Не удалось загрузить подписанный акт')
  }, [isActFail])

  const isOnSigning =
    act &&
    (act.approvalStatus === 'SIGNING' ||
      act.approvalStatus === 'SIGNED' ||
      act.approvalStatus === 'REJECTED')

  const userSignStatus = useMemo(() => {
    return (
      act &&
      externalSigningValue &&
      isOnSigning &&
      act.assignees.filter(
        item =>
          item.employmentId ===
          externalSigningValue?.externalUser.employment.employmentId,
      )[0]?.signatureState
    )
  }, [act, externalSigningValue, isOnSigning])

  const isActRejectedNotByUser =
    act &&
    act.approvalStatus === 'REJECTED' &&
    userSignStatus &&
    userSignStatus !== 'REJECTED'

  return (
    <Stack direction="row" width="100%">
      <Stack px={2}>
        <Stack alignItems="center" direction="row">
          {externalSigningValue?.externalUser && (
            <UserInfo user={externalSigningValue.externalUser} />
          )}

          <Divider orientation="vertical" sx={{ mx: 1 }} variant="middle" />

          {externalSigningValue && (
            <Stack direction="row" spacing={0.5}>
              <Typography color="text.secondary" id="sender" variant="caption">
                Отправитель:
              </Typography>
              <Link
                href={`mailto:${externalSigningValue?.senderUser.email}`}
                title="Написать письмо"
                underline="hover"
                variant="caption"
              >
                {externalSigningValue?.senderUser.email}
              </Link>
              <Typography color="text.secondary" variant="caption">{`${
                externalSigningValue?.senderUser.lastName
              } ${externalSigningValue?.senderUser.firstName ?? ''} ${
                externalSigningValue?.senderUser?.middleName ?? ''
              }, ${externalSigningValue?.senderOrganizationName}`}</Typography>
            </Stack>
          )}
        </Stack>

        {act && <Typography fontWeight="bold">{act.name}</Typography>}
        {externalSigningValue && (
          <Typography color="text.secondary" variant="caption">
            {externalSigningValue?.journalDescription}
          </Typography>
        )}
      </Stack>
      {act && (
        <Stack ml="auto" mt="auto" spacing={1}>
          {externalSigningValue?.journalId && <CommentsDialog actId={act.id} />}
          {isOnSigning ? (
            isActRejectedNotByUser ? (
              <Typography color="error.main">Ой! Акт был отклонён</Typography>
            ) : (
              <Stack alignItems="center" direction="row" spacing={1}>
                {isOnSigning && userSignStatus && (
                  <ActionButtons
                    act={act}
                    contractId={externalSigningValue?.journalId}
                    handleDeviate={handleDeviate}
                    handleSign={handleSign}
                    isLoading={isLoading || isActLoading}
                    userSignStatus={userSignStatus}
                  />
                )}
              </Stack>
            )
          ) : (
            <Typography color="error.main">
              Ой! Акт был снят с подписания
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  )
}
