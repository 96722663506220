/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Ability, AbilityBuilder, SubjectRawRule } from '@casl/ability'

import { Roles, User } from '../models'

import { Application } from './Application.constant'

type Props = (SubjectRawRule<any, any, any> | any)[]

export function defineRulesFor(auth: User): Props {
  const { can, rules, cannot } = new AbilityBuilder(Ability)

  // TODO: вытащить касл в отдельную библиотеку
  Object.keys(Application).forEach(key => {
    if (key === 'NORMATIVE') {
      // TODO: Убрать этот хардкод
      if (auth.phone === '+79650838309') {
        can('add', key)
        can('edit', key)
        can('view', key)
      } else {
        console.log('Denied normative')
        cannot('add', key)
        cannot('edit', key)
        can('view', key)
      }
    } else if (key === Application.EXECUTIVE_DRAWING) {
      cannot('add', key)
      can('edit', key)
      can('view', key)
    } else {
      can('add', key)
      can('edit', key)
      can('view', key)
    }
  })

  if (auth.role.roleId === Roles.USER) {
    can('add', 'Organization')
    cannot('edit', 'Organization')
    cannot('view', 'Organization')

    cannot('add', 'Contracts')
    cannot('edit', 'Contracts')
    cannot('view', 'Contracts')

    can('view', 'EmploymentInfo')
  }
  if (auth.role.roleId === Roles.WORKER) {
    cannot('add', 'Organization')
    cannot('edit', 'Organization')
    cannot('view', 'Organization')

    can('view', 'Projects')

    can('add', 'Contracts')
    can('edit', 'Contracts')
    can('view', 'Contracts')
    can('view', 'ContractsShortList')

    can('view', 'EmploymentInfo')
  }
  if (auth.role.roleId === Roles.PRORAB) {
    cannot('add', 'Organization')
    cannot('edit', 'Organization')
    cannot('view', 'Organization')

    cannot('add', 'Projects')
    can('view', 'Projects')

    can('add', 'Contracts')
    can('edit', 'Contracts')
    can('view', 'Contracts')
    can('view', 'ContractsShortList')

    can('view', 'EmploymentInfo')
  }
  // Админ в новой будущей системе Эксона
  if (auth.role.roleId === Roles.ADMIN) {
    can('edit', 'Organization')
    can('edit', 'Organization')
    can('view', 'Organization')

    can('add', 'Projects')
    can('view', 'Projects')

    can('add', 'Contracts')
    can('edit', 'Contracts')
    can('view', 'Contracts')
    can('view', 'ContractsShortList')

    can('view', 'ProjectsShortList')

    can('view', 'EmploymentInfo')

    can('edit', 'IntegrationsConfig')
    can('edit', 'IntegrationsConfig')
    can('view', 'IntegrationsConfig')

    can('view', 'InvoicesInProject')
    can('view', 'WorksInProject')
  }
  return rules
}
