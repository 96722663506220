/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { assigneesStateFamily } from '@builddocs/data-management'
import { useRecoilValueLoadable } from 'recoil'

interface SigneesHook {
  isLoading: boolean
  signers: any[] | undefined
  error?: Error
}

export const useSignees = (contractId: string): SigneesHook => {
  const signeesLoadable = useRecoilValueLoadable(
    assigneesStateFamily(contractId),
  )

  const signers =
    signeesLoadable.state === 'hasValue' ? signeesLoadable.contents : undefined

  const isLoading = signeesLoadable.state === 'loading'

  const error =
    signeesLoadable.state === 'hasError' ? signeesLoadable.contents : undefined

  return {
    signers,
    isLoading,
    error,
  }
}
