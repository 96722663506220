import { atomFamily, selectorFamily } from 'recoil'

import { Organization } from '../../models/organization'
import { currentUser } from '../current-user/User.container'

import { getOrganization, getOrganizationForUser } from './organization.service'

export const organizationQueryRequestIDState = atomFamily({
  key: 'OrganizationQueryRequestID',
  default: 0,
})

export const organizationQuery = selectorFamily<
  Organization,
  string | undefined
>({
  key: 'OrganizationQuery',
  get:
    inn =>
    async ({ get }) => {
      get(organizationQueryRequestIDState(inn))
      get(currentUser)

      if (inn) {
        return await getOrganization(inn)
      }

      return await getOrganizationForUser()
    },
})
