/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

type Stage = {
  stageId: number
  parentStageId?: number
  [propType: string]: any
}

/**
 * Получение подсписка от узла.
 * Если stageId не передан, то возвращается корневой список
 * @param stages - массив всех записей
 * @param stageId - родительский узел
 */
export function getTreeNode<T>(
  stages: Array<Stage & T>,
  stageId?: number,
): Array<T> {
  return stageId
    ? stages.filter(item => item.parentStageId === stageId)
    : stages
}
