/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { EventBaseType } from '../Event.base.type'

import type { Employment, UUID, User } from '../../../models'

export interface EmploymentEvent extends EventBaseType {
  businessKey: string
  employment: Employment
  generatorId: UUID
  journalId: UUID
  projectId: UUID

  receiver: User
  sender: User
  targetId: UUID[]
}
