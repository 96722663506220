/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import axios from 'axios'

import { checkToken } from '../rest/auth'

const DocgenAPI = axios.create({
  baseURL: '/generator/api/v1',
})

DocgenAPI.interceptors.request.use(
  config => {
    // Do something before request is sent
    return checkToken(config)
  },
  error => {
    return Promise.reject(error)
  },
)

DocgenAPI.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error.response?.status === 403) {
      console.log('Токен просрочен, получение нового токена...')
      return DocgenAPI.request(await checkToken(error.config))
    }

    return Promise.reject(error)
  },
)

export { DocgenAPI }
