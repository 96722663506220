import { User } from '@builddocs/data-management'
import { Typography } from '@mui/material'

type Props = {
  user: User
}

export const UserInfo = ({ user }: Props) => {
  return user ? (
    <Typography color="text.secondary" variant="caption">
      {`Получатель: ${user?.email} ${user?.lastName ?? ''} ${
        user?.firstName ?? ''
      } ${user?.middleName ?? ''}`}
    </Typography>
  ) : (
    <Typography>Пользователь не определён</Typography>
  )
}
