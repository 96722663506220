/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */
import { Buffer } from 'buffer'

import { styled } from '@mui/system'

export interface StampProps {
  picture: string
  title?: string
  onClick?: () => void
}

const Image = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  cursor: 'pointer',
  objectFit: 'contain',

  '&:hover': {
    boxShadow: `0 15px 10px -10px rgba(0, 0, 0, 0.3),
    0 1px 0px rgba(0, 0, 0, 0.1)`,
  },
})

export function Stamp({ picture, title, onClick, ...rest }: StampProps) {
  const buffer = Buffer.from(picture, 'base64')
  const blob = new Blob([buffer])
  const pictureUrl = `${URL.createObjectURL(blob)}`

  return <Image alt={title} src={pictureUrl} onClick={onClick} {...rest} />
}

export default Stamp
