/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useRecoilValueLoadable } from 'recoil'

import { UUID } from '../../models'

import { organizationsProjectQuery } from './Organizations.container'
import { OrganizationsProjectResponseType } from './types'

interface OrganizationsProjectHook {
  isLoading: boolean
  organizations: Record<string, OrganizationsProjectResponseType>
  error?: Error
}
/**
 *Хук для получения списка организаций из проекта
 */
export const useOrganizationsProject = (
  projectId: UUID,
  contractId: UUID,
): OrganizationsProjectHook => {
  const organizationsLoadable = useRecoilValueLoadable(
    //получение списка организаций указанных в проекте
    organizationsProjectQuery({ projectId, contractId }),
  )

  const organizations =
    organizationsLoadable.state === 'hasValue' //если спиок не пуст выдаем лист организаций иначе пустой массив
      ? organizationsLoadable.contents
      : {}

  const isLoading = organizationsLoadable.state === 'loading' //статус загрузки

  const error =
    organizationsLoadable.state === 'hasError' //выдача ошибки если запрос не прошел
      ? organizationsLoadable.contents
      : undefined

  return {
    organizations,
    isLoading,
    error,
  }
}
