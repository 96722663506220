/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { UUID } from '@builddocs/data-management'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { sheetsQuery, sheetsQueryRequestIDState } from './sheets.container'

export const useSheets = (id: UUID) => {
  const listsWorkingDraftLoadable = useRecoilValueLoadable(sheetsQuery(id))

  const setRequestID = useSetRecoilState(sheetsQueryRequestIDState(id))
  const refreshSheets = () => setRequestID(requestID => requestID + 1)

  const sheets =
    listsWorkingDraftLoadable.state === 'hasValue'
      ? listsWorkingDraftLoadable.contents
      : []

  return { sheets, refreshSheets }
}
