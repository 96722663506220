/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { TypeOfWorksEnum } from './TypeOfWorks'

export const WorkLabels = {
  [TypeOfWorksEnum.AUTOMATIC_FIRE_EXTINGUISHING_INSTALLATIONS]:
    'Автоматические установки пожаротушения',
  [TypeOfWorksEnum.ANTI_CORROSION_PROTECTION]:
    'Антикоррозионная защита сварных соединений',
  [TypeOfWorksEnum.CONCRETING]: 'Бетонирование',
  [TypeOfWorksEnum.IMPROVEMENT_OF_THE_TERRITORY]: 'Благоустройство территории',
  [TypeOfWorksEnum.DRILLING_WELLS]: 'Бурение скважин под сваи',
  [TypeOfWorksEnum.VENTILATION_AND_AIR_CONDITIONING]:
    'Вентиляция и кондиционирование',
  [TypeOfWorksEnum.INTERIOR_FINISHING_WORKS]:
    'Внутренние отделочные работы (полы, стены, потолки)',
  [TypeOfWorksEnum.INTERNAL_WATER_SUPPLY_AND_SEWERAGE_NETWORKS]:
    'Внутренние сети водопровода и канализации',
  [TypeOfWorksEnum.INTERNAL_POWER_SUPPLY_NETWORKS]:
    'Внутренние сети электроснабжения',
  [TypeOfWorksEnum.GAS_PIPELINE]: 'Газопровод',
  [TypeOfWorksEnum.PALIFICATION]: 'Забивка свай',
  [TypeOfWorksEnum.ROOF]: 'Кровля',
  [TypeOfWorksEnum.INSTALLATION_AND_COMMISSIONING_OF_AUTOMATION_SYSTEMS]:
    'Монтаж и наладка систем автоматизации',
  [TypeOfWorksEnum.INSTALLATION_OF_ELEVATORS]: 'Монтаж лифтов',
  [TypeOfWorksEnum.INSTALLATION_STRUCTURE]: 'Монтаж строительных конструкций',
  [TypeOfWorksEnum.INSTALLATION_OF_TECHNOLOGICAL_EQUIPMENT_AND_PIPELINES]:
    'Монтаж технологического оборудования и трубопроводов',
  [TypeOfWorksEnum.EXTERNAL_FINISHING_WORKS]:
    'Наружные отделочные работы (фасад)',
  [TypeOfWorksEnum.EXTERNAL_WATER_SUPPLY_AND_SEWERAGE_NETWORKS]:
    'Наружные сети водопровода и канализации',
  [TypeOfWorksEnum.EXTERNAL_POWER_SUPPLY_NETWORKS]:
    'Наружные сети электроснабжения',
  [TypeOfWorksEnum.BEARING_AND_ENCLOSING_STRUCTURES]:
    'Несущие и ограждающие конструкции',
  [TypeOfWorksEnum.CIVIL_WORKS]: 'Общестроительные работы',
  [TypeOfWorksEnum.FIRE_AND_SECURITY_AND_FIRE_ALARMS]:
    'Пожарная и охранно-пожарная сигнализация',
  [TypeOfWorksEnum.CABLING]: 'Прокладка кабеля',
  [TypeOfWorksEnum.OTHER]: 'Прочие',
  [TypeOfWorksEnum.WELDING]: 'Сварочные',
  [TypeOfWorksEnum.HEATING_AND_VENTILATION_NETWORKS]:
    'Сети отопления и вентиляции',
  [TypeOfWorksEnum.COMMUNICATION_SIGNALING_AND_AUTOMATION_SYSTEMS]:
    'Системы связи, сигнализации и автоматизации',
  [TypeOfWorksEnum.HEATING_NETWORK]: 'Тепловые сети',
  [TypeOfWorksEnum.TECHNOLOGICAL_SOLUTIONS]: 'Технологические решения',
  [TypeOfWorksEnum.ROAD_CONSTRUCTION]: 'Устройство автомобильных дорог',
  [TypeOfWorksEnum.CONSTRUCTION_OF_FOUNDATIONS]: 'Устройство фундаментов',
}

export const workTypes = [
  TypeOfWorksEnum.AUTOMATIC_FIRE_EXTINGUISHING_INSTALLATIONS,
  TypeOfWorksEnum.IMPROVEMENT_OF_THE_TERRITORY,
  TypeOfWorksEnum.VENTILATION_AND_AIR_CONDITIONING,
  TypeOfWorksEnum.INTERIOR_FINISHING_WORKS,
  TypeOfWorksEnum.INTERNAL_WATER_SUPPLY_AND_SEWERAGE_NETWORKS,
  TypeOfWorksEnum.INTERNAL_POWER_SUPPLY_NETWORKS,
  TypeOfWorksEnum.GAS_PIPELINE,
  TypeOfWorksEnum.ROOF,
  TypeOfWorksEnum.INSTALLATION_AND_COMMISSIONING_OF_AUTOMATION_SYSTEMS,
  TypeOfWorksEnum.INSTALLATION_OF_ELEVATORS,
  TypeOfWorksEnum.INSTALLATION_OF_TECHNOLOGICAL_EQUIPMENT_AND_PIPELINES,
  TypeOfWorksEnum.EXTERNAL_FINISHING_WORKS,
  TypeOfWorksEnum.EXTERNAL_WATER_SUPPLY_AND_SEWERAGE_NETWORKS,
  TypeOfWorksEnum.EXTERNAL_POWER_SUPPLY_NETWORKS,
  TypeOfWorksEnum.BEARING_AND_ENCLOSING_STRUCTURES,
  TypeOfWorksEnum.CIVIL_WORKS,
  TypeOfWorksEnum.FIRE_AND_SECURITY_AND_FIRE_ALARMS,
  TypeOfWorksEnum.HEATING_AND_VENTILATION_NETWORKS,
  TypeOfWorksEnum.COMMUNICATION_SIGNALING_AND_AUTOMATION_SYSTEMS,
  TypeOfWorksEnum.HEATING_NETWORK,
  TypeOfWorksEnum.TECHNOLOGICAL_SOLUTIONS,
  TypeOfWorksEnum.ROAD_CONSTRUCTION,
  TypeOfWorksEnum.CONSTRUCTION_OF_FOUNDATIONS,
]
