/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { atom, selector } from 'recoil'

import { LinkedMaterial, PairId } from './matlink.types'

export const linkedMaterialsAtom = atom<LinkedMaterial[]>({
  key: 'linkedMaterialsState',
  default: [],
})

export const stringSortMatlinkAtom = atom<string>({
  key: 'StringSortMatlinkAtom',
  default: '',
})

export const SortMatlinkQuery = selector<LinkedMaterial[]>({
  key: 'SortMatlinkQuery',
  get: ({ get }) => {
    const stringSortMatlink = get(stringSortMatlinkAtom)

    const array = get(linkedMaterialsAtom)

    if (stringSortMatlink === '') return array

    return array.filter(
      item =>
        (item.estimateMaterialName &&
          item.estimateMaterialName
            .toUpperCase()
            .includes(stringSortMatlink.toUpperCase())) ||
        (item.projectMaterialName &&
          item.projectMaterialName
            .toUpperCase()
            .includes(stringSortMatlink.toUpperCase())),
    )
  },
})

export const unlinkedMaterialsQuery = selector<LinkedMaterial[]>({
  key: 'unlinkedMaterialsQuery',
  get: ({ get }) => {
    const array = get(SortMatlinkQuery)

    return array.filter(item => !item.projectMaterialId)
  },
})

export const linkedMaterialsQuery = selector<LinkedMaterial[]>({
  key: 'linkedMaterialsQuery',
  get: ({ get }) => {
    const array = get(SortMatlinkQuery)

    return array.filter(item => item.projectMaterialId)
  },
})

export const PairsLinkedMaterialsQuery = selector<PairId[]>({
  key: 'PairsLinkedMaterialsQuery',
  get: ({ get }) => {
    const array = get(linkedMaterialsAtom)

    return array
      .filter(
        item =>
          item.estimateMaterialId !== null && item.projectMaterialId !== null,
      )
      .map(item => ({
        estimateMaterialId: item.estimateMaterialId,
        projectMaterialId: item.projectMaterialId,
        locked: item.locked,
      })) as PairId[]
  },
})
