/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import {
  InspectionState,
  SignatureState,
  UUID,
} from '@builddocs/data-management'
import qs from 'qs'

import { FilestoreAPI } from '../FilestoreAPI'

/******************
 * Вторичные акты *
 ******************/

/** Получение дайджеста для списка secondary актов */
export const getDigestListSecondaryAct = (actId: string[]) => {
  return FilestoreAPI.get(`/secondary-act/digest`, {
    params: { id: actId.join(',') },
  }).then(res => res.data)
}
/** Подготовка вторичного акта и получение хэша */
export const getDigestAndPrepareSecondaryAct = (formSignStamp: {
  base64signature: null
  documentId: string
  employmentId: string
  imprint: string
  keyIssuer: string
  signatureState: SignatureState.PENDING
  certificateDateStart: string
  certificateDateEnd: string
  name: string
  faceId: {
    faceImage?: string
    faceImageBytes?: string
    keySerialNumber: string
  }
}) =>
  FilestoreAPI.post(`/secondary-act/prepare`, formSignStamp, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.data)

/** Получение дайджеста с подписью для списка secondary актов */
export const getDigestAndPrepareListSecondaryActs = (acts: {
  faceId: {
    faceImage?: string
    faceImageBytes?: string
    keySerialNumber: string
  }
  signRequests: {
    certificateDateEnd: string
    certificateDateStart: string
    documentId: string
    employmentId: string
    imprint: string
    keyIssuer: string
    name: string
    signatureState: SignatureState.PENDING
  }[]
}) => {
  return FilestoreAPI.post(`/secondary-act/bulk-prepare `, acts, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.data)
}

/** Инициализация подписания secondary акта*/
export const signInitSecondaryAct = ({
  actId,
  isEmbedded = false,
  addActAssignees,
  projectId,
  journalId,
  workDocSection,
}: {
  actId: string
  isEmbedded: boolean
  addActAssignees: string[]
  projectId: UUID
  journalId: UUID
  workDocSection: string
}) => {
  return FilestoreAPI.post(`/secondary-act/${actId}/sign/add-signers`, {
    id: actId,
    embedded: isEmbedded.toString(),
    employmentIds: addActAssignees,
    projectId: projectId,
    journalId: journalId,
    workDocSection: workDocSection,
  }).then(res => res.data)
}
/** Подписание/Отклонение secondary акта */
export const signSecondaryAct = (
  data:
    | {
        documentId: string
        employmentId: string
        signatureState: SignatureState.SIGNED
        base64signature: string
        keyIssuer: string
        imprint: string
        name: string
        faceId: {
          faceImage?: string
          faceImageBytes?: string
          keySerialNumber: string
        }
      }
    | {
        comment: string
        documentId: string
        employmentId: string
        signatureState: SignatureState.REJECTED
      },
) => FilestoreAPI.post(`/secondary-act/sign`, data).then(res => res.data)
/** Добавление проверяющих в secondary акт */
export const inspectInitSecondaryAct = (
  projectId: string,
  journalId: string,
  workDocSection: string,
  actId: string,
  addActInspectors?: string[],
) => {
  const form = new FormData()

  form.append('id', actId)
  new Set(addActInspectors)?.forEach(id => form.append('employmentId', id))
  return FilestoreAPI.post(`/secondary-act/inspect`, form, {
    params: {
      id: actId,
      employmentId: addActInspectors,
      projectId: projectId,
      journalId: journalId,
      workDocSection: workDocSection,
    },
    paramsSerializer: p => {
      return qs.stringify(p, { arrayFormat: 'brackets' })
    },
  }).then(res => res.data)
}
/** Проверка secondary акта */
export const inspectSecondaryAct = (
  actId: string,
  employmentId: string,
  state: InspectionState,
) => {
  const form = new FormData()

  form.append('employmentId', employmentId)
  form.append('state', state)

  return FilestoreAPI.put(`/secondary-act/inspect/${actId}`, form, {
    params: {
      employmentId: employmentId,
      state: state,
    },
  }).then(res => res.data)
}
