/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export const ADOBE_PDF_CLIENT_ID =
  (process.env.NODE_ENV === 'development' ||
  process.env.NX_ENV === 'development'
    ? process.env.NX_ADOBE_PDF_CLIENT_ID_DEV
    : process.env.NX_ADOBE_PDF_CLIENT_ID) ?? ''

export class ViewSDKClient {
  private adobeDCView: any
  private readonly readyPromise: Promise<unknown>
  constructor() {
    this.readyPromise = new Promise<void>(resolve => {
      if ((window as any).AdobeDC) {
        resolve()
      } else {
        /* Wait for Adobe Document Services PDF Embed API to be ready */
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve()
        })
      }
    })
    this.adobeDCView = undefined
  }

  ready() {
    return this.readyPromise
  }

  previewFile(
    divId: string,
    { url, fileName, fileId }: any,
    viewerConfig?: any,
  ) {
    const config: {
      clientId: string
      locale: 'ru-RU'
      divId?: string
    } = {
      /* Pass your registered client id */
      clientId: ADOBE_PDF_CLIENT_ID,
      locale: 'ru-RU',
      divId: undefined,
    }

    if (divId) {
      /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new (window as any).AdobeDC.View(config)

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: {
          /* Location of file where it is hosted */
          location: {
            url,
            /*
          If the file URL requires some additional headers, then it can be passed as follows:-
          headers: [
              {
                  key: "<HEADER_KEY>",
                  value: "<HEADER_VALUE>",
              }
          ]
          */
          },
        },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName,
          /* file ID */
          id: fileId,
        },
      },
      viewerConfig,
    )

    return previewFilePromise
  }

  previewFileUsingFilePromise(divId: any, filePromise: any, fileName: any) {
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new (window as any).AdobeDC.View({
      /* Pass your registered client id */
      clientId: ADOBE_PDF_CLIENT_ID,
      /* Pass the div id in which PDF should be rendered */
      divId,
    })

    /* Invoke the file preview API on Adobe DC View object */
    this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: {
          /* pass file promise which resolve to arrayBuffer */
          promise: filePromise,
        },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName: fileName,
        },
      },
      {},
    )
  }

  registerSaveApiHandler() {
    /* Define Save API Handler */
    const saveApiHandler = (metaData: any, content: any, options: any) => {
      console.log(metaData, content, options)
      return new Promise(resolve => {
        /* Dummy implementation of Save API, replace with your business logic */
        setTimeout(() => {
          const response = {
            code: (window as any).AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, {
                updatedAt: new Date().getTime(),
              }),
            },
          }

          resolve(response)
        }, 2000)
      })
    }

    this.adobeDCView.registerCallback(
      (window as any).AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      {},
    )
  }

  registerEventsHandler() {
    /* Register the callback to receive the events */
    this.adobeDCView.registerCallback(
      /* Type of call back */
      (window as any).AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      (/* call back function */ event: any) => {
        console.log(event)
      },
      /* options to control the callback execution */
      {
        /* Enable PDF analytics events on user interaction. */
        enablePDFAnalytics: true,
      },
    )
  }
}

export default ViewSDKClient
