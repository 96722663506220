/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React from 'react'

import { DraftContext } from './DraftContext'
import { useDraftStore } from './useDraftStore'

type User = {
  userId: string
  employment: {
    employmentId: string
  }
}

interface DraftProviderWorksProps {
  children: React.ReactNode
  /** название группы хранилищ черновиков */
  groupName: 'generalJournal'
  contractId: string
  user: User
}

interface DraftProviderInvoiceProps {
  children: React.ReactNode
  /** название группы хранилищ черновиков */
  groupName: 'invoice'
  contractId: string
  user: User
}

type DraftProviderProps = DraftProviderInvoiceProps | DraftProviderWorksProps

export function DraftProvider<T>(props: DraftProviderProps) {
  const { children, groupName, contractId, user } = props

  const draftStore = useDraftStore<T>({
    group: groupName,
    contractId: contractId,
    userId: user.userId,
  })

  const [showDrafts, setShowDrafts] = React.useState(false)

  const draftsExists = React.useMemo(
    () => !!draftStore.currentDrafts.length,
    [draftStore],
  )

  const toggleDraftsShown = (forceValue?: boolean) => {
    setShowDrafts(prevState =>
      typeof forceValue === 'boolean' ? forceValue : !prevState,
    )
  }

  const handleCloneRecord = record => {
    draftStore.append({
      ...record,
      creatorEmploymentId: user.employment.employmentId,
    })
  }

  return (
    <DraftContext.Provider
      value={{
        store: draftStore,
        clone: handleCloneRecord,
        areDraftsShown: showDrafts,
        draftsExists: draftsExists,
        toggleDraftsShown,
      }}
    >
      {children}
    </DraftContext.Provider>
  )
}
