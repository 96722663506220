/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useEffect } from 'react'

import { useToast } from '@builddocs/system'
import { atom, useRecoilState } from 'recoil'

import { useRouter } from '../../../utils/useRouter'

import { TokenManipulate } from './useToken'

type Props = {
  redirectUrlOnSuccess?: string | Location
  loginUrl?: string
  isPrivateRoute?: boolean
  onSuccess?: () => void
  onFailure?: () => void
}

export const AuthState = atom({
  key: 'IsAuthState',
  default: false,
})

export const useTestAuth = (
  props: Props,
): { isAuth: boolean; __deprecated__isAuth: boolean } => {
  const {
    redirectUrlOnSuccess,
    isPrivateRoute = false,
    loginUrl,
    onSuccess,
    onFailure,
  } = props
  const { toast } = useToast()
  const { navigate, location } = useRouter()

  const [isAuth, setIsAuth] = useRecoilState(AuthState)

  useEffect(() => {
    const { from } = (location.state as any) || { from: undefined }

    if (!from) {
      TokenManipulate.refresh((localStorage.getItem('token') as string) || '')
        .then(() => {
          !isPrivateRoute && toast.info.t('auth.message.authenticated_success')

          !isPrivateRoute &&
            redirectUrlOnSuccess &&
            navigate(redirectUrlOnSuccess, { replace: true })

          onSuccess && onSuccess()
          setIsAuth(true)
        })
        .catch(e => {
          !!localStorage.getItem('token') &&
            isPrivateRoute &&
            from?.pathname !== '/' &&
            toast.warning.exception(e, 'auth.message.noAuthenticated')

          isPrivateRoute &&
            loginUrl &&
            navigate(loginUrl, { state: { from: location }, replace: true })

          onFailure && onFailure()
          setIsAuth(false)
        })
    }
  }, [])

  return {
    isAuth,
    __deprecated__isAuth: isAuth,
  }
}
