/*
Copyright 2021 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import { useEffect, useMemo } from 'react'

import { ViewSDKClient } from '@builddocs/data-management'

export const AdobeViewer = ({ file, divId, withoutReady = false }) => {
  const viewSDKClient = useMemo(() => new ViewSDKClient(), [])

  useEffect(() => {
    file.url &&
      (withoutReady
        ? viewSDKClient.previewFile(divId ?? 'pdf-div', file)
        : viewSDKClient.ready().then(() => {
            /* Invoke file preview */
            /* By default the embed mode will be Full Window */
            viewSDKClient.previewFile(divId ?? 'pdf-div', file)
          }))
  }, [divId, file])

  return <div className="full-window-div" id={divId ?? 'pdf-div'} />
}

export default AdobeViewer
