/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import {
  IncomingInvoice,
  InvoiceAssignee,
  InvoicePosition,
  Material,
} from './invoice'

export enum ReceiverAssigneeTypes {
  CUSTOMER_TECH_CONTROL = 'CUSTOMER_TECH_CONTROL',
  PREP = 'PREP',
  DEVELOPER = 'DEVELOPER',
  BUILDER = 'BUILDER',
  CUSTOMER = 'CUSTOMER',
  UNDEFINED = 'UNDEFINED',
}

export interface InvoiceAssigneeResponse extends InvoiceAssignee {
  createDateTime: string
}

export interface InvoicePositionResponse extends InvoicePosition {
  journalId: string // ID журнала
  invoiceId: string // id накладной
  positionId: string // id позиции
  material: MaterialResponse //
  createDateTime: string
  actualAmount: number
  usedAmount: number
}

export interface IncomingInvoiceResponse extends IncomingInvoice {
  invoiceId: string // id накладной
  invoiceAssignee: InvoiceAssigneeResponse[]
  invoicePositions: InvoicePositionResponse[]
  createDateTime: string
  receiverInn: string | null
  receiverCompanyName: string | null
  receiverAssigneeTypes: ReceiverAssigneeTypes[]
}

export interface MaterialResponse extends Material {
  materialId: string // id материала
  createDateTime: string
}
