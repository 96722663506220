import { WorkType, UnitType } from '../../constants'
import { DocumentMeta, UUID } from '../../models'
import { Assignee } from '../contracts'
import { MaterialResponse } from '../invoices'

import { Construction, WorksNames } from './worksNames'

export interface StageResponse extends Stage {
  stageId: number
  sectionId: UUID
  /** Проектная документация */
  executiveDoc: DocumentMeta[]
  /** Дата создания работ */
  createDateTime: string
  /** Статус акта */
  actState: string
  /** Уполномоченные */
  assignees: Assignee[]
  /** Создатель работы */
  creatorEmploymentId: UUID
  /** Описание */
  description: string
  /** Связанные акты */
  linkedAct: { actState: string; url: string }
  /** Примененные материалы */
  materials: MaterialResponse[]
  /** Место проведения работ */
  place: string
  /** Проектная документация */
  projectDocs: DocumentMeta[]
  /** Статус */
  state: string
  /** Дата проведения работ */
  workDate: string
  type: WorksNames.SECTION
  constructions: Construction[]
}

export interface Stage {
  canDelete: boolean
  journalId: UUID
  parentStageId?: number
  /** Наименование работы */
  title: string
  /** Раздел рабочей документации */
  workDocSection: string
  /** Вид работы */
  workType: WorkType
  /** Дата начала работ */
  startDate: string
  /** Дата окончания работ */
  endDate: string
  /** Проектная документация */
  projectDoc: string
  /** Нормативные документы */
  normativeDocs: DocumentMeta[]
  /** Объём работ */
  unit: UnitType
}

export type TypeOfWorks =
  | 'OTHER'
  | 'CONCRETING'
  | 'WELDING'
  | 'CABLING'
  | 'PALIFICATION'
  | 'DRILLING_WELLS'
  | 'INSTALLATION_STRUCTURE'
  | 'ANTI_CORROSION_PROTECTION'
  | 'AUTOMATIC_FIRE_EXTINGUISHING_INSTALLATIONS'
  | 'INTERNAL_WATER_SUPPLY_AND_SEWERAGE_NETWORKS'
  | 'INTERNAL_POWER_SUPPLY_NETWORKS'
  | 'INSTALLATION_AND_COMMISSIONING_OF_AUTOMATION_SYSTEMS'
  | 'INSTALLATION_OF_ELEVATORS'
  | 'INSTALLATION_OF_TECHNOLOGICAL_EQUIPMENT_AND_PIPELINES'
  | 'EXTERNAL_WATER_SUPPLY_AND_SEWERAGE_NETWORKS'
  | 'EXTERNAL_POWER_SUPPLY_NETWORKS'
  | 'CIVIL_WORKS'
  | 'FIRE_AND_SECURITY_AND_FIRE_ALARMS'
  | 'HEATING_AND_VENTILATION_NETWORKS'
  | 'HEATING_NETWORK'
  | 'CONSTRUCTION_OF_FOUNDATIONS'
  | 'BEARING_AND_ENCLOSING_STRUCTURES'
  | 'ROOF'
  | 'EXTERNAL_FINISHING_WORKS'
  | 'INTERIOR_FINISHING_WORKS'
  | 'VENTILATION_AND_AIR_CONDITIONING'
  | 'GAS_PIPELINE'
  | 'COMMUNICATION_SIGNALING_AND_AUTOMATION_SYSTEMS'
  | 'IMPROVEMENT_OF_THE_TERRITORY'
  | 'ROAD_CONSTRUCTION'
  | 'TECHNOLOGICAL_SOLUTIONS'
