/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { atom, selector } from 'recoil'

import { User } from '../../models'
import { currentUser } from '../current-user'

import { getUsersFromOrganization } from './organization.service'

export const organizationEmployeesRequestIDState = atom({
  key: 'organizationEmployeesRequestIDState',
  default: 0,
})

export const organizationEmployees = selector<User[]>({
  key: 'organizationEmployees',
  get: async ({ get }) => {
    get(organizationEmployeesRequestIDState)
    get(currentUser)
    return await getUsersFromOrganization()
  },
})
