/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { User } from '../../models'

import {
  organizationEmployees,
  organizationEmployeesRequestIDState,
} from './OrganizationEmployees.container'

interface IOrganizationEmployeesHook {
  isLoading: boolean
  users: User[] | null
  refreshUsers: () => void
  error?: Error
}

export const useOrganizationEmployees = (
  searchValue?,
): IOrganizationEmployeesHook => {
  const usersLogLoadable = useRecoilValueLoadable(organizationEmployees)
  const setRequestID = useSetRecoilState(organizationEmployeesRequestIDState)

  const refreshUsers = () => setRequestID(requestID => requestID + 1)
  const getFilteredUsers = (users: User[], searchValue: string) => {
    const value = searchValue.toLowerCase()
    const isIncludes = (fields: (string | undefined)[], value: string) =>
      fields.some(item => item?.toLowerCase().includes(value))

    return users.filter(user =>
      isIncludes(
        [
          user.firstName,
          user.lastName,
          user?.middleName,
          user.phone,
          user.email,
          user.employment?.position.description,
        ],
        value,
      ),
    )
  }
  const users =
    usersLogLoadable.state === 'hasValue' ? usersLogLoadable.contents : null

  const filteredUsers = users
    ? searchValue
      ? getFilteredUsers(users, searchValue)
      : users
    : null

  const isLoading = usersLogLoadable.state === 'loading'

  const error =
    usersLogLoadable.state === 'hasError'
      ? usersLogLoadable.contents
      : undefined

  return {
    users: filteredUsers,
    isLoading,
    error,
    refreshUsers,
  }
}
