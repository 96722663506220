/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { UUID } from '../../models'
import { RestAPI } from '../RestAPI'

import { InvoicePosition } from './invoice'

import type {
  IncomingInvoiceResponse,
  InvoicePositionResponse,
} from './invoice.response'

// import { InvoicePosition } from '../Types/invoice'
// import {
//   IncomingInvoiceResponse,
//   InvoicePositionResponse,
// } from '../Types/invoice.response'

// import { API } from './API'

type PaginationBase = {
  pageNumber: number
  pageSize: number
}

type GetListParams = {
  contractId: string
  searchValue: string
  sortParams: {
    // [key: string]: string
    fromDate: string | null
    toDate: string | null
  }
} & PaginationBase

const getList = ({
  contractId,
  pageNumber,
  pageSize,
  sortParams,
  searchValue,
}: GetListParams): Promise<{
  pageCount: number
  pageNumber: number
  pageSize: number
  workDocSection: string
  entityList: Array<IncomingInvoiceResponse>
}> => {
  let sort = {}

  Object.keys(sortParams).forEach(
    key =>
      (sort = {
        ...sort,
        [key]: sortParams[key] === '' ? null : sortParams[key],
      }),
  )
  return RestAPI.post(
    `/invoice/list`,
    {
      journalId: contractId,
      searchValue: searchValue || undefined,
      ...sort,
    },
    {
      params: {
        pageNumber,
        pageSize,
      },
    },
  ).then(
    res =>
      res.data ?? {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        entityList: [],
      },
  )
}
const create = (data: any) => RestAPI.post('/invoice', data)
const update = (data: any) => RestAPI.put('/invoice', data)

type DeleteParams = {
  invoiceId: string
}
const deleteInvoice = ({ invoiceId }: DeleteParams) =>
  RestAPI.delete(`/invoice/${invoiceId}`)

/**
 * Метод верифицирует пользователя и выдаёт хэш и подпись к накладной,
 * которую надо подписать.
 * Действует как для прораба, так и для уполномоченного.
 */
type VerifySignerInvoice = {
  imageBase64: string
  keySerialNumber: string
  employmentId: UUID
  journalId: UUID
  invoiceId: UUID
}
const verifySignerInvoice = ({
  imageBase64,
  keySerialNumber,
  employmentId,
  journalId,
  invoiceId,
}: VerifySignerInvoice) => {
  const dataBase64 = imageBase64.replace(/^data:.+;base64,/, '')

  return RestAPI.post(
    `/invoice/verify_signer/${employmentId}/${journalId}/${invoiceId}`,
    {
      faceIdCredentials: {
        faceImage: dataBase64,
        keySerialNumber,
      },
      journalId,
      invoiceId,
    },
  )
}

type MakeSignInvoice = {
  journalId: UUID
  invoiceId: UUID
  employmentId: UUID
  signature: string
}
const makeSignInvoice = ({
  journalId,
  invoiceId,
  employmentId,
  signature,
}: MakeSignInvoice) =>
  RestAPI.post(`/invoice/save_sign/${journalId}/${invoiceId}/${employmentId}`, {
    journalId: journalId,
    invoiceId: invoiceId,
    signature: signature,
  })

const createPosition = (
  data: InvoicePosition,
): Promise<InvoicePositionResponse> =>
  RestAPI.post('/invoice/position', data).then(res => res.data)

const updatePosition = (
  data: InvoicePosition & { positionId: InvoicePositionResponse['positionId'] },
): Promise<InvoicePositionResponse> =>
  RestAPI.put('/invoice/position', data).then(res => res.data)

type DeletePosition = {
  positionId: UUID
  materialId: UUID
}
const deletePosition = ({ positionId, materialId }: DeletePosition) =>
  RestAPI.delete(`/invoice/position/${positionId}`, {
    params: {
      materialId,
    },
  })

export default {
  getList,
  create,
  update,
  delete: deleteInvoice,

  verifySignerInvoice,
  makeSignInvoice,

  createPosition,
  updatePosition,
  deletePosition,
}
