/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useCallback, useEffect, useMemo, useState } from 'react'

import { generateUniqueId } from '../generateUniqueId'

import { Draft } from './draft.types'

const storage = window.localStorage

interface UseDraftStoreParams {
  group: 'generalJournal' | 'invoice'
  contractId: string
  userId: string
  additionalIds?: string[]
}

export interface IDraftStoreHook<T> {
  currentDrafts: Draft<T>[]
  get: () => Draft<T>[]
  getDraftItem: (draftId: string) => Draft<T> | undefined
  refreshCurrent: () => void
  set: (data: Draft<T> | Draft<T>[]) => void
  update: (newData: Draft<T>) => void
  append: (data: T) => void
  remove: (id: string) => void
  clear: () => void
}

export const useDraftStore = <T>({
  group,
  contractId,
  userId,
  additionalIds,
}: UseDraftStoreParams): IDraftStoreHook<T> => {
  const key = useMemo(
    () =>
      `${userId ?? 'global'}_${group}_${contractId}_recordDraft_` +
      additionalIds?.join('_'),
    [additionalIds, contractId, group, userId],
  )

  const get = useCallback(
    (): Array<Draft<T>> => JSON.parse(storage.getItem(key) ?? '[]'),
    [key],
  )

  const [currentDrafts, setCurrentDrafts] = useState<Array<Draft<T>>>(get())

  const refreshCurrent = useCallback(() => {
    setCurrentDrafts(get() ?? [])
  }, [get])

  const getDraftItem = (draftId: string): Draft<T> | undefined =>
    get().find(draft => draft.draftId === draftId)

  const set = (data: Draft<T> | Draft<T>[]): void => {
    storage.setItem(
      key,
      JSON.stringify(
        Array.isArray(data) ? data : [{ ...data, draftId: generateUniqueId() }],
      ),
    )
    refreshCurrent()
  }

  const update = (newData: Draft<T>): void => {
    if (newData.draftId) {
      set(
        get().map(draft =>
          draft.draftId === newData.draftId ? newData : draft,
        ),
      )
    }
  }

  const append = (data: T): void => {
    const stored = get()

    storage.setItem(
      key,
      JSON.stringify([...stored, { ...data, draftId: generateUniqueId() }]),
    )
    refreshCurrent()
  }

  const remove = (id: string): void => {
    const stored = get()

    storage.setItem(
      key,
      JSON.stringify(stored.filter(item => item.draftId !== id)),
    )
    refreshCurrent()
  }

  const clear = (): void => storage.removeItem(key)

  useEffect(() => {
    function checkUsedData(e: StorageEvent) {
      if (e.key === key) {
        console.log('Storage updated')
        refreshCurrent()
      }
    }

    window.addEventListener('storage', checkUsedData)

    return () => {
      window.removeEventListener('storage', checkUsedData)
    }
  }, [refreshCurrent])

  return {
    currentDrafts,
    get,
    getDraftItem,
    refreshCurrent,
    set,
    update,
    append,
    remove,
    clear,
  }
}
