/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { CommentItem } from '../../models'

import {
  commentsLogQuery,
  commentsLogQueryRequestIDState,
} from './CommentsLog.container'

interface CommentsProps {
  contractId: string
  pageNumber: number
  pageSize: number
}

interface ICommentsLogHook {
  isLoading: boolean
  comments: CommentItem[]
  pageNumber: number
  pageSize: number
  pageCount: number
  refreshComments: () => void
  error?: Error
}

export const useCommentsLog = (props: CommentsProps): ICommentsLogHook => {
  const { contractId } = props
  const commentsLogLoadable = useRecoilValueLoadable(commentsLogQuery(props))
  const setRequestID = useSetRecoilState(
    commentsLogQueryRequestIDState(contractId),
  )

  const refreshComments = () => setRequestID(requestID => requestID + 1)

  const data =
    commentsLogLoadable.state === 'hasValue'
      ? commentsLogLoadable.contents
      : {
          pageCount: 0,
          pageNumber: 0,
          pageSize: 0,
          entityList: [],
        }

  const isLoading = commentsLogLoadable.state === 'loading'

  const error =
    commentsLogLoadable.state === 'hasError'
      ? commentsLogLoadable.contents
      : undefined

  return {
    comments: data?.entityList,
    pageNumber: data?.pageNumber,
    pageSize: data?.pageSize,
    pageCount: data?.pageCount,
    isLoading,
    error,
    refreshComments,
  }
}
