import { RestAPI } from '../RestAPI'

interface InventoryRequest {
  contractId: string
  pageNumber?: number
  pageSize?: number
  searchValue?: string
  searchValueDate?: string
}

export function getInventory({
  contractId,
  pageNumber,
  pageSize,
  searchValue,
  searchValueDate,
}: InventoryRequest) {
  return RestAPI.get(`/contracts/inventory/${contractId}`, {
    params: {
      pageNumber,
      pageSize,
      searchValue: searchValue ?? undefined,
      searchValueDate: searchValueDate ?? undefined,
    },
  }).then(
    res =>
      res.data || {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        entityList: [],
      },
  )
}

export const getMaterialActualAmount = (materialId: string) =>
  RestAPI.get(`/material/${materialId}`)
