/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { toAPI } from '../../helpers'
import { RestAPI } from '../RestAPI'

import { Assignee } from './journal'

import type { UUID } from '../../models'

/**
 * Создание договора
 * @param data
 * @param projectId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const createContract = (data: any, projectId: string) => {
  return RestAPI.post('/contracts', toAPI(data, projectId))
}

export const updateContract = (data: any, projectId: string) => {
  return RestAPI.put('/contracts', toAPI(data, projectId)).then(
    res => res.data || {},
  )
}

interface GetContractsByPojectId {
  projectId: UUID
  pageNumber: number
  pageSize: number
}

/**
 * Получение списка договоров в проекте
 * @returns {Promise<Journal[]>}
 */
export const getContractsByProjectId = ({
  projectId,
  pageNumber,
  pageSize,
}: GetContractsByPojectId) =>
  RestAPI.get(`/contracts/byprojectid/${projectId}`, {
    params: {
      pageNumber,
      pageSize,
    },
  }).then(
    res =>
      res.data || {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        entityList: [],
      },
  )

/**
 * Получение списка договоров сотрудником
 * @returns {Promise<Journal[]>}
 */
export const getContracts = () =>
  RestAPI.get('/contracts/workerjournals').then(res => res.data || [])

/**
 * Получение списка договоров админом
 * @returns {Promise<Journal[]>}
 */
export const getContractsAdmin = () =>
  RestAPI.get('/contracts/adminjournals').then(res => res.data || [])

/**
 * Получение одного договора
 * @returns {Promise<Journal | {}>}
 */
export const getContract = (contractId: string | undefined | null) =>
  RestAPI.get(`/contracts/byjournalid/${contractId}`).then(
    res => res.data || {},
  )

export const getContractHierarchy = (contractId: string) =>
  RestAPI.get(`/contracts/simple-hierarchy/byjournalid/${contractId}`).then(
    res => res.data || {},
  )

export const getProjectHierarchy = (projectId: string) =>
  RestAPI.get(`/contracts/simple-hierarchy/byprojectid/${projectId}`).then(
    res => res.data || {},
  )

export const getProjectTree = (projectId: string) =>
  RestAPI.get(`/contracts/hierarchy/byprojectid/${projectId}`).then(
    res => res.data || {},
  )

/**
 * Метод верифицирует пользователя и выдаёт хэш и подпись к договору,
 * который надо подписать.
 * Действует как для прораба, так и для уполномоченного.
 * @param imageBase64 {string}
 * @param keySerialNumber {string}
 * @param {string} journalId
 * @return {Promise<any>}
 */
export const verifySigner = ({
  imageBase64,
  keySerialNumber = '',
  journalId,
}: any): Promise<any> => {
  const dataBase64 = imageBase64.replace(/^data:.+;base64,/, '')

  return RestAPI.post(
    `/contracts/verify_signer/${journalId}`,
    {
      faceIdCredentials: {
        faceImage: dataBase64,
        keySerialNumber,
      },
      journalId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const makeSign = (data: {
  journalId: UUID
  employmentId: UUID
  signature: string
}) =>
  RestAPI.post(`/contracts/sign/${data.journalId}/${data.employmentId}`, {
    journalId: data.journalId,
    signature: data.signature,
  })

export type ContractByEmploymentId = {
  assignee: Assignee
  creatorEmploymentId: UUID
  journalId: UUID
  projectId: UUID
  title: string
}

/**
 * Получение договоров с участием сотрудника
 * @returns ContractByEmploymentId[]
 */
export const getContractsByEmploymentId = (
  employmentId: UUID,
): Promise<ContractByEmploymentId[]> =>
  RestAPI.get(`/contracts/admin-user-journals/${employmentId}`).then(
    res => res.data || [],
  )

/**
 * Обновление полномочий сотрудника в договоре
 * @returns Promise<any>
 */
export const updateUserInContract = (data: ContractByEmploymentId) =>
  RestAPI.put(`/contractassignees`, data).then(res => res.data || [])
