/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

// Журнал замечаний

import { UUID } from '../../models'
import { CommentItem } from '../../models'
import { RestAPI } from '../RestAPI'

interface GetComments {
  contractId: UUID
  pageNumber: number
  pageSize: number
}

const getList = ({
  contractId,
  pageNumber,
  pageSize,
}: GetComments): Promise<{
  pageCount: number
  pageNumber: number
  pageSize: number
  entityList: Array<CommentItem>
}> =>
  RestAPI.get(`/section_four/${contractId}`, {
    params: {
      pageNumber,
      pageSize,
    },
  }).then(res => res.data || [])

const getListByEntityId = (entityId: string): Promise<Array<any>> =>
  RestAPI.get(`/section_four/entity_id/${entityId}`).then(res => res.data || [])

const getListByExternalEntityId = (
  externalEntityId: string,
): Promise<Array<any>> =>
  RestAPI.get(`/section_four/external_entity_id/${externalEntityId}`).then(
    res => res.data || [],
  )

const create = (journalID: UUID, data: any) =>
  RestAPI.post('/section_four', {
    journalId: journalID,
    ...data,
  })

const update = (journalID: UUID, data: any) =>
  RestAPI.put('/section_four', {
    journalId: journalID,
    ...data,
  })

type DeleteItem = {
  journalId: UUID
  commentId: UUID
}
const deleteItem = ({ journalId, commentId }: DeleteItem): Promise<unknown> =>
  RestAPI.delete(`/section_four/${journalId}/${commentId}`)

export class commentsLogService {
  static getList = getList
  static getListByEntityId = getListByEntityId
  static getListByExternalEntityId = getListByExternalEntityId
  static create = create
  static update = update
  static deleteComment = deleteItem
}

export default commentsLogService
