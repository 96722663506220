/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useState } from 'react'

type DisclosureProps = {
  isOpen?: boolean
}

interface Disclosure {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onToggle: () => void
}

export function useDisclosure(props?: DisclosureProps): Disclosure {
  const { isOpen: initialValue = false } = props ?? {}
  const [isOpen, setIsOpen] = useState(initialValue)

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  const handleToggle = () => setIsOpen(v => !v)

  return {
    isOpen,
    onOpen: handleOpen,
    onClose: handleClose,
    onToggle: handleToggle,
  }
}
