import { ReactNode } from 'react'
import { Button } from '../../atoms'
import { ButtonProps, SxProps } from '@mui/material'

export const ButtonTabsItem = ({children, value, sx, ...rest}:{children:ReactNode, sx?:SxProps, value:string} & ButtonProps) => {
  return (
    <Button value={value} sx={{borderRadius:'4px', ...sx}} {...rest}>
      {children}
    </Button>
  )
}
