import { useCallback, useState } from 'react'

import {
  AOSRType,
  getAOSRById,
  getSecondaryActById,
  SecondaryActType,
} from '@builddocs/filestore-api'
import { useSetRecoilState } from 'recoil'

import { actExternal } from './external.container'
import { ExternalSigningAct } from './ExternalSigning.type'

type UseActExternal = {
  loadAct: (externalSigningValue: ExternalSigningAct) => void
  isActLoading: boolean
  isActFail: boolean
}

export const useActExternal = (): UseActExternal => {
  const [isActLoading, setIsActLoading] = useState(false)
  const [isActFail, setIsActFail] = useState(false)
  const setActExternal = useSetRecoilState(actExternal)

  const loadAct = useCallback(
    async (externalSigningValue: ExternalSigningAct) => {
      let actloaded: AOSRType | SecondaryActType | undefined

      setIsActLoading(true)

      try {
        externalSigningValue.actType === 'AOSR'
          ? (actloaded = await getAOSRById(externalSigningValue.actId))
          : (actloaded = await getSecondaryActById(externalSigningValue.actId))
      } catch {
        setIsActLoading(false)
        setIsActFail(true)
      }

      setIsActLoading(false)

      actloaded && setActExternal(actloaded)
    },
    [],
  )

  return {
    loadAct,
    isActLoading,
    isActFail,
  }
}
