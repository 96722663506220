/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useCallback } from 'react'

import { UUID } from '@builddocs/data-management'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { actQuery, actQueryRequestIDState } from './Act.container'
import { AOSRType, SecondaryActType } from './acts.interfaces'

export type HookActs = {
  act: AOSRType | SecondaryActType | undefined
  refreshAct: (pageNumber?: number, pageSize?: number) => void
  isLoading: boolean
}

export const useAct = (props: { actId: UUID; actType: string }): HookActs => {
  const { actId, actType } = props

  const actLoadable = useRecoilValueLoadable(
    actQuery({
      actId,
      actType,
    }),
  )

  const setRequestID = useSetRecoilState(
    actQueryRequestIDState({
      actId,
      actType,
    }),
  )
  const act =
    actLoadable.state === 'hasValue'
      ? (actLoadable.contents as AOSRType | SecondaryActType)
      : undefined

  const fetchIsLoading = actLoadable.state === `loading`

  const refreshAct = useCallback(() => {
    setRequestID(requestID => requestID + 1)
  }, [setRequestID])

  return {
    act,
    refreshAct,
    isLoading: fetchIsLoading,
  }
}
