import { useEffect, useState } from 'react'

import {
  email,
  getFullNameReversed,
  RestAPI,
  useCurrentUser,
  User,
  useStickyState,
} from '@builddocs/data-management'
import { DocumentSign, extSignSend } from '@builddocs/filestore-api'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { SignTypes } from './InvitationExternalSigning'

const StyledTableRow = styled(TableRow)(() => ({
  '& td, & th': {
    border: 0,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  ':hover': {
    cursor: 'pointer',
  },
}))

const TableCellStyled = styled(TableCell)(() => ({
  paddingLeft: 24,
  paddingRight: 24,
}))

interface IFormFields {
  email: Record<string, string>
}

type OwnProps = {
  isOpen: boolean
  onClose: () => void
  assignee: DocumentSign
  signOpts: SignTypes['EXECUTIVE_SCHEME']
}

export const InvitationDialogApplication = ({
  isOpen,
  onClose,
  assignee,
  signOpts,
}: OwnProps) => {
  const { user } = useCurrentUser()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { control, errors, clearErrors } = useForm<IFormFields>()
  const [recipient, setRecipient] = useStickyState<User | undefined>(
    undefined,
    'session',
  )

  useEffect(() => {
    const source = axios.CancelToken.source()

    setIsLoading(true)
    RestAPI.get(`/users/byemploymentid/${assignee.employmentId}`, {
      cancelToken: source.token,
    })
      .then(r => {
        setRecipient(r.data)
        setIsLoading(false)
      })
      .catch(e => {
        setRecipient(undefined)
        setIsLoading(false)
        if (axios.isCancel(e)) {
          console.log(`request cancelled:${e.message}`)
        } else {
          console.log('another error happened:' + e.message)
        }
      })

    return function () {
      source.cancel('Cancelling in cleanup')
    }
  }, [])

  const onSendButtonClick = () => {
    if (recipient) {
      extSignSend({
        signType: signOpts.signType,
        documentId: signOpts.documentId,
        taskId: signOpts.taskId,
        senderUserId: user.userId,
        externalUserId: recipient.userId,
        externalUserEmail: recipient.email,
      })
        .then(() => toast.success(`Успешно отправлено ${recipient.email}`))
        .catch(() => toast.error(`Не удалось отправить`))
      handleClose()
    }
  }

  const handleClose = () => {
    clearErrors()
    onClose()
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
      <DialogTitle>Отправить на проверку по электронной почте</DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellStyled>Ф.И.О</TableCellStyled>
              <TableCellStyled>ЭЛЕКТРОННАЯ ПОЧТА</TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              {isLoading ? (
                <>
                  <TableCellStyled>
                    <Skeleton animation="wave" />
                  </TableCellStyled>
                  <TableCellStyled>
                    <Skeleton animation="wave" />
                  </TableCellStyled>
                </>
              ) : (
                recipient && (
                  <>
                    <TableCellStyled>
                      {getFullNameReversed(recipient)}
                    </TableCellStyled>
                    <TableCellStyled>
                      <Controller
                        control={control}
                        defaultValue={recipient.email}
                        name={`email.${recipient.userId}`}
                        render={({ onChange, ...rest }) => (
                          <TextField
                            fullWidth
                            error={
                              !!(
                                errors?.email &&
                                errors?.email[recipient.userId]?.message &&
                                recipient?.userId === recipient.userId
                              )
                            }
                            helperText={
                              errors?.email &&
                              errors?.email[recipient.userId]?.message
                            }
                            label="Электронная почта"
                            size="small"
                            onChange={event => {
                              onChange(event.target.value)
                              setRecipient(
                                prev =>
                                  prev && {
                                    ...prev,
                                    email: event.target.value,
                                  },
                              )
                            }}
                            onClick={e => {
                              e.stopPropagation()
                            }}
                            {...rest}
                          />
                        )}
                        rules={{
                          validate: email,
                        }}
                      />
                    </TableCellStyled>
                  </>
                )
              )}
            </StyledTableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography color="text.secondary" variant="caption">
            {recipient?.email}
          </Typography>
          <Button
            disabled={!recipient || !!errors.email}
            variant="contained"
            onClick={onSendButtonClick}
          >
            Отправить
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
