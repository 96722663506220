/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import {
  UUID,
  InspectionState,
  SignatureState,
  ApprovalState,
} from '@builddocs/data-management'

import { Application } from '../documentaryType'

import { IntegrationKeys, IntegrationType } from './IntegrationTypes'

export type DocumentType =
  | 'PERMIT'
  | 'WORKING_DRAFT'
  | 'EXECUTIVE_SCHEME'
  | 'EXECUTIVE_DRAWING'
  | 'CERTIFICATE'
  | 'LABORATORY'
  | 'ADDITIONAL'
  | 'NORMATIVE'
  | 'NORMATIVE_ADDITIONAL'
  | 'SUPPORTING'

export const documentTypeToEndpoint: Record<DocumentType, string> = {
  PERMIT: '/permits',
  WORKING_DRAFT: '/workingdraft',
  EXECUTIVE_SCHEME: '/executivescheme',
  EXECUTIVE_DRAWING: '/executivedrawing',
  CERTIFICATE: '/certificate',
  LABORATORY: '/laboratorydata',
  NORMATIVE: '/normative',
  ADDITIONAL: '/additional',
  SUPPORTING: '/supporting',
  NORMATIVE_ADDITIONAL: '/normativeadditional',
}

export enum CertificateType {
  DOCUMENT_ON_THE_QUALITY_OF_CONCRETE_MIX = 'DOCUMENT_ON_THE_QUALITY_OF_CONCRETE_MIX',
  QUALITY_CERTIFICATE = 'QUALITY_CERTIFICATE',
  QUALITY_PASSPORT = 'QUALITY_PASSPORT',
  PASSPORT = 'PASSPORT',
  DECLARATION_OF_CONFORMITY = 'DECLARATION_OF_CONFORMITY',
  CONFORMANCE_CERTIFICATE = 'CONFORMANCE_CERTIFICATE',
  INFORMATION_LETTER = 'INFORMATION_LETTER',
  SANITARY_AND_EPIDEMIOLOGICAL_CONCLUSION = 'SANITARY_AND_EPIDEMIOLOGICAL_CONCLUSION',
  CERTIFICATE_OF_STATE_REGISTRATION = 'CERTIFICATE_OF_STATE_REGISTRATION',
  FIRE_SAFETY_CERTIFICATE = 'FIRE_SAFETY_CERTIFICATE',
}

export interface BaseDocument {
  id: string
  projectId: UUID
  /** Наименование документа */
  name: string
  /** Дата создания */
  creationDate: string
  /** Дата выдачи */
  documentUrl: string
  /** Имя файла */
  fileName: string
  /** Дата выдачи */
  issueDate: string
  /** Организация, выдавшая документ */
  organization: string
  documentId: UUID
  /** Тип работ */
  workType?: string
  /** Тип работ */
  integrations?: Record<IntegrationKeys, IntegrationType>
  creatorId: UUID
}

/**
 * Разрешительный документ
 */
export interface Permits extends BaseDocument {
  documentType: Application.PERMIT
  assignees: DocumentSign[]
  base64signature: string
  deleted: boolean
  document: string
  documentStamp: DocumentStamp
  signatureEmbedded: boolean
  signatureState: SignatureState
  stampId: UUID
  number: string
  description: string // Описание
}

export type DocumentInspector = {
  employmentId: UUID
  inspectionState: InspectionState
}

export type StampSign = {
  comment: string
  employmentId: string
  imprint: string
  inn: string
  keyIssuer: string
  position: string
  signatureState: SignatureState
  signingDateTime: string
  userFullName: string
  userId: UUID
}

export type DocumentSign = {
  comment: string
  employmentId: UUID
  imprint: string
  keyIssuer: string
  signatureState: SignatureState
  signingDateTime: string
}

export type DocumentStamp = {
  documentId: UUID
  id: UUID
  stampSigns: StampSign[]
  stampTemplateId: UUID
}

/**
 * Один лист в проекте
 */
export type WorkingDraftPage = Omit<WorkingDraft, 'pagesObjects'>

/**
 * Проект
 */
export interface WorkingDraft extends BaseDocument {
  documentType: Application.WORKING_DRAFT
  number: string
  assignees: DocumentSign[]
  base64signature: string
  cipher: string
  deleted: boolean
  document: string
  documentStamp: DocumentStamp
  sheet: string
  signatureEmbedded: boolean
  signatureState: SignatureState
  stampId: UUID
  pageCorrespondence: Record<number, string>
  pagesObjects: WorkingDraftPage[]
  externalId: UUID
  parentId: UUID
  pages: UUID[]
  pageNumber: number
}

/**
 * Исполнительная схема
 */
export interface ExecutiveScheme extends BaseDocument {
  documentType: Application.EXECUTIVE_SCHEME
  cipher: string
  sheet: string
  assignees: DocumentSign[]
  base64signature: string
  deleted: boolean
  document: string
  documentStamp: DocumentStamp
  externalId: UUID
  signatureEmbedded: boolean
  signatureState: SignatureState
  stampId: UUID
  workingDrawing: string[]
  workingDrawingObjList: WorkingDraft[]
  number: string
  workingDraftsObjList: WorkingDraft[]
  workingDraftsIds: string[]
}

/**
 * Исполнительный чертёж
 */
export interface ExecutiveDrawing extends BaseDocument {
  documentType: Application.EXECUTIVE_DRAWING
  cipher: string
  sheet: string
  assignees: DocumentSign[]
  base64signature: string
  deleted: boolean
  document: string
  documentStamp: DocumentStamp
  externalId: UUID
  signatureEmbedded: boolean
  signatureState: SignatureState
  stampId: UUID
  sourceId: string
}

/**
 * Сертификат
 */
export interface Certificate extends BaseDocument {
  documentType: Application.CERTIFICATE
  kind: 'Certificate' | 'Declaration' | 'RejectionLetter'
  certificateType: CertificateType
  /** Дата окончания */
  expirationDate: string
  fileMistake: string
  manufacturer: string
  additionalDocuments: string[]
  additionalDocumentsObjList: Additional[]
  assignees: DocumentSign[]
  base64signature: string
  deleted: boolean
  document: string
  documentStamp: DocumentStamp
  externalId: string
  number: string
  signatureEmbedded: boolean
  signatureState: string
  stampId: string
  /** Описание */
  description: string
  approvalStatus: ApprovalState
  initialDocument: string
  inspectors: DocumentInspector[]
  integrations: Record<string, any>
  konturFileId: string
  operationId: string
  pdfPagesCount: number
  version: number
}

/**
 * Лаборатория
 */
export interface Laboratory extends BaseDocument {
  documentType: Application.LABORATORY
  description: string // Описание
  assignees: DocumentSign[]
  base64signature: string
  deleted: boolean
  document: string
  documentStamp: DocumentStamp
  externalId: UUID
  number: string
  signatureEmbedded: boolean
  signatureState: string
  stampId: UUID
  approvalStatus: ApprovalState
  conformed: string
  initialDocument: string
  inspectors: DocumentInspector[]
  integrations: Record<string, any>
  konturFileId: string
  operationId: string
  pdfPagesCount: number
  version: number
}

/** Дополнительный ДПК */
export interface Additional extends BaseDocument {
  certificateType: CertificateType
  documentType: Application.ADDITIONAL
  document: FileList
  startDate: string
  endDate: string
  kind: 'Supervision' | 'FireSafety' | 'Passport' | 'LaboratoryWorks'
  number: string

  assignees: DocumentSign[]
  /** Подпись */
  base64signature: string
  deleted: boolean

  parentId: UUID
  signatureEmbedded: boolean
  signatureState: SignatureState
  /** Описание */
  description: string

  manufacturer?: string
}

/** Сопроводительный документ */
export interface Supporting extends BaseDocument {
  documentType: Application.SUPPORTING
  assignees: DocumentSign[]
  base64signature: string
  deleted: boolean
  document: string
  documentStamp: DocumentStamp
  signatureEmbedded: boolean
  signatureState: SignatureState
  stampId: UUID
  number: string
}

export interface NormativeAdditionalDocument extends BaseDocument {
  documentType: Application.NORMATIVE_ADDITIONAL
  assignees: DocumentSign[]
  base64signature: string
  deleted: boolean
  document: string
  number: string
  parentId: UUID
  signatureEmbedded: boolean
  signatureState: SignatureState
}

export interface Normative extends BaseDocument {
  documentType: Application.NORMATIVE
  assignees: DocumentSign[]
  base64signature: string
  deleted: boolean
  document: string
  externalId: UUID
  normativeAdditionalDocuments: UUID[]
  normativeAdditionalDocumentsObjList: NormativeAdditionalDocument[]
  number: string
  signatureEmbedded: boolean
  signatureState: SignatureState
}

export function isWorkingDraft(
  doc: WorkingDraft | (BaseDocument & { documentType: Application }),
): doc is WorkingDraft {
  return (
    (doc as WorkingDraft).cipher !== undefined &&
    doc.documentType === Application.WORKING_DRAFT
  )
}

export function isCertificate(
  doc: Certificate | BaseDocument,
): doc is Certificate {
  return (
    (doc as Certificate).kind === 'Certificate' ||
    (doc as Certificate).kind === 'Declaration' ||
    (doc as Certificate).kind === 'RejectionLetter' ||
    (doc as Certificate).certificateType ===
      'DOCUMENT_ON_THE_QUALITY_OF_CONCRETE_MIX' ||
    (doc as Certificate).certificateType === 'QUALITY_CERTIFICATE' ||
    (doc as Certificate).certificateType === 'QUALITY_PASSPORT' ||
    (doc as Certificate).certificateType === 'PASSPORT' ||
    (doc as Certificate).certificateType === 'DECLARATION_OF_CONFORMITY' ||
    (doc as Certificate).certificateType === 'CONFORMANCE_CERTIFICATE' ||
    (doc as Certificate).certificateType === 'INFORMATION_LETTER' ||
    (doc as Certificate).certificateType ===
      'SANITARY_AND_EPIDEMIOLOGICAL_CONCLUSION' ||
    (doc as Certificate).certificateType ===
      'CERTIFICATE_OF_STATE_REGISTRATION' ||
    (doc as Certificate).certificateType === 'FIRE_SAFETY_CERTIFICATE'
  )
}
