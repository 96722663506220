/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useMemo } from 'react'

import { useProject } from '../projects'

import { ExternalAppsNames } from './config'

export const useIntegrationState = (projectId: string) => {
  const { project } = useProject(projectId)

  const { isExonConnected, isCyntekaConnected, isUlabConnected } = useMemo(
    () => ({
      isExonConnected: project?.integrations.includes(ExternalAppsNames.EXON),
      isCyntekaConnected: project?.integrations.includes(
        ExternalAppsNames.CYNTEKA,
      ),
      isUlabConnected: project?.integrations.includes(ExternalAppsNames.ULAB),
    }),
    [project?.integrations],
  )

  return {
    isExonConnected,
    isCyntekaConnected,
    isUlabConnected,
  }
}
