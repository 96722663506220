import { Stack, Typography } from '@mui/material'

type Props = {
  massage: string
}
export const ErrorContainer = ({ massage }: Props) => {
  return (
    <Stack alignItems="center" width="100%">
      <Typography color="error.main" variant="h5">
        {massage}
      </Typography>
    </Stack>
  )
}
