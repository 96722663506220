/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React from 'react'

import { routes } from '@builddocs/data-management'
import {
  Link as MuiLink,
  LinkProps,
  Breadcrumbs,
  Typography,
} from '@mui/material'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

const Link = React.forwardRef<any, RouterLinkProps & LinkProps>(
  (props, ref) => (
    <MuiLink
      ref={ref}
      color="inherit"
      component={RouterLink}
      underline="hover"
      {...props}
    />
  ),
)

Link.displayName = 'Link'

interface ProjectsBreadcrumbsProps {
  parentProject?: {
    projectId: string
    description: string
    constructionAddress: string
  }
  path?: { title: string; contractId: string }[]
}

/**
 * Хлебные крошки для проектов и договоров
 *
 * @param {{projectId: string, description: string, constructionAddress: string} | undefined} parentProject
 * @param {{title: string, contractId: string, constructionAddress: string}[] | undefined} path
 * @returns {JSX.Element}
 * @constructor
 */
export const ProjectsBreadcrumbs = ({
  parentProject,
  path,
}: ProjectsBreadcrumbsProps) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" maxItems={3}>
      {parentProject ? (
        <Link to={routes.navigator.base()}>Проекты</Link>
      ) : (
        <Typography color="text.primary">Проекты</Typography>
      )}
      {parentProject && (
        <Link
          style={{ whiteSpace: 'normal' }}
          title={`${parentProject.description} (${parentProject.constructionAddress})`}
          to={routes.projects.project(parentProject.projectId)}
        >
          {`${parentProject.description} (${parentProject.constructionAddress})`}
        </Link>
      )}
      {path?.map((part, index) =>
        index !== path.length - 1 ? (
          <Link
            to={routes.contract.base({
              projectId: parentProject?.projectId,
              contractId: path[index].contractId,
            })}
          >
            {part.title}
          </Link>
        ) : null,
      )}
    </Breadcrumbs>
  )
}
