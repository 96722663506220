/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { User } from '../../models'

type LetterOrderType = 'f' | 'l' | 'fl' | 'fm' | 'lf' | 'fml' | 'lfm'

interface NameBase {
  firstName: User['firstName']
  middleName?: User['middleName']
  lastName: User['lastName']
}

export function getFullName<T extends NameBase>(user?: T) {
  if (!user) return ''
  // if(!user.firstName)

  return user.middleName
    ? `${user.firstName ?? ''} ${user.middleName ?? ''} ${user.lastName ?? ''}`
    : `${user.firstName ?? ''} ${user.lastName ?? ''}`
}

export function getFullNameReversed<T extends NameBase>(user?: T) {
  if (!user) return ''

  return user.middleName
    ? `${user.lastName ?? ''} ${user.firstName ?? ''} ${user.middleName ?? ''}`
    : `${user.lastName ?? ''} ${user.firstName ?? ''}`
}

export function getShortName<T extends NameBase>(user?: T) {
  if (!user) return ''

  return `${user.firstName ?? ''} ${user.lastName ?? ''}`
}

export function getShortNameReversed<T extends NameBase>(user?: T) {
  if (!user) return ''

  return `${user.lastName ?? ''} ${user.firstName ?? ''}`
}

export function getNameWithInitialsReversed<T extends NameBase>(user?: T) {
  if (!user) return ''

  return user.middleName
    ? `${user.lastName ?? ''} ${user.firstName?.[0] ?? ''}. ${
        user.middleName[0] ?? ''
      }.`
    : `${user.lastName ?? ''} ${user?.firstName[0] ?? ''}.`
}

export function getInitials<T extends NameBase>(
  user: T | undefined,
  initials: LetterOrderType,
  separator = '',
) {
  if (!user) return ''

  return (
    initials
      .split('')
      .map(item =>
        item === 'f'
          ? user.firstName?.[0] ?? ''
          : item === 'l'
          ? user.lastName?.[0] ?? ''
          : item === 'm'
          ? user.middleName?.[0] ?? ''
          : '',
      )
      .join(separator) + separator
  )
}

export function stringToColor(string) {
  let i
  let hash = 0

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff

    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}
