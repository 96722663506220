import { RestAPI, WorkType, UUID } from '@builddocs/data-management'

import {
  Certificate,
  ExecutiveDrawing,
  ExecutiveScheme,
  Laboratory,
  Normative,
  WorkingDraft,
} from '../applications'
import { Application } from '../documentaryType'
import { FilestoreAPI } from '../FilestoreAPI'

export type CompletedWorks = {
  works: {
    stageID: number
    materialIds: UUID[]
    projectDocIds: UUID[]
    normativeDocIds: UUID[]
    place: string
    sectionId: UUID
    title: string
    workDate: string
    workType: WorkType
    description: string
    journalId: UUID
  }[]
  materials: Record<
    UUID,
    {
      certificateIds: UUID[]
      laboratoryIds: UUID[]
      manufacturer: string
      materialName: string
      sectionId: UUID
      materialId: UUID
    }
  >
  laboratories: Record<UUID, Laboratory>
  certificates: Record<UUID, Certificate>
  projectDocs: Record<UUID, WorkingDraft>
  normativeDocs: Record<UUID, Normative>
  executiveSchemes: Record<UUID, ExecutiveScheme>
  executiveDrawings: Record<UUID, ExecutiveDrawing>
  aosrDocs: { laboratoryIds: UUID[]; executiveSchemeIds: UUID[] }
}

/** Получение JSON АОСР по actId*/
export const getJSONAOSRById = (actId: UUID) =>
  FilestoreAPI.get(`/act-aosr/${actId}/json`).then(res => res.data)

/** Обновление JSON АОСР по actId*/
export const updateJSONAOSR = (actId: UUID, json: Record<string, any>) =>
  FilestoreAPI.put(`/act-aosr/${actId}/json`, {}, { params: { json: json } })

/** Получение АОСР по actId*/
export const getAOSRById = (actId: UUID) =>
  FilestoreAPI.get(`/act-aosr/${actId}`).then(res => res.data)

/** Обновление АОСР (все поля)*/
export const updateAOSR = (data: CompletedWorks) => {
  const formatedData = {
    material: Object.values(data.materials).map(material => ({
      materialId: material.materialId,
      documents: [
        ...material.certificateIds.map(id => ({
          documentId: data.certificates[id].id,
          documentName: data.certificates[id].name,
          documentNumber: data.certificates[id].number,
          documentType: Application.CERTIFICATE,
          documentUrl: data.certificates[id].documentUrl,
          expirationDate: data.certificates[id].expirationDate,
          issueDate: data.certificates[id].issueDate,
          manufacturer: data.certificates[id].manufacturer,
        })),
        ...material.laboratoryIds.map(id => ({
          documentId: data.laboratories[id].id,
          documentName: data.laboratories[id].name,
          documentNumber: data.laboratories[id].number,
          documentType: Application.LAB,
          documentUrl: data.laboratories[id].documentUrl,
          issueDate: data.laboratories[id].issueDate,
        })),
      ],
    })),
    certificatePart: Object.values(data.certificates).map(doc => ({
      id: doc.id,
      issueDate: doc.issueDate,
      expirationDate: doc.expirationDate,
      name: doc.name,
      number: doc.number,
    })),
    executiveSchemePart: Object.values(data.executiveSchemes).map(doc => ({
      cipher: doc.cipher,
      id: doc.id,
      name: doc.name,
      sheet: doc.sheet,
      issueDate: doc.issueDate,
    })),
    laboratoryPart: Object.values(data.laboratories).map(doc => ({
      id: doc.id,
      issueDate: doc.issueDate,
      name: doc.name,
      number: doc.number,
    })),
    normativeDoc: data.works.map(work => ({
      stageId: work.stageID,
      normativeDocs: work.normativeDocIds.map(id => ({
        documentId: data.normativeDocs[id].id,
        documentName: data.normativeDocs[id].name,
        documentNumber: data.normativeDocs[id].number,
        documentType: Application.NORMATIVE_DOC,
        documentUrl: data.normativeDocs[id].documentUrl,
      })),
    })),
    workPart: data.works.map(work => ({
      journalId: work.journalId,
      place: work.place,
      sectionId: work.sectionId,
      workDate: work.workDate,
    })),
    workingDraftPart: Object.values(data.projectDocs).map(doc => ({
      cipher: doc.cipher,
      id: doc.id,
      name: doc.name,
      sheet: doc.sheet,
      organization: doc.organization,
    })),
    executiveDrawingPart: Object.values(data.executiveDrawings).map(doc => ({
      id: doc.id,
      cipher: doc.cipher,
      sheet: doc.sheet,
      name: doc.name,
      issueDate: doc.issueDate,
    })),
    projectDocuments: data.works.map(work => ({
      sectionId: work.sectionId,
      projectDocs: work.projectDocIds.map(id => ({
        documentId: data.projectDocs[id].id,
        documentName: data.projectDocs[id].name,
        documentNumber: data.projectDocs[id].number,
        documentType: Application.WORKING_DRAFT,
        documentUrl: data.projectDocs[id].documentUrl,
        issueDate: data.projectDocs[id].issueDate,
      })),
    })),
  }

  return RestAPI.put(`/interactive-act`, formatedData)
}
