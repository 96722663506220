/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 *  Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 *  You may use, distribute and modify this code under the
 *  terms of the ООО АДАПТ license
 */

import { useResetCurrentUser } from './rest/current-user'

const useResetAll = () => {
  const resetCurrentUser = useResetCurrentUser()

  return () => {
    console.log('reset all')
    resetCurrentUser()
  }
}

export default useResetAll
