import { useContext, useMemo } from 'react'

import { SignatureState } from '@builddocs/data-management'
import { ExternalSigningApplication } from '@builddocs/ext-sign-data-access'
import {
  FaceControlContext,
  SignHandlerParams,
} from '@builddocs/feature-faceid'
import {
  DocumentSigningTask,
  // logToSectionFourForStamps,
  prepareStamp,
  signStamp,
  useStamps,
} from '@builddocs/filestore-api'
import { DeviationDialog, Stamp, StatusChip } from '@builddocs/mui'
import { Button, Stack, Skeleton } from '@mui/material'
import { toast } from 'react-toastify'

type OwnProps = {
  task: DocumentSigningTask
  externalSigningValue: ExternalSigningApplication
  refreshTasks: () => void
}

export const TaskForSigning = ({
  task,
  externalSigningValue,
  refreshTasks,
}: OwnProps) => {
  const { stampById, isLoading } = useStamps(
    undefined,
    undefined,
    task.stampTemplateId,
  )
  const faceControl = useContext(FaceControlContext)
  const statusTask = useMemo(
    () =>
      task.stampSigns.find(
        user =>
          user.employmentId ===
          externalSigningValue.externalUser.employment.employmentId,
      )?.signatureState ?? SignatureState.UNDEFINED,
    [task],
  )

  const handleSign = () => {
    // Открываем диалог верификации и передаём коллбэк для подписи
    faceControl.openFaceControl({
      isEmbedded: true,
      getDigestHandler: async ({
        signedHash,
        keyIssuer,
        imprint,
        facePhoto,
        keySerialNumber,
        certificateDateStart,
        certificateDateEnd,
        signerName,
      }) => ({
        hash: await prepareStamp({
          base64signature: signedHash,
          documentId: externalSigningValue.documentId,
          employmentId:
            externalSigningValue.externalUser.employment.employmentId,
          imprint: imprint,
          keyIssuer: keyIssuer,
          signatureState: SignatureState.SIGNED,
          name: signerName,
          stampTemplateId: task.stampTemplateId,
          certificateDateStart,
          certificateDateEnd,
          faceId: {
            faceImage: facePhoto,
            keySerialNumber,
          },
        }),
      }),
      signHandler: async ({
        signedHash,
        keyIssuer,
        imprint,
        facePhoto,
        keySerialNumber,
        certificateDateStart,
        certificateDateEnd,
      }: SignHandlerParams) =>
        await signStamp({
          base64signature: signedHash,
          documentId: externalSigningValue.documentId,
          employmentId:
            externalSigningValue.externalUser.employment.employmentId,
          imprint: imprint,
          keyIssuer: keyIssuer,
          signatureState: SignatureState.SIGNED,
          stampTemplateId: task.stampTemplateId,
          certificateDateStart,
          certificateDateEnd,
          faceId: {
            faceImage: facePhoto,
            keySerialNumber,
          },
        }),
      onSuccess: res => {
        if (res.status === 200) {
          refreshTasks()
          toast.success('Документ подписан')
        }
        console.debug('Получен дайджест документа')
      },
      onFailure: error => {
        if (error.response && error.response.status === 401) {
          toast.error(`Не удалось подтвердить вашу личность!`)
        } else {
          toast.error(`Не удалось подписать! Ошибка ${error.response?.status}`)
        }
      },
    })
  }

  const onRejectStamp = async args => {
    try {
      await signStamp({
        documentId: externalSigningValue.documentId,
        employmentId: externalSigningValue.externalUser.employment.employmentId,
        signatureState: SignatureState.REJECTED,
        stampTemplateId: task.stampTemplateId,
        comment: args[0].problemDescription,
      })
      toast.success('Штамп отклонён')
      // TODO бекенду необходимо убрать contractId из запроса, на данном уровне contractId неизвестен
      // await logToSectionFourForStamps({
      //   statusStamp: task,
      //   contractId: '',
      //   body: args[0],
      //   additionalInfo: args[1],
      //   onSuccess: args[2],
      //   refresh: () => refreshTasks(),
      // })
      refreshTasks()
    } catch {
      toast.error('Не удалось отклонить штамп!')
    }
  }

  if (isLoading) {
    return <Skeleton height="40px" width="240px" />
  }
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Stack width="40%">
        <Stamp picture={`${stampById?.pngFile}`} />
      </Stack>
      {statusTask === SignatureState.PENDING ? (
        <Stack direction="row" justifyContent="center" spacing={4} width="60%">
          <Button variant="contained" onClick={() => handleSign()}>
            Подписать
          </Button>
          <DeviationDialog
            additionalInfo={{ id: externalSigningValue.documentId }}
            documentType={externalSigningValue.signType}
            title="Записать отклонение"
            onSubmit={(...args) => {
              onRejectStamp(args)
            }}
          />
        </Stack>
      ) : (
        <StatusChip status={statusTask} variant="filled" />
      )}
    </Stack>
  )
}

export default TaskForSigning
