/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */
import { UUID } from '@builddocs/data-management'
import { ActFormKeys, DocumentSign } from '@builddocs/filestore-api'
import { useDisclosure } from '@builddocs/system'
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'
import { Button, IconButton, Tooltip } from '@mui/material'

import { InvitationDialog } from './InvitationDialog'
import { InvitationDialogApplication } from './InvitationDialogApplication'
import { SignType } from './SignType.type'

interface ExternalSigningInvitationProps {
  /**
   * Флаг блокировки кнопки приглашения
   */
  disabled?: boolean
  iconButton?: boolean
  assignees: DocumentSign[]
  signType: SignType
  forceContractId?: UUID
}

interface ActSigning extends ExternalSigningInvitationProps {
  signType: 'ACT'
  actId: string
  actType: ActFormKeys
  documentId?: unknown
  taskId?: unknown
}

interface ExSchemeSigning extends ExternalSigningInvitationProps {
  signType: 'EXECUTIVE_SCHEME'
  documentId: string
  taskId: string
  actId?: unknown
  actType?: unknown
}
export type SignTypes = {
  ['ACT']: {
    signType: 'ACT'
    actId: string
    actType: ActFormKeys
    documentId?: unknown
  }
  ['EXECUTIVE_SCHEME']: {
    signType: 'EXECUTIVE_SCHEME'
    documentId: string
    taskId: string
    actId?: unknown
    actType?: unknown
  }
}

type OwnProps = ActSigning | ExSchemeSigning

export const InvitationExternalSigning = ({
  disabled,
  signType,
  documentId,
  taskId,
  actId,
  actType,
  assignees,
  iconButton,
  forceContractId,
}: OwnProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      {iconButton ? (
        <Tooltip title="На подпись по имейлу">
          <IconButton
            disabled={disabled}
            id="add-assignee-documents"
            size="small"
            onClick={onOpen}
          >
            <ForwardToInboxOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button disabled={disabled} size="small" onClick={onOpen}>
          На подпись по имейлу
        </Button>
      )}
      {isOpen &&
        (signType === 'ACT' ? (
          <InvitationDialog
            assignees={assignees}
            forceContractId={forceContractId}
            isOpen={isOpen}
            signOpts={
              {
                actId,
                actType,
                documentId,
                signType,
              } as SignTypes['ACT']
            }
            onClose={onClose}
          />
        ) : (
          <InvitationDialogApplication
            assignee={assignees[0]}
            isOpen={isOpen}
            signOpts={
              {
                actId,
                actType,
                documentId,
                signType,
                taskId,
              } as SignTypes['EXECUTIVE_SCHEME']
            }
            onClose={onClose}
          />
        ))}
    </>
  )
}
