/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

/**
 * События переходов между состояниями в процессе согласования акта
 */
export enum ActEventFlow {
  /**
   * Отклонён во время согласования
   */
  APPROVAL_TO_REJECTED = 'APPROVAL_TO_REJECTED',
  /**
   * Последний участник согласовал
   */
  LAST_USER_APPROVED = 'LAST_USER_APPROVED',
  /**
   * Последний участник подписал
   */
  LAST_USER_SIGNED = 'LAST_USER_SIGNED',
  /**
   * Новый акт
   */
  NEW = 'NEW',
  /**
   * Акт переформирован
   */
  RECREATED = 'RECREATED',
  /**
   * Акт отправлен на согласование
   */
  SENT_TO_APPROVAL = 'SENT_TO_APPROVAL',
  /**
   * Акт отправлен на подписание
   */
  SENT_TO_SIGN = 'SENT_TO_SIGN',
  /**
   * Акт отклонён во время подписания
   */
  SIGNING_TO_REJECTED = 'SIGNING_TO_REJECTED',
  /**
   * Не последний участник согласовал акт
   */
  USER_APPROVED = 'USER_APPROVED',
  /**
   * Не последний участник подписал акт
   */
  USER_SIGNED = 'USER_SIGNED',
}
