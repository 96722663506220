/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 *  Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 *  You may use, distribute and modify this code under the
 *  terms of the ООО АДАПТ license
 */

export enum SignatureState {
  UNDEFINED = 'UNDEFINED',
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  REJECTED = 'REJECTED',
}

export enum InspectionState {
  UNDEFINED = 'UNDEFINED',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum ApprovalState {
  NEW = 'NEW',
  RECREATED = 'RECREATED',
  APPROVAL = 'APPROVAL',
  APPROVED = 'APPROVED',
  SIGNING = 'SIGNING',
  SIGNED = 'SIGNED',
  REJECTED = 'REJECTED',
  NADZORING = 'NADZORING',
  NADZOR_ACCEPTED = 'NADZOR_ACCEPTED',
}
