/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { createContext } from 'react'

import { UUID } from '@builddocs/data-management'
import { AxiosError, AxiosResponse } from 'axios'

import { CryptoproCertificate } from './cryptopro/types'

export type SignHandlerParams = {
  signedHash: string
  /**
   * дополнительный хэш для реестра, возможно надо поменять на более
   * универсальное что-то
   */
  actRegistryBase64signature?: string
  keyIssuer: string
  imprint: string
  facePhoto?: string
  keySerialNumber: string
  certificateDateStart: string
  certificateDateEnd: string
  signerName: string // Имя подписанта
}

export type SignHandlerParams1 = {
  signedHash: {
    aosrSignedHash: {
      actId: UUID
      base64signature: string
      actRegistryBase64signature: string
    }[]
    secondarySignedHash: { actId: UUID; base64signature: string }[]
  }
  keyIssuer: string
  imprint: string
  facePhoto?: string
  keySerialNumber: string
  signerName: string // Имя подписанта
}

export type SignHandlerMultipleStampsParams = {
  signedHash: {
    base64signature: string
    stampTemplateId: string
    documentId: string
  }[]
  keyIssuer: string
  imprint: string
  facePhoto?: string
  keySerialNumber: string
  signerName: string // Имя подписанта
}

export type DigestHandlerParams = {
  signedHash: null
  keyIssuer: string
  signerName: string // Имя, которое будет в штампе
  imprint: string
  facePhoto?: string | undefined
  keySerialNumber: string
  certificateDateStart: string
  certificateDateEnd: string
}

export interface ISignatureCallbacks {
  isEmbedded?: boolean
  signHandler: (args: SignHandlerParams) => Promise<AxiosResponse | void>
  getDigestHandler: (opts: DigestHandlerParams) => Promise<
    | {
        hash: string
        // временно для реестра
        actRegistryDigest?: string
        currentSignatureBase64?: string
      }
    | {
        hash: { hash: any; stampTemplateId: string; documentId: string }[]
        actRegistryDigest?: string
        currentSignatureBase64?: string
      }
    | string
  >
  onSuccess: (res: AxiosResponse) => void
  onFailure: (e: AxiosError) => void
}

export interface ISignatureSetActsCallbacks {
  isEmbedded?: boolean
  signHandler: (args: SignHandlerParams1) => Promise<void>
  getDigestHandler: (opts: DigestHandlerParams) => Promise<{
    hash: {
      aosrDigest: AxiosResponse<{
        digest: string
        // временно для реестра
        actRegistryDigest: string
      }>
      secondaryDigest: AxiosResponse<string>
    }
    actRegistryDigest?: string
    currentSignatureBase64?: string
  }>
  onSuccess: (res: AxiosResponse) => void
  onFailure: (e: AxiosError) => void
}

export interface ISignatureMultipleStampsCallbacks {
  signHandler: (args: SignHandlerMultipleStampsParams) => AxiosResponse
  getDigestHandler: () => {
    hash: Promise<{
      hash: Promise<string>
      stampTemplateId: string
      documentId: string
    }>[]
    actRegistryDigest?: string
    currentSignatureBase64: string
  }
  onSuccess: (res: AxiosResponse) => void
  onFailure: (e: AxiosError) => void
}

export interface IVerifyCallbacks {
  handleVerify: (args: {
    facePhoto?: string | undefined
    keySerialNumber: string
  }) => Promise<AxiosResponse>
  onSuccess: (res: AxiosResponse) => void
  onFailure: (e: AxiosError) => void
}

export interface IFaceControlCtx {
  //  handleOpen: (opts: {
  //    isSign: boolean
  //    data: Record<string, string | number>
  //  }) => void
  //  handleClose: () => void
  // handleVerify?: (opts: {
  //   facePhoto: string
  //   onFailure: ISignatureCallbacks['onFailure']
  //   onSuccess: ISignatureCallbacks['onSuccess']
  // }) => void
  handleClose: () => void
  signData: (opts: {
    facePhoto?: string
    keySerialNumber: string
    certSelected: CryptoproCertificate
    certificateDateStart: string
    certificateDateEnd: string
    signerName: string // Имя, которое будет в штампе
    onSuccess?: () => void
    onFailure?: () => void
  }) => void
  openFaceControl: (
    data: ISignatureCallbacks | ISignatureSetActsCallbacks,
  ) => void
  openFaceControlVerify: (data: IVerifyCallbacks) => void
  verifyPerson: (opts: {
    facePhoto?: string
    keySerialNumber: string
    onSuccess: (res: AxiosResponse) => void
    onFailure: (e: AxiosError) => void
  }) => void
  //  verifySigner: (opts: unknown) => Promise<string>
  //  handleSign: (
  //    resSignBase64: string,
  //    issuerName: string,
  //    thumbprint: string,
  //  ) => void
}

export const FaceControlContext = createContext<IFaceControlCtx>(null!)
