import { Button as MuiButton, ButtonProps } from '@mui/material'
import { styled } from '@mui/system'

const ButtonBase = styled(MuiButton)<ButtonProps>(() => ({
  color: '#000000',
  backgroundColor: '#EBEFF8',
  border: 'none',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#D3D6DF',
    border: 'none',
  },
}))

export const Button = ({ ...rest }) => {
  return <ButtonBase {...rest} />
}
