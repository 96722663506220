/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */
import { User } from '../models'
import { Journal, MyRights } from '../rest/contracts/journal'

export function makeProfiles(contracts: Journal[]) {
  const profiles: Record<string, User> = {}

  contracts.length &&
    contracts.forEach(contract => {
      ;[
        ...(contract.developerAssignees ?? []),
        ...(contract.executiveSchemeAssignees ?? []),
        ...(contract.customerAssignees ?? []),
        ...(contract.builderAssignees ?? []),
        ...(contract.firstAssignees ?? []),
        ...(contract.secondAssignees ?? []),
      ].forEach(item => {
        if (item.user?.employment?.employmentId) {
          profiles[item.user.employment.employmentId] = item.user
        }
      })

      if (contract.prorab?.employment?.employmentId) {
        profiles[contract.prorab.employment.employmentId] = contract.prorab
      }
    })

  return profiles
}

export function getMyRightsFromContract(
  contract: Journal | undefined,
  myEmploymentId: string | undefined,
): MyRights | any {
  if (!contract) {
    return {}
    //    throw Error('Договор не передан в функцию')
  }
  const foundAssignee = [
    ...(contract.developerAssignees ?? []),
    ...(contract.customerAssignees ?? []),
    ...(contract.customerTechControlAssignees ?? []),
    ...(contract.builderAssignees ?? []),
    ...(contract.prepAssignees ?? []),
    ...(contract.firstAssignees ?? []),
    ...(contract.secondAssignees ?? []),
  ].find(assigner => assigner.user?.employment?.employmentId === myEmploymentId)
  const { canExecute, canSign, canInspect, type } = foundAssignee || {}

  if (!type) {
    return {}
    //    throw Error('Договор не доступен текущему юзеру')
  }
  const isOtherInspector =
    contract.prorab?.employment?.employmentId === myEmploymentId

  return {
    canExecute,
    canSign,
    canInspect: isOtherInspector ? true : canInspect,
    type,
  }
}
