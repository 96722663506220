/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { ActFormKeys } from './acts'

export enum Application {
  ADDITIONAL = 'ADDITIONAL',
  ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
  CERTIFICATE = 'CERTIFICATE',
  PERMIT = 'PERMIT',
  EXECUTIVE_SCHEME = 'EXECUTIVE_SCHEME',
  WORKING_DRAFT = 'WORKING_DRAFT',
  WORKING_DRAWING = 'WORKING_DRAWING',
  LABORATORY = 'LABORATORY',
  LAB = 'LAB',
  LABORATORY_DATA = 'LABORATORY_DATA',
  NORMATIVE = 'NORMATIVE',
  NORMATIVE_DOC = 'NORMATIVE_DOC',
  NORMATIVE_ADDITIONAL = 'NORMATIVE_ADDITIONAL',
  NORMATIVE_ADDITIONAL_DOCUMENT = 'NORMATIVE_ADDITIONAL_DOCUMENT',
  REGISTRY = 'REGISTRY',
  MATERIALS_REGISTRY = 'MATERIALS_REGISTRY',
  SUPPORTING = 'SUPPORTING',
  EXECUTIVE_DRAWING = 'EXECUTIVE_DRAWING',
}

export type ApplicationKeys = keyof typeof Application

/**
 * Типы приложений, которые можно загружать в сервис
 */
export type LoadableApplicationKeys =
  | Application.NORMATIVE
  | Application.NORMATIVE_DOC
  | Application.LABORATORY
  | Application.EXECUTIVE_SCHEME
  | Application.EXECUTIVE_DRAWING
  | Application.PERMIT
  | Application.WORKING_DRAFT
  | Application.CERTIFICATE
  | Application.SUPPORTING
  | Application.ADDITIONAL_DOCUMENT

export type DocumentaryType = ActFormKeys | ApplicationKeys
