/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export enum UnitEnum {
  PIECES = 'PIECES',
  KG = 'KG',
  M = 'M', // метр
  M_2 = 'M_2', // метр^2
  M_3 = 'M_3', // метр^3
  KG_M2 = 'KG_M2', // кг/м2
  KG_M3 = 'KG_M3', // кг/м3
  TON = 'TON', // тонна
  HA = 'HA', // гектар
  /** Ниже юниты для совместимости с Синтекой */
  COIL = 'COIL', // бухта
  KM = 'KM', // километр
  PAIR = 'PAIR', // пара
  SHEET = 'SHEET', // лист
  ML = 'ML', // миллилитр
  PIECES_10 = 'PIECES_10', // десять штук
  PIECES_100 = 'PIECES_100', // сто штук
  PIECES_1000 = 'PIECES_1000', // тычяча штук
  KIT = 'KIT', // комплект
  M_100 = 'M_100', // 100 м
  M_10 = 'M_10', // 10 м
  GR = 'GR', // грамм
  CONDITIONAL_UNIT_1000 = 'CONDITIONAL_UNIT_1000', // тысяча условных единиц
  ROLL = 'ROLL', // рулон
  PACKAGING = 'PACKAGING', // упаковка
  LM = 'LM', // метр погонные
  CM = 'CM', // сантиметр
  LITER = 'LITER', // литр
}

export type Unit = keyof typeof UnitEnum
