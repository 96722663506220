/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

type Primitive = string | number | bigint | boolean | undefined | symbol

/**
 * Утилита для типизации пути к атрибутам объекта в виде строки.
 *
 * Например, "user.employment.employmentId"
 */
export type PropertyStringPath<T, Prefix = ''> = {
  [K in keyof T]: T[K] extends Primitive | Array<any>
    ? `${string & Prefix}${string & K}`
    :
        | `${string & Prefix}${string & K}`
        | PropertyStringPath<T[K], `${string & Prefix}${string & K}.`>
}[keyof T]
