/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React from 'react'

import { Box, Typography } from '@mui/material'

export interface UserFieldProps {
  name: string
  icon?: React.ReactElement
  maxWidth?: number
  color?: string
}

export function UserField({
  name,
  icon,
  maxWidth,
  color = 'primary',
}: UserFieldProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        transition: 'background-color 200ms',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'grey.50',
        },
        height: '32px',
        maxWidth: maxWidth,
        pr: 1,
      }}
    >
      {icon && (
        <Box flexShrink={0} minWidth="20px">
          {React.cloneElement(icon, { display: 'block' })}
        </Box>
      )}

      <Typography
        color={color}
        pl="3px"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {name}
      </Typography>
    </Box>
  )
}

export default UserField
