/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { UUID, ActForm } from '@builddocs/data-management'
import { atomFamily, selectorFamily } from 'recoil'

import { getSecondaryActById } from '../secondary-act/secondary-act.service'

import { AOSRType, SecondaryActType } from './acts.interfaces'
import { getAOSRById } from './aosr.service'

export const actQueryRequestIDState = atomFamily({
  key: 'actQueryRequestID',
  default: 0,
})

export const actQuery = selectorFamily<
  AOSRType | SecondaryActType,
  {
    actId: UUID
    actType: string
  }
>({
  key: 'actQuery',
  get:
    ({ actId, actType }) =>
    async ({ get }) => {
      get(
        actQueryRequestIDState({
          actId,
          actType,
        }),
      )
      return actType === ActForm.AOSR
        ? await getAOSRById(actId)
        : await getSecondaryActById(actId)
    },
})
