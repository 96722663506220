/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 *  Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 *  You may use, distribute and modify this code under the
 *  terms of the ООО АДАПТ license
 */

import queryString from 'query-string'
import { useLocation, useParams } from 'react-router-dom'

import { UUID } from '../models'

/**
 * Хук для доступа к текущему положению по проектам и договорам
 */
export const useCoordinator = () => {
  const { search } = useLocation()

  const { projectId, contractId } = useParams<{
    projectId: UUID
    contractId: UUID
  }>()

  return {
    isProjects: Boolean(projectId),
    projectId,
    contractId,
    search: {
      ...queryString.parse(search),
    },
  }
}
