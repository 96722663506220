import { Children, cloneElement, isValidElement, ReactNode } from 'react'
import { Box, ButtonProps, SxProps } from '@mui/material'
interface ButtonTabsProps {
  children: ReactNode;
  value: string | null;
  sx?:SxProps;
  onChange: (name: string) => void;
}
export const ButtonTabs = ({ children, value, sx, onChange }: ButtonTabsProps) => {
  return (
    <Box sx={{p:'6px', bgcolor:'#EAEEF8',width:'fit-content;',display:'flex',flexDirection:'row', border:'1px solid rgba(0,0,0,0.04)', borderRadius:'4px',gap:1, ...sx}}>
      {Children.map(children, child => {
        if (isValidElement<ButtonProps & { value: string, sx?:SxProps }>(child)) {
          return cloneElement(child, {
            onClick: () => onChange(child.props.value),
            sx: value === child.props.value ? { bgcolor: '#FFFFFF', ...child.props.sx } : { bgcolor: '#EAEEF8', ...child.props.sx }
          });
        }
        return null;
      })}
    </Box>
  );
};
