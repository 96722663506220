/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

/** @jsxImportSource @emotion/react */
import { FC, useState } from 'react'

import { CommentItem, UUID } from '@builddocs/data-management'
import { Skeleton, Stack, Divider, Typography } from '@mui/material'

import CommentCard from './CommentCard'
import EditComment from './EditComment'

type Props = {
  comments: CommentItem[]
  refreshComments: () => void
  isLoading: boolean
  forceContractId?: UUID
}
export const CommentsList: FC<Props> = ({
  comments,
  isLoading,
  refreshComments,
  forceContractId,
}) => {
  const [editCommentId, setEditCommentId] = useState<string>()

  if (isLoading) {
    return <Skeleton />
  }
  if (!comments.length) {
    return (
      <Typography gutterBottom color="text.secondary">
        {!comments.length && 'Нет замечаний'}
      </Typography>
    )
  }
  return (
    <Stack
      divider={<Divider />}
      height="100%"
      spacing={1}
      style={{ overflowY: 'auto', overflowX: 'hidden' }}
      width="100%"
    >
      {comments.map(comment => {
        if (comment.commentId === editCommentId) {
          return (
            <EditComment
              withoutDeadline
              comment={comment}
              forceContractId={forceContractId}
              refreshComments={refreshComments}
              onClose={() => setEditCommentId(undefined)}
            />
          )
        }

        return (
          <CommentCard
            key={comment.commentId}
            comment={comment}
            forceContractId={forceContractId}
            refreshComments={refreshComments}
            onEdit={() => setEditCommentId(comment.commentId)}
          />
        )
      })}
    </Stack>
  )
}

export default CommentsList
