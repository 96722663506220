import LinkIcon from '@mui/icons-material/Link'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import copy from 'copy-to-clipboard'

export interface CurrentLinkToClipboardProps {
  overrides?: string
}

export function CurrentLinkToClipboard(props: CurrentLinkToClipboardProps) {
  const { overrides } = props

  function copyHref() {
    copy(overrides ?? window.location.href)
  }

  return (
    <Tooltip placement="bottom" title="Скопировать ссылку на таблицу в буфер">
      <IconButton onClick={copyHref}>
        <LinkIcon />
      </IconButton>
    </Tooltip>
  )
}

export default CurrentLinkToClipboard
