import { useParams } from 'react-router-dom'

import { useSelector } from '../../useSelector.hook'

import { stagesQuery, stagesQueryRequestIDState } from './stages.container'
import { createStageOld as createStageRequest } from './stages.service'
import { WorkDocSection } from './worksNames'

interface IStagesHook {
  stages: WorkDocSection[] | undefined
  isLoading: boolean
  refreshStages: () => void
  error?: Error
  createStage: (opts: CreateStage) => void
}

interface CreateStage {
  contractId: string
  parentStageId: number
  data: any
  onSuccess?: () => void
  onFailure?: (error: Error) => void
}

export const useStages = (): IStagesHook => {
  const { contractId, projectId } = useParams() as {
    contractId: string
    projectId: string
  }

  const { data, isLoading, error, refresh } = useSelector<WorkDocSection[]>(
    stagesQuery({ contractId, projectId }),
    stagesQueryRequestIDState({ contractId, projectId }),
  )

  const createStage = ({
    contractId,
    data,
    parentStageId,
    onSuccess,
    onFailure,
  }: CreateStage) => {
    createStageRequest({
      contractId,
      parentStageId,
      data,
    })
      .then(() => {
        refresh()
        onSuccess && onSuccess()
      })
      .catch(error => onFailure && onFailure(error))
  }

  return {
    stages: data,
    isLoading,
    error,
    refreshStages: refresh,
    createStage,
  }
}
