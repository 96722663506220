import { useMemo } from 'react'

import { ActForm } from '@builddocs/data-management'
import {
  AOSRType,
  MaterialsRegistry,
  SecondaryActType,
  FILE_STORE_BASE,
  ActRegistry,
  Application,
  Laboratory,
  Certificate,
  ExecutiveScheme,
  WorkingDraft,
  Permits,
  Supporting,
  ExecutiveDrawing,
} from '@builddocs/filestore-api'
import { Box, List } from '@mui/material'
import format from 'date-fns/format'
import { ru } from 'date-fns/locale'
import parseISO from 'date-fns/parseISO'

import { NavContentProps } from '../TwoPdfViewer/drawer.type'

import { NavContentItem } from './NavContentItem'
import RowCompare from './RowCompare'
import { DocsTypes } from './types'

interface Props extends NavContentProps {
  //Акт для просмотра
  act: AOSRType | SecondaryActType
  //функция получения документа для комментариев
  onChangeDoc?: (doc: DocsTypes | undefined) => void
  //функция получения документа для сравнения
  onChangeRightDoc?: (doc: DocsTypes | undefined) => void
}

export const NavContent = (props: Props) => {
  const {
    act,
    onChangeUrlFirst,
    onChangeUrlSecond,
    url,
    urlSecond,
    onChangeDoc,
    onChangeRightDoc,
  } = props

  const handleGetDocument = async (doc: DocsTypes) => {
    const { documentUrl } = doc

    onChangeUrlFirst(`${FILE_STORE_BASE}${documentUrl}`)
  }
  const handleGetDocumentUrlSecond = async (doc: DocsTypes) => {
    const { documentUrl } = doc

    onChangeUrlSecond(`${FILE_STORE_BASE}${documentUrl}`)
  }

  const onLeftSelectHandler = (doc: DocsTypes) => {
    if (!url?.includes(doc.documentUrl)) {
      handleGetDocument(doc)
      onChangeDoc && onChangeDoc(doc)
    }
  }

  const onRightSelectHandler = (doc: DocsTypes) => {
    if (urlSecond?.includes(doc.documentUrl)) {
      onChangeUrlSecond('')
      onChangeRightDoc && onChangeRightDoc(undefined)
    } else {
      handleGetDocumentUrlSecond(doc)
      onChangeRightDoc && onChangeRightDoc(doc)
    }
  }

  const getNameAndDate = (doc: AOSRType | SecondaryActType) => {
    return `${doc.name} от ${format(
      parseISO(doc.actDate || doc.creationDate),
      'PP',
      { locale: ru },
    )}`
  }

  const actTitle: string = useMemo(() => getNameAndDate(act), [act])
  const showAOSR: boolean = useMemo(
    () => act.actType !== ActForm.AGSML && act.actType !== ActForm.APOS,
    [act],
  )

  const getRegistries = (act: AOSRType | SecondaryActType) => {
    const arr: Array<MaterialsRegistry | ActRegistry> = []

    'actRegistry' in act && act?.actRegistry && arr.push(act?.actRegistry)

    'materialsRegistry' in act &&
      act?.materialsRegistry &&
      arr.push(act?.materialsRegistry)
    return arr
  }

  const registries: Array<MaterialsRegistry | ActRegistry> = useMemo(
    () => getRegistries(act),
    [act],
  )

  const isOnLeft = (doc: DocsTypes) => {
    return url?.includes(doc.documentUrl)
  }
  const isOnRight = (doc: DocsTypes) => {
    return urlSecond?.includes(doc.documentUrl)
  }

  return (
    <Box pl={2} pr={1}>
      <List>
        {act && (
          <List>
            {act && 'actDate' in act && (
              <RowCompare
                isOnLeft={isOnLeft(act)}
                isOnRight={isOnRight(act)}
                title={actTitle}
                onLeftSelect={() => onLeftSelectHandler(act)}
                onRightSelect={() => onRightSelectHandler(act)}
              />
            )}
          </List>
        )}
        {!!registries.length && (
          <NavContentItem
            docs={registries}
            isOnLeft={isOnLeft}
            isOnRight={isOnRight}
            title="Реестры"
            type="registries"
            onLeftSelectHandler={onLeftSelectHandler}
            onRightSelectHandler={onRightSelectHandler}
          />
        )}
        {!!act.workingDraftsObjList?.length && (
          <NavContentItem
            docs={act.workingDraftsObjList}
            isOnLeft={isOnLeft}
            isOnRight={isOnRight}
            title="Проекты"
            onLeftSelectHandler={doc =>
              onLeftSelectHandler({
                ...doc,
                documentType: Application.WORKING_DRAFT,
              } as WorkingDraft)
            }
            onRightSelectHandler={onRightSelectHandler}
          />
        )}
        {!!act.executiveSchemesObjList?.length && (
          <NavContentItem
            docs={act.executiveSchemesObjList}
            isOnLeft={isOnLeft}
            isOnRight={isOnRight}
            title="Исполнительные схемы"
            onLeftSelectHandler={doc =>
              onLeftSelectHandler({
                ...doc,
                documentType: Application.EXECUTIVE_SCHEME,
              } as ExecutiveScheme)
            }
            onRightSelectHandler={onRightSelectHandler}
          />
        )}
        {!!act.executiveDrawingsObjList?.length && (
          <NavContentItem
            docs={act.executiveDrawingsObjList}
            isOnLeft={isOnLeft}
            isOnRight={isOnRight}
            title="Исполнительные чертежи"
            onLeftSelectHandler={doc =>
              onLeftSelectHandler({
                ...doc,
                documentType: Application.EXECUTIVE_DRAWING,
              } as ExecutiveDrawing)
            }
            onRightSelectHandler={onRightSelectHandler}
          />
        )}
        {!!act.certificateObjList?.length && (
          <NavContentItem
            docs={act.certificateObjList}
            isOnLeft={isOnLeft}
            isOnRight={isOnRight}
            title="Сертификаты"
            onLeftSelectHandler={doc =>
              onLeftSelectHandler({
                ...doc,
                documentType: Application.CERTIFICATE,
              } as Certificate)
            }
            onRightSelectHandler={onRightSelectHandler}
          />
        )}
        {!!act.laboratoriesObjList?.length && (
          <NavContentItem
            docs={act.laboratoriesObjList}
            isOnLeft={isOnLeft}
            isOnRight={isOnRight}
            title="Лаборатории"
            onLeftSelectHandler={doc =>
              onLeftSelectHandler({
                ...doc,
                documentType: Application.LABORATORY,
              } as Laboratory)
            }
            onRightSelectHandler={onRightSelectHandler}
          />
        )}
        {!!act?.permitsObjList?.length && (
          <NavContentItem
            docs={act.permitsObjList}
            isOnLeft={isOnLeft}
            isOnRight={isOnRight}
            title="Приказы"
            onLeftSelectHandler={doc =>
              onLeftSelectHandler({
                ...doc,
                documentType: Application.PERMIT,
              } as Permits)
            }
            onRightSelectHandler={onRightSelectHandler}
          />
        )}
        {!!act.supportingDocsObjList?.length && (
          <NavContentItem
            docs={act.supportingDocsObjList}
            isOnLeft={isOnLeft}
            isOnRight={isOnRight}
            title="Сопроводительные документы"
            onLeftSelectHandler={doc =>
              onLeftSelectHandler({
                ...doc,
                documentType: Application.SUPPORTING,
              } as Supporting)
            }
            onRightSelectHandler={onRightSelectHandler}
          />
        )}
        {'supportingDocsForWorksObjList' in act &&
          !!act.supportingDocsForWorksObjList?.length && (
            <NavContentItem
              docs={act.supportingDocsForWorksObjList}
              isOnLeft={isOnLeft}
              isOnRight={isOnRight}
              title="Сопроводительные документы пп. 2,6"
              onLeftSelectHandler={doc =>
                onLeftSelectHandler({
                  ...doc,
                  documentType: Application.SUPPORTING,
                } as Supporting)
              }
              onRightSelectHandler={onRightSelectHandler}
            />
          )}
        {'actsObjList' in act && !!act.actsObjList?.length && showAOSR && (
          <NavContentItem
            docs={act.actsObjList}
            isOnLeft={isOnLeft}
            isOnRight={isOnRight}
            title="Акты"
            type="acts"
            onLeftSelectHandler={onLeftSelectHandler}
            onRightSelectHandler={onRightSelectHandler}
          />
        )}
      </List>
    </Box>
  )
}
