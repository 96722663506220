/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React from 'react'

import { DocumentMeta, UUID } from '@builddocs/data-management'
import {
  Application,
  FILE_STORE_API,
  FILE_STORE_BASE,
  getApplicationUrlByType,
  WorkingDraft,
} from '@builddocs/filestore-api'
import { ViewerContext } from '@builddocs/shared/feature-pdf-viewer'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import format from 'date-fns/format'
import { ru } from 'date-fns/locale'
import parseISO from 'date-fns/parseISO'
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'

/** Компонент для отображения списка приложений с возможностью просмотра */
export function ApplicationsList(props: {
  docs: DocumentMeta[] | WorkingDraft[]
  size?: 'small'
}) {
  const { docs, size } = props

  const amount = docs.length

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'applicationsListPopover',
  })

  const pdfViewerContext = React.useContext(ViewerContext)

  const handleShowPDF: (args: {
    docType: Application
    id: UUID
    name: string
    url: string | null | undefined
  }) => void = ({ docType, id, name, url }) => {
    const params = { url: '', fileName: name }

    if (url) {
      params.url = `${FILE_STORE_BASE}/${url}`
    } else {
      params.url = `${FILE_STORE_API}${getApplicationUrlByType[docType]}/document/${id}.pdf`
    }

    pdfViewerContext.setPDF(params)
  }

  return (
    <div>
      <Button
        size={size}
        startIcon={<AttachFileIcon />}
        variant="text"
        {...bindTrigger(popupState)}
      >
        {amount}
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {docs.map(doc => (
            <ListItem key={doc.documentId} disablePadding>
              <ListItemButton
                onClick={() => {
                  handleShowPDF({
                    docType: doc.documentType,
                    id: doc.documentId,
                    name: doc.fileName,
                    url: doc.documentUrl,
                  })
                }}
              >
                <ListItemText
                  primary={doc.documentName || doc?.name}
                  secondary={
                    <Stack direction="row" spacing={2}>
                      {doc.issueDate
                        ? `${doc?.documentNumber ?? ''} от ${format(
                            parseISO(doc.issueDate),
                            'dd MMM yyyy г.',
                            {
                              locale: ru,
                            },
                          )}`
                        : doc?.documentNumber}

                      {doc?.cipher && (
                        <Typography variant="caption">
                          {`Шифр: ${doc?.cipher}`}
                        </Typography>
                      )}
                      {Number.isInteger(doc?.pageNumber) && (
                        <Typography variant="caption">{`Лист: ${
                          doc.pageNumber + 1
                        }`}</Typography>
                      )}
                    </Stack>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  )
}
