/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useCallback, useContext, useEffect, useState } from 'react'

import { useToast } from '@builddocs/system'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material'
import format from 'date-fns/format'

import { CertificateBlock } from './atoms'
import CertificateInfo from './CertificateInfo'
import { cryptopro, initCrypto } from './cryptopro/inits'
import { CertificateInList, CryptoproCertificate } from './cryptopro/types'
import { FaceControlContext } from './FaceControlContext'

interface SignDataDialogProps {
  onClose: () => void
  isOpen: boolean
  isVerifyOnly: boolean
  isLoading: boolean
}

/**
 * Диалог для подписи одним только ключом, без лица.
 * Верификация ключа не должна происходить. Человек имеет право подписывать
 * любым своим ключом
 * @param {SignDataDialogProps} props
 * @returns {JSX.Element}
 * @constructor
 */
function SignDataDialog(props: SignDataDialogProps) {
  const { onClose, isOpen, isVerifyOnly, isLoading } = props
  const [error, setError] = useState<Error>()
  const { toast } = useToast()

  const [certs, setCerts] = useState<CertificateInList[]>([])
  const [certSelected, setCertSelected] = useState<CryptoproCertificate>()
  const faceControl = useContext(FaceControlContext)

  const getCertInfo = useCallback((certID: string) => {
    initCrypto()
      .then(() => {
        return cryptopro.certificateInfo(certID)
      })
      .then((info: CryptoproCertificate) => {
        setCertSelected(info)
      })
      .catch((e: Error) => {
        console.error('Failed! ' + e)
      })
  }, [])

  const getCertsList = useCallback(() => {
    initCrypto()
      .then(() => {
        return cryptopro.listCertificates()
      })
      .then((_certs: CertificateInList[]) => {
        setCerts(_certs)
        getCertInfo(_certs[0].id)
      })
      .catch((e: Error) => {
        console.error(`Failed! ${e}`)
        toast.error('Ошибка при получении списка сертификатов')
      })
  }, [getCertInfo])

  const signEntity = () => {
    if (!certSelected) return

    // setIsLoading(true)
    const certificateDateStart = format(
      certSelected.ValidFromDate,
      'yyyy-MM-dd',
    )
    const certificateDateEnd = format(certSelected.ValidToDate, 'yyyy-MM-dd')

    faceControl.signData({
      certSelected,
      keySerialNumber: certSelected.SerialNumber,
      certificateDateStart,
      certificateDateEnd,
      signerName: `${certSelected.Subject.SN} ${certSelected.Subject.G}`,
      //        onFailure: () => {
      //          setIsSuccess(false)
      //          // setIsLoading(false)
      //        },
      //        onSuccess: () => {
      //          setIsSuccess(true)
      //          // setIsLoading(false)
      //        },
    })
  }

  useEffect(() => {
    isOpen && getCertsList()
  }, [getCertsList, isOpen])

  const allowSend = certSelected && certSelected?.IsValid

  const handleClose = () => {
    !isLoading && onClose()
  }

  const primaryActionTitle = isVerifyOnly
    ? isLoading
      ? 'Верификация...'
      : 'Продолжить'
    : isLoading
    ? 'Обработка подписи...'
    : 'Подписать запись'

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
      <DialogTitle>Подписание</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <CertificateBlock
            disableSignAction
            actionTitle={primaryActionTitle}
            certs={certs}
            disabled={!allowSend || isLoading}
            dismissError={() => setError(undefined)}
            error={error?.message}
            getCertInfo={getCertInfo}
            infoMessage="Подпись можно сделать только валидным ключом"
            loading={isLoading}
            selectedCertificate={certSelected}
            updateCertsList={getCertsList}
            onClick={signEntity}
          />
          {certSelected && <CertificateInfo certificate={certSelected} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          Отмена
        </Button>
        <LoadingButton
          disabled={!allowSend || isLoading}
          id="face-control-dialog-button-action"
          loading={isLoading}
          variant="contained"
          onClick={signEntity}
        >
          Подписать
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default SignDataDialog
