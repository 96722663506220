import { UUID } from '../../models'

export type AssigneeListType = {
  type: string
  title: string
}

export type OrganizationListType = {
  type: string
  title: string
}

export type InitialSigner = {
  firstName: string
  lastName: string
  middleName?: string
  position: string
  organizationInn: string
  permitId: UUID
  employmentId: UUID
}

export enum Role {
  PROJECT_CUSTOMER = 'Застройщик (проект)',
  PROJECT_DEVELOPER = 'Лицо, осущ. строительство (проект)',
  JOURNAL_CUSTOMER_TECH_CONTROL = 'Застройщик (договор)',
  JOURNAL_PREP = 'Проектная организация (договор)',
  JOURNAL_DEVELOPER = 'Лицо, осущ. строительство (договор)',
  JOURNAL_BUILDER = 'Организация, вып. строительство (договор)',
  JOURNAL_CUSTOMER = 'Иные организации (договор)',
}

export type InitialOrganization = {
  name: string
  inn: string
}

export const getTypeOrganization = (typeOrganization: string) => {
  switch (typeOrganization) {
    case 'PROJECT_CUSTOMER':
      return `Застройщик (проект)`
    case 'PROJECT_DEVELOPER':
      return `Лицо, осущ. строительство (проект)`
    case 'JOURNAL_CUSTOMER_TECH_CONTROL':
      return `Застройщик (договор)`
    case 'JOURNAL_PREP':
      return `Проектная организация (договор)`
    case 'JOURNAL_DEVELOPER':
      return `Лицо, осущ. строительство (договор)`
    case 'JOURNAL_BUILDER':
      return `Организация, вып. строительство (договор)`
    case 'JOURNAL_CUSTOMER':
      return `Иные организации (договор)`

    default:
      return typeOrganization
  }
}

export type OrganizationsProjectResponseType = {
  inn: string
  roleInContract:
    | 'developer'
    | 'customer'
    | 'builder'
    | 'prep'
    | 'customer_tech_control'
  name: string
}

export interface IFormInputs {
  nextWork: string
  actExamples: string
  currentWork: string | undefined
}
