/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Roles } from './roles'
import { UUID } from './uuid'

export interface Position {
  createDateTime: string
  description: string
  inn: string
  positionId: string
  value: string
}

export type Employment = {
  employmentId: string
  employmentEndDate: string
  employmentOrder: string
  employmentOrderDate: string
  employmentStartDate: string
  position: Position
}

type Role = {
  permissions: Array<{
    permissionId: number
    permissionName: string
    permissionDescription: string
  }>
  roleDescription: string
  roleId: Roles
  roleName: string
}

export interface NewUser {
  userId: UUID
  email: string
  password?: string
  firstName: string
  keySerialNumber?: string
  middleName?: string
  lastName: string
  phone: string
  dateOfBirth: string
  nostroyId?: string
  role: Role
  employment: Employment | null
  createDateTime: string
}
export interface User extends NewUser {
  employment: Employment
}
