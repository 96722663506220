/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useEffect, useState, Dispatch, SetStateAction } from 'react'

type StorageType = 'local' | 'session'

export function useStickyState<T = unknown>(
  defaultValue: T | undefined,
  key?: string,
  storageType?: StorageType,
): [T, Dispatch<SetStateAction<T>>] {
  const Storage =
    storageType === 'session' ? window.sessionStorage : window.localStorage

  const [value, setValue] = useState<T>(() => {
    if (key === undefined) {
      return defaultValue
    }

    const stickyValue = Storage.getItem(key)

    return stickyValue !== null
      ? JSON.parse(stickyValue as string)
      : defaultValue
  })

  useEffect(() => {
    if (value && key) {
      Storage.setItem(key, JSON.stringify(value))
    }
  }, [Storage, key, value])

  return [value, setValue]
}
