/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useRouter } from '../../../../index'
import { refreshToken } from '../auth.service'

type OwnProps = {
  redirectUrl?: string
}

export let ACCESSTOKEN = ''

export class TokenManipulate {
  static print(accessToken: string, refreshToken: string) {
    ACCESSTOKEN = accessToken
    localStorage.setItem('token', refreshToken)
    localStorage.setItem('iat', String(Math.floor(Date.now() / 1000)))
  }

  static async refresh(token: string) {
    if (!token) return new Promise((_resolve, reject) => reject())

    return await refreshToken(token).then(tokens => {
      this.print(tokens.jwtToken, tokens.refreshToken)
    })
  }

  static remove() {
    ACCESSTOKEN = ''
    localStorage.removeItem('token')
    localStorage.removeItem('iat')
  }
}

export const useToken = ({ redirectUrl }: OwnProps) => {
  const { location, navigate } = useRouter()

  const check = async () => {
    const { from } = (location.state as any) || {
      from: { pathname: redirectUrl },
    }

    redirectUrl && navigate(from)
  }

  return {
    check,
  }
}

export const expiredTime = 60 * 45

export const checkToken = async (config: any) => {
  if (config.url === `/tokens/jwt/refresh`) return config

  if (
    localStorage.getItem('iat') &&
    Number(localStorage.getItem('iat')) + expiredTime <
      Math.floor(Date.now() / 1000)
  ) {
    localStorage.removeItem('iat')
    return await TokenManipulate.refresh(
      localStorage.getItem('token') as string,
    )
      .then(() => {
        config.headers.Authorization = `Bearer ${ACCESSTOKEN}`

        return config
      })
      .finally(() => config)
  } else if (
    config.headers?.Authorization &&
    config.headers?.Authorization.toString() === 'Bearer '
  ) {
    return await TokenManipulate.refresh(
      localStorage.getItem('token') as string,
    )
      .then(() => {
        config.headers.Authorization = `Bearer ${ACCESSTOKEN}`

        return config
      })
      .finally(() => config)
  } else if (ACCESSTOKEN) {
    config.headers.Authorization = `Bearer ${ACCESSTOKEN}`

    return config
  } else return config
}
