/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

/**
 * Получение юзеров из организации
 * @returns {any}
 */
import axios from 'axios'

import { checkToken } from './auth/hooks/useToken'

export const OsnovaAPI = axios.create({
  baseURL: '/osnova/api/v1',
})

OsnovaAPI.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
  'Content-Type': 'application/json',
}

OsnovaAPI.interceptors.request.use(
  config => {
    // Do something before request is sent
    return checkToken(config)
  },
  function (error) {
    // Do something with request error

    if (error.status === 403) {
      window.alert(
        '⚠️ Сессия неожиданно сброшена! Чтобы сохранить текущую' +
          ' работу, откройте BuildDocs в новой вкладке и выполните вход, затем' +
          ' повторите попытку на этой странице.',
      )
    }
    return Promise.reject(error)
  },
)
