import React, { useState } from 'react'

import {
  AOSRType,
  FILE_STORE_BASE,
  SecondaryActType,
} from '@builddocs/filestore-api'

import { TwoPdfViewer } from '../TwoPdfViewer'
import { NavContentProps } from '../TwoPdfViewer/drawer.type'

import { NavContent } from './NavContent'
import { DocsTypes } from './types'

type Props = {
  //Акт для просмотра в Pdf viewer
  act: AOSRType | SecondaryActType
  //функция получения документа для комментариев
  onChangeDoc?: (doc: DocsTypes | undefined) => void
  //функция получения документа для для сравнения
  onChangeRightDoc?: (doc: DocsTypes | undefined) => void
  //если true, pdf всё равно перезагрузится
  forceUpdate?: boolean
  //Компонент, передаваемый для работы с замечаниями к документу
  commentContent?: React.ReactElement<NavContentProps> | undefined
  //Компонент, передаваемый для работы с подписанием документа
  signContent?: React.ReactElement<NavContentProps> | undefined
  isDrawerOpenInitial?: boolean
}

//Просмотрщик Pdf акта и его приложений
const ActPdfViewerInner = ({
  act,
  onChangeDoc,
  onChangeRightDoc,
  forceUpdate,
  isDrawerOpenInitial,
  commentContent,
  signContent,
}: Props) => {
  const documentURL = `${FILE_STORE_BASE}${act.documentUrl}`
  const [urlFirst, setUrlFirst] = useState<string>(documentURL)
  const [urlSecond, setUrlSecond] = useState<string>('')

  return (
    <TwoPdfViewer
      commentContent={commentContent}
      forceUpdate={forceUpdate}
      isDrawerOpenInitial={isDrawerOpenInitial}
      navContent={
        <NavContent
          act={act}
          url={urlFirst}
          urlSecond={urlSecond}
          onChangeDoc={onChangeDoc}
          onChangeRightDoc={onChangeRightDoc}
          onChangeUrlFirst={setUrlFirst}
          onChangeUrlSecond={setUrlSecond}
        />
      }
      navTitle="Приложения"
      signContent={signContent}
      url={urlFirst}
      urlSecond={urlSecond}
    />
  )
}

export const ActPdfViewer = React.memo(ActPdfViewerInner)
