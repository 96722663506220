/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useCallback, useEffect, useState } from 'react'

import { useOrganization } from '../organization'

import { getBuildDocsAPIToken } from './intergations.service'

interface IIntegrationsHook {
  generateBuildDocsAPIToken: () => void
  isLoading: boolean
  builddocsApiKey: string | undefined
}

export const useIntergrationsBuildDocsAPIToken = (): IIntegrationsHook => {
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState('')

  const { organization } = useOrganization()

  useEffect(() => {
    organization && organization.apiToken && setToken(organization.apiToken)
  }, [organization])

  const fetchBuildDocsAPIToken = useCallback(
    async (organizationInn: string) => {
      setIsLoading(true)
      const token = await getBuildDocsAPIToken(organizationInn)

      setToken(token)

      setIsLoading(false)
    },
    [],
  )

  const generateBuildDocsAPIToken = () => {
    if (organization?.inn && window.confirm('Точно нужно обновить?')) {
      fetchBuildDocsAPIToken(organization.inn)
    }
  }

  return {
    generateBuildDocsAPIToken,
    isLoading: isLoading,
    builddocsApiKey: token,
  }
}
