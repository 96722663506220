/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Ability } from '@casl/ability'

import { User } from '../models'

import { defineRulesFor } from './RulesDefinitions'

type Actions = 'add' | 'view' | 'edit'
type Subjects = 'Organization' | 'Contracts' | 'EmploymentInfo'

// Defines how to detect object's type
function subjectName(item: any) {
  if (!item || typeof item === 'string') {
    return item
  }
  return item.__type
}

export const ability = new Ability<[Actions, Subjects]>(undefined, {
  detectSubjectType: subjectName,
})

export const updateRules = (user: User): void => {
  ability.update(defineRulesFor(user))
}
