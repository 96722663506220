/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useRecoilValueLoadable } from 'recoil'

import { TemplateStampType } from './stamp.types'
import { stampQuery, stampsQuery } from './Stamps.container'

interface StampHook {
  isLoading: boolean
  data: TemplateStampType[]
  pageCount: number
  pageSize: number
  pageNumber: number
  error: Error | undefined
  stampById: TemplateStampType
}

export const useStamps = (page = 0, size = 10, stampId?: string): StampHook => {
  const stampsLoadable = useRecoilValueLoadable(stampsQuery({ page, size }))
  const stampLoadable = useRecoilValueLoadable(stampQuery({ stampId }))

  const stamps = stampsLoadable.contents as {
    entityList: TemplateStampType[]
    pageCount: number
    pageNumber: number
    pageSize: number
  }

  const stampById = stampLoadable.contents as TemplateStampType

  const isLoading = stampsLoadable.state === 'loading'

  const error =
    stampsLoadable.state === 'hasError' ? stampsLoadable.contents : undefined

  return {
    isLoading,
    data: stamps ? stamps.entityList : [],
    pageCount: stamps ? stamps.pageCount : 0,
    pageSize: stamps ? stamps.pageSize : 0,
    pageNumber: stamps ? stamps.pageNumber : 0,
    error,
    stampById,
  }
}
