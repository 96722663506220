import { useEffect, useState } from 'react'

import { CommentItem, commentsLogService } from '@builddocs/data-management'
import { UserInfoBubble } from '@builddocs/mui'
import { useDisclosure } from '@builddocs/system'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { format, formatDistanceToNow } from 'date-fns'
import { ru } from 'date-fns/locale'

type Props = {
  actId: string
}

export const CommentsDialog = ({ actId }: Props) => {
  const { onClose, onOpen, isOpen } = useDisclosure()
  const [comments, setComments] = useState<CommentItem[]>()

  useEffect(() => {
    const getComments = async () => {
      if (actId) {
        const result = await commentsLogService.getListByExternalEntityId(actId)

        result && setComments(result)
      }
    }

    getComments()
  }, [actId])

  return comments ? (
    <>
      <Button size="small" variant="outlined" onClick={onOpen}>
        Журнал замечаний
      </Button>
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
        <DialogTitle>Комментарии</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Место в документах</TableCell>
                <TableCell>Автор</TableCell>
                <TableCell>Комментарий</TableCell>
                <TableCell>Статус</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {comments.map(comment => (
                <TableRow key={comment.commentId}>
                  <TableCell>
                    <Typography>{comment.information}</Typography>
                    <Stack direction="row" spacing={2}>
                      <Tooltip
                        placement="top"
                        title={format(
                          new Date(comment.createDateTime),
                          'dd MMM yyyy г. в HH:mm',
                          { locale: ru },
                        )}
                      >
                        <Typography color="gray.500">
                          {`${formatDistanceToNow(
                            new Date(comment.createDateTime),
                            {
                              locale: ru,
                              addSuffix: true,
                            },
                          )}, `}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <UserInfoBubble
                      key={comment.employmentId}
                      employmentId={comment.employmentId}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>{comment.problemDescription}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {comment.resolved ? 'Устранено' : 'Не устранено'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <Typography>Комментариев нет</Typography>
  )
}
