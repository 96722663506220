/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

/**
 * У каждой интеграции свой фиксированный адйишник
 */

export enum ExternalAppsNames {
  EXON = 'EXON',
  CYNTEKA = 'CYNTEKA',
  ULAB = 'ULAB',
}

export type IntegrationApp = keyof typeof ExternalAppsNames
