/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export function buildAddressString(address, placeholder = '—') {
  if (!address) return placeholder

  const parties = [
    {
      name: 'postalCode',
    },
    // {
    //   name: 'country',
    // },
    {
      name: 'region',
    },
    {
      name: 'city',
    },
    {
      name: 'street',
    },
    {
      name: 'house',
      convert: v => `д ${v}`,
    },
    {
      name: 'office',
    },
  ]

  return parties
    .filter(item => {
      if (item.name === 'region' && address.region === address.city)
        return false
      return address[item.name]
    })
    .map(item =>
      item.convert ? item.convert(address[item.name]) : address[item.name],
    )
    .join(', ')
}
export function buildAddressStringFromDaData(address, placehold = '—') {
  if (!address) return placehold

  const parties = [
    {
      name: 'postal_code',
    },
    // {
    //   name: 'country',
    // },
    {
      name: 'region_with_type',
    },
    {
      name: 'city_with_type',
    },
    {
      name: 'street_with_type',
    },
    {
      name: 'house',
      convert: v => `д ${v}`,
    },
    {
      name: 'flat',
    },
  ]

  return parties
    .filter(item => {
      if (
        item.name === 'region_with_type' &&
        address.region_with_type === address.city_with_type
      )
        return false
      return address[item.name]
    })
    .map(item =>
      item.convert ? item.convert(address[item.name]) : address[item.name],
    )
    .join(', ')
}
