import { StrictMode } from 'react'

import { FaceControlProvider } from '@builddocs/feature-faceid'
import { MuiProviderCrutch } from '@builddocs/mui'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import ruLocale from 'date-fns/locale/ru'
import { SnackbarProvider } from 'notistack'
import * as ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import App from './app/App'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <StrictMode>
    <SnackbarProvider>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <BrowserRouter>
            <MuiProviderCrutch>
              <FaceControlProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ruLocale}
                >
                  <App />
                </LocalizationProvider>
              </FaceControlProvider>
            </MuiProviderCrutch>
          </BrowserRouter>
        </RecoilRoot>
      </QueryClientProvider>
    </SnackbarProvider>
  </StrictMode>,
  document.getElementById('root'),
)
