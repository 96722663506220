/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useQuery, useQueryClient } from 'react-query'

import { getMappedWorks } from '@builddocs/data-management'
import { mappedWorks } from './techzor.types'


interface IProjectHook {
  isLoading: boolean
  mappedWorks: undefined | mappedWorks[]
  error?: unknown
  isError?: boolean
  refreshWorks: () => void
}

export const useMappedWorks = (contractId: string): IProjectHook => {
  const queryClient = useQueryClient()

  const {
    data: mappedWorks,
    isLoading,
    isError,
    error,
  } = useQuery(['mappedWorks', contractId], async () =>
    contractId ? await getMappedWorks(contractId) : undefined,
  )

  const refreshWorks = () =>
    queryClient.invalidateQueries(['mappedWorks', contractId])

  return {
    mappedWorks,
    isLoading,
    error,
    isError,
    refreshWorks,
  }
}

export default useMappedWorks
