/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useQuery, useQueryClient } from 'react-query'

import { getOnAcceptanceWorks } from '@builddocs/data-management'
import { acceptanceWork } from './techzor.types'


interface IProjectHook {
  isLoading: boolean
  acceptanceWorks: undefined | acceptanceWork[]
  error?: unknown
  isError?: boolean
  refreshWorks: () => void
}

export const useAcceptanceWorks = (contractId: string): IProjectHook => {
  const queryClient = useQueryClient()

  const {
    data: acceptanceWorks,
    isLoading,
    isError,
    error,
  } = useQuery(['acceptanceWorks', contractId], async () =>
    contractId ? await getOnAcceptanceWorks(contractId) : undefined,
  )

  const refreshWorks = () =>
    queryClient.invalidateQueries(['acceptanceWorks', contractId])

  return {
    acceptanceWorks,
    isLoading,
    error,
    isError,
    refreshWorks,
  }
}

export default useAcceptanceWorks
