/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useMemo } from 'react'

import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { getMyRightsFromContract } from '../../helpers'
import { AssigneeTypes, Roles, UUID } from '../../models'
import { useRouter } from '../../utils/'
import { useCurrentUser } from '../current-user/'

import { contractQuery, contractStateRequestID } from './contract.container'
import { Journal } from './journal'

export interface IContractHook {
  contract: Journal | undefined
  isLoading: boolean
  getRights: {
    canSignInvoices: boolean
    canSignSchedulers: boolean
    canSignContract: boolean
    isMy: boolean
    iAmAdmin: boolean

    canExecute: boolean
    canWriteInvoices: boolean
    canWriteSection4: boolean
    canWriteSection3: boolean
    canWriteSchedulers: boolean
    canSignGeneralJournal: boolean

    canCreateAct: boolean
    canSignAct: boolean
    canInspect: boolean
    canSendActToSigning: boolean
  }
  refreshContract: () => void
  parentJournalId: string | null
}

function isJournal(contract: unknown): contract is Journal {
  return (contract as Journal)?.journalId !== undefined
}

export const useContract = (contractId: UUID | null): IContractHook => {
  const contractLoadable = useRecoilValueLoadable(contractQuery(contractId))
  const setRequestID = useSetRecoilState(contractStateRequestID(contractId))
  const refreshContract = () => setRequestID(requestID => requestID + 1)

  const { query } = useRouter()
  const { user, role } = useCurrentUser()
  const { location } = useRouter()
  const search = new URLSearchParams(location.search)

  const parentJournalId = search.get('parentJournalId')

  const contract = isJournal(contractLoadable.contents)
    ? contractLoadable.contents
    : undefined

  const isLoading = contractLoadable.state === 'loading'

  const getRights = useMemo(() => {
    const {
      canExecute = false,
      canSign = false,
      canInspect = false,
      type = '',
    } = isJournal(contract)
      ? getMyRightsFromContract(contract, user?.employment.employmentId)
      : {}

    const iAmGod = query.godMode !== undefined
    const isMy = iAmGod || contract?.prorab?.userId === user?.userId // Я создатель договора
    const iAmAdmin =
      contract?.inn === user?.employment?.position?.inn && role === Roles.ADMIN // Я Админ договора

    const iAmDeveloper =
      query.developer !== undefined || type === AssigneeTypes.DEVELOPER //Лицо, осуществляющее строительство
    const iAmBuilder =
      query.builder !== undefined || type === AssigneeTypes.BUILDER // Организация, выполнившая строительство
    const iAmCustomer =
      query.customer !== undefined || type === AssigneeTypes.CUSTOMER // Иные организации

    return {
      iAmAdmin, // админ договора

      canSignContract: isMy || !!type, // Контракт подписывают все,
      // кто указан в договоре

      canInspect: canInspect ?? false, // Может проверять акты и закрытия
      canExecute: canExecute ?? false, // Может исполнять (редактировать?)
      canSignInvoices: iAmGod || Boolean(canSign), // не используется
      canSignSchedulers: iAmGod || Boolean(canSign), // не используется
      canSignGeneralJournal: iAmGod || Boolean(canSign), // не используется

      canWriteSchedulers: isMy || iAmDeveloper || iAmBuilder, // Может редактировать Наименования работ
      canWriteSection3: isMy || iAmDeveloper || iAmBuilder, // Может редактировать Работы
      canWriteSection4: isMy || iAmDeveloper || iAmCustomer || iAmBuilder, // не используется
      canWriteInvoices: isMy || iAmDeveloper || iAmBuilder, // Может редактировать накладные

      canCreateAct: canExecute, // не используется
      canSignAct: isMy || iAmDeveloper || iAmCustomer || iAmBuilder, // Может подписывать акт
      canSendActToSigning: canExecute, // может отправить на подписание
      isMy, // Я создатель договора
    }
  }, [
    contract,
    query.builder,
    query.customer,
    query.developer,
    query.godMode,
    role,
    user?.employment.employmentId,
    user?.employment?.position?.inn,
    user?.userId,
  ])
  //
  //  if (contractId && validateGuid(contractId)) {
  //    return ({
  //      contract,
  //      isLoading,
  //      getRights: {} as any,
  //      refetchContract: () => fetchContract(contractId)
  //    })
  //  }

  return {
    contract,
    isLoading,
    getRights,
    refreshContract,
    parentJournalId,
  }
}

export default useContract
