/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { ItdFlexibleStatus, ItdStatus } from './itdStatus'
import { UUID } from './models'

/**
 * По факту замечания отличаются от апи 2.9
 * Здесь используется фактическая модель
 */
export type UserExon = {
  /** ИНН организации пользователя, оставившего замечание */
  organisationInn: number
  lastName: string // Фамилия
  firstName: string // Имя
  middleName: string // Отчество
  position: string // Должность
}
export type Remarks = {
  /** Замечание, оставленное в ГИС */
  remarkId: string // Id замечания
  text: string // Текст замечания
  createdAt: string // Дата и время комментария
  user: UserExon
  /** Ответ на замечание, оставленный в ГИС */
  reply: [
    {
      text: string // Текст ответа
      createdAt: string // Дата и время ответа на замечание
      user: UserExon
    },
  ]
  version: number
}

export interface DocumentResponse {
  id: string
  status: ItdStatus // не используется
  documentStatus: ItdFlexibleStatus | null
  supportDocumentIds: string[]
  version: number
  remarksList: Remarks[]
  supportDocsComments: Record<string, string>
  supportDocsChanged: Record<string, string>
  /** Изменённый id файла. Формат PDF. Формируется при внесении изменений
   * через PDFTron
   */
  changedPDFFileId: string
  pdfFileId: string
  /** Комментарии/замечания, указанные в документе через PDFTron в виде
   *  строки XML */
  comments: string | null
  /** Признак, который определяет, требуется ли действие в ВИС */
  isAwaitingAttention: boolean
}

export interface DocumentForSetResponse {
  id: string // Id документа (акта), отправленного на согласование в комплекте
  pdfFileId: string // Id файла акта, отправленного на согласование в комплекте
  supportDocumentIds: string[] // Id файла приложения к акту, отправленного на согласование в комплекте
  version: number // Версия акта в комплекте
  supportDocsComments: {
    additionalProp1: string
    additionalProp2: string
    additionalProp3: string
  } // Комментарии к сопроводительной документации
  supportDocsChanged: {
    additionalProp1: string
    additionalProp2: string
    additionalProp3: string
  } // Измененные файлы сопроводительной документации
  status: ItdStatus // не используется
  documentStatus: ItdFlexibleStatus | null // Статус документа в «Гибких маршрутах»
  remarksList: Remarks[]
  changedPDFFileId: string // Измененный id файла. Формат PDF. Формируется при внесении изменений через PDFTron
  comments: string | null // Комментарии/замечания, указанные в документе через PDFTron в виде строки XML
}

export interface ReapprovalResponse {
  documentId: string
  file: string
  attachments: string[]
  section: string
  projectId: string
  actNum: string
  docType: string
  actDate: string
  actSignature: {
    fileId: string
    signs: Array<{
      signerName: string
      fileHash: string
    }>
  }
  attachmentsSignatures: Array<{
    fileId: string
    signs: Array<{
      signerName: string
      fileHash: string
    }>
  }>
}

export type ResponseProjectsType = {
  id: string // Уникальный ID проекта
  name: string // Наименование проекта
  shortName: string // Краткое наименование проекта
}

export interface WorkTypeExon {
  id: string // Id Типа работ
  name: string // Наименование типа работ
  organizationId: string // Id организации
  defaultType: boolean // true
  code: string // Обозначение модуля (ITD)
}

export type ResponseRepresentativeType = {
  userId: string // Уникальный идентификатор пользователя в ГИС
  fullName: string // ФИО пользователя
  position: string // Должность пользователя
  inn: string // ИНН пользователя
}

export type SendActToApproveRequest = {
  inn: string
  projectId: string
  actId: string
  actType: string
  workTypeId: string
  userRouteId: string
  userSignerId: string
}

export type SendActToApproveAgainRequest = {
  inn: string
  projectId: UUID
  actId: string
  actType: string
  documentId: string
  workTypeId: string
  userRouteId: string
  userSignerId: string
}

export type SendActToSignRequest = {
  inn: string
  documentId: string
  actId: string
  actType: string
  userSignerId: string
  userRouteId: string
}

export interface SendActResponse {
  id: string // Уникальный идентификатор документа, по которому производится отслеживание статуса
  documentStatus: ItdFlexibleStatus | null // Статус документа в «гибком маршруте».Если признак «isNewRoutesIntegration»: true, возможные статусы ItdStatusLabels
  pdfFileId: string // Id отправленного файла
  supportDocumentIds: string[] // Вложения.Идентификатор документа приложения, возможно наличие нескольких ID
  version: number // Версия текущего документа
}

export type SendSetActsRequest = {
  inn: string
  projectId: UUID
  closingId: UUID
  closingName: string
  workTypeId: string
  userRouteId: string
  userSignerId: string
}

export type SendSetActsAgainParams = {
  inn: string
  externalId: UUID
  closingId: UUID
  projectId: UUID
  userRouteId: string
  userSignerId: string
}

export type SendSetActsSignParams = {
  inn: string
  closingId: UUID
  userRouteId: string
}

export interface SendSetActsResponse {
  id: UUID // Id комплекта
  name: string // Наименование комплекта
  projectId: UUID // Id проекта
  remarks: Remarks[] // Замечания к комплекту, оставленные в нижней части процессной формы
  documents: DocumentForSetResponse[] //Список документов (актов), входящих в комплект
  itdStatus: ItdStatus // НЕ ИСПОЛЬЗУЕТСЯ
  version: number //Версия комплекта
  user: UserExon // Пользователь, оставивший замечание к комплекту
  documentStatus: ItdFlexibleStatus | null // Статус документа в «гибком маршруте» если признак «isNewRoutesIntegration»: true
  processStarted: boolean // Признак запуска процесса true/false
  isAwaitingAttention: boolean // Признак, который определяет, требуется ли действие в ВИС.
}
