/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { CamundaAPI } from '../CamundaAPI'

import type { Notification } from '../../camunda'
import type { PaginationModel } from '../../models'

/** Получение уведомлений с пагинацией*/
export const getNotifications = ({
  pageNumber,
  pageSize,
  unread,
  filters,
  sort,
  forWorkOnly,
}: {
  pageNumber?: number
  pageSize?: number
  unread?: boolean | null
  filters?: string
  sort?: string
  forWorkOnly?: boolean
}): Promise<PaginationModel<Notification> | null> =>
  CamundaAPI.get('/notification', {
    params: { pageNumber, pageSize, unread, filters, sort, forWorkOnly },
  }).then(res => res.data)

/** Получение количества непрочитанных уведомлений*/
export const getCountUnreadNotifications = (): Promise<number> =>
  CamundaAPI.get('/notification/unread').then(res => res.data)

/**Сделать уведомления непрочитанными*/
export const markNotificationsAsUnread = (ids: string[]): Promise<number> =>
  CamundaAPI.post('/notification/unread', ids)

/** Сделать уведомления прочитанными, отправляем массив их id*/
export const readNotifications = (readNotifications: string[] | undefined) =>
  CamundaAPI.post('/notification/read', readNotifications)

/** Удалить уведомления, отправляем массив их id*/
export const deleteNotifications = (ids: string[] | undefined) =>
  CamundaAPI.post('/notification/delete', ids)
