/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Application, ApplicationKeys } from './documentaryType'
import { FilestoreAPI } from './FilestoreAPI'

export const getCiphersList = (
  projectId: string,
  type: ApplicationKeys,
): Promise<string[]> => {
  switch (type) {
    case Application.EXECUTIVE_SCHEME:
      return FilestoreAPI.get(
        `/executivescheme/ciphers?projectId=${projectId}`,
      ).then(response => response.data ?? [])
    case Application.EXECUTIVE_DRAWING:
      return FilestoreAPI.get(
        `/executivedrawing/ciphers?projectId=${projectId}`,
      ).then(response => response.data ?? [])
    case Application.WORKING_DRAFT:
      return FilestoreAPI.get(
        `/workingdraft/ciphers?projectId=${projectId}`,
      ).then(response => response.data ?? [])
    default:
      return new Promise(resolve => {
        resolve([])
      })
  }
}
