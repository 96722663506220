/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React, { FC, useState } from 'react'

import PDFViewer from './PDFViewer'
import { FileViewerType } from './types'

type FileState = {
  url: FileViewerType
  fileName: string
  fileId?: string
  title?: string
  links?: Array<{
    id?: string
    link: string
    label: {
      primary: string
      secondary: string
    }
    hasIssue: boolean
  }>
}

type ViewerContextType = {
  setPDF: (file: FileState, isAdobe?: boolean, tools?: React.ReactNode) => void
  setAdditionalPreview: (file: FileState | undefined) => void
}

export const ViewerContext = React.createContext<ViewerContextType>({
  setPDF: () => null,
  setAdditionalPreview: () => null,
})

export const ViewerProvider: FC = ({ children }) => {
  const [previewFile, setPreviewFile] = useState<FileState>()
  const [additionalPreview, setAdditionalPreview] = useState<FileState>()
  const [toolsNode, setToolsNode] = useState<React.ReactNode>()

  const [isAdobeMode, setIsAdobeMode] = useState(false)

  const setPDF = (
    file: FileState,
    isAdobe = false,
    tools?: React.ReactNode,
  ) => {
    setPreviewFile(file)
    setToolsNode(tools)
    setIsAdobeMode(isAdobe)
  }

  const handleClose = () => {
    setPreviewFile(undefined)
    setAdditionalPreview(undefined)
  }

  return (
    <ViewerContext.Provider
      value={{
        setPDF,
        setAdditionalPreview: file => setAdditionalPreview(file),
      }}
    >
      <PDFViewer
        file={previewFile?.url}
        forcePreview={additionalPreview}
        isAdobe={isAdobeMode}
        links={previewFile?.links}
        title={previewFile?.fileName ?? 'Без имени'}
        tools={toolsNode}
        onClose={handleClose}
      />
      {children}
    </ViewerContext.Provider>
  )
}

export default ViewerProvider
