/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Unit, UnitEnum } from './unit.constants'

export const UnitLabel: Record<Unit, string> = {
  PIECES: 'шт.',
  KG: 'кг.',
  M: 'м', // метр
  [UnitEnum.M_2]: 'м²', // метр^2
  [UnitEnum.M_3]: 'м³', // метр^3
  KG_M2: 'кг/м²', // кг/м2
  KG_M3: 'кг/м³', // кг/м3
  TON: 'т', // тонна
  HA: 'га', // гектар
  /** Ниже юниты для совместимости с Синтекой */
  COIL: 'бух.', // бухта
  KM: 'км', // километр
  PAIR: 'пар', // пара
  SHEET: 'лист', // лист
  ML: 'мм', // миллилитр
  PIECES_10: 'десяток штук', // десять штук
  PIECES_100: 'сотен штук', // сто штук
  PIECES_1000: 'тысяч штук', // тычяча штук
  KIT: 'компл.', // комплект
  M_100: 'сотен м', // 100 м
  M_10: 'десятков м', // 10 м
  GR: 'гр', // грамм
  CONDITIONAL_UNIT_1000: 'тыс. у.е.', // тысяча условных единиц
  ROLL: 'рул.', // рулон
  PACKAGING: 'уп.', // упаковка
  LM: 'пог. м.', // метр погонный
  CM: 'см', // сантиметр
  LITER: 'л', // литр
}
