/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import axios from 'axios'

const url = 'https://builddocs.online/dadata/findById/party'
const token = process.env.NX_DADATA_API_KEY ?? ''

export const searchOrganizationByInn = async (inn: string) => {
  const { data } = await axios.post(
    url,
    { query: inn },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + token,
      },
    },
  )

  return data.suggestions ? data.suggestions[0] : null
}
