/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { UUID } from '@builddocs/data-management'
import qs from 'qs'

import { LoadableApplicationKeys } from '../documentaryType'
import { FilestoreAPI } from '../FilestoreAPI'

import { BaseDocument, WorkingDraftPage } from './applications.interfaces'
import { getApplicationUrlByType } from './getApplicationsUrlByType'

interface DPKParams {
  documentType: LoadableApplicationKeys
  projectId: string
  pageNumber?: number
  pageSize?: number
  cipher?: string
  searchValue?: string
  sortParams?: {
    sortField: string
    sortDirection: 'DESC' | 'ASC'
  }
}

/** Получение документа по Id*/
export const getDocumentById = ({
  documentType,
  documentId,
}: {
  documentType: LoadableApplicationKeys
  documentId: UUID
}): Promise<BaseDocument> =>
  FilestoreAPI.get(
    `${getApplicationUrlByType[documentType]}/${documentId}`,
  ).then(res => res.data)

/** Обновление номеров листов документа*/
export const updateNumbersSheetsDocument = ({
  documentType,
  documentId,
  data,
}: {
  documentType: LoadableApplicationKeys
  documentId: UUID
  data: Record<number, string>
}) =>
  FilestoreAPI.put(
    `${getApplicationUrlByType[documentType]}/${documentId}/pageCorrespondence`,
    data,
  )

export const getDocumentsOriginal = ({
  documentType,
  projectId,
  pageNumber = 0,
  pageSize,
  cipher,
  searchValue,
  sortParams = {
    sortField: 'creationDate',
    sortDirection: 'DESC',
  },
}: DPKParams) =>
  FilestoreAPI.get(getApplicationUrlByType[documentType], {
    params: {
      projectId,
      pageNumber,
      pageSize,
      cipher,
      searchValue: searchValue?.length
        ? encodeURIComponent(searchValue as string)
        : searchValue,
      sortField: sortParams?.sortField,
      sortDirection: sortParams?.sortDirection,
    },
    paramsSerializer: params => qs.stringify(params, { encode: false }),
  }).then(res => res.data)

export const getWorkDocSectionsForExecutiveScheme = (journalId: string) =>
  FilestoreAPI.get(`/acts-aosr/workdocsections?journalId=${journalId}`).then(
    res => res.data,
  )

export const getWorkTypesForExecutiveScheme = (
  journalId: UUID,
  workDocSection: string,
) =>
  FilestoreAPI.post(`/acts-aosr/worktypes`, { journalId, workDocSection }).then(
    res => res.data,
  )

/** Получение документов Исполнительные чертежи*/
export const getExecutiveDrawing = ({
  projectId,
  pageNumber = 0,
  pageSize = 8,
  cipher,
  searchValue,
  sortParams = {
    sortField: 'creationDate',
    sortDirection: 'DESC',
  },
}: DPKParams) =>
  FilestoreAPI.post('/executivedrawing/search', {
    projectId,
    pageNumber,
    pageSize,
    cipher,
    searchValue,
    sortField: sortParams?.sortField,
    direction: sortParams?.sortDirection,
  }).then(res => res.data)

/** Получение Рабочего проекта по id документа*/
export const getWorkingDraft = (workingDraftId: UUID) =>
  FilestoreAPI.get(`/workingdraft/${workingDraftId}`).then(res => res.data)

/** Получение листов Рабочей Документации по id документа*/
export const getSheetsWorkingDraft = (
  workingDraftId: UUID,
): Promise<WorkingDraftPage[]> =>
  FilestoreAPI.get(`/workingdraft/${workingDraftId}/pages`).then(
    res => res.data,
  )

/** Получение листов Рабочей Документации по id документа*/
export const getApplicationsTitlesByExternalId = (
  externalIds: string[],
): Promise<
  Record<
    string,
    {
      id: string
      name: string
      issueDate: string | null
      number: string | null
    }
  >
> =>
  externalIds.length > 0
    ? FilestoreAPI.post(`/document-search/titles`, {
        ids: externalIds,
        integrationCompany: 'EXON',
      }).then(res => res.data)
    : new Promise(resolve => resolve({}))
