/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import {
  Certificate,
  ExecutiveDrawing,
  ExecutiveScheme,
  getApplicationUrlByType,
  Laboratory,
  Normative,
  Permits,
  WorkingDraft,
} from './applications'
import { docsQuery, docsQueryRequestIDState } from './docs.container'

export type DocsHook<T> = {
  docs: T[]
  pageCount: number
  pageNumber?: number
  pageSize?: number
  refreshDocs: () => void
  isLoading: boolean
}

export const useDocumentary = (props: {
  documentType: keyof typeof getApplicationUrlByType
  pageNumber: number
  pageSize?: number
  projectId: string
  cipher?: string
  searchValue?: string
  sortParams?: {
    sortField: string
    sortDirection: 'DESC' | 'ASC'
  }
}): DocsHook<
  | Permits
  | WorkingDraft
  | ExecutiveScheme
  | ExecutiveDrawing
  | Certificate
  | Laboratory
  | Normative
> => {
  const {
    pageSize = 8,
    pageNumber,
    documentType,
    projectId,
    cipher,
    searchValue,
    sortParams,
  } = props

  const docsLoadable = useRecoilValueLoadable(
    docsQuery({
      documentType,
      projectId,
      pageNumber,
      pageSize,
      cipher,
      searchValue,
      sortParams,
    }),
  )

  const setRequestID = useSetRecoilState(
    docsQueryRequestIDState({
      documentType,
      projectId,
    }),
  )
  const documents =
    docsLoadable.state === `hasValue`
      ? docsLoadable.contents
      : {
          entityList: [],
          pageNumber: 0,
          page: 0,
          pageCount: 0,
          pageSize: 0,
        }

  const fetchIsLoading = docsLoadable.state === `loading`

  const refreshDocs = () => setRequestID(requestID => requestID + 1)

  return {
    docs: documents?.entityList ?? [],
    pageNumber: documents?.pageNumber,
    pageCount: documents.pageCount,
    pageSize: documents.pageSize,
    refreshDocs,
    isLoading: fetchIsLoading,
  }
}
