import { useState } from 'react'

import { useLogOut, useRouter, useTestAuth } from '@builddocs/data-management'
import {
  ExternalSigningApplication,
  ExternalSigningAct,
  externalSigningData,
  useExternal,
  useLoginExternal,
} from '@builddocs/ext-sign-data-access'
import { LinearProgress, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useRecoilValue } from 'recoil'

import { ErrorContainer } from './ErrorContainer'
import ExtSignAct from './ExtSignAct'
import ExtSignApplication from './ExtSignApplication'
import { ExtSignHeader } from './ExtSignHeader'
import { LogoutRequestDialog } from './LogoutRequestDialog'

export const ExtSignMain = () => {
  const [isLogoutOpen, setIsLogoutOpen] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const [isLoadingDocument, setIsLoadingDocument] = useState(false)

  const { query, location } = useRouter()

  const { isExternalLoading, getExternal } = useExternal()

  const externalSigningValue = useRecoilValue(externalSigningData)

  /**
   * после логина получаем данные внешного подписания
   */
  const onLogin = () => {
    setIsLogged(true)
    query.signId && getExternal(query.signId)
  }

  /**
   * при смене пользователя сразу логиним пользователя из ссылки
   */
  const onLogout = () => {
    query.signId && loginExternal && loginExternal(query.signId)
  }
  const { signOut } = useLogOut({
    loginUrl: `${location.pathname}${location.search}`,
    onLogout,
  })

  const {
    loginExternal,
    isLoading: isLoginLoading,
    isFail,
  } = useLoginExternal({ onLogin })

  /**
   * если кто-то был залогинен до этого, открываем диалоговое окно с вопросом, под какой учёткой продолжить
   */
  const onTestAuthSuccess = () => {
    setIsLogged(true)
    setIsLogoutOpen(true)
  }

  /**
   * если никто не был залогинен, то просто логиним его
   */
  const onTestAuthFailure = () => {
    query.signId && loginExternal && loginExternal(query.signId)
  }

  /**
   * При старте проверяем, залогинен ли кто-то до этого
   */
  useTestAuth({
    onSuccess: onTestAuthSuccess,
    onFailure: onTestAuthFailure,
  })

  /**
   * если пользователь решил продолжить не под тем логином, который был до того, как он открыл ссылку, разлогиним предыдщего пользоватлея и логиним его логином из ссылки
   */
  const logoutHandler = () => {
    setIsLogged(false)
    signOut()
    setIsLogoutOpen(false)
  }

  /**
   * если решил продолжить под предыдущим пользователем, получаем внешнее подписание
   */
  const continueHandler = () => {
    setIsLogoutOpen(false)
    query.signId && getExternal(query.signId)
  }
  const isActSigning =
    externalSigningValue?.signType === 'ACT' && !isLoginLoading && !isFail
  const isApplicationSigning =
    externalSigningValue?.signType === 'EXECUTIVE_SCHEME' &&
    !isLoginLoading &&
    !isFail

  if (!query.signId) return <ErrorContainer massage="Ссылка некорректна" />

  const isAppLoading = isLoginLoading || isExternalLoading || isLoadingDocument

  return isLogged ? (
    <Stack height="97vh">
      <ExtSignHeader
        type={
          (isActSigning && isLogged && 'ACT') ||
          (isApplicationSigning && isLogged && 'APPLICATION') ||
          false
        }
      />
      {isAppLoading && <LinearProgress />}

      {isActSigning && (
        <ExtSignAct
          externalSigningValue={externalSigningValue as ExternalSigningAct}
          onLoad={state => setIsLoadingDocument(state)}
        />
      )}
      {isApplicationSigning && (
        <ExtSignApplication
          externalSigningValue={
            externalSigningValue as ExternalSigningApplication
          }
          onLoad={state => setIsLoadingDocument(state)}
        />
      )}
      {isLogoutOpen && (
        <LogoutRequestDialog
          isOpen={isLogoutOpen}
          logoutHandler={logoutHandler}
          onContinue={continueHandler}
        />
      )}
    </Stack>
  ) : isFail ? (
    <ErrorContainer massage="Возникли проблемы с верификацией" />
  ) : (
    <Box>
      <Typography>Проверка пользователя...</Typography>
      <LinearProgress />
    </Box>
  )
}
