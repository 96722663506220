export enum Application {
  ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
  CERTIFICATE = 'CERTIFICATE',
  PERMIT = 'PERMIT',
  EXECUTIVE_SCHEME = 'EXECUTIVE_SCHEME',
  EXECUTIVE_DRAWING = 'EXECUTIVE_DRAWING',
  WORKING_DRAFT = 'WORKING_DRAFT',
  WORKING_DRAWING = 'WORKING_DRAWING',
  LABORATORY = 'LABORATORY',
  LAB = 'LAB',
  LABORATORY_DATA = 'LABORATORY_DATA',
  NORMATIVE = 'NORMATIVE',
  NORMATIVE_DOC = 'NORMATIVE_DOC',
  NORMATIVE_ADDITIONAL_DOCUMENT = 'NORMATIVE_ADDITIONAL_DOCUMENT',
  REGISTRY = 'REGISTRY',
  MATERIALS_REGISTRY = 'MATERIALS_REGISTRY',
  SUPPORTING = 'SUPPORTING',
}
