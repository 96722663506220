/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 *  Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 *  You may use, distribute and modify this code under the
 *  terms of the ООО АДАПТ license
 */

import { useCallback, useEffect } from 'react'

import _ from 'lodash'
import { useQuery } from 'react-query'
import { useRecoilState } from 'recoil'

import routes from '../../routes'
import { useRouter } from '../../utils/useRouter'
import { contractsService } from '../contracts'
import { useCurrentUser } from '../current-user'

import { currentSakuraPath, currentSakuraPosition } from './TreeProject.state'

import type { UUID } from '../../models'
import type { ITreeNode, ITreeProjectHook } from './sakura.types'

export const useTreeProject = (
  projectId: UUID,
  contractId?: UUID,
  isExpanded?: boolean,
): ITreeProjectHook => {
  const { user } = useCurrentUser()
  const { navigate } = useRouter()
  const [currentHierarchyPosition, setCurrentHierarchyPosition] =
    useRecoilState(currentSakuraPosition({ projectId, userId: user.userId }))
  const [currentPath, setCurrentPath] = useRecoilState(
    currentSakuraPath({ projectId, userId: user.userId }),
  )

  const {
    data: projectTree,
    isLoading,
    error,
    refetch: refreshProject,
  } = useQuery(
    ['projectTree', projectId],
    async () => await contractsService.getProjectTree(projectId),
    {
      enabled: isExpanded === undefined ? true : isExpanded,
      keepPreviousData: true,
      staleTime: 1000 * 15 * 60,
    },
  )

  const parentProject = projectTree?.project

  /** Поиск текущего договора по древу */
  const getPathAndChildren = useCallback(
    (treeLayer: ITreeNode[] | undefined, path = []) => {
      if (!contractId || !treeLayer) return

      for (const i in treeLayer) {
        const contract = treeLayer[i]

        if (contract?.self?.journalId === contractId) {
          setCurrentHierarchyPosition(prevPos => ({
            path: [
              ...(prevPos?.path ?? []),
              {
                title: contract?.self?.title,
                contractId: contract?.self?.journalId,
                key: i,
              },
            ],
            children: contract.children,
            currentContract: contract.self,
          }))

          setCurrentPath([
            ...path,
            {
              title: contract?.self?.title,
              contractId: contract?.self?.journalId,
            },
          ])
          break
        } else if (contract?.children) {
          getPathAndChildren(contract.children, [
            ...path,
            {
              title: contract?.self?.title,
              contractId: contract?.self?.journalId,
              key: i,
            },
          ])
        }
      }
    },
    [contractId, setCurrentHierarchyPosition, setCurrentPath],
  )

  useEffect(() => {
    getPathAndChildren(projectTree?.journalNodes)
  }, [getPathAndChildren, projectTree])

  const handleUp: ITreeProjectHook['handleUp'] = ({ onSuccess }) => {
    if (currentPath.length === 1)
      navigate(routes.navigator.project(parentProject.projectId))
    else {
      const newPath = currentPath?.slice(0, -1)

      const contract = _.get(
        projectTree.journalNodes,
        newPath.reduce(
          (accumulator, currentValue, currentIndex, array) =>
            array.length - 1 !== currentIndex
              ? [...accumulator, currentValue.key, 'children']
              : [...accumulator, currentValue.key],
          [],
        ),
      )

      setCurrentHierarchyPosition(() => {
        return {
          path: newPath,
          children: contract?.children,
          currentContract: contract?.self,
        }
      })

      setCurrentPath(newPath)

      onSuccess(contract.self.contractId)
    }
  }

  return {
    parentProject,
    projectTree,
    currentPath,
    contractHierarchy: currentHierarchyPosition,
    handleUp,
    isLoading,
    error,
    refreshProject,
  }
}

export default useTreeProject
