/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { UUID } from '../models'
import { RestAPI } from '../rest/RestAPI'

/**
 * Получение blob аватарки юзера по userId
 * @param {UUID} uuid
 * @returns {`/api/users/avatar/${string}`}
 */
export const getAvatarSource = (uuid: UUID | undefined): Promise<string> => {
  if (!uuid) return new Promise<string>(resolve => resolve(''))

  return RestAPI.get(`/users/avatar/${uuid}`, { responseType: 'blob' })
    .then(response => URL.createObjectURL(response.data))
    .catch(() => '')
}

/**
 * Получение ссылки на аватарку юзера по userId
 * @param {UUID} uuid
 * @deprecated
 * @returns {`/api/users/avatar/${string}`}
 */
export const getAvatarUrl = (uuid: UUID) => `/api/users/avatar/${uuid}`
