import type { UUID } from '../../models'
import type { Journal } from '../contracts'
import type { Project } from './project.types'

export interface ITreeNode {
  self: Journal
  children: ITreeNode[]
}

export interface IContractHierarchy {
  path: { contractId: UUID; title: string }[]
  children: ITreeNode[]
  currentContract: Journal
}

export interface ITreeProjectHook {
  parentProject: Project
  currentPath: { contractId: UUID; title: string }[]
  handleUp: (params: { onSuccess: (contractId: UUID) => void }) => void
  projectTree: { journalNodes: ITreeNode[]; project: Project } | undefined
  contractHierarchy: IContractHierarchy | undefined
  isLoading: boolean
  error?: unknown
  refreshProject: () => void
}
