import React from 'react'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import {
  usePopupState,
  bindToggle,
  bindPopover,
} from 'material-ui-popup-state/hooks'

export interface HelpBubbleProps {
  title: string
  description: string
  kind?: 'info' | 'advice'
  width?: number
  component?: React.ReactNode
}

export function HelpBubble(props: HelpBubbleProps) {
  const {
    title,
    description,
    kind = 'advice',
    width = 240,
    component = null,
    ...rest
  } = props

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'adviceBubble',
  })

  return (
    <>
      <IconButton {...bindToggle(popupState)} {...rest}>
        {kind === 'info' ? <InfoOutlinedIcon /> : <HelpOutlineIcon />}
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper sx={{ p: 2, width }}>
          <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Typography>{description}</Typography>
          {component}
        </Paper>
      </Popover>
    </>
  )
}

export default HelpBubble
