/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React from 'react'

import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import ReactDadataBox, { AddressResponseType } from 'react-dadata-box'

/**
 * Скопировано из react-dadata-box
 */
interface BaseInputProps<T = HTMLInputElement> {
  autocomplete: boolean | 'off'
  className?: string
  onBlur: React.FocusEventHandler<T>
  onChange: React.ChangeEventHandler<T>
  onFocus: React.FocusEventHandler<T>
  onKeyDown: React.KeyboardEventHandler<T>
  placeholder: string
  value: string
}

const SearchInput = (props: BaseInputProps) => (
  <TextField
    fullWidth
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    size="small"
    {...props}
  />
)

type Props = {
  token: string
  onSelect: (value: AddressResponseType) => void
}

export const InnInput = ({ onSelect, token }: Props) => {
  const handleChange = (response: AddressResponseType) => {
    if (response && response.value) {
      onSelect(response)
    }
  }

  return (
    <ReactDadataBox
      // token="6329ff45b63e11199c8e2eb7b094cb29663906c6"
      className="hello"
      customEndpoint={{
        host: 'https://builddocs.online',
        api: 'dadata/suggest',
      }}
      customInput={({ className, ...props }) => <SearchInput {...props} />}
      placeholder="Введите название, ИНН или адрес"
      token={token}
      type="party"
      onChange={handleChange}
    />
  )
}

export default InnInput
