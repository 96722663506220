import { UUID, getAssigneesProject } from '@builddocs/data-management'
import { atom, selectorFamily } from 'recoil'

import { AssigneesProjectResponseType } from './useAssigneesProject'

export const assigneesProjectQueryRequestIDState = atom({
  key: 'AssigneesProjectQueryRequestID',
  default: 0,
})

export const assigneesProjectQuery = selectorFamily<
  AssigneesProjectResponseType[],
  { projectId: UUID } | undefined
>({
  key: 'AssigneesProjectQuery',
  get:
    props =>
    async ({ get }) => {
      get(assigneesProjectQueryRequestIDState)
      return props?.projectId
        ? await getAssigneesProject(props.projectId)
        : undefined
    },
})
