import { OsnovaAPI } from '../OsnovaAPI'

import { ActItem, ActKsEnum, ActKsInfo } from './ks.model'

export const getKSList = async (
  journalId: string,
  ksName: ActKsEnum,
  page: number,
  perPage?: number,
) => {
  const res = await OsnovaAPI.get(`/${journalId}/${ksName}/list`, {
    params: { page, perPage },
  })

  return res.data || []
}

export const getKSData = async (data: ActKsInfo[]): Promise<ActItem[]> => {
  const res = await OsnovaAPI.post('/full-list-by-ids', data)

  return res.data || []
}
