/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */
import { UUID } from '../../models'
import { RestAPI } from '../RestAPI'

export const createCommentToSectionFour = (journalID: UUID, data: any) =>
  RestAPI.post('/section_four', {
    journalId: journalID,
    ...data,
  })
