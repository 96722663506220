import { ApprovalState, SignatureState } from '@builddocs/data-management'
import { Chip } from '@mui/material'

type OwnProps = {
  status: ApprovalState | SignatureState
  size?: 'small' | 'medium'
  variant?: 'filled' | 'outlined'
}

const getStatus: Record<
  ApprovalState | SignatureState,
  {
    color:
      | 'default'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning'
      | 'rejected'
      | 'processing'
      | 'newable'
      | 'accepted'
    label: string
  }
> = {
  [ApprovalState.NEW]: { color: 'newable', label: 'Новое' },
  [ApprovalState.RECREATED]: { color: 'newable', label: 'Обновлено' },
  [ApprovalState.APPROVAL]: { color: 'processing', label: 'На согласовании' },
  [ApprovalState.APPROVED]: { color: 'processing', label: 'Согласовано' },
  [ApprovalState.SIGNING]: { color: 'processing', label: 'На подписании' },
  [ApprovalState.SIGNED]: { color: 'accepted', label: 'Подписано' },
  [ApprovalState.REJECTED]: { color: 'rejected', label: 'Отклонено' },
  [ApprovalState.NADZORING]: { color: 'default', label: 'На проверке' },
  [ApprovalState.NADZOR_ACCEPTED]: {
    color: 'default',
    label: 'Проверка пройдена',
  },
  [SignatureState.UNDEFINED]: { color: 'default', label: 'Статус неизвестен' },
  [SignatureState.PENDING]: { color: 'newable', label: 'Ожидает действия' },
}

export const StatusChip = ({ status, size, variant }: OwnProps) => {
  return (
    <Chip
      color={getStatus[status].color}
      label={getStatus[status].label}
      size={size}
      variant={variant}
    />
  )
}

export default StatusChip
