import { useEffect, useMemo } from 'react'

import { useCoordinator, WorkDocSection } from '@builddocs/data-management'
import { useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { workDocSectionAtom } from '../workDocSection.container'

export const useWorkDocSection = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const workDocSectionFromURL = useMemo(
    () => searchParams.get('workDocSection'),
    [searchParams],
  )
  const { contractId } = useCoordinator()

  const [selectedWorkDocSection, setSelectedWorkDocSection] = useRecoilState(
    workDocSectionAtom(contractId ?? ''),
  )

  useEffect(() => {
    if (!workDocSectionFromURL) {
      searchParams.set(
        'workDocSection',
        selectedWorkDocSection?.workDocSection ?? '',
      )

      setSearchParams(searchParams)
    }
    if (
      workDocSectionFromURL &&
      selectedWorkDocSection?.workDocSection !== workDocSectionFromURL
    ) {
      setSelectedWorkDocSection(prev =>
        prev
          ? {
              ...prev,
              workDocSection: workDocSectionFromURL,
            }
          : ({
              workDocSection: workDocSectionFromURL,
            } as WorkDocSection),
      )
    }
  }, [
    searchParams,
    selectedWorkDocSection?.workDocSection,
    setSearchParams,
    setSelectedWorkDocSection,
    workDocSectionFromURL,
  ])
}
