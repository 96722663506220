import { SignatureState } from '@builddocs/data-management'
import { downloadFile } from '@builddocs/ext-sign-data-access'
import { AOSRType, SecondaryActType } from '@builddocs/filestore-api'
import { DeviationDialog } from '@builddocs/mui'
import CheckIcon from '@mui/icons-material/Check'
import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'

type ActionButtons = {
  userSignStatus: SignatureState
  act: AOSRType | SecondaryActType
  handleDeviate: (data: any) => void
  handleSign: () => void
  isLoading: boolean
  contractId: string | undefined
}

export const ActionButtons = ({
  act,
  userSignStatus,
  handleDeviate,
  handleSign,
  isLoading,
}: ActionButtons) => {
  if (userSignStatus === 'REJECTED')
    return <Typography color="error.main">Отклонено</Typography>

  if (userSignStatus === 'SIGNED')
    return (
      <>
        <Button
          size="small"
          variant="outlined"
          onClick={() => downloadFile(act.documentUrl, act.fileName)}
        >
          Скачать документ
        </Button>
        <Stack direction="row">
          <CheckIcon color="success" />
          <Typography color="success.main">Подписано</Typography>
        </Stack>
      </>
    )

  return (
    <>
      <DeviationDialog
        additionalInfo={{ id: act.id }}
        documentType={act.actType}
        title="Записать отклонение"
        onSubmit={handleDeviate}
      />
      <LoadingButton
        loading={isLoading}
        size="small"
        variant="contained"
        onClick={handleSign}
      >
        Подписать
      </LoadingButton>
    </>
  )
}
