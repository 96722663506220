/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useEffect, useState } from 'react'

import { useToast } from '@builddocs/system'
import { useRecoilValue } from 'recoil'

import { NewUser, Roles, User } from '../../models'

import { useRefreshCurrentUser, currentUserQuery } from './User.container'
import {
  updateCurrentUserPassword,
  updateCurrentUserProfile,
} from './user.service'

interface IUserHook<T> {
  user: T
  role: Roles
  isLoading: boolean
  refreshCurrentUser: () => void
  update: (
    user: Omit<User, 'employment' | 'role' | 'createDateTime'>,
    onSuccess?: (
      profile: Omit<User, 'employment' | 'role' | 'createDateTime'>,
    ) => void,
  ) => void
  updatePassword: (passwords: {
    oldPassword: string
    newPassword: string
  }) => void
}

export function useCurrentUser<T extends NewUser = User>(): IUserHook<T> {
  const currentUserInfo = useRecoilValue(currentUserQuery)
  const { toast } = useToast()

  const refreshCurrentUser = useRefreshCurrentUser()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(!currentUserInfo?.userId)
    !currentUserInfo?.userId && refreshCurrentUser()
  }, [currentUserInfo, refreshCurrentUser])

  const update: IUserHook<T>['update'] = async (newProfile, onSuccess?) => {
    try {
      await updateCurrentUserProfile(newProfile)
      await refreshCurrentUser()
      onSuccess && onSuccess(newProfile)
      toast.success('Профиль обновлён')
    } catch (err) {
      toast.error('Не удалось обновить профиль')
    }
  }

  const updatePassword = async (newValue: {
    oldPassword: string
    newPassword: string
  }) => {
    try {
      await updateCurrentUserPassword(newValue)
      toast.success('Пароль обновлён')
    } catch (err) {
      toast.error('Не удалось обновить пароль')
    }
  }

  return {
    user: currentUserInfo as T,
    role: currentUserInfo?.role.roleId,
    isLoading,
    refreshCurrentUser,
    update,
    updatePassword,
  }
}
