/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { ActEventFlow } from './ActEventFlow.constant'

import type { ApprovalState, WorkType } from '../../../constants'
import type { UUID } from '../../../models'

export interface ActEvent {
  actDate: string
  actLocationUrl: string
  actDocumentUrl: string
  actAbbreviation: string
  actEventFlow: ActEventFlow
  businessKey: string
  /** Акт в закрытии */
  closed: boolean
  description: string
  endDate: string
  eventId: UUID
  generatorId: UUID
  journalId: UUID
  /** номер акта */
  actNumber: string
  startDate: string
  status: string
  Enum: ApprovalState
  targetId: UUID[]
  journalTitle: string
  viewed: boolean
  workDocSection: string
  workType: WorkType
  projectId: string
  /**
   * актуальный статус акта
   */
  actualState: 'FORMED' | 'IN_CLOSING' | 'DELETED' | null
  closingId: string | null
}
