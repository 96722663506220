import { UUID } from '@builddocs/data-management'
import { useQuery, useQueryClient } from 'react-query'

import { getClosingById } from './closing.service'
import { ResponseClosingType } from './closings'

interface ClosingProps {
  closingId: UUID | undefined
}

interface IClosingHookResponse {
  closing: ResponseClosingType | undefined
  refreshClosing: () => Promise<void>
  updateClosingLocal: (nextData: Record<string, any>) => void
  isLoading: boolean
  isFetching: boolean
  error?: unknown
  isError?: boolean
}

export const useClosing: (props: ClosingProps) => IClosingHookResponse =
  props => {
    const { closingId } = props

    const queryClient = useQueryClient()

    const queryKey = ['closing', closingId]
    const { data, isLoading, isFetching, isError, error } =
      useQuery<ResponseClosingType>(
        queryKey,
        async () =>
          closingId
            ? await getClosingById(closingId)
            : ({} as ResponseClosingType),
        { keepPreviousData: true },
      )

    const refreshClosing = () => queryClient.invalidateQueries(queryKey)

    const updateClosingLocal = (nextData: Record<string, any>) => {
      if (data) {
        queryClient.setQueryData(queryKey, () => ({ ...data, ...nextData }))
      }
    }

    return {
      closing: data,
      isLoading,
      isFetching,
      error,
      isError,
      refreshClosing,
      updateClosingLocal,
    }
  }
