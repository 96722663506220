import { atom, selectorFamily } from 'recoil'

import { getTemplatesStamps, getTemplateStamp } from './filestore.stamp.service'
import { TemplateStampType } from './stamp.types'

export const stampsQueryRequestIDState = atom({
  key: 'StampsQueryRequestID',
  default: 0,
})

export const stampsQuery = selectorFamily<
  {
    entityList: TemplateStampType[]
    pageCount: number
    pageNumber: number
    pageSize: number
  },
  { page: number; size: number }
>({
  key: 'StampsQuery',
  get:
    ({ page, size }) =>
    async ({ get }) => {
      get(stampsQueryRequestIDState)
      return await getTemplatesStamps(page, size)
    },
})

export const stampQuery = selectorFamily<
  {
    id: string
    name: string
    pngFile: string
  },
  { stampId?: string }
>({
  key: 'StampQuery',
  get:
    ({ stampId }) =>
    async ({ get }) => {
      get(stampsQueryRequestIDState)
      return stampId ? await getTemplateStamp(stampId) : undefined
    },
})
