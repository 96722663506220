/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { deepPurple, grey, orange, pink } from '@mui/material/colors'

export const CHART_COLORS = {
  colors: {
    1: deepPurple[300],
    2: pink[300],
    3: orange['A200'],
  },
  grey: grey[100],
}

export type ChartColors = keyof typeof CHART_COLORS.colors | 'grey'

export const BRANDING_COLOR = '#0D6797'
export const PRIMARY_COLOR = '#0034AD'
export const SECONDARY_COLOR = '#8A1C7C'
export const THIRTY_COLOR = '#f1a66a'

export const cadetGrey = {
  50: '#e6f5fd',
  100: '#cfdce1',
  200: '#b6c3c8',
  300: '#9caab0',
  400: '#819298',
  500: '#67787e',
  600: '#4e5e63',
  700: '#364447',
  800: '#1d292d',
  900: '#000f16',
}
export const romanSilver = {
  10: '#f5f9fa',
  50: '#e9f3fe',
  100: '#d2dae1',
  200: '#b8bfc7',
  300: '#9ea5ae',
  400: '#838c95',
  500: '#6a727c',
  600: '#515961',
  700: '#3a3f47',
  800: '#20262d',
  900: '#020e17',
}
export const richGrey = {
  10: '#f4f6fa',
  50: '#edf1f7',
  100: '#cdd4e2',
  200: '#abb7cf',
  300: '#8a9bbe',
  400: '#687ead',
  500: '#506495',
  600: '#3e4e73',
  700: '#2d3852',
  800: '#1b2131',
  900: '#080b12',
}
