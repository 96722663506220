/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export const required = (value: string) =>
  value && value.trim() ? undefined : 'Обязательное поле'
export const requiredText = 'Обязательное поле'

export const minLength = (min: number) => (value: string) => {
  if (!value) {
    return `Минимальная длина ${min} символов`
  }
  return value.trim().length < min
    ? `Минимальная длина ${min} символов`
    : undefined
}

export const maxLength = (max: number) => (value: string) => {
  if (!value) {
    return undefined
  }
  return value.trim().length > max
    ? `Максимальная длина ${max} символов`
    : undefined
}

export const url = (value: string) =>
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/gi.test(
    value,
  ) || 'Введите корректный URL'

export const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i

export const email = (value: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ||
  'Введите корректный email'

export const phone = (value: string) =>
  /^\+?[0-9]{1,3}[0-9]{8,10}$/i.test(value.replace(/[-()\s]/gi, '')) ||
  'Введите корректный телефон'

export const phoneAndEmailRegExp =
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$|^\+[0-9]{1,3}[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i

export const phoneAndEmail = (value: string) =>
  phoneAndEmailRegExp.test(value) || 'Введите корректный email или телефон'

export const username = (value: string) =>
  /^[A-Za-z0-9_.-]{2,30}$/i.test(value) || 'Некорректный никнейм'

export const password = (value: string) =>
  /^[A-Za-z0-9_!?-]{6,30}$/i.test(value) ||
  'В пароле могут быть только латинница, ' +
    'цифры и знаки препинания: _!?- и от 6 до 30 символов'

export const title = (value: string) =>
  /^[\wа-я—ёЁ–\s\u2000-\u200F\u0020-\u007F\u2116\u00A0-\u00FF]+$/i.test(
    value,
  ) ||
  'Введены некорректные символы. Используйте английские и русские буквы, а так же цифры и знаки препинания'
