/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { FC, useEffect, useState } from 'react'

import { getCiphersList } from '@builddocs/filestore-api'
import { ApplicationKeys } from '@builddocs/filestore-api'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

interface OwnProps {
  projectId: string
  isDisabled?: boolean
  initialValue?: string
  documentTypeTab: ApplicationKeys
  onSelect: (value: string) => void
}

type Props = OwnProps

export const CipherFilter: FC<Props> = ({
  projectId,
  initialValue = 'Все',
  documentTypeTab,
  onSelect,
}) => {
  const [cipherList, setCipherList] = useState<string[]>([])
  const [value, setValue] = useState<string>(initialValue)

  const handleChange = (value: string) => {
    onSelect(value === 'Все' ? '' : value)
    setValue(value)
  }

  useEffect(() => {
    getCiphersList(projectId, documentTypeTab).then(list => {
      setCipherList(['Все', ...list])
    })
  }, [projectId, documentTypeTab])

  return (
    <FormControl>
      <InputLabel id="select-label">Шифр</InputLabel>
      <Select
        id="cipherFilter"
        label="Шифр"
        labelId="select-label"
        size="small"
        sx={{ minWidth: 200 }}
        value={value}
        onChange={event => handleChange(event.target.value)}
      >
        {cipherList.map(cipher => (
          <MenuItem key={cipher} value={cipher}>
            {cipher}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CipherFilter
