/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Unit } from '../../utils/units'

import type { DocumentMeta, UUID } from '../../models'
import type { EntityState } from '../contracts'

export interface InvoiceAssignee {
  employmentId: string
  signatureState: EntityState
}

export interface IncomingInvoice {
  journalId: string
  title: string
  receivedDate: string
  updatedDateTime: string
  supplier: string
  creatorEmploymentId: string
  entityState: EntityState
  invoiceAssignee: InvoiceAssignee[]
  invoicePositions: InvoicePosition[]
}

export interface InvoicePosition {
  journalId: string // ID журнала
  invoiceId: UUID
  material: Material //
  amount: number // количество материала
  defect: boolean // Отклонение от ГОСТа, СНиПа, ТУ, дефекты
}

export interface Material {
  materialId?: UUID
  // инн
  inn: string
  // хз
  description?: string
  // наименование материала
  materialName: string
  // количество
  amount: number
  /** размерность */
  unit: Unit
  documents: DocumentMeta[]
  /** производитель */
  manufacturer?: string
  stageId?: number
}
