import { useEffect } from 'react'

import {
  actExternal,
  ExternalSigningAct,
  useActExternal,
} from '@builddocs/ext-sign-data-access'
import { ActPdfViewer } from '@builddocs/shared/feature-pdf-viewer'
import { Box } from '@mui/system'
import { useRecoilValue } from 'recoil'

import { ErrorContainer } from './ErrorContainer'

type OwnProps = {
  externalSigningValue: ExternalSigningAct
  onLoad: (state: boolean) => void
}

const ExtSignAct = ({ externalSigningValue, onLoad }: OwnProps) => {
  const { loadAct, isActLoading, isActFail } = useActExternal()

  useEffect(() => {
    onLoad(isActLoading)
  }, [isActLoading])
  const act = useRecoilValue(actExternal)

  /** после получения подписания получаем акт */
  useEffect(() => {
    if (externalSigningValue) {
      loadAct(externalSigningValue)
    }
  }, [externalSigningValue, loadAct])
  return (
    <>
      {act && (
        <Box flexGrow={1} overflow="auto">
          <ActPdfViewer act={act} />
        </Box>
      )}
      {isActFail && <ErrorContainer massage="Не удалось загрузить акт" />}
    </>
  )
}

export default ExtSignAct
