import { CryptoproCertificate } from '@builddocs/feature-faceid'
import { Chip, Paper, Stack, Typography } from '@mui/material'
import compareAsc from 'date-fns/compareAsc'

type OwnProps = {
  certificate: CryptoproCertificate
}

function CertificateInfo({ certificate }: OwnProps) {
  return (
    <Paper sx={{ overflow: 'hidden', p: 2, width: '100%' }} variant="outlined">
      <Stack alignItems="center" direction="row" spacing={1}>
        {certificate.IsValid ? (
          <Chip color="success" label="Валидный" variant="outlined" />
        ) : (
          <Chip color="error" label="Не валидный" variant="outlined" />
        )}
        {compareAsc(certificate.ValidToDate, new Date()) === -1 && (
          <Typography color="text.error">Срок действия истёк</Typography>
        )}
        {compareAsc(certificate.ValidFromDate, new Date()) === 1 && (
          <Typography color="text.error">
            Срок действия ещё не настал
          </Typography>
        )}
      </Stack>
      <Typography fontWeight="bold" my={1} variant="subtitle1">
        {certificate.Name}
      </Typography>
      <Typography>
        <Typography color="text.secondary" component="span">
          ИНН:
        </Typography>
        {` ${certificate.Subject.INN ?? 'Нет'}`}
      </Typography>
      <Typography mb={2}>
        <Typography color="text.secondary" component="span">
          СНИЛС:
        </Typography>
        {` ${certificate.Subject.SNILS ?? 'Нет'}`}
      </Typography>
      <Typography color="text.secondary">{`Алгоритм: ${certificate.Algorithm}`}</Typography>
      <Typography color="text.secondary">{`Выдан: ${certificate.Issuer.CN}`}</Typography>
      <Typography color="text.secondary">
        {`Владелец: ${certificate.Subject.SN ? certificate.Subject.SN : ''} ${
          certificate.Subject.G ? certificate.Subject.G : ''
        }`}
      </Typography>
      <Typography color="text.secondary">{`Номер сертификата: ${certificate.SerialNumber}`}</Typography>
    </Paper>
  )
}

export default CertificateInfo
