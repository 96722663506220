/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { UUID } from '../../models'
import { RestAPI } from '../RestAPI'

import { LinkedMaterial, PairId } from './matlink.types'
import { LinkedUsedArray } from './useMatlink'

export const getLinkedMaterials = (
  contractId: UUID | undefined,
  workDocSection: string | undefined,
): Promise<LinkedMaterial[]> =>
  RestAPI.get(
    `/material/linked/${contractId}?workDocSection=${workDocSection}`,
  ).then(res => res.data || [])

type UpdateMatlinkProps = {
  journalId: UUID | undefined
  linkedMaterials: PairId[]
  workDocSection: string | undefined
}
type LinkProjectIdProps = {
  contractId: UUID | undefined
  data: LinkedUsedArray
}
export const updateMatlink = (data: UpdateMatlinkProps) => {
  return RestAPI.put(`/material/link`, data)
}

export const linkToUsed = ({ contractId, data }: LinkProjectIdProps) => {
  return RestAPI.put(`/material/link-used?journalId=${contractId}`, data)
}
