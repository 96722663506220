/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { ProjectRequest } from '../projects'

export const toProjectAPI = ({
  projectId,
  inn,
  constructionAddress,
  description,
  developer,
  customer,
  developerAssignee,
  customerAssignee,
}: any): ProjectRequest => ({
  projectId,
  inn,
  description,
  constructionAddress,

  developer: {
    inn: developer.inn,
    sro: developer.sro,
    sroDate: developer.sroDate || null,
    phone: developer.phone?.replace(/[\s]/gi, '') || null,
  },
  developerAssigneeEmploymentId:
    developerAssignee?.employment?.employmentId ||
    developerAssignee?.user?.employmentId,

  customer: {
    inn: customer.inn,
    sro: customer.sro,
    sroDate: customer.sroDate || null,
    phone: customer.phone?.replace(/[\s]/gi, '') || null,
  },
  customerAssigneeEmploymentId:
    customerAssignee?.employment?.employmentId ||
    customerAssignee?.user?.employmentId,
})
