import { atomFamily, selectorFamily } from 'recoil'

import { UUID } from '../../models'
import { getHierarchyWorkDocSectionsForWork } from '../stages'

import { getStagesForProject } from './stages.service'
import { WorkDocSection } from './worksNames'

export const stagesQueryRequestIDState = atomFamily({
  key: 'StagesRequestID',
  default: 0,
})

export const stagesQuery = selectorFamily<
  WorkDocSection[],
  {
    contractId: UUID | undefined
    projectId: UUID
  }
>({
  key: 'Stages',
  get:
    ({ contractId, projectId }) =>
    async ({ get }) => {
      get(stagesQueryRequestIDState({ contractId, projectId }))

      if (contractId) {
        return await getHierarchyWorkDocSectionsForWork(contractId)
      }
      return await getStagesForProject(projectId)
    },
})
