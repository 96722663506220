/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useState } from 'react'

import { track } from '@amplitude/analytics-browser'
import { useToast } from '@builddocs/system'
import { useNavigate } from 'react-router-dom'

import { signUp as signUpReq, SignUpProps } from '../auth.service'

type OwnProps = {
  redirectUrl: string
}
export const useSignUp = ({ redirectUrl }: OwnProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { toast } = useToast()

  const signUp = (data: SignUpProps) => {
    setIsLoading(true)
    signUpReq(data)
      .then(() => {
        setIsLoading(false)

        toast.success.t('messages.registrationCompletedSuccessfully')
        track('user_signup')

        navigate(redirectUrl)
      })
      .catch(error => {
        setIsLoading(false)
        toast.error.exception(error)
      })
  }

  return {
    signUp,
    isLoading,
  }
}
