/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React, { SyntheticEvent } from 'react'

import {
  formatPhoneNumber,
  getAvatarSource,
  getFullName,
  getShortName,
} from '@builddocs/data-management'
import { TypographyProps } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  usePopupState,
  bindHover,
  bindPopper,
} from 'material-ui-popup-state/hooks'

import type { User } from '@builddocs/data-management'

export type UserInfoPopoverProps = {
  user: User | undefined
  isLoading?: boolean
  error?: {
    message?: string
    code: number
  } // TODO: Заменить на объект ошибки
  labelProps?: TypographyProps
}

export function UserInfoPopover(props: UserInfoPopoverProps) {
  const { user, isLoading, error, labelProps } = props

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'user-profile-bubble',
  })

  const [avatar, setAvatar] = React.useState<string>('')

  // React.useEffect(() => {
  //   getAvatarSource(user?.userId).then(avatar => setAvatar(avatar))
  // }, [])

  if (isLoading) return <Skeleton width={150} />

  if (error) return <Typography color="error">{error.message}</Typography>

  if (!user) return <Typography color="error">Профиль не найден</Typography>

  let timeout: NodeJS.Timeout
  const delay = (
    func: () => void,
    currentTarget: HTMLElement | SyntheticEvent<any, Event> | null | undefined,
  ) => {
    timeout = setTimeout(func, 400, currentTarget)
  }

  return (
    <>
      <Typography
        color={labelProps?.color ?? 'primary'}
        data-cy="userInfoPopover"
        {...labelProps}
        {...{
          ...bindHover(popupState),
          onMouseLeave: event => {
            event.relatedTarget.id !== 'popper-paper-element' &&
              popupState.close()
            timeout && clearTimeout(timeout)
          },
          onMouseOver: (event: {
            currentTarget:
              | HTMLElement
              | SyntheticEvent<any, Event>
              | null
              | undefined
          }) => delay(popupState.open, event.currentTarget),
        }}
      >
        {getShortName(user)}
      </Typography>
      <Popper
        transition
        {...{
          ...bindPopper(popupState),
          onMouseLeave: e => {
            e.relatedTarget !== popupState.anchorEl && popupState.close()
            timeout && clearTimeout(timeout)
          },
        }}
        placement="top"
        style={{ zIndex: 1450 }} //Костыль https://github.com/mui-org/material-ui/issues/14216
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper id="popper-paper-element" sx={{ p: 3, width: 320 }}>
              <Box sx={{ display: 'flex' }}>
                <Avatar src={avatar} sx={{ width: 72, height: 72 }} />
                <Stack sx={{ pl: 2 }}>
                  <Typography gutterBottom variant="h6">
                    {getFullName(user)}
                  </Typography>
                  <Typography>
                    {user.employment?.position.description}
                  </Typography>
                  <Link color="text.primary" href={`mailto:${user.email}`}>
                    {user.email}
                  </Link>
                  <Link color="text.primary" href={`tel:${user.phone}`}>
                    {formatPhoneNumber(user.phone)}
                  </Link>
                </Stack>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default UserInfoPopover
