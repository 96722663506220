/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { AxiosResponse } from 'axios'

import { RestAPI } from '../RestAPI'

import type { UUID } from '../../models'
import type { WorkSection3, WorkSection3Response } from '../contracts'

type ReqData = Record<string, string | number>

type PaginationBase = {
  pageNumber: number
  pageSize: number
}

type GetListParams = {
  contractId: string
  workDocSection?: string
  sortParams: {
    description: string
    fromDate?: string
    toDate?: string
  }
} & PaginationBase

export const getList = ({
  contractId,
  pageNumber,
  pageSize,
  workDocSection,
  sortParams,
}: GetListParams): Promise<{
  pageCount: number
  pageNumber: number
  pageSize: number
  workDocSection: string
  entityList: Array<WorkSection3Response>
}> => {
  let sort = {}

  Object.keys(sortParams).forEach(
    key =>
      (sort = {
        ...sort,
        [key]: sortParams[key] === '' ? null : sortParams[key],
      }),
  )

  return RestAPI.post(
    `/work_three/journals`,
    {
      ...sort,
      journalId: contractId,
      workDocSection,
    },
    {
      params: {
        pageNumber,
        pageSize,
      },
    },
  ).then(
    res =>
      res.data || {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        entityList: [],
        workDocSection: '',
      },
  )
}

export const create = (data: WorkSection3): Promise<AxiosResponse> =>
  RestAPI.post('/work_three/work', {
    ...data,
    stageId: Number(data.stageId),
  })

export const update = (data: WorkSection3): Promise<AxiosResponse> =>
  RestAPI.put('/work_three/work', data)

type DeleteOne = {
  journalId: UUID
  sectionId: UUID
}
export const deleteOne = ({
  journalId,
  sectionId,
}: DeleteOne): Promise<AxiosResponse> =>
  RestAPI.delete(`/work_three/work/${journalId}/${sectionId}`)

/**
 * Метод верифицирует пользователя и выдаёт хэш и подпись к накладной, которую надо подписать.
 * Действует как для прораба, так и для уполномоченного.
 * @param {base64Url} imageBase64
 * @param {string} keySerialNumber
 * @param {UUID} journalId
 * @param {UUID} sectionId
 * @return {Promise<AxiosResponse>}
 */
export const verifySigner = ({
  imageBase64 = '',
  keySerialNumber = '',
  journalId,
  sectionId,
}: {
  imageBase64: string
  keySerialNumber: string
  journalId: UUID
  sectionId: UUID
}): Promise<AxiosResponse> => {
  const dataBase64 = imageBase64.replace(/^data:.+;base64,/, '')

  return RestAPI.post(
    '/work_three/verify_signer',
    {
      faceIdCredentials: {
        faceImage: dataBase64,
        keySerialNumber,
      },
      journalId,
      sectionId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const makeSign = (data: ReqData): Promise<AxiosResponse> =>
  RestAPI.post('/work_three/save_sign', {
    journalId: data.journalId,
    sectionId: data.sectionId,
    signature: data.signature,
  })
