import { useContext } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material'

import { AttentionContext } from './FaceControlProvider'

const AttentionSignDialog = () => {
  const attention = useContext(AttentionContext)

  if (!attention) return null

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={attention.isOpen}
      onClose={attention.close}
    >
      <DialogTitle>{attention.title}</DialogTitle>
      {attention.description.split('\n').map(line => (
        <DialogContent key={line} sx={{ pt: 0, pb: 0 }}>
          {line}
        </DialogContent>
      ))}
      <DialogActions>
        <Button onClick={attention.close}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AttentionSignDialog
