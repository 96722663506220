/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import {
  InspectionState,
  RestAPI,
  SignatureState,
  UUID,
} from '@builddocs/data-management'

import { FilestoreAPI } from '../FilestoreAPI'

/**
 * АОСР
 * Подписи
 **/

/** Получение дайджеста АОСР */
export const getDigestAOSR = (id: string) => {
  return FilestoreAPI.get(`/act-aosr/digest/${id}`).then(res => res.data)
}
export const getBlobAOSR = (id: string) => {
  return FilestoreAPI.get(`/act-aosr/${id}/blob`).then(res => res.data)
}

type DigestAndPrepareAOSR = {
  base64signature: null
  documentId: string
  employmentId: string
  imprint: string
  keyIssuer: string
  signatureState: SignatureState.PENDING
  certificateDateStart: string
  certificateDateEnd: string
  name: string
  faceId: {
    faceImage?: string
    faceImageBytes?: string
    keySerialNumber: string
  }
}

/** Подготовка АОСР и получение хэша */
export const getDigestAndPrepareAOSR = (
  formSignStamp: DigestAndPrepareAOSR,
): Promise<{
  digest: string
  actRegistryDigest?: string
}> =>
  FilestoreAPI.post(`/act-aosr/prepare`, formSignStamp, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.data)

/** Получение дайджеста с подписью для списка АОСР */
export const getDigestAndPrepareListAOSR = (acts: {
  faceId: {
    faceImage?: string
    faceImageBytes?: string
    keySerialNumber: string
  }
  signRequests: {
    certificateDateEnd: string
    certificateDateStart: string
    documentId: string
    employmentId: string
    imprint: string
    keyIssuer: string
    name: string
    signatureState: SignatureState.PENDING
  }[]
}) => {
  return FilestoreAPI.post(`/act-aosr/bulk-prepare `, acts, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.data)
}

/** Получение дайджеста для списка АОСР */
export const getDigestListAOSR = (actId: string[]) => {
  return FilestoreAPI.get(`/act-aosr/digest`, {
    params: { id: actId.join(',') },
  }).then(res => res.data)
}

export const getDigestRegistry = (
  formSignStamp: DigestAndPrepareAOSR,
): Promise<string> =>
  FilestoreAPI.post(`/registry/prepare`, formSignStamp, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.data)

export const getDigestFinalRegistry = (
  formSignStamp: DigestAndPrepareAOSR,
): Promise<string> =>
  FilestoreAPI.post(`/final-registry/prepare`, formSignStamp, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.data)

/** Инициализация подписания АОСР*/
export const signInitAct = ({
  actId,
  isEmbedded = false,
  addActAssignees,
  projectId,
  journalId,
  workDocSection,
}: {
  actId: string
  isEmbedded?: boolean
  addActAssignees?: string[]
  projectId: UUID
  journalId: UUID
  workDocSection: string
}) => {
  return FilestoreAPI.post(`/act-aosr/sign/add-signers`, {
    id: actId,
    embedded: isEmbedded,
    employmentIds: addActAssignees,
    projectId,
    journalId,
    workDocSection,
  }).then(res => res.data)
}

/** Подписание/Отклонение АОСР */
export const signAct = (
  data:
    | {
        documentId: string
        employmentId: string
        signatureState: SignatureState.SIGNED
        base64signature: string
        actRegistryBase64signature?: string
        keyIssuer: string
        imprint: string
        name: string
        faceId: {
          faceImage?: string
          faceImageBytes?: string
          keySerialNumber: string
        }
      }
    | {
        documentId: string
        employmentId: string
        signatureState: SignatureState.REJECTED
      },
) => FilestoreAPI.post(`/act-aosr/sign`, data).then(res => res.data)

/** Добавление проверяющих в АОСР */
export const inspectInitAct = (
  projectId: string,
  journalId: string,
  workDocSection: string,
  actId: string,
  addActInspectors?: string[],
) => {
  const form = new FormData()

  form.append('id', actId)
  new Set(addActInspectors)?.forEach(id => form.append('employmentId', id))
  return FilestoreAPI.post(`/act-aosr/inspectors`, form, {
    params: {
      projectId: projectId,
      journalId: journalId,
      workDocSection: workDocSection,
    },
  }).then(res => res.data)
}
/** Проверка АОСР */
export const inspectAct = (
  actId: string,
  employmentId: string,
  state: InspectionState,
) => {
  const form = new FormData()

  form.append('id', actId)
  form.append('employmentId', employmentId)
  form.append('state', state)

  return FilestoreAPI.put(`/act-aosr/inspector`, form).then(res => res.data)
}

/** Получение файла подписи АОСР */
export const getSignatureFileAOSR = (id: string) => {
  return FilestoreAPI.get(`/act-aosr/${id}/sig`).then(res => res.data)
}

export const signRegistry = (
  data:
    | {
        documentId: string
        employmentId: string
        signatureState: SignatureState.SIGNED
        base64signature: string
        actRegistryBase64signature?: string
        keyIssuer: string
        imprint: string
        name: string
        faceId: {
          faceImage?: string
          faceImageBytes?: string
          keySerialNumber: string
        }
      }
    | {
        documentId: string
        employmentId: string
        signatureState: SignatureState.REJECTED
      },
) => FilestoreAPI.post(`/registry/sign`, data).then(res => res.data)

export const getActRemarks = ({
  author = 'YES',
  actId,
  status,
}: {
  author: 'YES' | 'NO'
  actId: string
  status: 'ALL' | 'DONE' | 'OPEN'
}) =>
  RestAPI.get(
    `section_four/acts-remarks/${actId}/count?status=${status}&author=${author}`,
  ).then(res => res.data)

export const autoUpdateActRemarks = ({ actId }: { actId: string }) =>
  RestAPI.put(`section_four/acts-remarks/${actId}/auto-update`).then(
    res => res.data,
  )

export const signFinalRegistry = (
  data:
    | {
        documentId: string
        employmentId: string
        signatureState: SignatureState.SIGNED
        base64signature: string
        actRegistryBase64signature?: string
        keyIssuer: string
        imprint: string
        name: string
        faceId: {
          faceImage?: string
          faceImageBytes?: string
          keySerialNumber: string
        }
      }
    | {
        documentId: string
        employmentId: string
        signatureState: SignatureState.REJECTED
      },
) => FilestoreAPI.post(`/final-registry/sign`, data).then(res => res.data)
