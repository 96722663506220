import {
  UUID,
  WorkType,
  InspectionState,
  SignatureState,
  ApprovalState,
  ActKsInfo,
} from '@builddocs/data-management'

import { AOSRType, SecondaryActType } from '../acts'
import { Supporting } from '../applications'

export interface ClosingType {
  id: UUID
  journalId: UUID
  startDate: string
  endDate: string
  workDocSection: string
  workType: WorkType[] | WorkType
  acts: UUID[]
  actsObjList: any[]
  creationDate: string
}

export type DataClosingType = {
  startDate: string
  endDate: string
  workDocSection: string
  contractId: UUID
  acts: Record<string, UUID[]>
  secondaryActs: Record<string, string[]>
  secondaryActsOutsideClosing: Record<string, UUID[]>
  repository: Record<string, AOSRType | SecondaryActType>
}

export type ResponseClosingType = {
  registries: any
  registriesObjects: Record<
    WorkType,
    {
      documentUrl: string
      manualFields?: RegistryManualFields
      supportingDocsObjList: Supporting[]
    }
  >
  approvalStatus: ApprovalState
  assignees: any[]
  inspectors: any[]
  name: string
  acts: Record<WorkType, UUID[]>
  actsObjList: Record<WorkType, AOSRType[]>
  closingNumberTemplates: TemplatesForChange
  creationDate: string
  creatorId: UUID
  deletable: boolean
  endDate: string
  id: string
  journalId: string
  registryId: UUID
  externalId: UUID
  registryObj: {
    externalId: UUID
    actIds: UUID[]
    assignees: any[]
    base64signature: string
    creationDate: string
    deleted: boolean
    document: string
    documentUrl: string
    fileName: string
    id: string
    inspectionState: InspectionState
    inspectors: any[]
    issueDate: string
    journalId: UUID
    name: string
    signatureEmbedded: boolean
    signatureState: SignatureState
    workDocSection: string
    manualFields?: RegistryManualFields
    supportingDocsObjList: Supporting[]
    approvalStatus: ApprovalState
  }
  secondaryActs: Record<WorkType, UUID[]>
  secondaryActsObjList: Record<WorkType, SecondaryActType[]>
  journalsObjList: SecondaryActType[]
  startDate: string // дата начала
  workDocSection: string // раздел работы
  attachedKsActs: ActKsInfo[]
}

export type RegistryAssigneesRole =
  | 'representative_executor_works'
  | 'representative_executor_building'
  | 'representative_developer'

export type RegistryManualFields = {
  assigneesForRegistry: Record<RegistryAssigneesRole, UUID | null> | null
  startPage: number | null
  supportingDocs: string[] | null
  registryNumber: number | null
  manualRegistryName: string | null
  shouldIncludeZhor: boolean
  shouldIncludeZhvk: boolean
}

export type TemplatesForChangeMap = Map<
  string,
  {
    prefix: string | null
    startNumber: number | null
    ending: string | null
  }
>

export type TemplatesForChange = Record<
  string,
  {
    prefix: string | null
    startNumber: number | null
    ending: string | null
  }
>
