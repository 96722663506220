export interface unmappedWork {
  buildDocsWorks: buildDocsWork[]
  externalWorks: externalWork[]
}
export interface acceptanceWork {
  buildDocsWork: buildDocsWork
  externalWorks: {externalWork:externalWork, status:string}[]
}
export enum AcceptanceTechzorStatuses {
  waiting = 'Готово к приемке',
  in_progress = 'В процессе приемки',
  problems_identified = 'Выявлены нарушения',
  accepted = 'Работы приняты',
  rejected = 'Работы отклонены'
}
export interface mappedWorks {
  buildDocsWork: buildDocsWork;
  externalWorks: externalWork[];
}


export type buildDocsWork = {
  creatorEmploymentId: string;
  sectionId: string;
  journalId: string;
  title: string;
  workDocSection: string;
  stageId: number;
  workType: string;
  otherDoc: null | any;
  concretingDoc: null | any;
  weldingDoc: null | any;
  cablingDoc: null | any;
  palificationDoc: null | any;
  drillingWellsDoc: null | any;
  installationStructureDoc: null | any;
  antiCorrosionProtectionDoc: null | any;
  materials: null | Array<{
    sectionId: string;
    materialId: string;
    stageId: number;
    amount: number;
    materialName: string;
    unit: string;
    manufacturer: string;
    documents: null | Array<{
      documentId: string;
      documentName: string;
      documentNumber: string;
      documentType: string;
      subDocuments: null | any;
      issueDate: string;
      expirationDate: string;
      documentUrl: string;
      manufacturer: string;
      createDateTime: string;
      main: boolean;
    }>;
  }>;
  projectDocs: null | any[];
  normativeDocs: null | any[];
  description: string;
  workDate: string;
  place: string;
  state: string;
  assignees: any[];
  actState: string;
  linkedAct: {
    actState: string;
    url: string;
  };
  createDateTime: string;
}

export type externalWork = {
   id:string;
   externalId:string;
   workCode:string;
   workName:string;
   materialCode:string;
   materialName:string;
   note:string;
   metricUnit:string;
   currentSize:string;
   estimatedSize:string;
   sectionThreeMaterialId:string;
   sectionThreeWorkId:string;
   contractId:string;
   externalBuildingObjectId:string;
   externalWorkGroupId:string;
   techzorWorkId:string;
   createDateTime:string;
   updateDateTime:string;
   status:string;
}
