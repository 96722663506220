import {
  TableContainer as MuiTableContainer,
  tableContainerClasses,
} from '@mui/material'
import { TableCell as MuiTableCell, tableCellClasses } from '@mui/material'
import { TableRow as MuiTableRow } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TableContainer = styled(MuiTableContainer)(() => ({
  [`&.${tableContainerClasses.root}`]: {
    borderTop: '1px solid #D9D9D9',
  },
}))

export const TableCell = styled(MuiTableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {},
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F5F5F5',
    color: 'rgba(0,0,0,0.6)',
    fontSize: 12,
    px: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
