/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 *  Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 *  You may use, distribute and modify this code under the
 *  terms of the ООО АДАПТ license
 */

import React from 'react'

import pdfobject, { Options } from 'pdfobject'

export type PageMode = 'bookmarks' | 'thumbs' | 'none'

export type ViewMode =
  | 'Fit'
  | 'FitH'
  | 'FitH,top'
  | 'FitV'
  | 'FitV,left'
  | 'FitB'
  | 'FitBH'
  | 'FitBH,top'
  | 'FitBV'
  | 'FitBV,left'

export type ZoomMode = 'scale' | 'scale,left,top'

// https://www.adobe.com/content/dam/acom/en/devnet/acrobat/pdfs/pdf_open_parameters.pdf
export interface OpenParams {
  page: number
  zoom: ZoomMode
  nameddest: string
  pagemode: PageMode
  view: ViewMode
}

export interface Props {
  url: string
  containerId?: string
  containerProps?: React.HTMLProps<HTMLDivElement>
  width?: string
  height?: string
  page?: string | number
  id?: string
  fallbackLink?: string | false
  pdfOpenParams?: OpenParams
  PDFJS_URL?: string
  forcePDFJS: boolean
  assumptionMode: boolean
  forceUpdate?: boolean
}

export class PDFObject extends React.PureComponent<Props> {
  public static defaultProps = {
    width: '100%',
    height: '100%',
    containerId: 'pdfobject',
    fallbackLink:
      '<div style="width: 500px; margin: 0 auto">Пожалуйста, для отображения PDF-файлов <ul><li>зайдите на страницу chrome://settings/content,</li> <li>найдите PDF documents</li> <li>и включите функцию "Open PDF files in the default PDF viewer application".</li></ul></div>',
    forcePDFJS: false,
    assumptionMode: false,
    forceUpdate: false,
  }

  public componentDidMount() {
    this.embed()
  }

  public componentDidUpdate(prevProps: Props) {
    // check for different props.url
    if (prevProps.url !== this.props.url && !this.props.forceUpdate) {
      this.embed()
    }
    if (this.props.forceUpdate) {
      this.embed()
    }
  }

  public render() {
    return <div {...this.props.containerProps} id={this.props.containerId} />
  }

  private embed = () => {
    const { url, containerId, containerProps, ...options } = this.props

    if (pdfobject) {
      pdfobject.embed(url, `#${containerId}`, options as never as Options)
    }
  }
}
