/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useRecoilValueLoadable } from 'recoil'

import { assigneesProjectQuery } from './Assignees.container'

export type AssigneesProjectResponseType = {
  inn: string
  type: 'DEVELOPER' | 'CUSTOMER' | 'BUILDER' | 'PREP' | 'CUSTOMER_TECH_CONTROL'
  employmentId: string
  firstName: string
  middleName?: string
  lastName: string
  userId: string
  positionDescription: string
}

interface AssigneesProjectHook {
  isLoading: boolean
  assignees: AssigneesProjectResponseType[]

  error?: Error
}

export const useAssigneesProject = (
  projectId: string,
): AssigneesProjectHook => {
  const assigneesLoadable = useRecoilValueLoadable(
    assigneesProjectQuery({ projectId }),
  )

  const assignees =
    assigneesLoadable.state === 'hasValue'
      ? Array.isArray(assigneesLoadable.contents)
        ? assigneesLoadable.contents
        : []
      : []

  const isLoading = assigneesLoadable.state === 'loading'

  const error =
    assigneesLoadable.state === 'hasError'
      ? assigneesLoadable.contents
      : undefined

  return {
    assignees,
    isLoading,
    error,
  }
}
