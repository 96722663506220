/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { CryptoPro } from '@builddocs/ruscrypto'

export const cryptopro = new CryptoPro()

export const initCrypto = () => cryptopro.init()
