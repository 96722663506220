/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { WorkType } from '../../constants'

import type {
  DocumentMeta,
  Employment,
  Organization,
  User,
  UUID,
} from '../../models'
import type { MaterialResponse } from '../invoices'

export type EntityState =
  | 'DRAFT'
  | 'PENDING'
  | 'AGREEMENT'
  | 'ACCEPTED'
  | 'SIGNING'
  | 'SIGNED'
  | 'UNDEFINED'
  | 'REJECTED'

export type MyRights = {
  canSign?: boolean
  canExecute?: boolean
  canInspect?: boolean
  type: 'DEVELOPER' | 'CUSTOMER' | 'BUILDER' | 'PREP' | 'CUSTOMER_TECH_CONTROL'
}

export interface Assignee extends MyRights {
  order: DocumentMeta
  workTypes: WorkType[]
  user: User
  startDate: string
  endDate: string
  employment: Employment
  employmentId?: string
  organization?: Organization
}

export interface Journal {
  entityState: EntityState
  inn: string
  /** ID журнала */
  journalId: UUID
  projectId: UUID | undefined
  /** наименование объекта */
  buildingName: string
  /** номер договора (заголовок) */
  title: string
  /** описание договора */
  description: string
  /** адрес объекта */
  constructionAddress: string

  /** Прораб */
  prorab: User // прораб
  prorabEmploymentId?: UUID

  /** Информация о застройщике */
  developer: Organization
  developerAssignees: Assignee[] // уполномоченный представитель застройщика (4 штуки)
  executiveSchemeAssignees: Assignee[] // Кто может согласовывать
  // исполнительные схемы
  /** информация о заказчике */
  customer: Organization
  customerAssignees: Assignee[] // уполномоченный представитель заказчика

  /** информация о техническом заказчике */
  customerTechControl: Organization
  customerTechControlAssignees: Assignee[] // уполномоченный представитель технического заказчика

  /** Сведения о выданном разрешении на строительство */
  /** номер сведения, о выданном разрешении на строительство */
  permitNumber: string
  /** дата выдачи */
  permitDateOfIssue: string
  permitAuthorityValue: string // наименование органа, выдавшего разрешение

  /** Лицо, осуществляющее подготовку проектной документации */
  prep: Organization
  // Уполномоченные лица, осуществляющего подготовку проектной деятельности
  prepAssignees: Assignee[]

  // Сведения о гос. экспертизе проектной докементации в случаях, предусмотренных ГК РФ
  stateExamNumber: string // номер сведения, о выданном разрешении на строительство
  stateExamDateOfIssue: string // дата выдачи
  stateAuthorityValue: string // наименование органа, выдавшего разрешение

  // лицо, осуществляющие строительство
  builder: Organization
  builderAssignees: Assignee[] // уполномоченный представитель лица, осуществляющего строительство (Евгений договорил, что он один)
  firstAssignees: Assignee[] // уполномоченный представитель застройщика или заказчика, по вопросам строительного контроля
  secondAssignees: Assignee[] // уполномоченный представитель застройщика или заказчика, по вопросам строительного контроля

  // Сведения о государственном строительном надзоре - отдельная сущность GovernmentRevisor
  inspectorTitle: string // наименование органа Госстройнадзора
  inspectorAddress: string // почтовые реквизиты
  inspectorPhone: string // телефон
  inspectorFax: string // факс
  inspectorEmp: string // должностное лицо

  projectDescription: string // краткие проектные характеристики
  projectStartData: string // дата начала работ
  projectEndData: string // дата окончания работ

  registrationNumber: string // регистрационный номер в GovernmentRevisor

  publishedDatetime: string // время публикации договора
  updateDatetime: string // время обновления договора

  myRights: Assignee // Для Павла

  createDateTime: string // дата создания
}

export enum ActState {
  UNDEFINED = 'UNDEFINED',
  AOSR = 'AOSR',
  RECONCILING = 'RECONCILING',
  RECONCILED = 'RECONCILED',
  SIGNING = 'SIGNING',
  SIGNED = 'SIGNED',
  CLOSING = 'CLOSING',
  CLOSING_RECONCILING = 'CLOSING_RECONCILING',
  CLOSING_RECONCILED = 'CLOSING_RECONCILED',
  CLOSING_SIGNING = 'CLOSING_SIGNING',
  CLOSING_SIGNED = 'CLOSING_SIGNED',
}

export interface WorkSection3 {
  workDate: string
  journalId: string
  projectDocs: DocumentMeta[]
  description: string
  entityState?: EntityState
  executiveDoc: { concretingDoc?: any; weldingDoc?: any }
  materials: MaterialResponse[]
  place: string
  stageId: number | string // от формы может строка получиться
  title: string
  workType: WorkType
  workDocSection: string
}

export interface WorkSection3Response extends WorkSection3 {
  stageId: number
  sectionId: string
  createDateTime: string
  creatorEmploymentId: string
  linkedAct: {
    actState: keyof typeof ActState
    url: string | null
    actId: string | null
    fullName: string | null
    actDate: string | null
    number: string | null
  }
  normativeDocs?: DocumentMeta[]
}
