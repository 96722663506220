/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Unit, UnitEnum } from './unit.constants'
import { UnitLabel } from './unitLabel'

export const unitsList = [
  {
    unit: UnitEnum.PIECES,
    label: 'штуки',
  },
  {
    unit: UnitEnum.KG,
    label: 'кг',
  },
  {
    unit: UnitEnum.TON,
    label: 'тонна',
  },
  {
    unit: UnitEnum.M,
    label: 'метры',
  },
  {
    unit: UnitEnum.M_2,
    label: 'квадратные метры',
  },
  {
    unit: UnitEnum.LM,
    label: 'метр погонные (м/п)',
  },
  // {
  //   unit: UnitEnumType.KG_M2,
  //   label: 'кг/м^2'
  // },
  // {
  //   unit: UnitEnumType.KG_M3,
  //   label: 'кг/м^3'
  // },
  {
    unit: UnitEnum.HA,
    label: 'гектар',
  },
  {
    unit: UnitEnum.M_3,
    label: 'кубические метры',
  },
  {
    unit: UnitEnum.LITER,
    label: 'литры',
  },
  {
    unit: UnitEnum.PACKAGING,
    label: 'упаковка',
  },
]

const terms: Record<string, string[]> = {
  [UnitEnum.PIECES]: ['штук', 'штука', 'штуки'],
  [UnitEnum.KG]: ['килограмм', 'килограмм', 'килограмма'],
  [UnitEnum.M]: ['метров', 'метр', 'метра'],
  [UnitEnum.M_2]: ['кв. метров', 'кв. метр', 'кв. метра'],
  [UnitEnum.M_3]: ['куб. метров', 'куб. метр', 'куб. метра'],
  [UnitEnum.TON]: ['тонн', 'тонна', 'тонны'],
  [UnitEnum.HA]: ['гектаров', 'гектар', 'гектара'],
}

export const shortTerms = (unit: Unit): string => {
  return UnitLabel[unit] || ''
}

export const getUnitString = (
  n: number,
  unit: Unit | [string, string, string],
): string => {
  const end =
    n % 10 === 1 && n % 100 !== 11
      ? 1
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 2
      : 0

  let liter

  if (typeof unit === 'string') {
    liter = terms[unit] ? terms[unit][end] : shortTerms(unit)
  } else {
    liter = unit[end]
  }

  return `${n} ${liter}`
}
