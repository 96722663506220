/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React, { useContext } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import CommentIcon from '@mui/icons-material/Comment'
import { DialogTitle, Link, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { AdobeViewer } from './AdobeViewer'
import { ViewerContext } from './PdfViwerContext'
import { PDFObject } from './React-pdfobject'

import type { FileViewerType } from './types'

interface Props {
  title: string
  file: FileViewerType | undefined
  isAdobe?: boolean
  onClose: () => void
  /** Компонент в футере */
  tools?: React.ReactNode
  /** Ссылки для переключения между документами */
  links?: Array<{
    id?: string
    link: string
    label: {
      primary: string
      secondary?: string
    }
    hasIssue?: boolean
  }>
  forcePreview?: any
}

export const PDFViewer = (props: Props) => {
  const {
    forcePreview,
    file: initFile,
    title,
    isAdobe,
    onClose,
    tools,
    links,
  } = props

  const isDialogWide = isAdobe || links

  const file = forcePreview?.url || initFile

  const viewerContext = useContext(ViewerContext)

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          height: '100%',
        },
        style: { overflow: 'hidden' },
      }}
      id="pdf-viewer"
      maxWidth={isDialogWide ? 'xl' : 'lg'}
      open={!!file}
      onClose={handleClose}
    >
      <DialogTitle>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="h5">
            {forcePreview?.fileName ?? title}
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            {file && (
              <Link href={file} rel="noopener" target="_blank" variant="body1">
                Открыть в новом окне
              </Link>
            )}
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <Box overflow="auto" sx={{ display: 'flex', height: '100%' }}>
        <Box sx={{ bgcolor: 'grey.100', flex: '90%', height: '100%', p: 0 }}>
          {/* <Flex align="center" justify={'center'}>
            <Box boxShadow="md" m={1}>
               <Document
                file={file}
                onLoadSuccess={pdf => setNumPages(pdf.numPages)}
              >
                <Page pageNumber={pageNumber} width={900} />
              </Document>
            </Box>
          </Flex> */}
          {isAdobe ? (
            <AdobeViewer
              divId="adobe-pdf"
              file={{
                url: file,
                fileName: 'Замечания из Экзона',
              }}
            />
          ) : (
            file && (
              <PDFObject
                containerId="lightbox-pdf"
                containerProps={{
                  style: { height: '100%', width: '100%' },
                }}
                url={file}
              />
            )
          )}
        </Box>
        <Stack minWidth="250px" overflow="auto">
          {links && initFile ? (
            <List aria-label="applications">
              <ListItemButton
                selected={forcePreview === undefined}
                onClick={() => viewerContext.setAdditionalPreview(undefined)}
              >
                <ListItemIcon>
                  <CommentIcon />
                </ListItemIcon>
                <ListItemText primary="Акт" />
              </ListItemButton>
              {links.map(({ label, link, hasIssue, id }) => (
                <ListItem
                  key={id}
                  disablePadding
                  secondaryAction={
                    hasIssue && (
                      <IconButton aria-label="comments" edge="end">
                        <CommentIcon />
                      </IconButton>
                    )
                  }
                >
                  <ListItemButton
                    dense
                    role={undefined}
                    selected={forcePreview?.fileId === id}
                    onClick={() =>
                      viewerContext.setAdditionalPreview({
                        url: link,
                        fileName: label.primary,
                        fileId: id,
                      })
                    }
                  >
                    <ListItemText
                      primary={label.primary}
                      secondary={label.secondary}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ) : null}
        </Stack>
      </Box>

      {tools && <DialogActions>{tools}</DialogActions>}
    </Dialog>
  )
}

export default PDFViewer
