/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 *  Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 *  You may use, distribute and modify this code under the
 *  terms of the ООО АДАПТ license
 */

export interface CryptoproCertificate {
  Algorithm: string
  HasPrivateKey: boolean
  IsValid: boolean
  Issuer: {
    C: string
    CN: string
    E: string
    INN: string
    L: string
    O: string
    OGRN: string
    OU: string
    S: string
    STREET: string
  }
  IssuerName: string
  Name: string
  ProviderName: string
  ProviderType: number
  SerialNumber: string
  Subject: {
    CN: string
    E: string
    G: string
    INN: string
    SN: string
    SNILS: string
  }
  SubjectName: string
  Thumbprint: string
  ValidFromDate: Date
  ValidToDate: Date
  Version: number
  toString: () => string
}

export interface CertificateInList {
  name: string
  id: string
}
