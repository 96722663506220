import type { Organization, OrganizationBase, User } from '../../models'

export interface Project {
  avatar?: string
  projectName?: string
  projectId: string
  inn: string
  description: string
  constructionAddress: string
  developer?: Organization
  customer?: Organization
  developerAssignee?: User
  customerAssignee?: User
  createDateTime?: string
  paid?: boolean
  integrations: string[]
}

export interface ProjectRequest {
  projectId?: string
  inn: string
  description: string
  constructionAddress: string
  developer?: OrganizationBase
  customer?: OrganizationBase
  customerAssigneeEmploymentId?: string
  developerAssigneeEmploymentId?: string
  createDateTime?: string
}
export interface ProjectResponse {
  projectId?: string
  inn: string
  description: string
  constructionAddress: string
  developer?: Organization
  customer?: Organization
  developerAssignee?: User
  customerAssignee?: User
  createDateTime?: string
}
