/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { Position, User, UUID } from '../../models'
import { RestAPI } from '../RestAPI'

type SwitchPositionReq = {
  /**
   * Инн администратора организации
   */
  adminInn: string
  /**
   * Id администратора организации
   */
  adminUserId: UUID
  /**
   * Id пользователя, чья должность меняется
   */
  userId: UUID
  /**
   * Id должности
   */
  positionId: UUID | null
  /**
   * Название должности
   */
  description: string
}

/**
 Создание новой должности
 */
export const createPosition = (description: string) =>
  RestAPI.post('/position', {}, { params: { description } }).then(
    res => res.data,
  )

/**
 Переименование должности
 */
export const renamePositions = (
  position: Omit<Position, 'createDateTime'>,
): Promise<unknown> => RestAPI.put('/position', position)

/**
 Получение всех должностей компании
 */
export const getPositions = (): Promise<Position[]> =>
  RestAPI.get('/position').then(res => res.data || [])

/**
 Смена позиции для сотрудника
 */
export const switchPosition = (data: SwitchPositionReq): Promise<User> =>
  RestAPI.put('/users/switch-position', data).then(res => res.data || {})
