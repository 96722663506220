/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 *  Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 *  You may use, distribute and modify this code under the
 *  terms of the ООО АДАПТ license
 */

import { atomFamily } from 'recoil'

import { IContractHierarchy } from './sakura.types'

import type { UUID } from '../../models'

export const currentSakuraPath = atomFamily<
  any[],
  { projectId: UUID; userId: UUID }
>({
  key: 'CurrentSakuraPath',
  default: [],
})

export const currentSakuraPosition = atomFamily<
  IContractHierarchy | undefined,
  { projectId: UUID; userId: UUID }
>({
  key: 'CurrentSakuraPosition',
  default: undefined,
})
