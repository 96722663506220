import { Box, List, ListItem, Typography } from '@mui/material'
import format from 'date-fns/format'
import { ru } from 'date-fns/locale'
import parseISO from 'date-fns/parseISO'

import RowCompare from './RowCompare'
import { DocsTypes } from './types'

type NavContentItemProps = {
  docs: Array<DocsTypes>
  title: string
  isOnLeft: (doc: DocsTypes) => boolean
  isOnRight: (doc: DocsTypes) => boolean
  onLeftSelectHandler: (doc: DocsTypes) => void
  onRightSelectHandler: (doc: DocsTypes) => void
  type?: 'registries' | 'acts'
}

export const NavContentItem = ({
  docs,
  title,
  isOnLeft,
  isOnRight,
  onLeftSelectHandler,
  onRightSelectHandler,
  type,
}: NavContentItemProps) => {
  const getDocTitle = (doc: DocsTypes) => {
    if (type === 'registries') return doc.name
    if (type === 'acts' && 'actDate' in doc)
      return `${doc.name} от ${format(
        parseISO(doc.actDate || doc.creationDate),
        'PP',
        { locale: ru },
      )}`

    return `${doc.name} ${
      'number' in doc && doc.number && !('sourceId' in doc)
        ? doc.number
        : 'cipher' in doc && doc.cipher
        ? doc.cipher
        : 'б/н'
    } от ${
      'issueDate' in doc && doc.issueDate
        ? format(parseISO(doc.issueDate), 'PP', {
            locale: ru,
          })
        : 'б/д'
    }`
  }

  return (
    <Box>
      <Typography fontWeight="bolder">{title}</Typography>
      <List component="ol">
        {docs.map((doc: DocsTypes) => (
          <ListItem key={doc.id} sx={{ pr: 0, py: 0 }}>
            <RowCompare
              isOnLeft={isOnLeft(doc)}
              isOnRight={isOnRight(doc)}
              title={getDocTitle(doc)}
              onLeftSelect={() => onLeftSelectHandler(doc)}
              onRightSelect={() => onRightSelectHandler(doc)}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
