import { FC } from 'react'

import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode'
import { IconButton, Stack, Typography } from '@mui/material'

interface OwnProps {
  onLeftSelect: () => Promise<void> | void
  onRightSelect: () => Promise<void> | void
  title: string
  isOnLeft: boolean
  isOnRight: boolean
}

type Props = OwnProps

const RowCompare: FC<Props> = props => {
  const { onLeftSelect, onRightSelect, title, isOnLeft, isOnRight } = props

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={1}
      width="100%"
    >
      <Typography sx={{ wordWrap: 'break-word' }} variant="body2">
        {title}
      </Typography>
      <Stack alignItems="center" direction="row" spacing={0.5}>
        <IconButton
          disabled={isOnLeft}
          id="show_doc_left"
          size="small"
          onClick={() => onLeftSelect()}
        >
          <ChromeReaderModeIcon
            color={isOnLeft ? 'primary' : 'action'}
            sx={{ transform: `scale(-1, 1)` }}
          />
        </IconButton>

        <IconButton
          id="show_doc_right"
          size="small"
          onClick={() => onRightSelect()}
        >
          <ChromeReaderModeIcon color={isOnRight ? 'primary' : 'action'} />
        </IconButton>
      </Stack>
    </Stack>
  )
}

export default RowCompare
