/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { selectorFamily } from 'recoil'

import { getPositions } from './positions.service'

export const positionsQuery = selectorFamily({
  key: 'Positions',
  get: () => async () => {
    return await getPositions()
  },
})
