import { useState } from 'react'

import {
  commentsLogQueryRequestIDState,
  UUID,
} from '@builddocs/data-management'
import { useDisclosure } from '@builddocs/system'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Stack,
  SxProps,
  TextField,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'

import HelpBubble from '../../molecules/help-bubble/HelpBubble'

type OwnProps = {
  contractId: UUID
  canInspect: boolean
  sx?: SxProps
  onDeviate?: (
    body: Record<string, any>,
    additionalInfo: Record<string, any>,
    onSuccess: () => any,
  ) => void
  commentEntity: {
    place: string
    docType: string
    docId: UUID
    name: string
  }
  isShowButtonForAll?: boolean
  refreshComments: () => void
}

function AddComment({
  contractId,
  onDeviate,
  refreshComments,
  isShowButtonForAll,
  commentEntity,
  canInspect,
  sx,
}: OwnProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { handleSubmit, errors, control } = useForm({
    defaultValues: {
      information: commentEntity['place'] ?? '',
      problemDescription: '',
      deadline: '',
      isAllClosing: false,
    },
  })

  const setRequestID = useSetRecoilState(
    commentsLogQueryRequestIDState(contractId),
  )

  const onCreate = data => {
    if (onDeviate) {
      setIsLoading(true)
      onDeviate(
        {
          information: data.information,
          problemDescription: data.problemDescription,
        },
        {
          externalEntityId: commentEntity.docId,
          externalEntityInfo: data.isAllClosing
            ? 'CLOSING_ALL_DOC'
            : commentEntity.docType,
          workTypeComment: { workType: 'workType' },
          name: commentEntity.name,
        },
        () => {
          setIsLoading(false)
          setRequestID(requestID => requestID + 1)
          refreshComments()
          onClose()
        },
      )
    }
  }

  return (
    <Stack spacing={1}>
      <Stack alignItems="center" direction="row" spacing={1}>
        {!isOpen && (
          <Button disabled={!canInspect} sx={sx} onClick={onOpen}>
            Добавить замечание
          </Button>
        )}
        {!canInspect && (
          <HelpBubble
            description="Вы не можете оставлять замечания, так как не обладаете правами
            проверяющего в договоре."
            kind="info"
            title="Информация"
            width={320}
          />
        )}
      </Stack>
      <form style={{ position: 'relative' }} onSubmit={handleSubmit(onCreate)}>
        <Collapse unmountOnExit in={isOpen} timeout="auto">
          <Stack spacing={1} sx={{ mb: 2 }}>
            <Stack direction="row" spacing={2}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    multiline
                    disabled={isLoading}
                    error={!!errors?.information?.message}
                    helperText={errors?.information?.message}
                    label="Место в документах"
                    name="information"
                    size="small"
                  />
                }
                control={control}
                name="information"
              />
              <Controller
                as={
                  <TextField
                    fullWidth
                    multiline
                    disabled={isLoading}
                    error={!!errors?.problemDescription?.message}
                    helperText={errors?.problemDescription?.message}
                    label="Комментарий"
                    name="problemDescription"
                    size="small"
                  />
                }
                control={control}
                name="problemDescription"
                rules={{
                  required: 'Должно быть заполнено',
                }}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              {isShowButtonForAll && (
                <Controller
                  control={control}
                  name="isAllClosing"
                  render={({ onChange, onBlur, value, ref, ...rest }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value}
                          inputRef={ref}
                          onBlur={onBlur}
                          onChange={e => onChange(e.target.checked)}
                          {...rest}
                        />
                      }
                      disabled={isLoading}
                      label="Применить ко всем актам закрытия"
                      {...rest}
                    />
                  )}
                />
              )}
              <Stack direction="row" spacing={1}>
                <Button disabled={isLoading} onClick={onClose}>
                  Отмена
                </Button>
                <LoadingButton
                  id="act-comments-drawer-button-add-comment"
                  loading={isLoading}
                  type="submit"
                >
                  Сохранить
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </Collapse>
      </form>
    </Stack>
  )
}

export default AddComment
