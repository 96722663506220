import { TypeOfWorksEnum } from '../../constants'
import { DocumentMeta } from '../../models'

export enum WorksNames {
  /** Раздел наименования работ */
  SECTION = 'SECTION',
  /** Конструкция наименования работ */
  CONSTRUCTION = 'CONSTRUCTION',
  /** Наименование работы или вложенное наименование работы */
  WORK = 'WORK',
}

export type ActionWorksNames = 'workDocSection' | 'construction' | 'worksName'

export interface WorksName {
  workName: string
  ksiId?: string | null
  workType: keyof typeof TypeOfWorksEnum
  projectDoc?: string
  works?: WorksName[]
  normativeDocs?: DocumentMeta[]
  stageId: number
  schedulerType: WorksNames.WORK
  parentStageId?: number
  workDate?: string
  workDocSection: string
}

export interface Construction {
  constructionName: string
  ksiId: string
  works?: WorksName[]
  stageId: number
  schedulerType: WorksNames.CONSTRUCTION
  parentStageId: number
}

export interface WorkDocSection {
  workDocSection: string //title
  constructions: Construction[]
  sections?: WorkDocSection[]
  works?: WorksName[]
  schedulerType: WorksNames.SECTION
  parentStageId: number
  stageId: number
  hierarchyScheduler: boolean //Признак true - новая иерархия, false - старая иерархия
}

export type AssociatedWork = {
  stageId: number
  title: string
  workDocSection: string
}

export type AssociatedWorkNode =
  | {
      stageId: number
      workDocSection: null
    }
  | {
      stageId: null
      workDocSection: string
    }
