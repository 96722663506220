/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useEffect, useState } from 'react'

import { PaginationModel } from '@builddocs/data-management'
import { useQuery, useQueryClient } from 'react-query'

import { getTasksSignStamps } from './filestore.stamp.service'
import { DocumentSigningTask } from './stamp.types'

interface StampHook {
  isLoading: boolean
  isFetching: boolean
  tasks: DocumentSigningTask[]
  pageNumber: number
  pageCount: number
  error: unknown
  isError: boolean
  refresh: () => void
  refetch: () => void
}

export const useTasksSignStamps = (
  employmentId: string,
  pageNumber = 25,
): StampHook => {
  const queryClient = useQueryClient()

  const [pageCount, setPageCount] = useState(1)

  const { data, isLoading, isFetching, isError, error, refetch, isSuccess } =
    useQuery<PaginationModel<DocumentSigningTask>>(
      ['tasks_signing_stamps', employmentId, pageNumber],
      async () => await getTasksSignStamps(employmentId, pageNumber),
    )

  useEffect(() => {
    if (!isSuccess && !data) return

    setPageCount(data?.pageCount ?? 1)
  }, [isSuccess, data])

  const refresh = () => queryClient.invalidateQueries('tasks_signing_stamps')

  return {
    isLoading,
    isFetching,
    tasks: data?.entityList ?? [],
    pageNumber: data?.pageNumber ?? 0,
    pageCount: pageCount,
    error,
    isError,
    refresh,
    refetch,
  }
}
