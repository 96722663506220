/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { UUID } from '@builddocs/data-management'

import { ApplicationKeys, LoadableApplicationKeys } from '../documentaryType'

import { AssigneesProjectResponseType } from './useAssigneesProject'

export type TemplateStampType = {
  id: string
  name: string
  pngFile: string
}

export type ResponseTemplateStampType = {
  pages: number
  stampTemplates: TemplateStampType[]
}

export enum SignatureState {
  UNDEFINED = 'UNDEFINED',
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  REJECTED = 'REJECTED',
}

/**
 * Задание на подписание штампа
 * TODO: уточнить смысл параметров
 */
export type SigningStampTask = {
  /** Список айдишников документов на подпимание  */
  documentIds: string[]
  /** Подписанты штампа */
  signers: AssigneesProjectResponseType[]
  stampTemplateId: TemplateStampType
  /** Список документов, на которых будет поставлен штамп */
  documents: { documentType: ApplicationKeys; documentId: string }[]
}

export type SignerInStampType = {
  userId: string
  userFullName: string
  inn: string
  position: string
  employmentId: string
  signatureState: SignatureState
  keyIssuer: string
  imprint: string
  comment: string
  signingDateTime: string
}

/** Статус подписи документа (штампа) */
export type DocumentSigningTask = {
  creatorUserId: UUID
  creationDateTime: string
  documentName: string
  /** Подписываемый документ */
  document: {
    documentId: string
    documentType: LoadableApplicationKeys
  }
  /** Идентификатор таски */
  id: string
  projectId: string
  /** Спискок подписей */
  stampSigns: Array<{
    comment: string
    employmentId: string
    imprint: string
    inn: string
    keyIssuer: string
    position: string
    signatureState: SignatureState
    signingDateTime: string
    userFullName: string
    userId: string
  }>
  stampTemplateId: string
  creatorId: UUID
}
