/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { FunctionComponent } from 'react'

import { Paper, PaperProps } from '@mui/material'

interface OwnProps {
  fullWidth?: boolean
}

type Props = OwnProps & PaperProps

export const PageSection: FunctionComponent<Props> = props => {
  const { children, sx, fullWidth, ...rest } = props

  return (
    <Paper sx={{ p: 2, maxWidth: '100%', ...sx }} {...rest}>
      {children}
    </Paper>
  )
}

export default PageSection
