import { useMemo } from 'react'

import { useOrganization } from '../rest/organization'

import { getInnOrKpp } from './getInnOrKpp'

const SETL_INN_ARRAY = ['7810747019', '7810489897', '7801443529', '78133296203']

interface INeedLogoHook {
  withoutLogo: boolean
}

export const useNeedLogo = (): INeedLogoHook => {
  const { organization, isLoading } = useOrganization()

  const isSETL = useMemo(
    () =>
      organization &&
      SETL_INN_ARRAY.includes(getInnOrKpp(organization.inn).inn),
    [organization],
  )

  return {
    withoutLogo: isSETL || isLoading,
  }
}
