export default {
  CKR_CANCEL: 'пользователь отказался от выполнения операции.',
  CKR_HOST_MEMORY: 'недостаточно памяти для выполнения функции.',
  CKR_SLOT_ID_INVALID: 'неправильный идентификатор слота.',
  CKR_GENERAL_ERROR:
    'Критическая ошибка, связанная с аппаратным обеспечением; Неудачная попытка проверки пути сертификации.',
  CKR_FUNCTION_FAILED: 'при выполнении функции возник сбой.',
  CKR_ARGUMENTS_BAD: 'недопустимый аргумент.',
  CKR_ATTRIBUTE_READ_ONLY:
    'предпринята попытка присвоения значения атрибуту, который нельзя изменять.',
  CKR_ATTRIBUTE_TYPE_INVALID: 'некорректный тип атрибута.',
  CKR_ATTRIBUTE_VALUE_INVALID: 'атрибут нулевой длины.',
  CKR_DEVICE_ERROR: 'ошибка при обращении к устройству или слоту.',
  CKR_DEVICE_MEMORY: 'для выполнения функции недостаточно памяти в устройстве.',
  CKR_FUNCTION_CANCELED: 'время ожидания выполнения функции истекло.',
  CKR_KEY_HANDLE_INVALID: 'функции передан некорректный дескриптор ключа.',
  CKR_KEY_SIZE_RANGE: 'недопустимый размер ключа.',
  CKR_KEY_TYPE_INCONSISTENT:
    'данный тип ключа не может использоваться с данным механизмом.',
  CKR_MECHANISM_INVALID:
    'при выполнении криптографической функции был указан неправильный механизм.',
  CKR_MECHANISM_PARAM_INVALID:
    'при выполнении криптографической функции были заданы некорректные параметры механизма.',
  CKR_OBJECT_HANDLE_INVALID: 'функции передан некорректный дескриптор объекта.',
  CKR_OPERATION_ACTIVE:
    'одна или несколько выполняющихся операций препятствуют выполнению новой операции.',
  CKR_OPERATION_NOT_INITIALIZED:
    'выполнение операции без предварительного указания параметров невозможно.',
  CKR_PIN_INCORRECT: 'функции передан неверный PIN-код.',
  CKR_PIN_LEN_RANGE: 'недопустимая длина PIN-кода.',
  CKR_PIN_LOCKED: 'PIN-код заблокирован.',
  CKR_SESSION_HANDLE_INVALID: 'функции передан некорректный дескриптор сессии.',
  CKR_SESSION_PARALLEL_NOT_SUPPORTED: 'невозможно открыть параллельную сессию.',
  CKR_SESSION_EXISTS: 'уже открыта сессия работы с тем же устройством.',
  CKR_SESSION_READ_ONLY_EXISTS:
    'сессия открыта только для чтения. Смена режима невозможна.',
  CKR_SESSION_READ_WRITE_SO_EXISTS:
    'открыта сессия чтения/записи. Открыть сеанс только для чтения невозможно.',
  CKR_SIGNATURE_INVALID: 'неправильное значение электронной подписи.',
  CKR_TEMPLATE_INCOMPLETE: 'для создания объекта недостаточно атрибутов.',
  CKR_TOKEN_NOT_PRESENT:
    'в момент выполнения функции устройство было отключено.',
  CKR_TOKEN_WRITE_PROTECTED: 'устройство недоступно для записи.',
  CKR_USER_ALREADY_LOGGED_IN: 'пользователь уже предъявил PIN-код.',
  CKR_USER_NOT_LOGGED_IN:
    'функция не может быть выполнена в гостевом режиме работы устройства.',
  CKR_USER_PIN_NOT_INITIALIZED: 'начальное значение PIN-кода не установлено.',
  CKR_USER_TYPE_INVALID:
    'функция не может быть выполнена в текущем режиме работы устройства.',
  CKR_USER_ANOTHER_ALREADY_LOGGED_IN:
    'невозможно переключение из режима администратора в режим пользователя или обратно.',
  CKR_BUFFER_TOO_SMALL:
    'размер заданного буфера является недостаточным для сохранения результатов функции.',
  CKR_INFORMATION_SENSITIVE: 'запрашиваемый объект недоступен для чтения.',
  CKR_CRYPTOKI_NOT_INITIALIZED:
    'выполнение функции без инициализации Единой библиотеки PKCS#11 (библиотеки Cryptoki) невозможно.',
  CKR_CRYPTOKI_ALREADY_INITIALIZED:
    'попытка повторно инициализировать библиотеку Cryptoki.',
  CKR_FUNCTION_REJECTED: 'пользователь отменил операцию.',
  NOT_STATE_TOKEN_BINDED:
    'значение параметра state отличается от STATE_TOKEN_BINDED (1).',
  NOT_STATE_NOT_BINDED:
    'значение параметра state отличается от STATE_TOKEN_BINDED (0).',
  IS_STATE_NOT_BINDED:
    'параметр state принимает значение STATE_TOKEN_BINDED (0).',
  NOT_STATE_UNILATERAL_AUTHENTICATION_IN_PROGRESS:
    'значение параметра state отличается от STATE_TOKEN_BINDED (4).',
  NOT_STATE_SECURE_CHANNEL_CONNECTION_IN_PROGRESS:
    'значение параметра state отличается от STATE_TOKEN_BINDED (2).',
  NOT_STATE_SECURE_CHANNEL_ESTABLISHED:
    'значение параметра state отличается от STATE_TOKEN_BINDED (3).',
  CERTIFICATE_NOT_FOUND: 'сертификат не обнаружен.',
  PUBLIC_KEY_NOT_FOUND:
    'открытый ключ не обнаружен в памяти поддерживаемого устройства.',
  SERVER_PUBLIC_KEY_NOT_FOUND: 'не найден открытый ключ сервера.',
  INVALID_SERVER_PUBLIC_KEY: 'неверный открытый ключ сервера.',
  DATA_TO_SIGN_ZERO_LENGTH:
    'на подпись были представлены данные, имеющие нулевую длину.',
  DATA_TO_VERIFY_ZERO_LENGTH:
    'на проверку подписи были представлены данные, имеющие нулевую длину.',
  SIGNATURE_TO_VERIFY_ZERO_LENGTH:
    'на проверку была представлена подпись, имеющая нулевую длину.',
  INVALID_EC_PARAMS:
    'неверно заданы параметры криптографических преобразований по ГОСТ Р 34.10-2001.',
  TOKEN_MEMORY_TOO_SMALL:
    'для выполнения операции в поддерживаемом устройстве недостаточно свободной памяти.',
  KEY_SIZE_NOT_64: 'длина ключа не равна 64 битам.',
  CERT_NOT_MATCH_PUBLIC_KEY: 'сертификат не соответствует открытому ключу.',
  FAILED_CHECK_BROWSER_VERS: 'ошибка проверки версии используемого браузера.',
  BROWSER_VERS_NOT_SUPPORTED: 'используемая версия браузера не поддерживается.',
  INVALID_USER_TYPE: 'неверно указан тип PIN-кода.',
  PINS_NOT_MATCH: 'введённые значения нового PIN-кода неодинаковы.',
  USER_CHANGE_ADMIN_PIN_NOT_STATE_NOT_BINDED:
    'cмена PIN-кода администратора невозможна, поскольку значение параметра state отличается от STATE_TOKEN_BINDED (0).',
  SSL_ERR_GEN_SESSION_KEY:
    'ошибка при генерации ключа защиты данных, передаваемых между клиентом и сервером.',
  SSL_ERR_GOST_ENGINE:
    'ошибка средства защиты, программно выполняющего криптографические преобразования на стороне клиента.',
  SSL_ERR_SSL_NEW: 'ошибка в начале выполнения протокола Handshake.',
  SSL_ERR_CONNECT: 'ошибка при выполнении протокола Handshake.',
  SSL_ERR_SSL_WRITE: 'ошибка при подготовке данных к защищённой передаче.',
  SSL_ERR_SSL_READ: 'ошибка при чтении защищённых для передачи данных.',
  SSL_ERR_LOAD_CERT_AND_KEY_FROM_TOKEN:
    'ошибка при попытке считать сертификат и открытый ключ из памяти поддерживаемого устройства.',
  SSL_ERR_GET_PEER_PUBLIC_KEY: 'ошибка при получении открытого ключа сервера.',
  CERT_PARS_ERR_GET_PUBLIC_KEY:
    'не удалось считать открытый ключ из сертификата.',
  CERT_PARS_ERR_GET_ISSUER_AND_SER_NUMBER:
    'не удалось считать имя удостоверяющего центра и серийный номер из сертификата.',
  UNKNOWN_ERROR: 'неизвестная ошибка.',
}
