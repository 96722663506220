/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useEffect, useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  IconButton,
  Collapse,
  Stack,
  Typography,
  Select,
  Box,
  FormControl,
  InputLabel,
  Link,
  Button,
  List,
  ListItemText,
} from '@mui/material'

import { CryptoproCertificate, CertificateInList } from '../cryptopro/types'

type Props = {
  /**
   * Скрыть кнопку подписи
   */
  disableSignAction?: boolean
  /**
   * Сообщение для алёрта извне
   */
  infoMessage?: string
  certs: CertificateInList[]
  disabled: boolean
  loading: boolean
  selectedCertificate: CryptoproCertificate | undefined
  getCertInfo: (value: string) => void
  onClick: () => void
  dismissError: () => void
  updateCertsList: () => void
  actionTitle?: string
  error?: string
}

export function CertificateBlock({
  disableSignAction,
  infoMessage,
  actionTitle,
  error,
  certs,
  disabled,
  loading,
  selectedCertificate,
  getCertInfo,
  dismissError,
  updateCertsList,
  onClick,
}: Props) {
  const hasCertificate = useMemo(() => !!certs.length, [certs])
  const [cert, setCert] = useState([selectedCertificate?.Thumbprint])

  useEffect(
    () => setCert([selectedCertificate?.Thumbprint]),
    [selectedCertificate],
  )

  return (
    <Stack alignItems="stretch" spacing={2}>
      <Box width="100%">
        {hasCertificate ? (
          <Stack spacing={1}>
            <Alert severity="info">
              {infoMessage ??
                'Рекомендуем использовать защищённый носитель рутокен'}
            </Alert>
            {!disableSignAction && (
              <Typography variant="h6">Выберите свой сертификат</Typography>
            )}
          </Stack>
        ) : (
          <Alert
            action={
              <Button size="small" onClick={() => updateCertsList()}>
                Повторить
              </Button>
            }
            severity="warning"
          >
            Не удалось найти сертификаты ЭЦП
          </Alert>
        )}
        <Collapse in={!!error}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={dismissError}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            severity="warning"
            sx={{ mt: 1 }}
          >
            <AlertTitle>КриптоПро</AlertTitle>
            {error}
          </Alert>
        </Collapse>
      </Box>

      {hasCertificate ? (
        <Stack spacing={1} width="100%">
          <FormControl>
            <InputLabel shrink htmlFor="select-multiple-native">
              Выберите сертификат
            </InputLabel>
            <Select
              multiple
              native
              inputProps={{
                id: 'select-multiple-native',
              }}
              label="Выберите сертификат"
              name="certs"
              value={cert}
              onChange={e => {
                getCertInfo(e.target.value as string)
                setCert([e.target.value as string])
              }}
            >
              {certs.map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <Box>
            <Button onClick={() => updateCertsList()}>Обновить список</Button>
          </Box>
        </Stack>
      ) : (
        <Stack>
          <Typography>Убедитесь в следующем:</Typography>
          <List>
            <ListItemText>
              1. Настроено ли рабочее место
              <Link
                href="Настройка ЭЦП.pdf"
                ml={1}
                target="_blank"
                type="application/pdf"
              >
                Инструкция
              </Link>
            </ListItemText>
            <ListItemText>
              2. Установлен ли сертификат на компьютер
            </ListItemText>
          </List>
          <Typography color="text.secondary">
            Разрешите браузеру операции с сертификатами
          </Typography>
        </Stack>
      )}

      {!disableSignAction && (
        <div>
          <LoadingButton
            disabled={disabled}
            id="face-control-dialog-button-action"
            loading={loading}
            variant="contained"
            onClick={onClick}
          >
            {actionTitle}
          </LoadingButton>
        </div>
      )}
    </Stack>
  )
}
