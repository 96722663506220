/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import React, { FC } from 'react'

import { User } from '@builddocs/data-management'
import { useDisclosure, useToast } from '@builddocs/system'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material'

import FindEmployeeDialogContent from './FindEmployeeDialogContent'

type Props = {
  onSelect: (employee: User | undefined) => void
  inn?: string
}

export const FindEmployeeDialog: FC<Props> = ({ onSelect, inn }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { toast } = useToast()

  const [employee, setEmployee] = React.useState<User>()
  const [valueSearch, setValueSearch] = React.useState('')

  const handleClose = () => {
    onClose()
    setEmployee(undefined)
    setValueSearch('')
  }

  const handleAdd = () => {
    onClose()
    onSelect(employee)
    setEmployee(undefined)
    setValueSearch('')
  }

  const handleOpen = () => {
    inn ? onOpen() : toast.error.t('messages.firstAddOrganization')
  }

  return (
    <>
      <Tooltip title="Представитель должен быть устроен в выбранную организацию">
        <Button
          id="find-employee-dialog-button-open-modal"
          startIcon={<AddOutlinedIcon />}
          variant="outlined"
          onClick={handleOpen}
        >
          Добавить представителя
        </Button>
      </Tooltip>
      <Dialog
        id="project-find-employee-dialog-modal"
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>Поиск представителя</DialogTitle>
        <DialogContent>
          <FindEmployeeDialogContent
            employee={employee}
            inn={inn}
            valueSearch={valueSearch}
            onChange={value => setValueSearch(value)}
            onFind={setEmployee}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button
            disabled={!employee}
            id="add-representative"
            variant="contained"
            onClick={handleAdd}
          >
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FindEmployeeDialog
