import React from 'react'

import type { IDraftStoreHook } from './useDraftStore'

interface IDraftContext<T = any> {
  store: IDraftStoreHook<T>
  clone: (record: T) => void
  /** Признак,что черновики есть */
  draftsExists: boolean
  /** Признак,что черновики надо показать или скрыть */
  areDraftsShown: boolean
  /** Переключатель показывания черновиков */
  toggleDraftsShown: (forceValue?: boolean) => void
}
// Контекста дженерик как сделать неведомо мне...
export const DraftContext = React.createContext<Partial<IDraftContext>>({})
