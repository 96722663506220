/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export const DADATA_API_KEY = process.env.NX_DADATA_API_KEY ?? ''

console.log(DADATA_API_KEY)
