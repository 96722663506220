/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useQuery, useQueryClient } from 'react-query'

import { getOneProject } from './projects.service'

import type { Project } from './project.types'

interface IProjectHook {
  isLoading: boolean
  project: Project | undefined
  error?: unknown
  isError?: boolean
  refreshProject: () => void
}

export const useProject = (projectId: string): IProjectHook => {
  const queryClient = useQueryClient()

  const {
    data: project,
    isLoading,
    isError,
    error,
  } = useQuery<Project>(['project', projectId], async () =>
    projectId ? await getOneProject(projectId) : undefined,
  )

  const refreshProject = () =>
    queryClient.invalidateQueries(['project', projectId])

  return {
    project,
    isLoading,
    error,
    isError,
    refreshProject,
  }
}

export default useProject
