import { FC } from 'react'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { Scrollbars } from 'react-custom-scrollbars'

import { NavContentProps } from './drawer.type'

export type NavDocumentsProps = {
  //Компонент, передаваемый в качестве содержимого для левого меню
  navContent: React.ReactElement<NavContentProps>
  //Признак открытости левого меню
  isOpen: boolean
  //Заголовок левого меню
  navTitle: string
  //Ширина левого меню. По умолчанию 350
  drawerWidth: number
  //Закрывает левое меню
  setIsOpenHandler: () => void
}

//Левое меню TwoPdfViewer
export const NavDocuments: FC<NavDocumentsProps> = (
  props: NavDocumentsProps,
) => {
  const { navContent, isOpen, navTitle, drawerWidth, setIsOpenHandler } = props

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      sx={{
        width: drawerWidth,
        '& .MuiPaper-root': {
          position: 'unset',
        },
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
    >
      <Scrollbars autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Stack
          direction="row"
          justifyContent="space-between"
          left={0}
          pb={2}
          position="sticky"
          pt={0}
          px={2}
          sx={{
            background:
              'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0.83) 80%, rgba(255,255,255,0) 100%)',
            zIndex: 999,
          }}
          top={0}
        >
          <Typography fontWeight="bold" py={1} variant="h6">
            {navTitle}
          </Typography>
          <Box pt={1}>
            <IconButton
              color="info"
              id="documents-drawer-button-open-modal"
              onClick={setIsOpenHandler}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
        </Stack>
        {navContent}
      </Scrollbars>
    </Drawer>
  )
}
