import { useCallback, useState } from 'react'

import { ExecutiveScheme, getDocumentById } from '@builddocs/filestore-api'
import { useSetRecoilState } from 'recoil'

import { applicationExternal } from './external.container'
import { ExternalSigningApplication } from './ExternalSigning.type'

type UseApplicationExternal = {
  loadApp: (externalSigningValue: ExternalSigningApplication) => void
  isAppLoading: boolean
  isAppFail: boolean
}

export const useApplicationExternal = (): UseApplicationExternal => {
  const [isAppLoading, setIsAppLoading] = useState(false)
  const [isAppFail, setIsAppFail] = useState(false)
  const setAppExternal = useSetRecoilState(applicationExternal)

  const loadApp = useCallback(
    async (externalSigningValue: ExternalSigningApplication) => {
      let apploaded: ExecutiveScheme | undefined

      setIsAppLoading(true)

      try {
        apploaded = (await getDocumentById({
          documentType: externalSigningValue.signType,
          documentId: externalSigningValue.documentId,
        })) as ExecutiveScheme
      } catch {
        setIsAppLoading(false)
        setIsAppFail(true)
      }

      setIsAppLoading(false)

      apploaded && setAppExternal(apploaded)
    },
    [],
  )

  return {
    loadApp,
    isAppLoading,
    isAppFail,
  }
}
