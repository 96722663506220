import { useEffect, useState } from 'react'

import {
  applicationExternal,
  ExternalSigningApplication,
  tasksExternal,
} from '@builddocs/ext-sign-data-access'
import {
  useApplicationExternal,
  useExtTasksOfDocument,
} from '@builddocs/ext-sign-data-access'
import { DocumentSigningTask, FILE_STORE_BASE } from '@builddocs/filestore-api'
import { PDFObject } from '@builddocs/shared/feature-pdf-viewer'
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined'
import {
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Box,
} from '@mui/material'
import { useRecoilValue } from 'recoil'

import { ErrorContainer } from './ErrorContainer'
import TaskForSigning from './TaskForSigning'

type OwnProps = {
  externalSigningValue: ExternalSigningApplication
  onLoad: (state: boolean) => void
}

const ExtSignApplication = ({ externalSigningValue, onLoad }: OwnProps) => {
  const { loadApp, isAppLoading, isAppFail } = useApplicationExternal()
  const { loadTasks, isTasksLoading, isTasksFail } = useExtTasksOfDocument()
  const refreshTasks = () => {
    loadTasks(externalSigningValue.documentId)
  }
  const application = useRecoilValue(applicationExternal)
  const documentURL = `${FILE_STORE_BASE}${application?.documentUrl}`

  useEffect(() => {
    if (externalSigningValue.documentId) {
      loadTasks(externalSigningValue.documentId)
    }
  }, [externalSigningValue, loadTasks])
  const allTasks = useRecoilValue(tasksExternal)
  const [tasks, setTasks] = useState<DocumentSigningTask[]>([])

  useEffect(() => {
    if (allTasks) {
      setTasks(
        externalSigningValue.taskId
          ? allTasks.filter(task => task.id === externalSigningValue.taskId)
          : allTasks.filter(task =>
              task.stampSigns.some(
                user =>
                  user.userId === externalSigningValue.externalUser.userId,
              ),
            ),
      )
    }
  }, [allTasks])

  useEffect(() => {
    onLoad(isAppLoading)
  }, [isAppLoading])

  /** после получения подписания получаем акт */
  useEffect(() => {
    if (externalSigningValue) {
      loadApp(externalSigningValue)
    }
  }, [externalSigningValue, loadApp])

  return (
    <Box flexGrow={1} overflow="auto">
      <Stack direction="row" height="100%" px={1} spacing={1}>
        {documentURL && (
          <PDFObject
            containerId="application-pdf"
            containerProps={{
              style: { width: '50%' },
            }}
            pdfOpenParams={{
              view: 'FitH,top',
              page: 1,
              zoom: 'scale',
              nameddest: '',
              pagemode: 'none',
            }}
            url={documentURL + '?showDeleted=true'}
          />
        )}
        {isAppFail && (
          <ErrorContainer massage="Не удалось загрузить документ" />
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          px={2}
          spacing={1}
          width="50%"
        >
          {isTasksLoading ? (
            <CircularProgress />
          ) : (
            !isTasksFail && (
              <Stack pt={4} spacing={2}>
                {tasks.map(task => (
                  <TaskForSigning
                    key={task.id}
                    externalSigningValue={externalSigningValue}
                    refreshTasks={refreshTasks}
                    task={task}
                  />
                ))}
              </Stack>
            )
          )}
          {isTasksFail && (
            <Typography>Не удалось загрузить задачи на подпись</Typography>
          )}
          <Stack>
            <Tooltip title="Обновить задачи">
              <IconButton aria-label="Update-tasks" onClick={refreshTasks}>
                <LoopOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

export default ExtSignApplication
