/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { RestAPI } from '@builddocs/data-management'

type ExtSignSend =
  | {
      signType: 'ACT'
      actId: string
      actType: string

      senderUserId: string
      externalUserId: string
      journalDescription?: string
      externalUserEmail: string
      journalId: string
    }
  | {
      signType: 'EXECUTIVE_SCHEME'
      documentId: string
      taskId: string
      senderUserId: string
      externalUserId: string
      externalUserEmail: string
    }

export const extSignSend = (data: ExtSignSend) =>
  RestAPI.post('/external-signing', data).then(res => res.data)
