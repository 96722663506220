/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import {
  RecoilState,
  RecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil'

interface IHook<T> {
  isLoading: boolean
  data?: T | undefined
  refresh: () => void
  error?: Error
}

export const useSelector = <T>(
  query: RecoilValue<T>,
  queryRequestId: RecoilState<number>,
): IHook<T> => {
  const dataLoadable = useRecoilValueLoadable(query)
  const setRequestID = useSetRecoilState(queryRequestId)

  const refresh = () => setRequestID(requestID => requestID + 1)

  switch (dataLoadable.state) {
    case 'hasValue':
      return {
        data: dataLoadable.contents,
        isLoading: false,
        error: undefined,
        refresh,
      }
    case 'loading':
      return {
        data: undefined,
        isLoading: true,
        error: undefined,
        refresh,
      }
    case 'hasError':
      return {
        data: undefined,
        isLoading: false,
        error: dataLoadable.contents,
        refresh,
      }
  }
}
