import jwt_decode from 'jwt-decode'

/**
 * Метод верификации токена.
 * По-хорошему он должен быть реализован на бэке.
 * @param {string} token
 * @returns {boolean}
 */
export const verifyToken = (token: string | undefined | null) => {
  if (!token) return false

  const { exp } = jwt_decode<{ exp: number }>(token)

  return Date.now() < exp * 1000
}
