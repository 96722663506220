export interface ActKsWork {
  /**
   * Код работы
   */
  code: string
  /**
   * Наименование работы
   */
  name: string
}

export interface ActKsFile {
  /**
   * Идентификатор файла
   */
  id: string
  /**
   * Наименование файла
   */
  name: string
}
export interface ActItem {
  /** Тип сущности 1-СП; 2-ГП
   */
  type: number
  /**
   * Идентификатор работы в рамках Акта
   */
  id: number
  /**
   * Идентификатор договора
   */
  contractId: string
  /**
   * Номер акта в рамках договора
   */
  number: number
  /**
   * Дата составления
   */
  dtAct: Date
  /**
   * Дата начала периода
   */
  periodFrom: Date
  /**
   * Дата окончания периода
   */
  periodTo: Date
  /**
   * Стоимость акта
   */
  sum: string
  /**
   * Статус
   */
  statusName: string
  /**
   * Дата создания
   */
  dtCreate: string
  /**
   * Работы акта
   */
  dtUpdate: Date

  works: ActKsWork[]
  files: ActKsFile[]
}

export interface ResponseKSType {
  count: number
  totalCount: number
  items: ActItem[]
}

export enum ActKsEnum {
  KS2 = 'KS2',
  KS3 = 'KS3',
}

export const KSName = {
  [ActKsEnum.KS2]: 'КС2',
  [ActKsEnum.KS3]: 'КС3',
}

export type ActKsInfo = {
  id: number
  type: number
  ksName: ActKsEnum
}
