import { atom } from 'recoil'
// import '@types/wicg-file-system-access'

export type Status = 'NOT_SENT' | 'SENDING' | 'SUCCESS' | 'ERROR' | 'NO_FILE'

export type ListError =
  | 'FIELDS_INCOMPLETE'
  | 'NO_FILE'
  | 'UNKNOWN_APPLICATION'
  | 'SIZE_LIMIT'

export type PreparedListItem = {
  fileName: string
  fileHandle: any | undefined
  dirHandle: any
  sendStatus: Status
  errors: ListError[]
  endPoint: string | undefined
  metaData: Record<string, string | any>
  projectId: string
  id: string
  dirHandlerId: string
  userId: string
  applicationName: string
}

export const bulkFilesQueue = atom<PreparedListItem[]>({
  key: 'bulkFilesQueue',
  default: [],
})
