import { createContext, useContext } from 'react'

import { SendSetActsResponse } from './exonTypes'

export type ExDocContextType = {
  reportSetActsExon: SendSetActsResponse | null
  setReportSetActsExon: (v: SendSetActsResponse) => void
  isClosingCreator: boolean
}

export const ExDocContext = createContext<ExDocContextType>({
  reportSetActsExon: null,
  setReportSetActsExon: () => ({}),
  isClosingCreator: false,
})

export const useExDocContext = () => useContext(ExDocContext)
